import React, { Component } from 'react';
const UpdateWareHouseChargesBtn = (props) => {
    let parent = null;
    if (props.context && props.context.componentParent) {
        parent = props.context.componentParent;
    } else if (
        props.agGridReact &&
        props.agGridReact.gridOptions &&
        props.agGridReact.gridOptions.context &&
        props.agGridReact.gridOptions.context.componentParent
    ) {
        parent = props.agGridReact.gridOptions.context.componentParent;
    }

    const handleClick = (e) => {
        e.stopPropagation();
        if (parent && parent.onClickWareHouseChargesUpdateBtn) {
            parent.onClickWareHouseChargesUpdateBtn(props); // Call parent method
        } else {
            console.error(
                "Error: 'componentParent' or 'onClickWareHouseChargesUpdateBtn' is not defined."
            );
        }
    };
    return (
        <div>
            {(props.data.invoice_no !== "Total") &&
                <button type="button"
                    className={"btn btn-warning nopadding font12"}
                    title="ViewRequest"
                    onClick={handleClick} >
                    <i className="icofont icofont-edit f16"></i>&nbsp;Update Charges
                </button>
            }
        </div>
    );
};
export default UpdateWareHouseChargesBtn;