
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Datetime from 'react-datetime';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import 'react-datetime/css/react-datetime.css';
import redirectURL from '../redirectURL';

// import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import ExportIcon from "../../assets/icons/export-arrow.svg";
import ImportIcon from "../../assets/icons/import.svg";
import Filter from '../../assets/icons/SEARCH.png'
import CountdownTimer from './runningtime.js';

//  import io from 'socket.io-client';
import { getHyphenYYYYMMDDHHMM } from '../common/utils';
// import { FaEdit, FaTrash, FaEye, FaTrashAlt, FaArrowRight, FaCheck, FaPen, FaBell, FaRegBell } from 'react-icons/fa';

const unitsOptions = [
    { value: "truck", label: "FTL" },
    { value: "metric_tons", label: "PTL" },
    { value: "kilogram", label: "Courier" },
    { value: "box", label: "FTL/PTL" }]

var moment = require('moment');


var sourcearray = [
    "Mumbai", "Delhi", "Bangalore", "Hyderabad", "Ahmedabad",
    "Chennai", "Kolkata", "Pune", "Jaipur", "Lucknow",
    "Kanpur", "Nagpur", "Indore", "Thane", "Bhopal",
    "Visakhapatnam", "Pimpri-Chinchwad", "Patna", "Vadodara", "Ghaziabad",
    "Ludhiana", "Agra", "Nashik", "Faridabad", "Meerut",
    "Rajkot", "Kalyan-Dombivli", "Vasai-Virar", "Varanasi", "Srinagar",
    "Aurangabad", "Dhanbad", "Amritsar", "Navi Mumbai", "Allahabad",
    "Ranchi", "Howrah", "Coimbatore", "Jabalpur", "Gwalior",
    "Vijayawada", "Jodhpur", "Madurai", "Raipur", "Kota",
    "Guwahati", "Chandigarh", "Solapur", "Hubli-Dharwad", "Bareilly",
    "Moradabad", "Mysore", "Gurgaon", "Aligarh", "Jalandhar",
    "Tiruchirappalli", "Bhubaneswar", "Salem", "Mira-Bhayandar", "Thiruvananthapuram",
    "Bhiwandi", "Saharanpur", "Gorakhpur", "Guntur", "Amravati",
    "Bikaner", "Noida", "Jamshedpur", "Bhilai Nagar", "Warangal",
    "Cuttack", "Firozabad", "Kochi", "Bhavnagar", "Dehradun",
    "Durgapur", "Asansol", "Nanded-Waghala", "Kolhapur", "Ajmer",
    "Gulbarga", "Jamnagar", "Ujjain", "Loni", "Siliguri",
    "Jhansi", "Ulhasnagar", "Nellore", "Jammu", "Sangli-Miraj & Kupwad",
    "Belgaum", "Mangalore", "Ambattur", "Tirunelveli", "Malegaon",
    "Gaya", "Jalgaon", "Udaipur", "Maheshtala"
]

const customSelectStyles = {
    option: (provided) => ({
        ...provided,
        fontSize: '14px',
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '14px',
    }),
};

const unitsOptions1 = [
    { value: "others", label: "Others" },
    { value: "truck", label: "Truck(FTL)" },
    { value: "metric_tons", label: "Metric Tonnes(PTL)" },
    { value: "kilogram", label: "Kilogram(Courier)" },
    { value: "box", label: "Box(FTL/PTL)" }
]

const getOptionsLabel = (options, value) => {
    for (let e of options) {
        if (e.value == value) return e.label
    }
}

const IndentAuctionComponentforadmin = () => {
    // const { event_id } = useParams()
    const [open, setopen] = useState(false)
    const [openchat, setopenchat] = useState(false)
    const [open1, setopen1] = useState(false)
    const [selectedrowfornegotiate, setselectedrowfornegotiate] = useState({})
    const [openforadmin, setopenforadmin] = useState(false)
    const [baseprice, setbaseprice] = useState(0)
    const [selectedindent, setselectedindent] = useState({})
    const [draftdata, setdraftdata] = useState([])
    const [uploadpaymentterms, setuploadpaymentterms] = useState('')
    const [loadshow, setLoadshow] = useState("show-n")
    const [overlay, setOverlay] = useState("show-n")
    const [isToggled, setIsToggled] = useState(0);  // 0 for off, 1 for on
    const [show, setShow] = useState(false)
    const [hoveredIconId, setHoveredIconId] = useState(null);
    const [hidebidnowbtn, sethidebidnowbtn] = useState(false)
    var [filterToggle, setFilterToggle] = useState(false)
    const [basicType, setBasicType] = useState("")
    const [basicTitle, setBasicTitle] = useState("")
    const [newBidRate, setNewBidRate] = useState('');
    const [newBidQuantity, setNewBidQuantity] = useState('');
    const [activeDateBtn, setactiveDateBtn] = useState('')
    var filterCardStyle1 = { minHeight: "234px" };
    const [showTooltip, setShowTooltip] = useState(false);
    const [hoveredIcon, setHoveredIcon] = useState(null)
    var filterCardStyle2 = { padding: '20px' };
    var mnFilterClass = { marginLeft: "0px", padding: "0px" };
    const [negotiatedetails, setnegotiatedetails] = useState([])
    const [rfqform, setrfqform] = useState({
        active: 'activet1',
        awarded: '',
        drafts: '',
        cancelled: '',
        myactivity: '',
        archieved: '',
        upcoming: '',
        selectedindentid: '',
        completed: '',
        current_bidding: 'activet',
        bidding_history: '' // Optionally, clear other tabs

    })
    const [indentdetails, setindentdetails] = useState({
        indent_id: '',
        bid_start_date: '',
        end_date: '',
        source: '',
        destination: '', distance: '',
        truck_type: '',
        material: '',
        quantity: 0,
        base_price: 0,
        quote: 0
    })
    const [biddetails, setbiddetails] = useState([])
    const [allindents, setallindents] = useState([])
    const [bulkUploadModal, setBulkUploadModal] = useState({
        overly: "show-n",
        bulkPrioritySlide: ""
    })
    const [modalloadData, setmodalloadData] = useState([])
    const [filterdata, setfiltereddata] = useState([])
    const [originalindents, setoriginalindents] = useState([])
    const [rankingdetails, setrankingdetails] = useState([])
    const [selected_bid_price, setselected_bid_price] = useState('')
    const [negotiate_value, setnegotiate_value] = useState('')

    const [selected_transit_days, setselected_transit_days] = useState('')
    const [transit_time, settransit_time] = useState('')

    const [bidsdata, setbidsdata] = useState([])
    const [rankedBids, setRankedBids] = useState([]);
    const [searchfilter, setsearchfilter] = useState({})
    const [isCardOpen, setIscardOpen] = useState("")
    const [isCardOpen1, setIscardOpen1] = useState("")
    const [cardforadminbifurcation, setcardforadminbifurcation] = useState('')

    const [dataforadminbifur, setdataforadminbifur] = useState([])

    const [isCardOpen2, setIscardOpen2] = useState("")
    const [isCardOpen3, setisCardOpen3] = useState('')
    const [indent_for_approve, setindent_for_approve] = useState([]);

    const [indent_for_approve_for_bid, setindent_for_approve_for_bid] = useState([]);
    const [indent_for_approve_for_bid1, setindent_for_approve_for_bid1] = useState([]);
const [indentfortruckdetails,setindentfortruckdetails] = useState([])
    const [indent_for_approve1, setindent_for_approve1] = useState([]);
    const [lr_no_data, setlr_no_data] = useState([]);

    const [selecteddata, setselecteddata] = useState({})
    const [showAllRecords, setShowAllRecords] = useState(false);

    const [bidHistory, setBidHistory] = useState([])
    const [inputTextValue, setInputTextValue] = useState("")
    const [inputTextQuantity, setInputTextQuantity] = useState("")
    const [negotiations, setNegotiations] = useState([])
    const [negotiationsHistory, setNegotiationsHistory] = useState([])
    const [isProposedInputOpen, setIsProposedInputOpen] = useState("")
    const [isVendorInputOpen, setIsVendorInputOpen] = useState("")
    const [additionalComments, setAdditionalComments] = useState("")
    const [modelForAdditionalComments, setModelForAdditionalComments] = useState(false)
    const [vendorQuotes, setVendorQoutes] = useState([])
    const [modelForShowBids, setModelForShowBids] = useState(false)
    const [modelForNotifications, setModelForNotifications] = useState(false)
    const [notificationUnread, setNotificationUnread] = useState([])
    const [notificationRead, setNotificationRead] = useState([])
    const [modelForEditAssigned, setModelForEditAssigned] = useState(false)
    const [rejectedVendorBid, setRejectedVendorBid] = useState("")
    const [rejectedVendorIndent, setRejectedVendorIndent] = useState("")
    const [rejectionReason, setRejectionReason] = useState("")
    const [isPreviewMode, setIsPreviewMode] = useState(false)
    const [rfqForm, setRfqForm] = useState({})
    const [dealers, setDealers] = useState([
        {
            dealer_code: "",
            dealer_name: "",
            dealer_city: "",
            dealer_state: "",
            dealer_pincode: "",
            materials: [
                { material_type: "", material_length: 0, material_breath: 0, material_height: 0, material_units: "cm", quantity: 0, basic_requirment: '' },
            ],
        },
    ]);

    const [timeLeft, setTimeLeft] = useState(null);
    const [text, setText] = useState('');
    var [destinationdetails, setdestinationdetails] = useState([])

    const calculateTimeLeft = (expirationTime) => {
        const now = moment();
        const difference = moment(expirationTime).diff(now);

        if (difference > 0) {
            return {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            return null;
        }
    };
    // useEffect(() => {
    //     const timer = setInterval(() => {
    //       allindents.forEach((indent, index) => {
    //         const expirationTime = moment
    //           .parseZone(indent.bid_start_date)
    //           .utcOffset("-05:30")
    //           .add(parseInt(indent.quotation_validity_hours), 'hours')
    //           .toISOString();

    //         const newTimeLeft = calculateTimeLeft(expirationTime);

    //         setTimeLeft(newTimeLeft);

    //         if (!newTimeLeft) {
    //           setText('End');
    //         } else {
    //           setText(`${newTimeLeft.hours}h ${newTimeLeft.minutes}m ${newTimeLeft.seconds}s remaining`);
    //         }
    //       });
    //     }, 1000);

    //     return () => clearInterval(timer);
    //   }, [allindents]);  
    useEffect(() => {
        const fetchData = (active) => {
            fetchInitialData()
            getdraftdata()
        };
        fetchData();

    }, []);
    // const rejectindent = ()=>{
    //     var params = {
    //         user_type: localStorage.getItem("user_type"),
    //         email: localStorage.getItem("email")
    //     }
    //     redirectURL.post("/consignments/fetchRFQsForDashboard", params).then(response => {
    //         var data = response.data;
    //         setoriginalindents(data);
    //     })

    // }

    const getdraftdata = () => {

    }
    const fetchInitialData = () => {

        setLoadshow("show-m")


        var params = {
            user_type: localStorage.getItem("user_type"),
            email: localStorage.getItem("email")
        }
        redirectURL.post("/consignments/fetchRFQsForDashboard", params).then(response => {
            var record = response.data
            var data = record.filter(e => e.is_draft != 1)
            var records = data
            var draftData = record.filter(e => e.is_draft == 1)
            setdraftdata(draftData)
            setoriginalindents(data);
            var current_date = new Date();
            let filterData = [];
            console.log(rfqform, '219')
            if (rfqform.cancelled === 'activet1') {
                console.log('s1')
                filterData = data.filter(e =>
                    current_date > moment.parseZone(new Date(e.bid_start_date)).utcOffset("-05:30").add(parseInt(e.quotation_validity_hours), 'hours')._d &&
                    e.vendordetails && e.vendordetails.length === 0
                );
            } else if (rfqform.active === 'activet1') {
                console.log('s2')
                filterData = data.filter(e =>
                    (e.vendordetails && e.vendordetails.length > 0) || current_date < moment.parseZone(new Date(e.bid_start_date)).utcOffset("-05:30").add(parseInt(e.quotation_validity_hours), 'hours')._d
                );
                filterData = filterData.filter(e => e.indent_assigned != 1 && e.validity_end != 1 && e.rejected != 1 && e.rejected != 1)
            }
            setallindents(filterData)
            setLoadshow("show-n")
            var rejecteddata
            records = records.filter(e => e.indent_assigned == 1)
            if (records != '' && records != undefined) {
                records.map((e) => {
                    if (e.indent_assigned_date) {
                        var indent_assigned_date = getHyphenYYYYMMDDHHMM(indent_assigned_date)
                        const givenDate = new Date(indent_assigned_date);
                        const currentDate = new Date();
                        const timeDifference = currentDate - givenDate;
                        const hoursDifference = timeDifference / (1000 * 60 * 60);
                        if (hoursDifference > 6) {
                            if (e.lr_no_updated != '' && e.lr_no_updated != undefined) {
                                var params = { 'indent_id': e.indent_id }
                                redirectURL.post('/consignments/rejectvendor', params).then((resp) => {

                                })
                            }
                        }
                    }
                })
            }

        })

    }

    // const socket = React.useRef(null);

    // useEffect(() => {
    //     socket.current = io('http://34.131.191.3:5006', {
    //         transports: ['websocket'], // Ensure WebSocket transport is used
    //         reconnectionAttempts: 5,    // Retry connecting a few times
    //         reconnectionDelay: 1000,    // Optional: delay between retries
    //     });

    //     // Listen for connect event
    //     socket.current.on('connect', () => {
    //         console.log('Connected to the server');
    //     });

    //     // Listen for connection errors
    //     socket.current.on('connect_error', (error) => {
    //         console.error('Connection Error:', error);
    //     });

    //     // Listen for new bid updates
    //     socket.current.on('newBid', (data) => {
    //         console.log(selectedindent, "test_190")
    //         fetchInitialData()
    //         if (selectedindent.indent_id) {
    //             getbidhistory(selectedindent.indent_id);
    //         }
    //     });

    //     // Cleanup function to disconnect the socket when the component is unmounted
    //     return () => {
    //         socket.current.disconnect();
    //         socket.current.off('newBid');
    //     };
    // }, [selectedindent]);

    const resetindent = () => {
        fetchInitialData()
        if (selectedindent.indent_id) {
            getbidhistory(selectedindent.indent_id);
        }
    }



    const getbidhistory = async (indent_id) => {
        console.log(indent_id)
        await redirectURL.post("/consignments/getBidHistory", { indent_id }).then(response => {
            setBidHistory(response.data.bid_history)
            setNegotiations(response.data.negotiations)
            setNegotiationsHistory(response.data.negotiationslogs)
        })
    }

    const onClickBidNow = async (e) => {

        setIscardOpen(prev => {
            if (prev == e.indent_id) {
                setselectedindent({})
                return ""
            } else {
                getbidhistory(e.indent_id)
                setselectedindent(e)

                return e.indent_id

            }
        })
        var data = originalindents.filter(f => f.indent_id == e.indent_id)
        console.log(data, '371')
        console.log(data[0].destination, 'data[0].destination')
        var destinations = []
        var data1 = data[0].destination
        console.log(data1, 'data1')
        data1 = JSON.parse(data1);
        console.log(data1, 'data1')
        data1.map((g) => {
            destinations.push({
                "indent_id": e.indent_id,
                "dealer_city": g.dealer_city,
                'dealer_pincode': g.dealer_pincode,
                "dealer_code": g.dealer_code,
                //    "materials": g.materials.map(material => ({
                //     "material_type": material.material_type,
                //     "material_dimensions": `${material.material_length} x ${material.material_breath} x ${material.material_height}`,
                //     "quantity": material.quantity
                // })),
                "quantity": g.quantity,

            })
        })
        var params = { "indent_id": e.indent_id, 'vendor_email_id': localStorage.getItem('email') }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var transit_data = resp.data; // Response data

            transit_data.forEach((transitRecord) => {
                // Find matching record in destinations
                const destinationRecord = destinations.find(
                    (dest) =>
                        dest.indent_id === transitRecord.indent_id &&
                        dest.dealer_city === transitRecord.dealer_city
                );
                if (destinationRecord) {
                    // Add bid_price from transit_data to destinations
                    destinationRecord.bid_price = transitRecord.bid_price;
                    destinationRecord.transit_days = transitRecord.transit_days;
                }
            });

            console.log(destinations); // Updated destinations array
        });

        setindent_for_approve_for_bid(destinations)
        setindent_for_approve_for_bid1(destinations)
        console.log(destinations, 'destinations382')
        setInputTextValue("")
        setInputTextQuantity("")
        if (e.indent_assigned == 1) {
            onShowdata(e.indent_id)
        }
        var params = { 'indent_id': e.indent_id }
        redirectURL.post('/consignments/getlr_data', params).then((resp) => {
            var data = resp.data
            console.log('s1')
            if (data != '' && data != undefined) {
                if (localStorage.getItem('roles') == 'TRANSPORTER') {
                    // data = data.filter(f => f.vendor_email_id == localStorage.getItem('email'))
                    setlr_no_data(data)
                }
                else {
                    setlr_no_data(data)
                }
            }
        })
        var params = {
            indent_id: e.indent_id,
            vendor_email_id: localStorage.getItem('email')
        }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var data1 = resp.data
            var destinations = []
            data1.map((f) => {
                destinations.push({
                    "indent_id": e.indent_id,
                    "dealer_city": f.dealer_city,
                    'dealer_pincode': f.dealer_pincode,
                    "truck_no": f.truck_no,

                    "transit_days": f.transit_days,
                    'material': f.material,
                })
            })
            console.log(destinations, '432')
            setindent_for_approve1(destinations)
            setindentfortruckdetails(indentfortruckdetails)

        })
        var indentfortruckdetails = []
        data1.map((g) => {
            indentfortruckdetails.push({
                "indent_id": e.indent_id,
                "dealer_city": g.dealer_city,
                'dealer_pincode': g.dealer_pincode,
                "dealer_code": g.dealer_code,
               "truck_no":g.truck_no,
               "lr_no":g.lr_no

            })
        })
        setindentfortruckdetails(indentfortruckdetails)

    }
    const changeFileHandler = (e) => {
        const formData = new FormData()
        formData.append("fileUpload", e.target.files[0])

    }

    const closeBulkUploadModal = () => {
        setBulkUploadModal({
            overly: "show-n",
            bulkPrioritySlide: ""
        })
        setOverlay("show-n")
    }
    const onClickHideall = () => {
        setLoadshow('show-m')
        setOverlay('show-n')

    }
    const onShowdealerdata = (indent_id, email) => {
        console.log(indent_id, 'indent_id')
        setisCardOpen3(true)
        var params = {
            indent_id: indent_id,
            vendor_email_id: email
        }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var data1 = resp.data
            var destinations = []
            data1.map((e) => {
                destinations.push({
                    "indent_id": indent_id,
                    "dealer_city": e.dealer_city,
                    'dealer_pincode': e.dealer_pincode,
                    "bid_price": e.bid_price,
                    "transit_days": e.transit_days,
                    'material': e.material,

                })
            })
            setindent_for_approve1(destinations)

        })
        redirectURL.post('/consignments/getlr_data', params).then((resp) => {
            var data = resp.data
            if (data != '' && data != undefined) {
                console.log('s2')
                if (localStorage.getItem('roles') == 'TRANSPORTER') {
                    // data = data.filter(f => f.vendor_email_id == localStorage.getItem('email'))
                    setlr_no_data(data)
                }
                else {
                    setlr_no_data(data)
                }
            }
        })

    }
    const onClickAcceptNegotiation = (indent_id, bid_price, no_of_deliveries, vendor_email_id, indent_created_by) => {
        const params = {
            indent_id,
            bid_price,
            no_of_deliveries,
            vendor_email_id,
            email: localStorage.getItem("email"),
            user_type: localStorage.getItem("user_type"),
            indent_created_by
        };

        redirectURL.post("/consignments/acceptNegotiationValue", params).then(response => {
            const isSuccess = response.data.status === "success";
            setShow(true);
            setBasicType(isSuccess ? "success" : "danger");
            setBasicTitle('Negotiated value has been accepted successfully.');

            if (isSuccess) {
                setIsVendorInputOpen("");
                setIsProposedInputOpen("");
                // socket.current.emit('bidUpdate', []);
            }
        }).catch(error => {
            console.log(error)
            setShow(true);
            setBasicType("danger");
            setBasicTitle("An error occurred while processing the request.");
        });
        // if (localStorage.getItem('roles') == 'TRANSPORTER') {
        //     // setIscardOpen1(true)
        //     var data = originalindents.filter(e => e.indent_id == indent_id)
        //     console.log(data, '371')
        //     console.log(data[0].destination, 'data[0].destination')
        //     var destinations = []
        //     var data1 = data[0].destination
        //     data1 = JSON.parse(data1);

        //     data1.map((e) => {
        //         destinations.push({
        //             "indent_id": indent_id,
        //             "dealer_city": e.dealer_city,
        //             "dealer_pincode": e.dealer_pincode,
        //             "material": `${e.material} (${e.material_length} x ${e.material_breath} x ${e.material_height}) - Qty: ${e.quantity}`
        //         });
        //     });

        //     setindent_for_approve(destinations)
        //     setselecteddata({
        //         indent_id: indent_id,
        //         bid_price: bid_price,
        //         no_of_deliveries: no_of_deliveries,
        //         vendor_email_id: vendor_email_id,
        //         indent_created_by: indent_created_by
        //     });
        // }
        // else if (localStorage.getItem('roles') == 'AL_ADMIN') {
        //     const params = {
        //         indent_id,
        //         bid_price,
        //         no_of_deliveries,
        //         vendor_email_id,
        //         email: localStorage.getItem("email"),
        //         user_type: localStorage.getItem("user_type"),
        //         indent_created_by
        //     };

        //     redirectURL.post("/consignments/acceptNegotiationValue", params).then(response => {
        //         const isSuccess = response.data.status === "success";
        //         setShow(true);
        //         setBasicType(isSuccess ? "success" : "danger");
        //         setBasicTitle(response.data.message);

        //         if (isSuccess) {
        //             setIsVendorInputOpen("");
        //             setIsProposedInputOpen("");
        //             // socket.current.emit('bidUpdate', []);
        //         }
        //     }).catch(error => {
        //         console.log(error)
        //         setShow(true);
        //         setBasicType("danger");
        //         setBasicTitle("An error occurred while processing the request.");
        //     });
        // }
    };
    const onShowdata = (indent_id) => {
        // setIscardOpen2(true)
        var params = {
            indent_id: indent_id,
            vendor_email_id: localStorage.getItem('email')
        }
        redirectURL.post('/consignments/getstroreddata', params).then((resp) => {
            var data1 = resp.data
            var destinations = []
            data1.map((e) => {
                destinations.push({
                    "indent_id": indent_id,
                    "dealer_city": e.dealer_city,
                    'dealer_pincode': e.dealer_pincode,
                    "bid_price": e.bid_price,
                    "transit_days": e.transit_days,
                    'material': e.material
                })
            })
            setindent_for_approve1(destinations)

        })

    }
    const onSubmitdataforadminbifurcation = (e) => {
        console.log(dataforadminbifur, 'dataforadminbifur')
        var params = dataforadminbifur
        var negotiatedconfirmed_price = dataforadminbifur[0].negotiatedconfirmed_price
        console.log(negotiatedconfirmed_price, '644')
        console.log(dataforadminbifur)
        const totalBidPrice = dataforadminbifur.reduce((sum, item) => {
            return sum + (parseFloat(item.bid_price) || 0);
        }, 0);
        if (totalBidPrice == negotiatedconfirmed_price) {
            const params1 = {
                indent_id: dataforadminbifur[0].indent_id,
                bid_price: dataforadminbifur[0].negotiatedconfirmed_price,
                // no_of_deliveries,
                vendor_email_id: dataforadminbifur[0].vendor_email_id,
                email: localStorage.getItem("email"),
                user_type: localStorage.getItem("user_type"),
                indent_created_by: dataforadminbifur[0].indent_created_by
            };
            redirectURL.post("/consignments/AcceptQuotationwithadmin", params1).then(response => {
                const isSuccess = response.data.status === "success";
                // setShow(true);
                // setBasicType(isSuccess ? "success" : "danger");
                // setBasicTitle(response.data.message);

                if (isSuccess) {
                    setIsVendorInputOpen("");
                    setIsProposedInputOpen("");
                    setIscardOpen1(false)
                    //  socket.current.emit('bidUpdate', []);
                }
            })
                .catch(error => {
                    console.log(error)
                    setShow(true);
                    setBasicType("danger");
                    setBasicTitle("An error occurred while processing the request.");
                });

            redirectURL.post('/consignments/storeadminbifur', params).then((resp) => {
                setShow(true);
                setBasicType("success");
                setBasicTitle('Vendor negotiation value accepted and submitted successfully');
                setcardforadminbifurcation(false)
            })

        }
        else {
            setShow(true);
            setBasicType("danger");
            setBasicTitle('Bifurcation bid price must equal bid price');
        }



    }
    const onClickAcceptQuotation = (indent_id, bid_price, no_of_deliveries, vendor_email_id, indent_created_by, indent, vendorValue) => {
        if (localStorage.getItem('roles') == 'AL_ADMIN') {
            console.log(vendorValue, '643')
            setcardforadminbifurcation(true)
            var data = originalindents.filter(f => f.indent_id == indent_id)

            var destinations = []
            var data1 = data[0].destination
            data1 = JSON.parse(data1);

            data1.map((g) => {
                destinations.push({
                    "indent_id": indent_id,
                    "dealer_city": g.dealer_city,
                    "dealer_state": g.dealer_state,
                    'negotiatedconfirmed_price': vendorValue,
                    'dealer_pincode': g.dealer_pincode,
                    "dealer_name": g.dealer_name,
                    "dealer_code": g.dealer_code,
                    'vendor_email_id': vendor_email_id,
                    'indent_created_by': indent_created_by,
                    "materials": g.materials.map(material => ({
                        "material_type": material.material_type,
                        "material_dimensions": `${material.material_length} x ${material.material_breath} x ${material.material_height}`,
                        "quantity": material.quantity
                    })),
                    "quantity": g.quantity,

                })
            })

            setdataforadminbifur(destinations)
            // const params = {
            //     indent_id,
            //     bid_price: vendorValue,
            //     no_of_deliveries,
            //     vendor_email_id,
            //     email: localStorage.getItem("email"),
            //     user_type: localStorage.getItem("user_type"),
            //     indent_created_by
            // };
            // console.log(params, 'params')
            // redirectURL.post("/consignments/AcceptQuotationwithadmin", params).then(response => {
            //     const isSuccess = response.data.status === "success";
            //     // setShow(true);
            //     // setBasicType(isSuccess ? "success" : "danger");
            //     // setBasicTitle(response.data.message);

            //     if (isSuccess) {
            //         setIsVendorInputOpen("");
            //         setIsProposedInputOpen("");
            //         setIscardOpen1(false)
            //         //  socket.current.emit('bidUpdate', []);
            //     }
            // })
            //     .catch(error => {
            //         console.log(error)
            //         setShow(true);
            //         setBasicType("danger");
            //         setBasicTitle("An error occurred while processing the request.");
            //     });
        }

    };
    const onCloseModal = () => {
        setModelForAdditionalComments(false)
        setModelForShowBids(false)
        setModelForNotifications(false)
        setModelForEditAssigned(false)
        setIscardOpen1(false)
        setIscardOpen2(false)
        setisCardOpen3(false)
        setcardforadminbifurcation(false)
    };
    const fetchNotificationsData = (indent_id) => {
        var params = {
            email: localStorage.getItem("email"),
            indent_id: indent_id
        }
        redirectURL.post("/consignments/fetchNotificationsData", params).then(response => {
            setNotificationUnread(response.data.Unreadnotifications)
            setNotificationRead(response.data.Readnotifications)
            setModelForNotifications(true)
            const indent = allindents.find(e => e.indent_id === indent_id);
            if (indent) {
                indent.notification_count = 0;
            }
        })
    }


    const modalStyles = {
        width: '500px !important',
    }
    const modalStyles1 = {
        width: '300px !important',
    }
    const columnwithdefs = [
        {
            headerName: "Rank",
            field: "truck_no",
            width: 200,
            resizable: true,
        },
        {
            headerName: "Bid Price",
            field: "truck_no",
            width: 100,
            resizable: true,
        },
        {
            headerName: "Bid Quantity",
            field: "truck_no",
            width: 100,
            resizable: true,
        },
    ]

    const handleSelectChange = (name, selectedOption) => {
        // Check if selectedOption is not null before accessing value
        setsearchfilter({
            ...searchfilter,
            [name]: selectedOption ? selectedOption.value : null
        });
    };
    const resetSearchFilter = () => {

        if (rfqform.active === 'activet') {
            setallindents(originalindents.filter(e => e.indent_expired != 1));
        }
        else if (rfqform.cancelled === 'activet') {
            setallindents(originalindents.filter(e => e.indent_expired == 1));
        }
        // else if (rfqform.drafts === 'activet') {
        //     setallindents(originalindents.filter(e => e.is_active == 0));
        // }

        setsearchfilter({})

    }

    const getindents = (e) => {
        e.preventDefault();
        let data = []
        if (rfqform.active === 'activet') {
            data = originalindents.filter(e => e.indent_expired != 1)
        }
        else if (rfqform.cancelled === 'activet') {
            data = originalindents.filter(e => e.indent_expired == 1)
        }
        // else if (rfqform.drafts === 'activet') {
        //     data = originalindents.filter(e => e.is_active == 0)
        // }


        // Assuming searchfilter is an object with properties to filter by
        const filterdata = data.filter(indent => {
            return Object.keys(searchfilter).every(key => {
                // Check if the searchfilter value is not empty and matches the indent's corresponding value
                return !searchfilter[key] || indent[key] == searchfilter[key];
            });
        });
        // Now you can set the filtered data or perform other actions with filterdata
        setallindents(filterdata);
    };
    const handleInputChangeforbid = (index, field, value) => {
        const updatedDestinations = [...indent_for_approve_for_bid];
        updatedDestinations[index][field] = value; // Update the specific field
        setindent_for_approve_for_bid(updatedDestinations); // Update state
    };
    const handleInputChangeforaccept = (index, field, value) => {
        const updatedDestinations = [...indent_for_approve];
        updatedDestinations[index][field] = value; // Update the specific field
        setindent_for_approve(updatedDestinations); // Update state
    };
    const handleInputChangeforaccept1 = (index, field, value) => {
        const updatedDestinations = [...indent_for_approve1];
        updatedDestinations[index][field] = value; // Update the specific field
        setindent_for_approve1(updatedDestinations); // Update state
    };
    const handleInputChangeforacceptfortruck = (index, field, value) => {
        const updatedDestinations = [...indentfortruckdetails];
        updatedDestinations[index][field] = value; // Update the specific field
        setindentfortruckdetails(updatedDestinations); // Update state
        const updatedDestinations1 = [...lr_no_data];
        updatedDestinations1[index][field] = value; // Update the specific field
        setlr_no_data(updatedDestinations1); // Update state
    };
   
    const handleInputChangeforadmin_bifur = (index, field, value) => {
        const updatedDestinations = [...dataforadminbifur];
        updatedDestinations[index][field] = value; // Update the specific field
        setdataforadminbifur(updatedDestinations); // Update state
    };

    const onSubmitdata = (e) => {
        const hasInvalidFields = indent_for_approve.some(
            (destination) =>
                !destination.bid_price || !destination.transit_days
        );

        if (hasInvalidFields) {
            alert("Please fill in all Bid Price and Transit Days fields before submitting.");
            return;
        }
        const submissionData = indent_for_approve.map((destination) => ({
            indent_id: destination.indent_id,
            dealer_city: destination.dealer_city,
            dealer_pincode: destination.dealer_pincode,
            bid_price: destination.bid_price,
            transit_days: destination.transit_days,
            material: destination.material,
            'vendor_email_id': localStorage.getItem('email'),
            'vendor_name': localStorage.getItem('firstname')
        }));
        console.log("Prepared submission data:", submissionData);
        var params1 = {
            submissionData: submissionData
        }
        redirectURL.post('/consignments/storedata', params1).then((resp) => {

        })
        var { indent_id, bid_price, no_of_deliveries, vendor_email_id, indent_created_by } = selecteddata
        const params = {
            indent_id,
            bid_price,
            no_of_deliveries,
            vendor_email_id,
            email: localStorage.getItem("email"),
            user_type: localStorage.getItem("user_type"),
            indent_created_by
        };

        redirectURL.post("/consignments/acceptNegotiationValue", params).then(response => {
            const isSuccess = response.data.status === "success";
            setShow(true);
            setBasicType(isSuccess ? "success" : "danger");
            setBasicTitle(response.data.message);
            setIscardOpen1(false)

            if (isSuccess) {
                setIsVendorInputOpen("");
                setIsProposedInputOpen("");
                // socket.current.emit('bidUpdate', []);
            }
        }).catch(error => {
            console.log(error)
            setShow(true);
            setBasicType("danger");
            setBasicTitle("An error occurred while processing the request.");
        });



    }
    const onshowPreview = (e) => {
        console.log('832', e)
        setIsPreviewMode(true)
        setRfqForm(e)
        const destinationDetails = JSON.parse(e.destination || '[]');
        setDealers(destinationDetails);

    }
    const onSubmitdata1 = (e) => {
        const hasInvalidFields = indent_for_approve.some(
            (destination) =>
                !destination.bid_price || !destination.lr_no
        );

        if (hasInvalidFields) {
            alert("Please fill LR No field before submitting");
            return;
        }
        const submissionData = indentfortruckdetails.map((destination) => ({
            indent_id: destination.indent_id,
            dealer_city: destination.dealer_city,
            dealer_pincode: destination.dealer_pincode,
            bid_price: destination.bid_price,
            truck_no: destination.truck_no,
            lr_no: destination.lr_no,
            submitted:1,
            started_truck_details:1
        }));
        console.log("Prepared submission data:", submissionData);
        var params1 = {
            submissionData: submissionData
        }
        redirectURL.post('/consignments/storetruckdetails', params1).then((resp) => {
            if (resp.data == 'success') {
                setShow(true);
                setBasicType("success");
                setBasicTitle('Successfully Submitted');
                setIscardOpen2(false)
            }

        })
            .catch(error => {
                console.log(error)
                setShow(true);
                setBasicType("danger");
                setBasicTitle("An error occurred while processing the request.");
            });


    }

    const handleInputChange = (e) => {
        setselected_transit_days(e)
        // setNewBidQuantity(e)
    };
    const handlertransittime = (e) => {
        settransit_time(e)
    }
    const handleInputChange1 = (e) => {
        setselected_bid_price(e)
        // setNewBidRate(e)
    };
    const handleInputChange2 = (e) => {
        setnegotiate_value(e)
    };

    const onSubmitquote = (e) => {
        e.preventDefault()
        // var selected_bid_price = 0
        // var selected_bid_quantity = selectedindent.destination
        // selected_bid_quantity = JSON.parse(selected_bid_quantity)
        // console.log(selected_bid_quantity.length, 'selected_bid_quantityselected_bid_quantity')
        // var transit_time = 0
        // indent_for_approve_for_bid.forEach(item => {
        //     selected_bid_price += parseFloat(item.bid_price); // Sum the bid prices
        //     transit_time += parseInt(item.transit_days); // Sum the transit days
        // });

        // var params = {
        //     bid_price: selected_bid_price,
        //     no_of_deliveries: selected_bid_quantity.length,
        //     email: localStorage.getItem('email'),
        //     indent_id: selectedindent.indent_id,
        //     username: localStorage.getItem('user_name'),
        //     transit_time: transit_time
        // }
        var params = {
            bid_price: selected_bid_price,
            transit_time: selected_transit_days,
            email: localStorage.getItem('email'),
            indent_id: selectedindent.indent_id,
            username: localStorage.getItem('user_name')
        }
        redirectURL.post('consignments/submitQuote', params).then((response) => {
            if (response.data.status == 'success') {
                setIscardOpen("")
                setShow(true)
                setBasicType("success")
                setBasicTitle('Your Bid has been successfully placed')
                setselected_bid_price("")
                setselected_transit_days("")
                // if (response.data.status == "success") socket.current.emit('bidUpdate', []);
                // setopen(false)
                // fetchgetindents()
            }
        })
        const submissionData = indent_for_approve_for_bid.map((destination) => ({
            indent_id: selectedindent.indent_id,
            dealer_city: destination.dealer_city,
            dealer_pincode: destination.dealer_pincode,
            bid_price: destination.bid_price,
            transit_days: destination.transit_days,
            material: destination.material,
            'vendor_email_id': localStorage.getItem('email'),
            'vendor_name': localStorage.getItem('firstname')
        }));
        var params3 = { submissionData: submissionData, indent_id: selectedindent.indent_id }
        redirectURL.post('/consignments/updatebidprice', params3).then((resp) => {

        })

        console.log("Prepared submission data:", submissionData);
        var params1 = {
            submissionData: submissionData
        }
        redirectURL.post('/consignments/storedata', params1).then((resp) => {

        })


    }

    const closeAlert = () => {
        setShow(false)
        setopen(false)
    }

    const onIconHover = (rowId) => {
        setHoveredIconId(rowId); // Set the hovered icon ID to the specific row ID
    };
    const onClickparentTab = (e) => {
        var current_date = new Date()
        let data = []
        if (e === 'Expired') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: 'activet1',
                active: '',
                completed: '',
                awarded: '',
                rejected: '',
                Delivered: "",
                vendorrejected: "",
                vehicle_placement_missed: "",
                drafts: '' // Optionally, clear other tabs
            }));

            data = []
            if (localStorage.getItem('user_type') === 'TRANSPORTER') {
                data = originalindents.filter((e) => {
                    // Check if `vendorquotes` exists and is an array
                    if (e.vendorquotes && Array.isArray(e.vendorquotes)) {
                        // Filter `vendorquotes` to find entries that match the email and have `bid_winner` set to 0
                        const vendorquote = e.vendorquotes.find(
                            (quote) =>
                                quote.vendor_email_id === localStorage.getItem('email') &&
                                quote.bid_winner === 0
                        );
                        // Return `true` if a matching vendor quote is found, else `false`
                        return vendorquote !== undefined;
                    }
                    return false; // If `vendorquotes` is empty or not an array, exclude this indent
                });
            }

            //  data = originalindents.filter(e => {
            //     const bidEndDate = moment.parseZone(e.bid_start_date)
            //         .utcOffset("-05:30")
            //         .add(parseInt(e.quotation_validity_hours, 10), 'hours');

            //     return current_date < bidEndDate && (e.vendordetails && e.vendordetails.length === 0);
            // });

        }
        if (e === 'Awarded') {
            setrfqform((prevForm) => ({
                ...prevForm,
                awarded: 'activet1',
                cancelled: '',
                active: '',
                completed: '',
                rejected: '',
                Delivered: "",
                vendorrejected: "",
                vehicle_placement_missed: "",
                drafts: '' // Optionally, clear other tabs
            }));
            data = []
            if (localStorage.getItem('user_type') === 'TRANSPORTER') {
                // data = originalindents.filter((e) => {
                //     // Check if `vendorquotes` exists and is an array
                //     if (e.vendorquotes && Array.isArray(e.vendorquotes)) {
                //         // Filter `vendorquotes` to find entries that match the email and have `bid_winner` set to 0
                //         const vendorquote = e.vendorquotes.find(
                //             (quote) =>
                //                 quote.vendor_email_id === localStorage.getItem('email') &&
                //                 quote.bid_winner === 1
                //         );
                //         // Return `true` if a matching vendor quote is found, else `false`
                //         return vendorquote !== undefined;
                //     }
                //     return false; // If `vendorquotes` is empty or not an array, exclude this indent
                // });
                data = originalindents.filter(e => e.accepted_vendor == localStorage.getItem('email'))
            }
            else {
                data = originalindents.filter(e => e.indent_assigned == 1)
            }

        }
        else if (e === 'Active') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                completed: '',
                awarded: '',
                rejected: '',
                active: 'activet1',
                Delivered: "",
                vendorrejected: "",
                vehicle_placement_missed: "",
                drafts: '' // Optionally, clear other tabs
            }));
            // data = originalindents
            data = originalindents.filter(function (e) {
                return e.vendordetails && e.vendordetails.length > 0 && e.indent_assigned != 1 && e.validity_end != 1 && e.rejected != 1 && e.rejected != 1;
            });

        }
        else if (e === 'Completed') {
            console.log('1019')
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                completed: 'activet1',
                awarded: '',
                rejected: '',
                active: '',
                Delivered: "",
                vendorrejected: "",
                vehicle_placement_missed: "",
                drafts: '' // Optionally, clear other tabs
            }));
            data = originalindents.filter(e => e.validity_end == 1)
        }
        else if (e === 'rejected') {
            console.log('1019')
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                rejected: 'activet1',
                completed: '',
                awarded: '',
                active: '',
                Delivered: "",
                vendorrejected: "",
                vehicle_placement_missed: "",
                drafts: '' // Optionally, clear other tabs
            }));
            console.log(originalindents, '1142')
            if (localStorage.getItem('user_type') === 'TRANSPORTER') {
                data = originalindents.filter((e) => {
                    // Check if `vendorquotes` exists and is an array
                    if (e.vendorquotes && Array.isArray(e.vendorquotes)) {
                        // Filter `vendorquotes` to find entries that match the email and have `bid_winner` set to 0
                        const vendorquote = e.vendorquotes.find(
                            (quote) =>
                                quote.vendor_email_id === localStorage.getItem('email') &&
                                quote.bid_winner === 0
                        );
                        // Return `true` if a matching vendor quote is found, else `false`
                        return vendorquote !== undefined;
                    }
                    return false; // If `vendorquotes` is empty or not an array, exclude this indent
                });
            }
            else {
                data = originalindents.filter(e => e.rejected == 1 || e.rejectedwithapproval1 == 1)
            }
        }

        else if (e === 'Drafts') {
            setrfqform((prevForm) => ({
                ...prevForm,
                cancelled: '',
                active: '',
                rejected: '',
                awarded: "",
                Delivered: "",
                vendorrejected: "",
                vehicle_placement_missed: "",
                completed: "",
                drafts: 'activet1' // Optionally, clear other tabs
            }));
            console.log(draftdata, '1063')
            data = draftdata
        }

        const filterdata = data.filter(indent => {
            return Object.keys(searchfilter).every(key => {
                // Check if the searchfilter value is not empty and matches the indent's corresponding value
                return !searchfilter[key] || indent[key] === searchfilter[key];
            });
        });
        setallindents(data)
    };
    const onClickViewPOD = (e) => {
        // console.log("documnent_view", e)

        let params = {
            pod_document: e,
        }
        // console.log("params",params)
        redirectURL.post("/billing/downloadfileforroad", params, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
            }
        }).then(res => {
            console.log(res, '652')
            const downloadUrl = decodeURIComponent(res.data.url);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", e || ""); // Optional: specify download filename
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }).catch(function (error) {
            // console.log(error);
        })
    }
    const onClickcreateindent = () => {
        window.location.href = "/createindent";
    }
    const onClickOpenBulkUploadModal = (e) => {
        setOverlay("show-m")
        setBulkUploadModal({
            bulkPrioritySlide: "slider-translate-84p"
        })
        setOverlay('show-m')
    }

    const submitNegotiateValue = (indent_id, vendor_email_id, indent_created_by) => {
        const params = {
            user_type: localStorage.getItem("user_type"),
            email: localStorage.getItem("email"),
            indent_id,
            negotiated_value: inputTextValue,
            // negotiated_quantity: inputTextQuantity,
            vendor_email_id,
            indent_created_by
        };

        redirectURL.post("/consignments/submitNegotiateValue", params)
            .then(response => {
                const { status, message } = response.data;

                setShow(true);
                setBasicType(status === 'success' ? 'success' : 'danger');
                setBasicTitle('The negotiated value has been updated');


                if (status === "success") {
                    setIsProposedInputOpen("");
                    setIsVendorInputOpen("");
                    // socket.current.emit('bidUpdate', []);
                }
            })
            .catch(error => {
                console.log(error, '600')
                setShow(true);
                setBasicType("danger");
                setBasicTitle("An error occurred while submitting the negotiation.");
            });
    };
    const columnDef = []
    console.log(bulkUploadModal, 'isVendorInputOpen')
    const toggleShowAllRecords = () => {
        setShowAllRecords((prev) => !prev);
    };

    console.log(lr_no_data, 'lr_no_data123')
    return (
        <div className="container-fluid">
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            {/* <div className="row ml-50p">
                <h5 class="col-sm-12 mt-30p fbold f20">
                    <span>Indents Auction</span>
                </h5>
            </div > */}
            <div className="row d-flex align-items-center justify-content-between">
                <h5 className="col-sm-6  fbold f20">
                    <span>Indent Summary</span>
                </h5>
                <div className="col-sm-6 d-flex justify-content-end" style={{ marginTop: "0px" }}>

                    {localStorage.getItem('roles') == 'AL_ADMIN' ? <>

                        <button type="button" className="btn  float-right" style={{ padding: "8px 0px", marginRight: "8px" }} onClick={resetindent} >

                            <i className='icofont icofont-refresh' style={{ height: "16px", width: "16px", cursor: "pointer", float: "right" }} ></i>
                        </button>

                        <button type="button" className="btn btn-default float-right" style={{ padding: "8px 0px", marginRight: "8px" }}>

                            <img className='mx-2' onClick={(e) => setFilterToggle(prev => !prev)} src={Filter} style={{ height: "16px", width: "16px", cursor: "pointer", float: "right" }} title="Filters" />
                        </button> <button
                            className="btn btn-success mr-2"
                            style={{ cursor: 'pointer', borderRadius: "5px" }} onClick={onClickcreateindent}
                        >
                            Create Indent
                        </button>
                        <button
                            className="btn btn-outline-info"
                            style={{
                                cursor: 'pointer',
                                borderRadius: "5px",
                                // borderColor: '#17a2b8', // Set the border color to match the 'info' color
                                // color: '#17a2b8' // Set text color to match the info theme
                            }}
                            onClick={onClickOpenBulkUploadModal}
                        >
                            Create Bulk Indent
                        </button>
                    </> :
                        <>
                            <button type="button" className="btn  float-right" style={{ padding: "8px 0px", marginRight: "8px" }} onClick={resetindent} >

                                <i className='icofont icofont-refresh' style={{ height: "16px", width: "16px", cursor: "pointer", float: "right" }} ></i>
                            </button>
                            <button type="button" className="btn btn-default float-right" style={{ padding: "8px 0px", marginRight: "48px" }}>
                                <img className='mx-2' onClick={(e) => setFilterToggle(prev => !prev)} src={Filter} style={{ height: "16px", width: "16px", cursor: "pointer", float: "right" }} title="Filters" />

                            </button></>

                    }

                </div>
            </div>

            <div className={filterToggle ? "filter-in" : "filter-out"}>
                {
                    filterToggle &&
                    <div className={filterToggle ? "row" : "row"} style={{ marginTop: "20px" }}>
                        <div className={"col-xl-12 col-lg-12 "}>
                            <div className="card shadow-sm">
                                <div className="card-body" style={{ padding: "20px 40px" }}>
                                    <form className="row" onSubmit={getindents}>
                                        <div className="col-md-3 mb-3">
                                            <label>Indent ID</label>
                                            <Select
                                                isMulti={false}
                                                id="indent_id"
                                                value={originalindents.find(e => e.indent_id === searchfilter.indent_id) ? { label: searchfilter.indent_id, value: searchfilter.indent_id } : null}
                                                onChange={(selectedOption) => handleSelectChange("indent_id", selectedOption)}
                                                options={originalindents.map(e => ({ label: e.indent_id, value: e.indent_id }))}
                                                styles={customSelectStyles}
                                            />
                                        </div>

                                        <div className="col-md-3 mb-3">
                                            <label>Source</label>
                                            <Select
                                                value={originalindents.find(e => e.source === searchfilter.source) ? { label: searchfilter.source, value: searchfilter.source } : null}
                                                onChange={(selectedOption) => handleSelectChange("source", selectedOption)}
                                                options={[...new Set(originalindents.map(e => e.source).filter(source => source))].map(source => ({ label: source, value: source }))}
                                                styles={customSelectStyles}
                                            />
                                        </div>

                                        <div className="col-md-3 mb-3">
                                            <label>Destination</label>
                                            <Select
                                                value={originalindents.find(e => e.destination === searchfilter.destination) ? { label: searchfilter.destination, value: searchfilter.destination } : null}
                                                onChange={(selectedOption) => handleSelectChange("destination", selectedOption)}
                                                options={[...new Set(originalindents.map(e => e.destination).filter(destination => destination))].map(destination => ({ label: destination, value: destination }))}
                                                styles={customSelectStyles}
                                            />
                                        </div>

                                        <div className="col-md-3 mb-3">
                                            <label>Material</label>
                                            <Select
                                                value={originalindents.find(e => e.material === searchfilter.material) ? { label: searchfilter.material, value: searchfilter.material } : null}
                                                onChange={(selectedOption) => handleSelectChange("material", selectedOption)}
                                                options={[...new Set(originalindents.map(e => e.material).filter(material => material))].map(material => ({ label: material, value: material }))}
                                                styles={customSelectStyles}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label>Truck Type</label>
                                            <Select
                                                value={originalindents.find(e => e.type_of_truck === searchfilter.type_of_truck) ? { label: searchfilter.type_of_truck, value: searchfilter.type_of_truck } : null}
                                                onChange={(selectedOption) => handleSelectChange("type_of_truck", selectedOption)}
                                                options={[...new Set(originalindents.map(e => e.type_of_truck).filter(truckType => truckType))].map(truckType => ({ label: truckType, value: truckType }))}
                                                styles={customSelectStyles}
                                            />
                                        </div>

                                        <div className="col-md-9 mb-3" style={{ minHeight: "38px", display: 'flex', gap: "15px", alignItems: "flex-end" }}>
                                            <div className="mr-4">
                                                <label>
                                                    Insurance
                                                    <input
                                                        className="ml-2"
                                                        type="checkbox"
                                                        checked={searchfilter.insurancecheck}
                                                        onChange={e => setsearchfilter({ ...searchfilter, insurancecheck: e.target.checked ? 1 : 0 })}
                                                    />

                                                </label>
                                            </div>

                                            <div className="mr-4">
                                                <label>
                                                    Over Length
                                                    <input
                                                        className="ml-2"
                                                        type="checkbox"
                                                        checked={searchfilter.overlength}
                                                        onChange={e => setsearchfilter({ ...searchfilter, overlength: e.target.checked ? 1 : 0 })}
                                                    />

                                                </label>
                                            </div>

                                            <div className="mr-4">
                                                <label>
                                                    Over Breath
                                                    <input
                                                        className="ml-2"
                                                        type="checkbox"
                                                        checked={searchfilter.overheight}
                                                        onChange={e => setsearchfilter({ ...searchfilter, overheight: e.target.checked ? 1 : 0 })}
                                                    />

                                                </label>
                                            </div>

                                            {/* <div>
                                                <label>
                                                Show Bids
                                                    <input
                                                     className="ml-2"
                                                        type="checkbox"
                                                        checked={searchfilter.bid_visibility}
                                                        onChange={e => setsearchfilter({ ...searchfilter, bid_visibility: e.target.checked })}
                                                    />
                                                </label>
                                            </div> */}
                                        </div>

                                        <div className="col-md-12 d-flex justify-content-end">
                                            <button type="submit" className="btn btn-success mr-2">Submit</button>
                                            <button type="button" onClick={resetSearchFilter} className="btn btn-secondary">Reset</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                }
            </div>

            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <ul className="rfq-tabs d-flex flex-wrap">
                        <li onClick={() => onClickparentTab('Active')}
                            className={"lttabs1 " + rfqform.active}
                        >
                            <span className="tab-title">Active</span>
                        </li>
                        <li onClick={() => onClickparentTab('Awarded')}

                            className={"lttabs1 " + rfqform.awarded}
                        >
                            <span className="tab-title">Awarded</span>
                        </li>
                        <li onClick={() => onClickparentTab('Delivered')}

                            className={"lttabs1 " + rfqform.Delivered}
                        >
                            <span className="tab-title">Delivered</span>
                        </li>
                        <li onClick={() => onClickparentTab('vendorrejected')}

                            className={"lttabs1 " + rfqform.vendorrejected}
                        >
                            <span className="tab-title">Vendor Rejected</span>
                        </li>
                        <li onClick={() => onClickparentTab('vehicle_placement_missed')}

                            className={"lttabs1 " + rfqform.vehicle_placement_missed}
                        >
                            <span className="tab-title">Vehicle Placement Missed</span>
                        </li>
                        {localStorage.getItem('roles') == 'AL_ADMIN' ? <li onClick={() => onClickparentTab('Drafts')}

                            className={"lttabs1 " + rfqform.drafts}
                        >
                            <span className="tab-title">Drafts</span>
                        </li> : ''}

                        <li onClick={() => onClickparentTab('rejected')}

                            className={"lttabs1 " + rfqform.rejected}
                        >
                            <span className="tab-title">Rejected</span>
                        </li>
                        <li onClick={() => onClickparentTab('Completed')}

                            className={"lttabs1 " + rfqform.completed}
                        >
                            <span className="tab-title">Expired</span>
                        </li>

                        {/* <li onClick={() => onClickparentTab('Archieved')}
                            //   onClick={this.onClickparentTab.bind(this, "intransit")}
                            className={"lttabs " + rfqform.archieved}
                        >
                            <span className="tab-title">Archieved</span>
                        </li>
                        <li onClick={() => onClickparentTab('My Activity')} className={"lttabs " + rfqform.myactivity}                >
                            <span className="tab-title">My Activity</span>
                        </li> */}
                    </ul>
                </div>
                {allindents.length > 0 && <div className="col-xl-12 col-md-12">
                    <div className='card '>
                        {/* <div className="card-body">
                            <div className="d-flex mt-14p ml-18p col-xl-3"
                                style={{ position: "relative" }}>
                                <input
                                    type="text"
                                    name="base_rate"
                                    value={rfqform.selectedindentid}
                                    className="form-control1"
                                    onChange={handleInputChange}
                                />
                                <img
                                    src={searchicon}
                                    className="search_icon"
                                // onClick={filterData}
                                />
                            </div>


                        </div> */}
                        <div className='card-container mt-20p'>
                            {allindents.map((indent, index) => {

                                const expirationTime = moment
                                    .parseZone(indent.bid_start_date)
                                    .utcOffset("-05:30")
                                    .add(parseInt(indent.quotation_validity_hours), 'hours')._d

                                const displayedVendorQuotes = indent.vendorquotes && Array.isArray(indent.vendorquotes)
                                    ? showAllRecords
                                        ? indent.vendorquotes
                                        : indent.vendorquotes.slice(0, 5)
                                    : [];
                                var destinations
                                if (indent && indent.destination) {
                                    destinations = JSON.parse(indent.destination);
                                }


                                return (
                                    <>
                                        <>
                                            <div key={index} className='card ml-30p mr-30p'>
                                                <div className="indent-card row col-xl-12 col-md-12" style={{ margin: 0, padding: 0 }}>
                                                    {/* First Column: Indent ID, Status, Bids */}
                                                    <div className="column3 ml-38p">
                                                        {
                                                            indent.is_draft == 1 ?
                                                                <p className='mt-20p'><img src={require('../../assets/images/road_black.png')} alt="Source Icon" className="icon1" /><strong>Draft ID</strong>:&nbsp;<br /> {indent.draft_id}</p>
                                                                : <p className='mt-20p'><img src={require('../../assets/images/road_black.png')} alt="Source Icon" className="icon1" /><strong>Indent ID</strong>:&nbsp; {indent.indent_id}</p>
                                                        }
                                                        <div className="status-section">
                                                            {/* {indent.type == 'sea' ? <span className="status-circle ftl">FCL</span> : <span className="status-circle ftl">{indent.units && unitsOptions.filter(e => e.value == indent.units)[0].label}</span>} */}
                                                            <span className="status-circle c">
                                                                {/* {indent.auction_type === "spot" ? "Spot" : "Contract"} */}
                                                                Spot
                                                            </span>

                                                            {/* {indent.type === 'sea' ? (
                                                        <span className="status-circle c">
                                                            {indent.auction_type === "spot" ? "Spot" : `${indent.movement_type}- ${indent.incoterm}`}
                                                        </span>
                                                    ) : (
                                                        <span className="status-circle c">
                                                            {indent.auction_type === "spot" ? "Spot" : "Contract"}
                                                        </span>
                                                    )} */}

                                                        </div>
                                                        {/* {localStorage.getItem("roles") == "AL_ADMIN" ? (
                                                            <>
                                                                <p className='mt-10p'>
                                                                    <strong>Status</strong>:&nbsp;
                                                                    <span className="bidscount">
                                                                        {(indent.vendorquotes && indent.vendorquotes.length === 0)
                                                                            ? "Waiting for Quotes"
                                                                            : (indent.quantity === indent.confirmed_quantity
                                                                                ? "Indent Assigned"
                                                                                : (indent.indent_assigned == 1 ? 'Contract Awarded' : "Bidding in Progress"))}
                                                                    </span>
                                                                </p>
                                                            </>
                                                        ) : ''} */}
                                                        <>
                                                            <p className='mt-10p'>
                                                                <strong>Status</strong>:&nbsp;
                                                                {/* <span className="bidscount">
                                                                    {indent.validity_end === 1
                                                                        ? "Expired"
                                                                        : (indent.vendorquotes && indent.vendorquotes.length === 0)
                                                                            ? "Waiting for Quotes"
                                                                            : (indent.quantity === indent.confirmed_quantity
                                                                                ? "Indent Assigned"
                                                                                : (indent.indent_assigned === 1
                                                                                    ? "Contract Awarded"
                                                                                    : "Bidding in Progress"))}
                                                                </span> */}
                                                               <span className="bidscount">
    {indent.rejected === 1 ? (
        'Rejected'
    ) : indent.is_draft === 1 ? (
        <span className="bidscount">Draft</span>
    ) : (
        <span className="bidscount">
            {indent.waiting_for_approval1 === 1
                ? "Waiting for Approval"
                : indent.validity_end === 1
                ? "Expired"
                : indent.vendorquotes && indent.vendorquotes.length === 0
                ? "Waiting for Quotes"
                : indent.quantity === indent.confirmed_quantity || indent.indent_assigned === 1
                ? "Indent Assigned"
                : "Bidding in Progress"}
        </span>
    )}
</span>

                                                            </p>
                                                        </>

                                                        {localStorage.getItem('roles') == 'TRANSPORTER' ? (
                                                            <>
                                                                <p className='mt-10p'>
                                                                    <strong>Bids Received</strong>:&nbsp;
                                                                    <span className="bidscount">
                                                                        {(indent.vendorquotes && indent.vendorquotes.length) || 0}
                                                                    </span>
                                                                </p>
                                                            </>
                                                        ) : ''}


                                                    </div>

                                                    {/* Second Column: Source, Distance, Destination */}
                                                    <div className="column3">
                                                        <p className='mt-20p'>
                                                            <img src={require('../../assets/images/Group.png')} alt="Source Icon" className="icon1" />
                                                            <strong>Source</strong>:&nbsp; {indent.source}
                                                        </p>


                                                        <p className='mt-10p'>
                                                            <img src={require('../../assets/images/vector.png')} alt="Destination Icon" className="icon1" />
                                                            <strong>Destination</strong>:&nbsp;
                                                            {(() => {
                                                                try {
                                                                    const destinations = JSON.parse(indent.destination);
                                                                    if (destinations.length > 1) {
                                                                        return (
                                                                            <>
                                                                                Multiple Destinations({destinations.length})


                                                                                {hoveredIconId === `multipledestinations-${index}` && (
                                                                                    <div
                                                                                        style={{
                                                                                            display: "inline-block",
                                                                                            padding: "8px",
                                                                                            backgroundColor: "#ffffff",
                                                                                            border: "1px solid #ccc",
                                                                                            borderRadius: "4px",
                                                                                            fontSize: "12px",
                                                                                            position: "absolute",
                                                                                            cursor: "pointer",
                                                                                            zIndex: "10",
                                                                                            width: "200px"
                                                                                        }}
                                                                                    >
                                                                                        {/* Display each destination with material and dimensions */}
                                                                                        {destinations.map((destination, index) => (
                                                                                            <div key={destination.dealer_city}>
                                                                                                {`${index + 1}. ${destination.dealer_city} : ${destination.dealer_pincode}`}
                                                                                            </div>
                                                                                        ))}

                                                                                        <div
                                                                                            style={{
                                                                                                position: "absolute",
                                                                                                top: "100%",
                                                                                                left: "10px",
                                                                                                width: "0",
                                                                                                height: "0",
                                                                                                borderLeft: "5px solid transparent",
                                                                                                borderRight: "5px solid transparent",
                                                                                                borderTop: "5px solid #ccc"
                                                                                            }}
                                                                                        ></div>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    } else {
                                                                        // For a single destination, display it directly
                                                                        return `${destinations[0].dealer_city} : ${destinations[0].dealer_pincode}`;
                                                                    }
                                                                } catch (error) {
                                                                    console.error("Error parsing destination:", error);
                                                                    return indent.destination; // Fallback to original string if parsing fails
                                                                }
                                                            })()}
                                                        </p>

                                                        <p className='mt-10p'>
                                                            <img src={require('../../assets/images/startdate.png')} alt="Start Date Icon" className="icon1" />
                                                            <strong>Start Date</strong>:&nbsp; {getHyphenYYYYMMDDHHMM(indent.bid_start_date)}
                                                        </p>
                                                        <p className='mt-10p'>
                                                            <img src={require('../../assets/images/startdate.png')} alt="Start Date Icon" className="icon1" />
                                                            <strong>Vehicle Placement Date</strong>:&nbsp; {getHyphenYYYYMMDDHHMM(indent.vehicle_placement_date)}
                                                        </p>
                                                    </div>
                                                    {/* Third Column: Material, Truck, Quantity, Start Date */}
                                                    <div className="column3">
                                                        <p className='mt-20p'>
                                                            <img src={require('../../assets/images/materialtype.png')} alt="Material Type Icon" className="icon1" />
                                                            <strong>Material</strong>:&nbsp;
                                                            {(() => {
                                                                try {
                                                                    if (indent && indent.destination) {
                                                                        const destinations = JSON.parse(indent.destination);
                                                                        // Assuming each destination entry corresponds to a single dealer
                                                                        if (destinations.some(dest => dest.materials.length > 1)) {
                                                                            // More than one material in any dealer
                                                                            return (
                                                                                <>
                                                                                    Multiple materials
                                                                                    {/* Assuming `onIconHover` and `setHoveredIconId` are handled outside this scope */}
                                                                                    {/* {destinations.map((destination, idx) => (
                                <i
                                    key={idx}
                                    className="icofont icofont-eye f20"
                                    style={{ cursor: 'pointer', marginLeft: "10px", marginBottom: "-3px" }}
                                    onMouseEnter={() => onIconHover(`multiplematerials-${idx}`)}
                                    onMouseLeave={() => setHoveredIconId(null)}
                                ></i>
                            ))} */}
                                                                                    {destinations.map((destination, idx) =>
                                                                                        hoveredIconId === `multiplematerials-${idx}` && (
                                                                                            <div
                                                                                                key={idx}
                                                                                                style={{
                                                                                                    display: "inline-block",
                                                                                                    padding: "8px",
                                                                                                    backgroundColor: "#ffffff",
                                                                                                    border: "1px solid #ccc",
                                                                                                    borderRadius: "4px",
                                                                                                    fontSize: "12px",
                                                                                                    position: "absolute",
                                                                                                    cursor: "pointer",
                                                                                                    zIndex: "10",
                                                                                                    width: "350px"
                                                                                                }}
                                                                                            >
                                                                                                {destination.materials.map((material, matIdx) => (
                                                                                                    <div key={matIdx}>
                                                                                                        {`${matIdx + 1}. ${material.material_type} (${material.material_length} x ${material.material_breath} x ${material.material_height} ${material.material_units}) -Qty: ${material.quantity}`}
                                                                                                    </div>
                                                                                                ))}
                                                                                                <div
                                                                                                    style={{
                                                                                                        position: "absolute",
                                                                                                        top: "100%",
                                                                                                        left: "10px",
                                                                                                        width: "0",
                                                                                                        height: "0",
                                                                                                        borderLeft: "5px solid transparent",
                                                                                                        borderRight: "5px solid transparent",
                                                                                                        borderTop: "5px solid #ccc"
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        } else {
                                                                            // Single material per dealer
                                                                            const destination = destinations[0];
                                                                            const firstMaterial = destination.materials[0];
                                                                            return `${firstMaterial.material_type} (${firstMaterial.material_length} x ${firstMaterial.material_breath} x ${firstMaterial.material_height} ${firstMaterial.material_units}) -Qty: ${firstMaterial.quantity}`;
                                                                        }
                                                                    }
                                                                } catch (error) {
                                                                    console.error("Error parsing destination:", error);
                                                                    return "Error in material data"; // Fallback message on parsing error
                                                                }
                                                            })()}
                                                        </p>
                                                        <p className='mt-10p'>
                                                            <img src={require('../../assets/images/trucktype.png')} alt="Truck Type Icon" className="icon1" />
                                                            <strong>{indent.type === 'sea' ? 'Container Type' : 'Truck Type'}</strong>:&nbsp;
                                                            {![undefined, null, "", "undefined"].includes(
                                                                indent.type === 'sea'
                                                                    ? indent.container_type
                                                                    : (indent.type_of_truck === 'others' ? indent.selected_truck_type : indent.type_of_truck)
                                                            )
                                                                ? (indent.type === 'sea'
                                                                    ? indent.container_type
                                                                    : (indent.type_of_truck === 'others' ? indent.selected_truck_type : indent.type_of_truck))
                                                                : ""}
                                                        </p>

                                                        <p className='mt-10p'>
                                                            {indent.type === 'sea' ? (
                                                                <>
                                                                    <img src={require('../../assets/images/quantity.png')} alt="Quantity Icon" className="icon1" />
                                                                    <strong>Quantity</strong>:&nbsp;
                                                                    {indent.confirmed_quantity}/{indent.quantity}&nbsp;
                                                                    ({Array.isArray(indent.containers) ?
                                                                        indent.containers.map((container, index) =>
                                                                            `${container.container_type}:${container.container_qty}`
                                                                        ).join(', ') : ''}
                                                                    )
                                                                    <br />
                                                                </>
                                                            ) :
                                                                (
                                                                    <>
                                                                        <img src={require('../../assets/images/quantity.png')} alt="Quantity Icon" className="icon1" />
                                                                        <strong>Total Distance</strong>:&nbsp;
                                                                        {indent.total_km}
                                                                        <br />
                                                                    </>
                                                                )}
                                                        </p>
                                                        <p className='mt-10p'>
                                                            <img src={require('../../assets/images/startdate.png')} alt="Start Date Icon" className="icon1" />
                                                            <strong>Pickup Date</strong>:&nbsp; {getHyphenYYYYMMDDHHMM(indent.pickup_date)}
                                                        </p>


                                                    </div>

                                                    <> <div className="column4">
                                                        <p className='mt-20p'>
                                                            {indent.type === 'sea' ?
                                                                (
                                                                    indent.cfsWarehouse_destination == 'true' ? (
                                                                        <div style={{ position: "relative", display: "inline-block" }}>
                                                                            <img
                                                                                src={require('../../assets/icons/warehouseenable.png')}
                                                                                alt="warehouse Icon"
                                                                                className="icon1"
                                                                                onMouseEnter={() => onIconHover(`warehouse-${index}`)}
                                                                                onMouseLeave={() => setHoveredIconId(null)}
                                                                            />
                                                                            {hoveredIconId === `warehouse-${index}` && (
                                                                                <div style={{
                                                                                    display: "inline-block",
                                                                                    padding: "8px",
                                                                                    backgroundColor: "#ffffff",
                                                                                    border: "1px solid #ccc",
                                                                                    borderRadius: "4px",
                                                                                    fontSize: "12px",
                                                                                    position: "absolute",
                                                                                    top: "-37px",
                                                                                    left: "0",
                                                                                    cursor: "pointer",
                                                                                    zIndex: "10",
                                                                                    width: "150px"
                                                                                }}>
                                                                                    Warehouse Charges
                                                                                    <div style={{
                                                                                        position: "absolute",
                                                                                        top: "100%",
                                                                                        left: "10px",
                                                                                        width: "0",
                                                                                        height: "0",
                                                                                        borderLeft: "5px solid transparent",
                                                                                        borderRight: "5px solid transparent",
                                                                                        borderTop: "5px solid #ccc"
                                                                                    }}></div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ position: "relative", display: "inline-block" }}>
                                                                            <img
                                                                                src={require('../../assets/icons/warehousedisable.png')}
                                                                                alt="warehouse Disabled Icon"
                                                                                className="icon1"
                                                                                onMouseEnter={() => onIconHover(`insurance-${index}`)}
                                                                                onMouseLeave={() => setHoveredIconId(null)}
                                                                            />
                                                                            {hoveredIconId === `warehouse-${index}` && (
                                                                                <div style={{
                                                                                    display: "inline-block",
                                                                                    padding: "8px",
                                                                                    backgroundColor: "#ffffff",
                                                                                    border: "1px solid #ccc",
                                                                                    borderRadius: "4px",
                                                                                    fontSize: "12px",
                                                                                    position: "absolute",
                                                                                    top: "-37px",
                                                                                    left: "0",
                                                                                    cursor: "pointer",
                                                                                    zIndex: "10",
                                                                                    width: "150px"
                                                                                }}>
                                                                                    Warehouse Charges
                                                                                    <div style={{
                                                                                        position: "absolute",
                                                                                        top: "100%",
                                                                                        left: "10px",
                                                                                        width: "0",
                                                                                        height: "0",
                                                                                        borderLeft: "5px solid transparent",
                                                                                        borderRight: "5px solid transparent",
                                                                                        borderTop: "5px solid #ccc"
                                                                                    }}></div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )
                                                                : (
                                                                    <div style={{ position: "relative", display: "inline-block" }}>
                                                                        {/* <img
                                                                                src={require('../../assets/images/insurance.png')}
                                                                                alt="Insurance Icon"
                                                                                className="icon1"
                                                                                onMouseEnter={() => onIconHover(`insurance-${index}`)}
                                                                                onMouseLeave={() => setHoveredIconId(null)}
                                                                            /> */}
                                                                        <i
                                                                            className="icofont icofont-eye icon1 f20"
                                                                            style={{ cursor: 'pointer', marginBottom: "-3px" }}
                                                                            onMouseEnter={() => onIconHover(`insurance-${index}`)}
                                                                            onClick={() => onshowPreview(indent)}
                                                                            onMouseLeave={() => setHoveredIconId(null)}
                                                                        ></i>
                                                                        {hoveredIconId === `insurance-${index}` && (
                                                                            <div style={{
                                                                                display: "inline-block",
                                                                                padding: "8px",
                                                                                backgroundColor: "#ffffff",
                                                                                border: "1px solid #ccc",
                                                                                borderRadius: "4px",
                                                                                fontSize: "12px",
                                                                                position: "absolute",
                                                                                top: "-37px",
                                                                                left: "0",
                                                                                cursor: "pointer",
                                                                                zIndex: "10",
                                                                                width: "150px"
                                                                            }}>
                                                                                View Preview
                                                                                <div style={{
                                                                                    position: "absolute",
                                                                                    top: "100%",
                                                                                    left: "10px",
                                                                                    width: "0",
                                                                                    height: "0",
                                                                                    borderLeft: "5px solid transparent",
                                                                                    borderRight: "5px solid transparent",
                                                                                    borderTop: "5px solid #ccc"
                                                                                }}>

                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                        </p>



                                                        <p className='mt-10p'>
                                                            {indent.type === 'sea' ?
                                                                (
                                                                    indent.port_destination === 'true' ? (
                                                                        <div style={{ position: "relative", display: "inline-block" }}>
                                                                            <img
                                                                                src={require('../../assets/icons/Group.png')}
                                                                                alt="Port Icon"
                                                                                className="icon1"
                                                                                onMouseEnter={() => onIconHover(`port-${index}`)}
                                                                                onMouseLeave={() => setHoveredIconId(null)}
                                                                            />
                                                                            {hoveredIconId === `port-${index}` && (
                                                                                <div style={{
                                                                                    display: "inline-block",
                                                                                    padding: "8px",
                                                                                    backgroundColor: "#ffffff",
                                                                                    border: "1px solid #ccc",
                                                                                    borderRadius: "4px",
                                                                                    fontSize: "12px",
                                                                                    position: "absolute",
                                                                                    top: "-37px",
                                                                                    left: "0",
                                                                                    cursor: "pointer",
                                                                                    zIndex: "10",
                                                                                    width: "150px"
                                                                                }}>
                                                                                    Port Charges
                                                                                    <div style={{
                                                                                        position: "absolute",
                                                                                        top: "100%",
                                                                                        left: "10px",
                                                                                        width: "0",
                                                                                        height: "0",
                                                                                        borderLeft: "5px solid transparent",
                                                                                        borderRight: "5px solid transparent",
                                                                                        borderTop: "5px solid #ccc"
                                                                                    }}></div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ position: "relative", display: "inline-block" }}>
                                                                            {/* <img
                                                                        src={require('../../assets/icons/portdisable.png')}
                                                                        alt="Disabled Port Icon"
                                                                        className="icon1"
                                                                        onMouseEnter={() => onIconHover(`port-${index}`)}
                                                                        onMouseLeave={() => setHoveredIconId(null)}
                                                                    /> */}
                                                                            {hoveredIconId === `port-${index}` && (
                                                                                <div style={{
                                                                                    display: "inline-block",
                                                                                    padding: "8px",
                                                                                    backgroundColor: "#ffffff",
                                                                                    border: "1px solid #ccc",
                                                                                    borderRadius: "4px",
                                                                                    fontSize: "12px",
                                                                                    position: "absolute",
                                                                                    top: "-37px",
                                                                                    left: "0",
                                                                                    cursor: "pointer",
                                                                                    zIndex: "10",
                                                                                    width: "150px"
                                                                                }}>
                                                                                    Port Charges
                                                                                    <div style={{
                                                                                        position: "absolute",
                                                                                        top: "100%",
                                                                                        left: "10px",
                                                                                        width: "0",
                                                                                        height: "0",
                                                                                        borderLeft: "5px solid transparent",
                                                                                        borderRight: "5px solid transparent",
                                                                                        borderTop: "5px solid #ccc"
                                                                                    }}></div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                ) : (
                                                                    indent.over_length === 1 ? (
                                                                        <div style={{ position: "relative", display: "inline-block" }}>
                                                                            <img
                                                                                src={require('../../assets/images/overload.png')}
                                                                                alt="Over Length Icon"
                                                                                className="icon1"
                                                                                onMouseEnter={() => onIconHover(`overlength-${index}`)}
                                                                                onMouseLeave={() => setHoveredIconId(null)}
                                                                            />
                                                                            {hoveredIconId === `overlength-${index}` && (
                                                                                <div style={{
                                                                                    display: "inline-block",
                                                                                    padding: "8px",
                                                                                    backgroundColor: "#ffffff",
                                                                                    border: "1px solid #ccc",
                                                                                    borderRadius: "4px",
                                                                                    fontSize: "12px",
                                                                                    position: "absolute",
                                                                                    top: "-37px",
                                                                                    left: "0",
                                                                                    cursor: "pointer",
                                                                                    zIndex: "10",
                                                                                    width: "150px"
                                                                                }}>
                                                                                    Over Length
                                                                                    <div style={{
                                                                                        position: "absolute",
                                                                                        top: "100%",
                                                                                        left: "10px",
                                                                                        width: "0",
                                                                                        height: "0",
                                                                                        borderLeft: "5px solid transparent",
                                                                                        borderRight: "5px solid transparent",
                                                                                        borderTop: "5px solid #ccc"
                                                                                    }}></div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ position: "relative", display: "inline-block" }}>
                                                                            <img
                                                                                src={require('../../assets/images/overlengthdisable .png')}
                                                                                alt="Disabled Over Length Icon"
                                                                                className="icon1"
                                                                                onMouseEnter={() => onIconHover(`overlength-${index}`)}
                                                                                onMouseLeave={() => setHoveredIconId(null)}
                                                                            />
                                                                            {hoveredIconId === `overlength-${index}` && (
                                                                                <div style={{
                                                                                    display: "inline-block",
                                                                                    padding: "8px",
                                                                                    backgroundColor: "#ffffff",
                                                                                    border: "1px solid #ccc",
                                                                                    borderRadius: "4px",
                                                                                    fontSize: "12px",
                                                                                    position: "absolute",
                                                                                    top: "-37px",
                                                                                    left: "0",
                                                                                    cursor: "pointer",
                                                                                    zIndex: "10",
                                                                                    width: "150px"
                                                                                }}>
                                                                                    Over Length
                                                                                    <div style={{
                                                                                        position: "absolute",
                                                                                        top: "100%",
                                                                                        left: "10px",
                                                                                        width: "0",
                                                                                        height: "0",
                                                                                        borderLeft: "5px solid transparent",
                                                                                        borderRight: "5px solid transparent",
                                                                                        borderTop: "5px solid #ccc"
                                                                                    }}></div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                        </p>

                                                        <p className='mt-10p'>
                                                            {indent.over_height == 1 ?
                                                                <>
                                                                    <div style={{ position: "relative", display: "inline-block" }}>
                                                                        <img src={require('../../assets/images/OB.png')} alt="Destination Icon" className="icon1" onMouseEnter={() => onIconHover(`overheight-${index}`)}
                                                                            onMouseLeave={() => setHoveredIconId(null)} />
                                                                        {hoveredIconId === `overheight-${index}` && (
                                                                            <div style={{
                                                                                display: "inline-block",
                                                                                padding: "8px",
                                                                                backgroundColor: "#ffffff",
                                                                                border: "1px solid #ccc",
                                                                                borderRadius: "4px",
                                                                                fontSize: "12px",
                                                                                position: "absolute",  // Tooltip positioned absolutely to its container
                                                                                top: "-37px",  // Position above the image
                                                                                left: "0",  // Adjust the horizontal position as needed (relative to the image)
                                                                                cursor: "pointer",
                                                                                zIndex: "10",
                                                                                width: "150px"
                                                                            }}>
                                                                                Over Breath
                                                                                <div style={{
                                                                                    position: "absolute",
                                                                                    top: "100%",  // Position the arrow at the bottom of the tooltip
                                                                                    left: "0px",  // Adjust to control where the arrow points horizontally
                                                                                    width: "0",
                                                                                    height: "0",
                                                                                    borderLeft: "5px solid transparent",
                                                                                    borderRight: "5px solid transparent",
                                                                                    borderTop: "5px solid #ccc"  // Color matches the tooltip border
                                                                                }}></div>
                                                                            </div>
                                                                        )}</div>
                                                                </> :
                                                                <><div style={{ position: "relative", display: "inline-block" }}><img src={require('../../assets/images/OB_disable.png')} alt="Source Icon" className="icon1" onMouseEnter={() => onIconHover(`overheight-${index}`)}
                                                                    onMouseLeave={() => setHoveredIconId(null)} />
                                                                    {hoveredIconId === `overheight-${index}` && (
                                                                        <div style={{
                                                                            display: "inline-block",
                                                                            padding: "8px",
                                                                            backgroundColor: "#ffffff",
                                                                            border: "1px solid #ccc",
                                                                            borderRadius: "4px",
                                                                            fontSize: "12px",
                                                                            position: "absolute",  // Tooltip positioned absolutely to its container
                                                                            top: "-37px",  // Position above the image
                                                                            left: "0",  // Adjust the horizontal position as needed (relative to the image)
                                                                            cursor: "pointer",
                                                                            zIndex: "10",
                                                                            width: "150px"
                                                                        }}>
                                                                            Over Breath
                                                                            <div style={{
                                                                                position: "absolute",
                                                                                top: "100%",  // Position the arrow at the bottom of the tooltip
                                                                                left: "0px",  // Adjust to control where the arrow points horizontally
                                                                                width: "0",
                                                                                height: "0",
                                                                                borderLeft: "5px solid transparent",
                                                                                borderRight: "5px solid transparent",
                                                                                borderTop: "5px solid #ccc"  // Color matches the tooltip border
                                                                            }}></div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                </>
                                                            }
                                                        </p>
                                                    </div>
                                                        <div className="column4">
                                                            <p className='mt-20p'>
                                                                <div className='cust-add1' style={{ position: "relative", display: "inline-block" }}>
                                                                    {indent.type == 'sea' ? <img
                                                                        src={indent.additional_comments_check == 1 ? require('../../assets/images/group2.png') : require('../../assets/images/additionalcommnetsdisableicon.png')}
                                                                        alt="Distance Icon"
                                                                        className="icon1"
                                                                        onClick={() => {
                                                                            if (indent.additional_details) {
                                                                                setModelForAdditionalComments(true);
                                                                                setAdditionalComments(indent.additional_details)
                                                                                setHoveredIconId("")
                                                                            }
                                                                        }}
                                                                        onMouseEnter={() => onIconHover(`AdditionalComments-${index}`)}
                                                                        onMouseLeave={() => setHoveredIconId(null)}
                                                                    /> : <img
                                                                        src={indent.additional_comments ? require('../../assets/images/group2.png') : require('../../assets/images/additionalcommnetsdisableicon.png')}
                                                                        alt="Distance Icon"
                                                                        className="icon1"
                                                                        onClick={() => {
                                                                            if (indent.additional_comments) {
                                                                                setModelForAdditionalComments(true);
                                                                                setAdditionalComments(indent.additional_comments)
                                                                                setHoveredIconId("")
                                                                            }

                                                                        }}
                                                                        onMouseEnter={() => onIconHover(`AdditionalComments-${index}`)}
                                                                        onMouseLeave={() => setHoveredIconId(null)}
                                                                    />}

                                                                    {(
                                                                        <div className='cust-tooltip-add' style={{
                                                                            display: "none",
                                                                            padding: "8px",
                                                                            backgroundColor: "#ffffff",
                                                                            border: "1px solid #ccc",
                                                                            borderRadius: "4px",
                                                                            fontSize: "12px",
                                                                            position: "absolute",  // Tooltip positioned absolutely to its container
                                                                            top: "-59px",  // Position above the image
                                                                            left: "0",  // Adjust the horizontal position as needed (relative to the image)
                                                                            cursor: "pointer",
                                                                            zIndex: "10",
                                                                            width: "150px"
                                                                        }}>
                                                                            Additional Comments
                                                                            <div style={{
                                                                                position: "absolute",
                                                                                top: "100%",  // Position the arrow at the bottom of the tooltip
                                                                                left: "0px",  // Adjust to control where the arrow points horizontally
                                                                                width: "0",
                                                                                height: "0",
                                                                                borderLeft: "5px solid transparent",
                                                                                borderRight: "5px solid transparent",
                                                                                borderTop: "5px solid #ccc"  // Color matches the tooltip border
                                                                            }}></div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </p>
                                                            {indent.type == 'sea' ? <>
                                                                <p className='mt-10p' >
                                                                    <div style={{ position: "relative", display: "inline-block" }}>
                                                                        <img
                                                                            src={require('../../assets/images/download_doc.png')}
                                                                            alt="Distance Icon"
                                                                            className="icon1"
                                                                            onClick={() => {
                                                                                onClickViewPOD(indent.upload_for_road); // Pass indent_id to onClickViewPOD
                                                                            }}
                                                                            onMouseEnter={() => onIconHover(`ViewBids-${index}`)}
                                                                            onMouseLeave={() => setHoveredIconId(null)}
                                                                        />
                                                                        {/* <img src={require('../../assets/images/downloadpaymentterms.png')} alt="Destination Icon" className="icon1" onClick={() => {
                                                                            if (indent.payment_terms && indent.payment_terms.length > 0) {
                                                                                window.open(indent.payment_terms, '_blank', 'noopener,noreferrer')
                                                                            }
                                                                        }} onMouseEnter={() => onIconHover(`payment_terms-${index}`)}
                                                                            onMouseLeave={() => setHoveredIconId(null)} /> */}
                                                                        {/* {hoveredIconId === `payment_terms-${index}` && (
                                                                            <div style={{
                                                                                display: "inline-block",
                                                                                padding: "8px",
                                                                                backgroundColor: "#ffffff",
                                                                                border: "1px solid #ccc",
                                                                                borderRadius: "4px",
                                                                                fontSize: "12px",
                                                                                position: "absolute",  // Tooltip positioned absolutely to its container
                                                                                top: "-37px",  // Position above the image
                                                                                left: "0",  // Adjust the horizontal position as needed (relative to the image)
                                                                                cursor: "pointer",
                                                                                zIndex: "10",
                                                                                width: "150px"
                                                                            }}>
                                                                                Payment Terms
                                                                                <div style={{
                                                                                    position: "absolute",
                                                                                    top: "100%",  // Position the arrow at the bottom of the tooltip
                                                                                    left: "0px",  // Adjust to control where the arrow points horizontally
                                                                                    width: "0",
                                                                                    height: "0",
                                                                                    borderLeft: "5px solid transparent",
                                                                                    borderRight: "5px solid transparent",
                                                                                    borderTop: "5px solid #ccc"  // Color matches the tooltip border
                                                                                }}></div>
                                                                            </div>
                                                                        )} */}
                                                                    </div>
                                                                </p>
                                                            </> : <>
                                                                <p className='mt-10p' >
                                                                    <div style={{ position: "relative", display: "inline-block" }}>
                                                                        <img
                                                                            src={require('../../assets/images/download_doc.png')}
                                                                            alt="Distance Icon"
                                                                            className="icon1"
                                                                            onClick={() => {
                                                                                onClickViewPOD(indent.upload_for_road); // Pass indent_id to onClickViewPOD
                                                                            }}
                                                                            onMouseEnter={() => onIconHover(`ViewBids-${index}`)}
                                                                            onMouseLeave={() => setHoveredIconId(null)}
                                                                        />
                                                                        {hoveredIconId === `ViewBids-${index}` && (
                                                                            <div style={{
                                                                                display: "inline-block",
                                                                                padding: "8px",
                                                                                backgroundColor: "#ffffff",
                                                                                border: "1px solid #ccc",
                                                                                borderRadius: "4px",
                                                                                fontSize: "12px",
                                                                                position: "absolute",  // Tooltip positioned absolutely to its container
                                                                                top: "-37px",  // Position above the image
                                                                                left: "0",  // Adjust the horizontal position as needed (relative to the image)
                                                                                cursor: "pointer",
                                                                                zIndex: "10",
                                                                                width: "150px"
                                                                            }}>
                                                                                File
                                                                                <div style={{
                                                                                    position: "absolute",
                                                                                    top: "100%",  // Position the arrow at the bottom of the tooltip
                                                                                    left: "0px",  // Adjust to control where the arrow points horizontally
                                                                                    width: "0",
                                                                                    height: "0",
                                                                                    borderLeft: "5px solid transparent",
                                                                                    borderRight: "5px solid transparent",
                                                                                    borderTop: "5px solid #ccc"  // Color matches the tooltip border
                                                                                }}></div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </p>
                                                                {/* <p className='mt-20p'>
                                                                    <div style={{ position: "relative", display: "inline-block" }}>
                                                                        {<img
                                                                            src={indent.show_bids == 1 ? require('../../assets/images/viewbidicon.png') : require('../../assets/images/viewbiddisableicon.png')}
                                                                            alt="Distance Icon"
                                                                            className="icon1"
                                                                            onClick={() => {
                                                                                if (localStorage.getItem("user_type") == "TRANSPORTER" && indent.show_bids == 1) {
                                                                                    setModelForShowBids(true);
                                                                                    setVendorQoutes(indent.vendorquotes)
                                                                                    setHoveredIconId("")
                                                                                }
                                                                            }}
                                                                            onMouseEnter={() => onIconHover(`ViewBids-${index}`)}
                                                                            onMouseLeave={() => setHoveredIconId(null)}
                                                                        />}

                                                                        {hoveredIconId === `ViewBids-${index}` && (
                                                                            <div style={{
                                                                                display: "inline-block",
                                                                                padding: "8px",
                                                                                backgroundColor: "#ffffff",
                                                                                border: "1px solid #ccc",
                                                                                borderRadius: "4px",
                                                                                fontSize: "12px",
                                                                                position: "absolute",  // Tooltip positioned absolutely to its container
                                                                                top: "-37px",  // Position above the image
                                                                                left: "0",  // Adjust the horizontal position as needed (relative to the image)
                                                                                cursor: "pointer",
                                                                                zIndex: "10",
                                                                                width: "150px"
                                                                            }}>
                                                                                {indent.show_bids == 1 ? "File" : 'File'}
                                                                                <div style={{
                                                                                    position: "absolute",
                                                                                    top: "100%",  // Position the arrow at the bottom of the tooltip
                                                                                    left: "0px",  // Adjust to control where the arrow points horizontally
                                                                                    width: "0",
                                                                                    height: "0",
                                                                                    borderLeft: "5px solid transparent",
                                                                                    borderRight: "5px solid transparent",
                                                                                    borderTop: "5px solid #ccc"  // Color matches the tooltip border
                                                                                }}></div>
                                                                            </div>
                                                                        )}

                                                                    </div>
                                                                </p> */}


                                                            </>}

                                                        </div>

                                                        {/* Fourth Column: Validity, Pricing, Ranking */}
                                                        <div style={{ backgroundColor: "#f0faff", padding: "5px", position: 'relative', flex: 1 }}>


                                                            {/* {text} */}
                                                            {indent.indent_assigned !== 1 && (
                                                                <div style={{
                                                                    backgroundColor: "#32CD32",
                                                                    padding: "2px 10px",
                                                                    borderBottomLeftRadius: "12px",
                                                                    color: "#fff",
                                                                    position: "absolute",
                                                                    fontSize: "14px",
                                                                    top: "0px",
                                                                    right: "0px"
                                                                }}>
                                                                    <strong>Validity: </strong>
                                                                    <CountdownTimer expirationTime={expirationTime} indentId={indent.indent_id} />
                                                                </div>
                                                            )}

                                                            <p className='mt-20p' ><strong>Base Price</strong>:&nbsp;  <span style={{ fontSize: "18px", fontWeight: "bold" }}>{indent.base_price || '0'}</span></p>
                                                            {localStorage.getItem('roles') == 'AL_ADMIN' ? <><>
                                                                <p className='mt-10p'>
                                                                    <strong>Best Quotation</strong>:&nbsp; <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                                                                        {/* {indent.indent_assigned == 1
                                                                            ? indent.finalized_value
                                                                            : (indent.vendorquotes && indent.vendorquotes.length > 0
                                                                                ? indent.vendorquotes[0].bid_price
                                                                                : '0')} */}
                                                                        {indent.indent_assigned == 1
                                                                            ? indent.finalized_value
                                                                            : indent.confirmed_value}
                                                                    </span>
                                                                </p>
                                                                <p className='mt-10p'>
                                                                    <strong>Participants</strong>: &nbsp;
                                                                    <span className="ranking" onMouseEnter={() => onIconHover(`participants-${index}`)}
                                                                            
                                                                            onMouseLeave={() => setHoveredIconId(null)}>
                                                                        {(indent.vendorquotes && indent.vendorquotes.length) || 0} /
                                                                        <span style={{ color: '#5C97B5' }} >
                                                                            {/* {(indent.vendors_names ? indent.vendors_names.length : 0)} */}
                                                                            {(indent.vendors_names ? JSON.parse(indent.vendors_names).length : 0)}
                                                                        </span>

                                                                    </span>
                                                                   
                                                                    {hoveredIconId === `participants-${index}` && (
                                                                            <div style={{
                                                                                display: "inline-block",
                                                                                padding: "8px",
                                                                                backgroundColor: "#ffffff",
                                                                                border: "1px solid #ccc",
                                                                                borderRadius: "4px",
                                                                                fontSize: "12px",
                                                                                // position: "absolute",
                                                                                top: "-37px",
                                                                                left: "0",
                                                                                cursor: "pointer",
                                                                                zIndex: "10",
                                                                                width: "150px"
                                                                            }}>
{indent.vendors_names 
            ? JSON.parse(indent.vendors_names).join(", ") 
            : "No vendors available"}                                                                                <div style={{
                                                                                    position: "absolute",
                                                                                    top: "100%",
                                                                                    left: "10px",
                                                                                    width: "0",
                                                                                    height: "0",
                                                                                    borderLeft: "5px solid transparent",
                                                                                    borderRight: "5px solid transparent",
                                                                                    borderTop: "5px solid #ccc"
                                                                                }}>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        )}


                                                                    {indent.vendorquotes && indent.vendorquotes.length > 0 ? (
                                                                        <>
                                                                            <i
                                                                                className="icofont icofont-eye f20"
                                                                                style={{ cursor: "pointer", color: "#1463CE", marginLeft: "10px", marginBottom: "-3px" }}
                                                                                onClick={() => onClickBidNow(indent)}
                                                                            ></i>

                                                                            <div
                                                                                onClick={() => fetchNotificationsData(indent.indent_id)}
                                                                                className="notification-bell"
                                                                                style={{ marginLeft: "10px", cursor: "pointer" }}
                                                                            >
                                                                                <i
                                                                                    className="icofont icofont-bell-alt f22"
                                                                                    style={{ fontSize: 18, color: "grey" }}
                                                                                ></i>

                                                                                {indent.notification_count > 0 && (
                                                                                    <span className="notification-count">{indent.notification_count}</span>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        indent.is_draft === 1 && (
                                                                            <i
                                                                                className="icofont icofont-pen-alt-4 f16"
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    fontSize: "18px",
                                                                                    color: "#1463CE",
                                                                                    marginLeft: "10px",
                                                                                    marginBottom: "-3px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    window.location.href = `/editrfq/${indent._id}`;
                                                                                }}
                                                                            ></i>
                                                                        )
                                                                    )}

                                                                </p>


                                                            </></> : <>
                                                                {(indent.vendorquotes && indent.vendorquotes.filter(e => e.vendor_email_id == localStorage.getItem("email")).length > 0) ?

                                                                    <>
                                                                        <p className='mt-10p' >
                                                                            <strong>Your Quotation</strong>:&nbsp; <span style={{ fontSize: "18px", fontWeight: "bold" }}>{indent.vendorquotes.filter(e => e.vendor_email_id == localStorage.getItem("email"))[0].bid_price}</span>
                                                                            {/* <span className="edit-icon" onClick={() => onClickBidNow(indent)}>✏️</span> */}
                                                                        </p>
                                                                        <p className='mt-10p'  >
                                                                            <strong>Your Ranking</strong>:&nbsp; <span className="ranking">{`L${indent.vendorquotes.find(e => e.vendor_email_id == localStorage.getItem('email')).rank}`}</span>

                                                                            <i className="icofont icofont-eye f20" style={{ cursor: 'pointer', color: "#1463CE", marginLeft: "10px", marginBottom: "-3px" }} onClick={() => onClickBidNow(indent)}></i>

                                                                            <div
                                                                                onClick={() => fetchNotificationsData(indent.indent_id)}
                                                                                className="notification-bell"
                                                                                style={{ marginLeft: "10px", cursor: "pointer" }}
                                                                            >
                                                                                <i className="icofont icofont-bell-alt f22" style={{ fontSize: 18, color: 'grey' }}></i>
                                                                                {/* <FaRegBell size={18} color={'grey'} /> */}
                                                                                {indent.notification_count > 0 && (
                                                                                    <span className="notification-count">
                                                                                        {indent.notification_count}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </p>
                                                                    </>
                                                                    : <button
                                                                        type="submit"
                                                                        className="btn btn-success"
                                                                        style={{ cursor: 'pointer', borderRadius: "5px", marginTop: "10px" }}
                                                                        onClick={() => onClickBidNow(indent)} // Notice the arrow function
                                                                    >
                                                                        Bid Now
                                                                    </button>
                                                                }</>}
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                            {isCardOpen === indent.indent_id && (
                                                <div key={index} className="card ml-30p mr-30p" style={{ marginTop: "-24px" }}>
                                                    {localStorage.getItem("roles") === "AL_ADMIN" && indent.vendorquotes && indent.vendorquotes.length > 0 && (
                                                        <>
                                                            <table className="table table-bordered table-hover text-center">
                                                                <thead className="table-primary">
                                                                    <tr>
                                                                        <th rowSpan="2">Rank</th>
                                                                        <th rowSpan="2">Bid Price</th>
                                                                        {/* <th rowSpan="2">No Of Deliveries</th> */}
                                                                        <th rowSpan="2">Transit Time</th>
                                                                        <th rowSpan="2">Vendor Name</th>
                                                                        <th rowSpan="2">Vendor SOB</th>
                                                                        <th rowSpan="2">Action</th>
                                                                        <th colSpan="3" style={{ textAlign: "center" }}>Negotiations</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Proposed Value</th>
                                                                        <th>Vendor Value</th>
                                                                        <th>History</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {displayedVendorQuotes.map((bid, index) => (
                                                                        <tr key={index}>
                                                                            {/* <td>{`L${index + 1}`}</td> */}
                                                                            <td>
                                                                                {`L${bid.rank}`}
                                                                            </td>
                                                                            <td>
                                                                                {bidHistory
                                                                                    .filter(e => e.vendor_email_id === bid.vendor_email_id)
                                                                                    .map((e, index, arr) =>
                                                                                        index === arr.length - 1
                                                                                            ?
                                                                                            <strong key={e.bid_id}>{`${e.bid_price}`}</strong>
                                                                                            : `${e.bid_price}`
                                                                                    )
                                                                                    .reduce((acc, curr, idx) => idx === 0 ? [curr] : [...acc, '<<', curr], [])
                                                                                }
                                                                            </td>
                                                                            {/* <td>
                                                                                {bidHistory
                                                                                    .filter(e => e.vendor_email_id === bid.vendor_email_id)
                                                                                    .map((e, index, arr) =>
                                                                                        index === arr.length - 1
                                                                                            ?
                                                                                            <strong key={e.bid_id}>{`${e.no_of_deliveries}`}</strong>
                                                                                            : `${e.no_of_deliveries}`
                                                                                    )
                                                                                    .reduce((acc, curr, idx) => idx === 0 ? [curr] : [...acc, '<<', curr], [])


                                                                                }
                                                                            </td> */}
                                                                            <td>
                                                                                {bidHistory
                                                                                    .filter(e => e.vendor_email_id === bid.vendor_email_id)
                                                                                    .slice(0, 1) // Select the first element (if it exists)
                                                                                    .map(e => <strong key={e.bid_id}>{`${e.transit_time}`}</strong>)
                                                                                }
                                                                            </td>

                                                                            <td>
                                                                                {indent.vendordetails && indent.vendordetails.filter(e => e.email === bid.vendor_email_id)[0]
                                                                                    ? indent.vendordetails.filter(e => e.email === bid.vendor_email_id)[0].source
                                                                                    : ''
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {Array.from({
                                                                                    length: indent.vendordetails && indent.vendordetails.filter(e => e.email_id === bid.vendor_email_id)[0]
                                                                                        ? indent.vendordetails.filter(e => e.email_id === bid.vendor_email_id)[0].rating || 0
                                                                                        : 0
                                                                                }, (_, i) => '★').join('')}
                                                                            </td>

                                                                            {/*  */}
                                                                            <td>
    {indent.rejected === 1 ? (
        <div>
            <button
                type="button"
                className="btn btn-danger"
                style={{ padding: "3px 26px", marginRight: "5px" }}
            >
                Rejected
            </button>
        </div>
    ) : bid.in_negotiation === 0 ? (
        <div>
            <button
                type="button"
                className="btn btn-success"
                style={{ padding: "3px 26px", marginRight: "5px" }}
            >
                Accepted
            </button>
        </div>
    ) : (new Date() > moment
          .parseZone(new Date(indent.bid_start_date))
          .utcOffset("-05:30")
          .add(parseInt(indent.quotation_validity_hours), "hours")._d &&
        indent.quantity === indent.confirmed_quantity) ||
      [1].includes(bid.pending_vendor_approval) ? (
        <button
            type="button"
            className="btn btn-danger"
            style={{ padding: "3px 26px", marginRight: "5px" }}
        >
            In Progress
        </button>
    ) : (
        <>
            <button
                type="button"
                className="btn btn-info"
                style={{ padding: "3px 26px", cursor: "pointer" }}
                onClick={(e) => {
                    e.stopPropagation();
                    console.log(bid.vendor_email_id);

                    const vendorNegotiation = negotiations.find(
                        (e) => e.vendor_email_id === bid.vendor_email_id
                    );

                    const vendorValue =
                        vendorNegotiation && vendorNegotiation.vendor_value
                            ? vendorNegotiation.vendor_value
                            : bid.bid_price || null;

                    console.log(vendorValue, "vendorValue");

                    onClickAcceptQuotation(
                        indent.indent_id,
                        bid.bid_price,
                        bid.no_of_deliveries,
                        bid.vendor_email_id,
                        indent.created_by,
                        indent,
                        vendorValue
                    );
                }}
            >
                Accept
            </button>

            {(() => {
                const vendorNegotiation = negotiations.filter(
                    (e) => e.vendor_email_id === bid.vendor_email_id
                );

                if (vendorNegotiation.length === 0) {
                    return (
                        <button
                            type="button"
                            className="btn btn-danger"
                            style={{
                                padding: "3px 26px",
                                cursor: "pointer",
                                marginLeft: "5px",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                const current_date = new Date();
                                const end_date = moment
                                    .parseZone(new Date(indent.bid_start_date))
                                    .utcOffset("-05:30")
                                    .add(parseInt(indent.quotation_validity_hours), "hours")._d;

                                if (
                                    current_date < end_date &&
                                    ![0, 1].includes(bid.bid_winner) &&
                                    isProposedInputOpen !== `${indent.indent_id}_${`L${index + 1}`}`
                                ) {
                                    setInputTextValue("");
                                    setInputTextQuantity("");
                                    setIsProposedInputOpen(`${indent.indent_id}_${`L${index + 1}`}`);
                                }
                            }}
                        >
                            Negotiate
                        </button>
                    );
                }

                return null; // Do not render the button if vendorNegotiation exists
            })()}
        </>
    )}
</td>

                                                                            <td
                                                                                onClick={() => {
                                                                                    var current_date = new Date();
                                                                                    var end_date = moment
                                                                                        .parseZone(new Date(indent.bid_start_date))
                                                                                        .utcOffset("-05:30")
                                                                                        .add(parseInt(indent.quotation_validity_hours), "hours")._d;

                                                                                    if (
                                                                                        current_date < end_date &&
                                                                                        ![0, 1].includes(bid.bid_winner) && bid.negotiation_closed != 1 &&
                                                                                        isProposedInputOpen !== `${indent.indent_id}_${`L${index + 1}`}`
                                                                                    ) {
                                                                                        const vendorNegotiation = negotiations.find(
                                                                                            (e) => e.vendor_email_id === bid.vendor_email_id
                                                                                        );

                                                                                        setInputTextValue(
                                                                                            vendorNegotiation && vendorNegotiation.proposed_value
                                                                                                ? vendorNegotiation.proposed_value
                                                                                                : ""
                                                                                        );
                                                                                        setInputTextQuantity(
                                                                                            vendorNegotiation && vendorNegotiation.proposed_quantity
                                                                                                ? vendorNegotiation.proposed_quantity
                                                                                                : bid.bid_quantity
                                                                                        );
                                                                                        setIsProposedInputOpen(`${indent.indent_id}_${`L${index + 1}`}`);
                                                                                    }
                                                                                }}
                                                                            >


{isProposedInputOpen === `${indent.indent_id}_${`L${index + 1}`}` &&
   
    bid.vendor_accepted != 1 &&
    bid.pending_vendor_approval != 1 ? (
        <div>
            <input
                type="text"
                autoFocus
                value={inputTextValue}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                        setInputTextValue(value);
                    }
                }}
                placeholder="Enter value"
                style={{ padding: '5px', marginRight: '10px', width: '100px' }}
                required
            />
            <button
                style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                onClick={() => {
                    const vendorNegotiation = negotiations.find(
                        (e) => e.vendor_email_id === bid.vendor_email_id
                    );
                    const proposedValue = vendorNegotiation
                        ? vendorNegotiation.proposed_value
                        : undefined;

                    if (!inputTextValue) {
                        setShow(true);
                        setBasicType('danger');
                        setBasicTitle('Negotiation value cannot be empty');
                        return;
                    }
                    if (inputTextValue === proposedValue) {
                        setShow(true);
                        setBasicType('danger');
                        setBasicTitle(
                            'Negotiation value cannot be the same as the previous one'
                        );
                        return;
                    }

                    submitNegotiateValue(
                        indent.indent_id,
                        bid.vendor_email_id,
                        indent.created_by
                    );
                }}
            >
                <i
                    className="icofont icofont-arrow-right f20"
                    style={{ color: 'green' }}
                ></i>
            </button>
        </div>
    ) : (
        (() => {
            const vendorNegotiation = negotiations.find(
                (e) => e.vendor_email_id === bid.vendor_email_id
            );
            return vendorNegotiation ? (
                <div>
                    {`${vendorNegotiation.proposed_value}`}
                    {bid.in_negotiation === 1 &&
                        bid.vendor_accepted != 1 &&
                        bid.pending_vendor_approval != 1 && (
                            <i
                                className="icofont icofont-edit"
                                style={{
                                    marginLeft: '10px',
                                    cursor: 'pointer',
                                    color: 'blue',
                                }}
                                onClick={() => {
                                    const vendorNegotiation = negotiations.find(
                                        (e) => e.vendor_email_id === bid.vendor_email_id
                                    );
                                    setInputTextValue(
                                        vendorNegotiation
                                            ? vendorNegotiation.proposed_value
                                            : ''
                                    );
                                    setInputTextQuantity(
                                        vendorNegotiation
                                            ? vendorNegotiation.proposed_quantity
                                            : bid.no_of_deliveries
                                    );
                                    setIsProposedInputOpen(
                                        `${indent.indent_id}_${`L${index + 1}`}`
                                    );
                                }}
                            ></i>
                        )}
                </div>
            ) : null;
        })()
    )}
                                                                          </td>



                                                                            <td>
                                                                                <div>
                                                                                    {(() => {
                                                                                        const vendorNegotiation = negotiations.find(e => e.vendor_email_id === bid.vendor_email_id);
                                                                                        return vendorNegotiation && vendorNegotiation.vendor_value ? (
                                                                                            <div>
                                                                                                {`${vendorNegotiation.vendor_value}`}
                                                                                            </div>
                                                                                        ) : null;
                                                                                    })()}
                                                                                </div>
                                                                            </td>

                                                                            <td>
                                                                                {negotiationsHistory
                                                                                    .filter(e => e.vendor_email_id === bid.vendor_email_id)
                                                                                    .map(e => `${e.negotiated_value}`)
                                                                                    .join('<<')
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            {indent.vendorquotes.length > 5 && !showAllRecords && (
                                                                <div style={{ textAlign: 'right' }}>
                                                                    <button onClick={toggleShowAllRecords} className="btn btn-primary" style={{ width: '122px' }}>
                                                                        Show More
                                                                    </button>
                                                                </div>
                                                            )}

                                                            {showAllRecords && (
                                                                <div style={{ textAlign: 'right' }}> <button onClick={toggleShowAllRecords} className="btn btn-secondary" style={{ width: '122px' }}>
                                                                    Show Less
                                                                </button></div>

                                                            )}
                                                        </>
                                                    )}
                                                    {localStorage.getItem("user_type") === "TRANSPORTER" &&
                                                        indent.vendorquotes && indent.vendorquotes.filter(e => e.vendor_email_id === localStorage.getItem("email")).length > 0 && (
                                                            <>
                                                                <table className="table table-bordered table-hover text-center">
                                                                    <thead className="table-primary">
                                                                        <tr>
                                                                            <th rowSpan="2">Rank</th>
                                                                            <th rowSpan="2">Bid Price</th>
                                                                            <th rowSpan="2">Transit Days</th>
                                                                            {/* <th rowSpan="2">Bid History</th> */}
                                                                            <th rowSpan="2">Action</th>
                                                                            <th colSpan="3" style={{ textAlign: "center" }}>Negotiations</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Proposed Value</th>
                                                                            <th>Your Value</th>
                                                                            <th>History</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {indent.vendorquotes
                                                                            .filter(e => e.vendor_email_id === localStorage.getItem("email"))
                                                                            .map((bid, index) => (
                                                                                <tr key={index}>
                                                                                    <td>{`L${indent.vendorquotes.findIndex(e => e.vendor_email_id === localStorage.getItem("email")) + 1}`}</td>
                                                                                    <td>{bid.bid_price}</td>
                                                                                    <td>{bid.transit_time}</td>
                                                                                    {/* <td>
                                                                                {bidHistory
                                                                                    .filter(e => e.vendor_email_id === bid.vendor_email_id)
                                                                                    .map((e, index, arr) =>
                                                                                        index === arr.length - 1
                                                                                            ? <strong key={e.bid_id}>{`${e.bid_price}`}</strong>
                                                                                            : `${e.bid_price}`
                                                                                    )
                                                                                    .reduce((acc, curr, idx) => idx === 0 ? [curr] : [...acc, '<<', curr], [])
                                                                                }
                                                                            </td> */}
                                                                                    <td style={{ gap: '5px' }}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className={`btn btn-${bid.bid_winner === 1
                                                                                                ? "success"
                                                                                                : bid.bid_winner === 0
                                                                                                    ? "danger"
                                                                                                    : bid.vendor_accepted === 1
                                                                                                        ? "info"
                                                                                                        : bid.vendor_rejected === 1
                                                                                                            ? "danger"
                                                                                                            : "danger"
                                                                                                }`}
                                                                                            style={{
                                                                                                padding: "3px 26px",
                                                                                                cursor:
                                                                                                    bid.vendor_accepted === 1 ||
                                                                                                        bid.vendor_rejected === 1 ||
                                                                                                        bid.vendor_accepted_negotiation === 1 ||
                                                                                                        ((new Date() > moment.parseZone(new Date(indent.bid_start_date))
                                                                                                            .utcOffset("-05:30")
                                                                                                            .add(parseInt(indent.quotation_validity_hours), "hours")._d &&
                                                                                                            indent.quantity === indent.confirmed_quantity) ||
                                                                                                            [1].includes(bid.pending_vendor_approval))
                                                                                                        ? "not-allowed"
                                                                                                        : "pointer",
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                // Prevent action if the button is "In Progress", "Rejected", or "Awarded"
                                                                                                if (
                                                                                                    bid.vendor_accepted === 1 ||
                                                                                                    bid.vendor_rejected === 1 ||
                                                                                                    bid.vendor_accepted_negotiation === 1 ||
                                                                                                    ((new Date() > moment.parseZone(new Date(indent.bid_start_date))
                                                                                                        .utcOffset("-05:30")
                                                                                                        .add(parseInt(indent.quotation_validity_hours), "hours")._d &&
                                                                                                        indent.quantity === indent.confirmed_quantity) ||
                                                                                                        [1].includes(bid.pending_vendor_approval))
                                                                                                ) {
                                                                                                    return; // Do nothing if the button is in "In Progress", "Rejected", or similar states
                                                                                                }

                                                                                                var current_date = new Date();
                                                                                                var end_date = moment
                                                                                                    .parseZone(new Date(indent.bid_start_date))
                                                                                                    .utcOffset("-05:30")
                                                                                                    .add(parseInt(indent.quotation_validity_hours), "hours")._d;

                                                                                                if (
                                                                                                    current_date < end_date &&
                                                                                                    bid.in_negotiation === 1 &&
                                                                                                    isVendorInputOpen !== `${indent.indent_id}_${`L${index + 1}`}`
                                                                                                ) {
                                                                                                    const vendorNegotiation = negotiations.find(
                                                                                                        (e) => e.vendor_email_id === bid.vendor_email_id
                                                                                                    );

                                                                                                    // Set values for negotiation input
                                                                                                    setInputTextValue(vendorNegotiation ? vendorNegotiation.vendor_value : "");
                                                                                                    setInputTextQuantity(
                                                                                                        vendorNegotiation ? vendorNegotiation.vendor_quantity : bid.no_of_deliveries
                                                                                                    );
                                                                                                    setIsVendorInputOpen(`${indent.indent_id}_${`L${index + 1}`}`);
                                                                                                }
                                                                                            }}
                                                                                            disabled={
                                                                                                bid.vendor_accepted === 1 ||
                                                                                                bid.vendor_rejected === 1 ||
                                                                                                bid.vendor_accepted_negotiation === 1 ||
                                                                                                ((new Date() > moment.parseZone(new Date(indent.bid_start_date))
                                                                                                    .utcOffset("-05:30")
                                                                                                    .add(parseInt(indent.quotation_validity_hours), "hours")._d &&
                                                                                                    indent.quantity === indent.confirmed_quantity) ||
                                                                                                    [1].includes(bid.pending_vendor_approval))
                                                                                            } // Disable button if in "In Progress", "Rejected", or similar states
                                                                                        >
                                                                                            {bid.bid_winner === 1
                                                                                                ? "Awarded"
                                                                                                : bid.vendor_rejected === 1||indent.rejected==1
                                                                                                    ? "Rejected"
                                                                                                    : bid.vendor_accepted === 1 || bid.vendor_accepted_negotiation === 1||indent.waiting_for_approval1==1
                                                                                                        ? "In Progress"
                                                                                                        : (new Date() > moment.parseZone(new Date(indent.bid_start_date))
                                                                                                            .utcOffset("-05:30")
                                                                                                            .add(parseInt(indent.quotation_validity_hours), "hours")._d &&
                                                                                                            indent.quantity === indent.confirmed_quantity) ||
                                                                                                            [1].includes(bid.pending_vendor_approval)
                                                                                                            ? "In Progress"
                                                                                                            : "Negotiate"}
                                                                                        </button>
                                                                                    </td>




                                                                                    {/* {bid.bid_winner == 1 ? 
                                                                                <button
                                                                                    type="button"
                                                                                    className={`btn btn-${bid.bid_winner === 1 ? "warning" : bid.bid_winner === 0 ? "danger" : "info"}`}
                                                                                    style={{ padding: "3px 26px" }} onClick={() =>
                                                                                        onShowdata(indent.indent_id)
                                                                                    }
                                                                                >
                                                                                    View Data
                                                                                </button> : ''} */}
                                                                                    {/* </td> */}
                                                                                    <td>
                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                                                                                            {(() => {
                                                                                                const vendorNegotiation = negotiations.find(e => e.vendor_email_id === bid.vendor_email_id);
                                                                                                return vendorNegotiation ? (
                                                                                                    <div>
                                                                                                        {`${vendorNegotiation.proposed_value}`}
                                                                                                    </div>
                                                                                                ) : null;
                                                                                            })()}
                                                                                            {negotiations.find(e => e.vendor_email_id === bid.vendor_email_id) &&
                                                                                                bid.vendor_accepted !== 1 &&
                                                                                                bid.pending_vendor_approval !== 1 && ( // Add condition to hide button when pending_vendor_approval is 1
                                                                                                    <button
                                                                                                        type='button'
                                                                                                        style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                                                                                                        onClick={() =>
                                                                                                            onClickAcceptNegotiation(
                                                                                                                indent.indent_id,
                                                                                                                negotiations.find(e => e.vendor_email_id === bid.vendor_email_id).proposed_value,
                                                                                                                negotiations.find(e => e.vendor_email_id === bid.vendor_email_id).proposed_quantity,
                                                                                                                bid.vendor_email_id,
                                                                                                                indent.created_by
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src={require('../../assets/images/approve.png')}
                                                                                                            alt="Source Icon"
                                                                                                            className="icon1"
                                                                                                        />
                                                                                                    </button>
                                                                                                )}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        onClick={() => {
                                                                                            var current_date = new Date();
                                                                                            var end_date = moment
                                                                                                .parseZone(new Date(indent.bid_start_date))
                                                                                                .utcOffset("-05:30")
                                                                                                .add(parseInt(indent.quotation_validity_hours), "hours")._d;

                                                                                            if (
                                                                                                current_date < end_date &&
                                                                                                bid.in_negotiation === 1 &&
                                                                                                isVendorInputOpen !== `${indent.indent_id}_${`L${index + 1}`}` &&
                                                                                                bid.vendor_accepted_negotiation !== 1 // Prevent opening the input if negotiation is already accepted
                                                                                            ) {
                                                                                                const vendorNegotiation = negotiations.find(
                                                                                                    (e) => e.vendor_email_id === bid.vendor_email_id
                                                                                                );
                                                                                                setInputTextValue(vendorNegotiation ? vendorNegotiation.vendor_value : "");
                                                                                                setInputTextQuantity(
                                                                                                    vendorNegotiation ? vendorNegotiation.vendor_quantity : bid.no_of_deliveries
                                                                                                );
                                                                                                setIsVendorInputOpen(`${indent.indent_id}_${`L${index + 1}`}`);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {isVendorInputOpen === `${indent.indent_id}_${`L${index + 1}`}` && bid.vendor_accepted_negotiation !== 1 ? (
                                                                                            <div>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    autoFocus
                                                                                                    value={inputTextValue}
                                                                                                    onChange={(e) => {
                                                                                                        const value = e.target.value;
                                                                                                        if (/^\d*$/.test(value)) {
                                                                                                            setInputTextValue(value);
                                                                                                        }
                                                                                                    }}
                                                                                                    placeholder="Enter value"
                                                                                                    style={{ padding: "5px", marginRight: "10px", width: "100px" }}
                                                                                                    required
                                                                                                />
                                                                                                <button
                                                                                                    style={{ border: "none", background: "none", cursor: "pointer" }}
                                                                                                    type="button"
                                                                                                    onClick={() => {
                                                                                                        const data = negotiations.find((e) => e.vendor_email_id === bid.vendor_email_id);
                                                                                                        if (inputTextValue === "") {
                                                                                                            setShow(true);
                                                                                                            setBasicType("danger");
                                                                                                            setBasicTitle("Negotiation value cannot be empty");
                                                                                                        } else if (inputTextValue === data.vendor_value) {
                                                                                                            setShow(true);
                                                                                                            setBasicType("danger");
                                                                                                            setBasicTitle("Negotiation value cannot be the same as the previous one");
                                                                                                        } else {
                                                                                                            submitNegotiateValue(
                                                                                                                indent.indent_id,
                                                                                                                bid.vendor_email_id,
                                                                                                                indent.created_by
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="icofont icofont-arrow-right f20" style={{ color: "green" }}></i>
                                                                                                </button>
                                                                                            </div>
                                                                                        ) :
                                                                                            (
                                                                                                (() => {
                                                                                                    const vendorNegotiation = negotiations.find(
                                                                                                        (e) => e.vendor_email_id === bid.vendor_email_id
                                                                                                    );

                                                                                                    return vendorNegotiation && vendorNegotiation.vendor_value ? (
                                                                                                        <div>
                                                                                                            {`${vendorNegotiation.vendor_value}`}
                                                                                                            {bid.in_negotiation === 1 && bid.vendor_accepted !== 1 && bid.pending_vendor_approval !== 1 && (
                                                                                                                <i
                                                                                                                    className="icofont icofont-edit"
                                                                                                                    style={{ marginLeft: "10px", cursor: "pointer", color: "blue" }}
                                                                                                                    onClick={() => {
                                                                                                                        const vendorNegotiation = negotiations.find(
                                                                                                                            (e) => e.vendor_email_id === bid.vendor_email_id
                                                                                                                        );
                                                                                                                        setInputTextValue(
                                                                                                                            vendorNegotiation ? vendorNegotiation.vendor_value : ""
                                                                                                                        );
                                                                                                                        setInputTextQuantity(
                                                                                                                            vendorNegotiation ? vendorNegotiation.vendor_quantity : bid.no_of_deliveries
                                                                                                                        );
                                                                                                                        setIsVendorInputOpen(`${indent.indent_id}_${`L${index + 1}`}`);
                                                                                                                    }}
                                                                                                                ></i>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    ) : null;
                                                                                                })()
                                                                                            )}
                                                                                    </td>



                                                                                    <td>
                                                                                        {negotiationsHistory
                                                                                            .filter(e => e.vendor_email_id === bid.vendor_email_id)
                                                                                            .map(e => `${e.negotiated_value}`)
                                                                                            .join('<<')
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                                {indent.indent_assigned == 1 ?
                                                                    <>
                                                                        {indent.started_truck_details == 1 ?
                                                                            <table className="table table-bordered table-hover text-center" style={{ width: '100%' }}>
                                                                                <thead className="table-primary">
                                                                                    <tr>
                                                                                        <th>S.No</th>
                                                                                        <th>Indent ID</th>
                                                                                        <th>Dealer City</th>
                                                                                        <th>Dealer Pincode</th>
                                                                                        <th>Truck No</th>
                                                                                        <th>LR No/GC No</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {lr_no_data.length > 0 ? (
                                                                                        lr_no_data.map((destination, index) => (
                                                                                            <tr key={index}>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{destination.indent_id || "N/A"}</td>
                                                                                                <td>{destination.dealer_city || "N/A"}</td>
                                                                                                <td>{destination.dealer_pincode || "N/A"}</td>
                                                                                                <td>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        placeholder="Enter Truck No"
                                                                                                        value={destination.truck_no || ""}
                                                                                                        onChange={(e) =>
                                                                                                            handleInputChangeforacceptfortruck(index, "truck_no", e.target.value)
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                              
                                                                                                <td>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        placeholder="Enter LR No/GC No"
                                                                                                        value={destination.lr_no || ""}
                                                                                                        onChange={(e) =>
                                                                                                            handleInputChangeforacceptfortruck(index, "lr_no", e.target.value)
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    ) : (
                                                                                        <tr>
                                                                                            <td colSpan="6">No destinations available</td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <table className="table table-bordered table-hover text-center" style={{ width: '100%' }}>
                                                                                <thead className="table-primary">
                                                                                    <tr>
                                                                                        <th>S.No</th>
                                                                                        <th>Indent ID</th>
                                                                                        <th>Dealer City</th>
                                                                                        <th>Dealer Pincode</th>
                                                                                        <th>Truck No</th>
                                                                                        <th>LR No/GC No</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {indentfortruckdetails.length > 0 ? (
                                                                                        indentfortruckdetails.map((destination, index) => (
                                                                                            <tr key={index}>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>{destination.indent_id || "N/A"}</td>
                                                                                                <td>{destination.dealer_city || "N/A"}</td>
                                                                                                <td>{destination.dealer_pincode || "N/A"}</td>
                                                                                                <td>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        placeholder="Enter Truck No"
                                                                                                        value={destination.truck_no || ""}
                                                                                                        onChange={(e) =>
                                                                                                            handleInputChangeforacceptfortruck(index, "truck_no", e.target.value)
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                              
                                                                                                <td>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        placeholder="Enter LR No/GC No"
                                                                                                        value={destination.lr_no || ""}
                                                                                                        onChange={(e) =>
                                                                                                            handleInputChangeforacceptfortruck(index, "lr_no", e.target.value)
                                                                                                        }
                                                                                                    />
                                                                                                </td>

                                                                                            </tr>
                                                                                        ))
                                                                                    ) : (
                                                                                        <tr>
                                                                                            <td colSpan="6">No destinations available</td>
                                                                                        </tr>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>}

                                                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                                                <button className="btn btn-success mt-3" onClick={onSubmitdata1}>Submit</button>
                                                                            </div>
                                                                    </>
                                                                    : ''}</>)

                                                    }
                                                    {/* For vendors: Show quote submission form if the indent isn't fully confirmed */}
                                                    {/* {localStorage.getItem('user_type') === 'TRANSPORTER' && (
                                                        indent.vendorquotes.length === 0 ||
                                                        !indent.vendorquotes.some(e => e.vendor_email_id === localStorage.getItem("email") && (e.in_negotiation == 0 || e.in_negotiation == 1))
                                                    ) &&
                                                        <>
                                                            <table className="table table-bordered table-hover text-center mb-10p">
                                                                <thead className="table-primary">
                                                                    <tr>
                                                                        <th rowSpan="2">Dealer Name</th>
                                                                        <th rowSpan="2">Dealer City</th>
                                                                        <th rowSpan="2">Dealer Pincode</th>
                                                                        <th rowSpan="2">Material Dimension</th>
                                                                        <th rowSpan="2">Bid Price</th>
                                                                        <th rowSpan="2">Transit Time (Days)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {indent_for_approve_for_bid.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{item.dealer_code}</td>
                                                                            <td>{item.dealer_city}</td>
                                                                            <td>{item.dealer_pincode}</td>

                                                                            <td>
                                                                                {item.materials && item.materials.length == 1 ?
                                                                                    `${item.materials[0].material_dimensions}` :
                                                                                    "Multiple materials"}
                                                                            </td>

                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Enter Bid Price"
                                                                                    value={item.bid_price || ""}
                                                                                    onChange={(e) => handleInputChangeforbid(index, "bid_price", e.target.value)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="text"
                                                                                    placeholder="Enter Transit Time"
                                                                                    value={item.transit_days || ""}
                                                                                    onChange={(e) => handleInputChangeforbid(index, "transit_days", e.target.value)}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>

                                                            </table>
                                                            {Array.isArray(indent.transitdaysdata) &&
                                                                indent.transitdaysdata.some((e) => e.vendor_email_id === localStorage.getItem('email')) ? null : (
                                                                <form
                                                                    className="d-flex justify-content-between mt-4 ml-4 mb-4"
                                                                    onSubmit={onSubmitquote}
                                                                >
                                                                    <div className="form-group2 align-self-end">
                                                                        <button type="submit" className="btn btn-success">
                                                                            Quote
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            )}

                                                        </>
                                                    } */}
                                                    {localStorage.getItem("user_type") === "TRANSPORTER" && (
                                                        indent.vendorquotes.length === 0 
                                                    //     ||
                                                    //     !indent.vendorquotes.some(e => e.vendor_email_id === localStorage.getItem("email") && (e.in_negotiation == 0 || e.in_negotiation == 1))
                                                    //
                                                     ) &&
                                                        <form className="d-flex justify-content-between mt-4 ml-4 mb-4" onSubmit={onSubmitquote}>
                                                            <div className="form-group2">
                                                                <label>Bid Price</label>
                                                                <input type="text" value={selected_bid_price} onChange={e => {
                                                                    var value = e.target.value
                                                                    if (/^\d*$/.test(value)) {
                                                                        handleInputChange1(e.target.value)
                                                                    }
                                                                }} className="form-control" placeholder="Enter Bid Price" required />
                                                            </div>
                                                            <div className="form-group2">
                                                                <label>Transit Days</label>
                                                                <input type="text" value={selected_transit_days} onChange={e => {
                                                                    var value = e.target.value
                                                                    if (/^\d*$/.test(value)) {
                                                                        handleInputChange(e.target.value)
                                                                    }
                                                                }
                                                                } className="form-control" placeholder="Enter Transit Days" required />
                                                            </div>
                                                            <div className="form-group2 align-self-end">
                                                                <button type="submit" className="btn btn-success">Quote</button>
                                                            </div>
                                                        </form>
                                                    }


                                                </div>
                                            )}

                                            {/* for accept */}





                                        </>

                                    </>
                                )
                            }
                            )}
                        </div>


                    </div>

                </div>}


            </div>


            <div className={"slide-r " + (bulkUploadModal.bulkPrioritySlide)} style={{ overflow: "auto" }}>
                <h3 className="subH">Bulk Upload Consignments
                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={closeBulkUploadModal} >X</span>
                </h3>
                <div className="slide-r-body" style={{ position: "relative" }}>

                    <div className="container-fluid">
                        <form method="POST" id="upform" className="theme-form">
                            <div className="col-xl-6 col-lg-6 form-group1">
                                <label className="">Upload File</label>
                                <input type="file" name="uploadFile" id="bulkUploadCritical" onChange={changeFileHandler} className="form-control1" required />
                            </div>
                        </form>
                        <div className='col-xl-12 col-lg-12' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div className="form-group1">
                                <a className="btn btn-primary" target="_blank">Sample Template</a>
                            </div>
                            {/* <div className='form-group1' style={{ marginRight: "8px" }}>
                                <div><i className="fa fa-circle" style={{ color: "#c85f62" }}></i>&nbsp; Invalid Field Value</div>
                                <div><i className="fa fa-circle" style={{ color: "#ffcc00" }}></i>&nbsp; Order Number and FO Number combination already exists</div>
                            </div> */}
                        </div>
                        <div className="ag-theme-balham mt-2" style={{ height: '58vh', width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnDef}
                                rowData={modalloadData}
                                // defaultColDef={defaultColDef}
                                // onGridReady={onGridReady}
                                pagination={true}
                                paginationPageSize={50}
                                enableRangeSelection={true}
                                enableCellContextMenu={true}
                                stopEditingWhenGridLosesFocus={true}
                                sideBar={{
                                    toolPanels: [
                                        {
                                            id: "columns",
                                            labelDefault: "Columns",
                                            labelKey: "columns",
                                            iconKey: "columns",
                                            toolPanel: "agColumnsToolPanel",
                                            toolPanelParams: {
                                                suppressPivotMode: true,
                                                suppressRowGroups: true,
                                                suppressValues: true,
                                            }
                                        },
                                        {
                                            id: "filters",
                                            labelDefault: "Filters",
                                            labelKey: "filters",
                                            iconKey: "filter",
                                            toolPanel: "agFiltersToolPanel"
                                        }
                                    ]
                                }}
                                statusBar={{
                                    statusPanels: [
                                        {
                                            statusPanel: "agTotalAndFilteredRowCountComponent",
                                            align: "left"
                                        },
                                        {
                                            statusPanel: "agTotalRowCountComponent",
                                            align: "center"
                                        },
                                        { statusPanel: "agFilteredRowCountComponent" },
                                        { statusPanel: "agSelectedRowCountComponent" },
                                        { statusPanel: "agAggregationComponent" }
                                    ]
                                }} />
                        </div>
                        <br />
                        <div className="form-group1">
                            <button type="submit" className="btn btn-success" >Submit</button>
                            <button type="button" onClick={closeBulkUploadModal} className="btn btn-default">Cancel</button>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <Modal open={isPreviewMode} onClose={() => setIsPreviewMode(false)}>
                <div className="modal-header">
                    <h3 className="modal-title">RFQ Preview</h3>
                </div>
                <div className="modal-content">
                    {/* Basic Information Section */}
                    <section className="section" style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
                        <h3 className="section-title" style={{ marginBottom: '16px', fontSize: '18px', fontWeight: 'bold' }}>Basic Information</h3>
                        <div className="grid-container pl-4" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '12px' }}>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>RFQ Type :</label>
                                <span className="field-value" style={{ color: '#333' }}>{(rfqForm.auction_type ? (rfqForm.auction_type.charAt(0).toUpperCase() + rfqForm.auction_type.slice(1)) : rfqForm.auction_type) || 'N/A'}</span>
                            </div>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Source :</label>
                                <span className="field-value" style={{ color: '#333' }}>{(rfqForm.source === "others" ? rfqForm.selected_source_type : rfqForm.source) || 'N/A'}</span>
                            </div>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Distance :</label>
                                <span className="field-value" style={{ color: '#333' }}>{rfqForm.total_km ? `${rfqForm.total_km} km` : 'N/A'}</span>
                            </div>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Units :</label>
                                <span className="field-value" style={{ color: '#333' }}>{(rfqForm.units === "others" ? rfqForm.selected_units : getOptionsLabel(unitsOptions1, rfqForm.units)) || 'N/A'}</span>
                            </div>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Truck Type :</label>
                                <span className="field-value" style={{ color: '#333' }}>{(rfqForm.type_of_truck === "others" ? rfqForm.selected_truck_type : rfqForm.type_of_truck) || 'N/A'}</span>
                            </div>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Base Price :</label>
                                <span className="field-value" style={{ color: '#333' }}>{rfqForm.base_price || 'N/A'}</span>
                            </div>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Bid Start Date :</label>
                                <span className="field-value" style={{ color: '#333' }}>{rfqForm.bid_start_date ? moment.utc(rfqForm.bid_start_date).format("DD-MM-YYYY HH:mm:ss") : 'N/A'}</span>
                            </div>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>
                                    Pickup Date :
                                </label>
                                <span className="field-value" style={{ color: '#333' }}>
                                    {rfqForm.pickup_date ? moment.utc(rfqForm.pickup_date).format("DD-MM-YYYY HH:mm:ss") : 'N/A'}
                                </span>
                            </div>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>
                                    Vehicle Placement Date :
                                </label>
                                <span className="field-value" style={{ color: '#333' }}>
                                    {rfqForm.vehicle_placement_date ? moment.utc(rfqForm.vehicle_placement_date).format("DD-MM-YYYY HH:mm:ss") : 'N/A'}
                                </span>
                            </div>

                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Bid Validity Hours :</label>
                                <span className="field-value" style={{ color: '#333' }}>{rfqForm.quotation_validity_hours || 'N/A'}</span>
                            </div>
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Payment Terms :</label>
                                <span className="field-value" style={{ color: '#333' }}>{'As per the signed agreement' || 'N/A'}</span>
                            </div>
                        </div>
                    </section>


                    {/* Dealer Information Section */}
                    <section className="section" style={{ padding: '20px' }}>
                        <h3 className="section-title ml-5p">Dealer Information</h3>
                        {Array.isArray(dealers) && dealers.length > 0 ? (
                            dealers.map((dealer, index) => (
                                <div key={index} className="dealer-section">
                                    <h4 className="dealer-title">Dealer {index + 1}</h4>
                                    <div className="grid-container">
                                        <div className="grid-item">
                                            <label className="field-title" style={{ marginRight: '8px' }}>Code :</label>
                                            <span className="field-value">{dealer.dealer_code || "N/A"}</span>
                                        </div>
                                        <div className="grid-item">
                                            <label className="field-title" style={{ marginRight: '8px' }}>Name :</label>
                                            <span className="field-value">{dealer.dealer_name || "N/A"}</span>
                                        </div>
                                        <div className="grid-item">
                                            <label className="field-title" style={{ marginRight: '8px' }}>City :</label>
                                            <span className="field-value">{dealer.dealer_city || "N/A"}</span>
                                        </div>
                                        <div className="grid-item">
                                            <label className="field-title" style={{ marginRight: '8px' }}>State :</label>
                                            <span className="field-value">{dealer.dealer_state || "N/A"}</span>
                                        </div>
                                        <div className="grid-item">
                                            <label className="field-title" style={{ marginRight: '8px' }}>Pincode :</label>
                                            <span className="field-value">{dealer.dealer_pincode || "N/A"}</span>
                                        </div>
                                    </div>
                                    {/* Material Details */}
                                    {dealer.materials && dealer.materials.length > 0 ? (
                                        <div className="materials-section">
                                            <h5 className="materials-title">Material Details</h5>
                                            <table className="materials-table">
                                                <thead>
                                                    <tr>
                                                        <th>Material</th>
                                                        <th>Dimensions</th>
                                                        <th>Quantity</th>
                                                        <th>Requirements</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dealer.materials.map((material, mIndex) => (
                                                        <tr key={mIndex}>
                                                            <td>{material.material_type === "others" ? material.other_material_type : material.material_type || "N/A"}</td>
                                                            <td>{material.material_length && material.material_breath && material.material_height
                                                                ? `${material.material_length} X ${material.material_breath} X ${material.material_height} (${material.material_units})`
                                                                : "N/A"}</td>

                                                            <td>{material.quantity || "N/A"}</td>
                                                            <td>{material.basic_requirment || "N/A"}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <p className="no-data">No material details available</p>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p className="no-data">No dealers available</p>
                        )}
                    </section>
                    <section className="section" style={{ padding: '20px' }}>
                        <h3 className="section-title">Additional Information</h3>
                        <div className='pl-4'>
                            {/* Additional Comments */}
                            <div className="grid-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Additional Comments :</label>
                                <span className="field-value" style={{ color: '#333' }}>{rfqForm.additional_comments || "No comments provided"}</span>
                            </div>
                            {/* Over Breadth */}
                            <div className="grid-item toggle-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Over Breadth :</label>
                                <span className="field-value" style={{ color: '#333' }}>
                                    {rfqForm.over_breath === 1 ? 'Yes' : 'No'}
                                </span>

                            </div>
                            {/* Over Height */}
                            <div className="grid-item toggle-item">
                                <label className="field-title" style={{ fontWeight: 'bold', marginRight: '8px' }}>Over Height :</label>
                                <span className="field-value" style={{ color: '#333' }}>
                                    {rfqForm.over_height === 1 ? 'Yes' : 'No'}
                                </span>

                            </div>
                        </div>
                    </section>


                </div>

            </Modal>

            <Modal
                onHide={onCloseModal}
                dialogClassName="my-modal"
                show={modelForShowBids}
                centered>
                <Modal.Header>
                    <Modal.Title style={{ fontWeight: 'bold' }}>Participants Bids</Modal.Title>
                    <div className="btn-box d-flex gap-3">
                        <button type="button" className="btn btn-sm btn-icon" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseModal}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body className="view-task">
                    <div>
                        {vendorQuotes.length > 0 ?
                            <table className="table table-bordered table-hover text-center">
                                <thead className="table-primary">
                                    <tr>
                                        <th >Rank</th>
                                        <th >Bid Price</th>
                                        <th >Bid Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {console.log(vendorQuotes, "test")}
                                    {/* Loop over each bid in vendorquotes and render the corresponding row */}
                                    {vendorQuotes.map((bid, index) => (

                                        <tr key={index}>
                                            <td>{`L${index + 1} ${localStorage.getItem("email") == bid.vendor_email_id ? ' (Your Rank)' : ""}`}</td>
                                            <td>{bid.bid_price}</td>
                                            <td>{bid.no_of_deliveries}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table> : "No active bids currently"}
                    </div>

                </Modal.Body>
            </Modal>
            {/* <Modal
                onHide={onCloseModal}
                dialogClassName="my-modal"
                show={modelForAdditionalComments}
                centered>
                <Modal.Header>
                    <Modal.Title style={{ fontWeight: 'bold' }}>Additional Comments</Modal.Title>
                    <div className="btn-box d-flex gap-3">
                        <button type="button" className="btn btn-sm btn-icon" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseModal}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body className="view-task">
                    <div>
                        {additionalComments}
                    </div>

                </Modal.Body>
            </Modal> */}
            <Modal open={modelForNotifications} onClose={onCloseModal} styles={modalStyles}>
                <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                    <h5 className="model-title1">Notifications</h5>
                    <div className="btn-box d-flex gap-3 mt-10p">

                        <div>
                            {notificationRead.length > 0 || notificationUnread.length > 0 ? (
                                <ul>
                                    {notificationUnread.map((notification, index) => (
                                        <li
                                            onClick={() => {
                                                setModelForNotifications(false);
                                                // setIscardOpen(notification.indent_id);
                                            }}
                                            key={index}
                                            style={{
                                                cursor: "pointer",
                                                fontWeight: "bold", // Bold for unread notifications
                                                backgroundColor: "#e0e0e0", // Darker background for unread
                                                padding: "5px",
                                                borderRadius: "4px",
                                                marginBottom: "4px"
                                            }}
                                        >
                                            {notification.notification}
                                        </li>
                                    ))}
                                    {notificationRead.map((notification, index) => (
                                        <li
                                            onClick={() => {
                                                setModelForNotifications(false);
                                                // setIscardOpen(notification.indent_id);
                                            }}
                                            key={index}
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: "#f0f0f0", // Lighter background for read notifications
                                                padding: "5px",
                                                borderRadius: "4px",
                                                marginBottom: "4px"
                                            }}
                                        >
                                            {notification.notification}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No notifications available.</p>
                            )}
                        </div>
                    </div>

                </div>
            </Modal>
            {/* <Modal
                onHide={onCloseModal}
                dialogClassName="my-modal"
                show={modelForNotifications}
                centered>
                <Modal.Header>
                    <Modal.Title style={{ fontWeight: 'bold' }}>Notifications</Modal.Title>
                    <div className="btn-box d-flex gap-3">
                        <button type="button" className="btn btn-sm btn-icon" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseModal}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body className="view-task">
                    {notificationRead.length > 0 || notificationUnread.length > 0 ? (
                        <ul>
                            {notificationUnread.map((notification, index) => (
                                <li
                                    onClick={() => {
                                        setModelForNotifications(false);
                                        // setIscardOpen(notification.indent_id);
                                    }}
                                    key={index}
                                    style={{
                                        cursor: "pointer",
                                        fontWeight: "bold", // Bold for unread notifications
                                        backgroundColor: "#e0e0e0", // Darker background for unread
                                        padding: "5px",
                                        borderRadius: "4px",
                                        marginBottom: "4px"
                                    }}
                                >
                                    {notification.notification}
                                </li>
                            ))}
                            {notificationRead.map((notification, index) => (
                                <li
                                    onClick={() => {
                                        setModelForNotifications(false);
                                        // setIscardOpen(notification.indent_id);
                                    }}
                                    key={index}
                                    style={{
                                        cursor: "pointer",
                                        backgroundColor: "#f0f0f0", // Lighter background for read notifications
                                        padding: "5px",
                                        borderRadius: "4px",
                                        marginBottom: "4px"
                                    }}
                                >
                                    {notification.notification}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No notifications available.</p>
                    )}
                </Modal.Body>

            </Modal> */}
            <Modal open={modelForAdditionalComments} onClose={onCloseModal} styles={modalStyles}>
                <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                    <h5 className="model-title1">Additional Comments</h5>
                    <div className="btn-box d-flex gap-3 mt-10p">

                        <div>
                            {additionalComments}
                        </div>
                    </div>

                </div>
            </Modal>
            <Modal open={isCardOpen1} onClose={onCloseModal} styles={modalStyles1}>
                <div
                    className="row"
                    style={{
                        padding: '10px 15px',
                        width: '90%', // Increase modal width
                        maxWidth: '1200px', // Optional: Set a max width
                        margin: '0 auto', // Center the modal
                        zIndex: 9999,
                    }}
                >
                    <h5 className="model-title1">Enter Details</h5>
                    <div className="btn-box d-flex gap-3 mt-10p">
                        <table className="table table-bordered table-hover text-center" style={{ width: '100%' }}>
                            <thead className="table-primary">
                                <tr>
                                    <th>S.No</th>
                                    <th>Indent ID</th>
                                    <th>Dealer City</th>
                                    <th>Dealer Pincode</th>
                                    <th>Bid Price</th>
                                    <th>Transit Days</th>
                                </tr>
                            </thead>
                            <tbody>
                                {indent_for_approve.length > 0 ? (
                                    indent_for_approve.map((destination, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{destination.indent_id || "N/A"}</td>
                                            <td>{destination.dealer_city || "N/A"}</td>
                                            <td>{destination.dealer_pincode || "N/A"}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Bid Price"
                                                    value={destination.bid_price || ""}
                                                    onChange={(e) =>
                                                        handleInputChangeforaccept(index, "bid_price", e.target.value)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Transit Days"
                                                    value={destination.transit_days || ""}
                                                    onChange={(e) =>
                                                        handleInputChangeforaccept(index, "transit_days", e.target.value)
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6">No destinations available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                </div>
                <div style={{ float: 'right' }}>
                    <button className="btn btn-success mt-3" onClick={onSubmitdata}>Submit</button>
                </div>
            </Modal>
            <Modal open={cardforadminbifurcation} onClose={onCloseModal} styles={modalStyles1}>
                <div
                    className="row"
                    style={{
                        padding: '10px 15px',
                        width: '90%', // Increase modal width
                        maxWidth: '1200px', // Optional: Set a max width
                        margin: '0 auto', // Center the modal
                        zIndex: 9999,
                    }}
                >
                    <h5 className="model-title1">
                       
                        {dataforadminbifur && dataforadminbifur.length > 0 && dataforadminbifur[0].indent_id
                            ? dataforadminbifur[0].indent_id
                            : "No Indent Id available"}{" "}
                        &nbsp;&nbsp;&nbsp; {/* Add spaces */}
                        Bid Price :
                        {dataforadminbifur && dataforadminbifur.length > 0 && dataforadminbifur[0].negotiatedconfirmed_price
                            ? dataforadminbifur[0].negotiatedconfirmed_price
                            : "N/A"}
                    </h5>

                    <div className="btn-box d-flex gap-3 mt-10p">
                        <table className="table table-bordered table-hover text-center" style={{ width: '100%' }}>
                            <thead className="table-primary">
                                <tr>
                                    <th>S.No</th>
                                    {/* <th>Indent ID</th> */}
                                    <th>Dealer Name</th>
                                    <th>Dealer City</th>
                                    <th>Dealer State</th>
                                    <th>Dealer Pincode</th>
                                    <th>Bid Price</th>
                                    {/* <th>Transit Days</th> */}
                                    {/* <th>LR No/GC No</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {dataforadminbifur.length > 0 ? (
                                    dataforadminbifur.map((destination, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            {/* <td>{destination.indent_id || "N/A"}</td> */}
                                            <td>{destination.dealer_name || "N/A"}</td>
                                            <td>{destination.dealer_city || "N/A"}</td>
                                            <td>{destination.dealer_state || "N/A"}</td>
                                            <td>{destination.dealer_pincode || "N/A"}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Bid Price"
                                                    value={destination.bid_price || ""}
                                                    onChange={(e) =>
                                                        handleInputChangeforadmin_bifur(index, "bid_price", e.target.value)
                                                    }
                                                />
                                            </td>
                                            {/* <td>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        placeholder="Enter Transit Days"
                                                                                                        value={destination.transit_days || ""}
                                                                                                        onChange={(e) =>
                                                                                                            handleInputChangeforaccept1(index, "transit_days", e.target.value)
                                                                                                        }
                                                                                                    />
                                                                                                </td> */}
                                            {/* <td>{destination.bid_price || "N/A"}</td>
                                                                                                <td>{destination.transit_days || "N/A"}</td> */}
                                            {/* <td>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        placeholder="Enter LR No/GC No"
                                                                                                        value={destination.lr_no || ""}
                                                                                                        onChange={(e) =>
                                                                                                            handleInputChangeforaccept1(index, "lr_no", e.target.value)
                                                                                                        }
                                                                                                    />
                                                                                                </td> */}

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6">No destinations available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                </div>
                <div style={{ float: 'right' }}>
                    <button type="button" className="btn btn-success mt-3" onClick={onSubmitdataforadminbifurcation}>Submit</button>
                </div>
            </Modal>


            {/* <Modal open={isCardOpen2} onClose={onCloseModal} styles={modalStyles1}>
                <div
                    className="row"
                    style={{
                        padding: '10px 15px',
                        width: '90%', // Increase modal width
                        maxWidth: '1200px', // Optional: Set a max width
                        margin: '0 auto', // Center the modal
                        zIndex: 9999,
                    }}
                >
                    <h5 className="model-title1">Enter Details</h5>
                    <div className="btn-box d-flex gap-3 mt-10p">
                        <table className="table table-bordered table-hover text-center" style={{ width: '100%' }}>
                            <thead className="table-primary">
                                <tr>
                                    <th>S.No</th>
                                    <th>Indent ID</th>
                                    <th>Dealer City</th>
                                    <th>Dealer Pincode</th>
                                    <th>Bid Price</th>
                                    <th>Transit Days</th>
                                    <th>LR No/GC No</th>
                                </tr>
                            </thead>
                            <tbody>
                                {indent_for_approve1.length > 0 ? (
                                    indent_for_approve1.map((destination, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{destination.indent_id || "N/A"}</td>
                                            <td>{destination.dealer_city || "N/A"}</td>
                                            <td>{destination.dealer_pincode || "N/A"}</td>
                                            <td>{destination.bid_price || "N/A"}</td>
                                            <td>{destination.transit_days || "N/A"}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Enter LR No/GC No"
                                                    value={destination.lr_no || ""}
                                                    onChange={(e) =>
                                                        handleInputChangeforaccept1(index, "lr_no", e.target.value)
                                                    }
                                                />
                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6">No destinations available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                </div>
                <div style={{ float: 'right' }}>
                    <button className="btn btn-success mt-3" onClick={onSubmitdata1}>Submit</button>
                </div>
            </Modal> */}
            <Modal open={isCardOpen3} onClose={onCloseModal} styles={modalStyles1}>
                <div
                    className="row"
                    style={{
                        padding: '10px 15px',
                        width: '90%', // Increase modal width
                        maxWidth: '1200px', // Optional: Set a max width
                        margin: '0 auto', // Center the modal
                        zIndex: 9999,
                    }}
                >
                    <h5 className="model-title1">Freight Bifurcation</h5>
                    <div className="btn-box d-flex gap-3 mt-10p">
                        <table className="table table-bordered table-hover text-center" style={{ width: '100%' }}>
                            <thead className="table-primary">
                                <tr>
                                    <th>S.No</th>
                                    <th>Indent ID</th>
                                    <th>Dealer City</th>
                                    <th>Dealer Pincode</th>
                                    <th>Bid Price</th>
                                    <th>Transit Days</th>
                                    <th>LR No/GC No</th>
                                </tr>
                            </thead>
                            <tbody>
                                {lr_no_data.length > 0 ? (
                                    lr_no_data.map((destination, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{destination.indent_id || "N/A"}</td>
                                            <td>{destination.dealer_city || "N/A"}</td>
                                            <td>{destination.dealer_pincode || "N/A"}</td>
                                            <td>{destination.bid_price || "N/A"}</td>
                                            <td>{destination.transit_days || "N/A"}</td>
                                            <td>{destination.lr_no || "N/A"}</td>


                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6">No destinations available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                </div>

            </Modal>









            <Modal
                onHide={onCloseModal}
                dialogClassName="my-modal"
                show={modelForEditAssigned}
                centered>
                <Modal.Header>
                    <Modal.Title style={{ fontWeight: 'bold' }}>Reject Vendor</Modal.Title>
                    <div className="btn-box d-flex gap-3">
                        <button type="button" className="btn btn-sm btn-icon" data-bs-dismiss="modal" aria-label="Close" onClick={onCloseModal}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body className="view-task">
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <textarea style={{ marginBottom: '5px', width: '100%' }} onChange={(e) => {
                            setRejectionReason(e.target.value)
                        }} />
                        <button
                            type="button"
                            className="btn btn-danger"
                            style={{ padding: '10px 26px' }}
                            onClick={() => {
                                if (!rejectionReason) {
                                    setShow(true);
                                    setBasicType("danger");
                                    setBasicTitle("Please provide reason");
                                    return;
                                }
                                var params = {
                                    indent_id: rejectedVendorIndent.indent_id,
                                    vendor_email_id: rejectedVendorBid.vendor_email_id,
                                    bid_price: rejectedVendorBid.bid_price,
                                    no_of_deliveries: rejectedVendorBid.no_of_deliveries,
                                    rejection_reason: rejectionReason
                                }
                                redirectURL.post("/consignments/rejectQuotation", params).then(response => {
                                    const isSuccess = response.data.status === "success";
                                    setShow(true);
                                    setBasicType(isSuccess ? "success" : "danger");
                                    setBasicTitle(response.data.message);

                                    if (isSuccess) {
                                        // socket.current.emit('bidUpdate', []);
                                        setModelForEditAssigned(false)
                                    }
                                })
                            }}
                        >
                            Reject
                        </button>
                    </div>

                </Modal.Body>

            </Modal>


            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay " + (overlay)} onClick={onClickHideall}></div>




        </div >
    );
};

export default IndentAuctionComponentforadmin;

function giveUTCDate(date) {
    return moment.parseZone(new Date(date)).utcOffset("-05:30")._d
}
const generateUniqueId = () => {
    return 'icon-' + Math.random().toString(36).substr(2, 9); // Generate a simple unique ID
};