/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Select from 'react-select';
// import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
// import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
// import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
// import 'ag-grid-enterprise';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import redirectURL from '../redirectURL';
import { getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY } from "../common/utils";
// import { createStaticHandler } from '@remix-run/router';
import BillingTripsData from "./BillingTripsDataGrid";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import ViewPOD from "./viewpodbutton";
import ViewPODChild from "./viewpodchildbutton"; 
import ViewDamageChild from "./viewdamagechild";
import UploadPOD from "./uploadpodbutton";
import Datetime from 'react-datetime';
import ViewBatchItems from "./viewbatchitems";
// import ViewRemarkItems from "./viewremarkitems"
import $ from 'jquery';
import ViewDocumentsList from "./viewdocuments";
import ViewRemarksAction from "./viewremarksaction";
import ViewDamageItem from "./viewDamageItem";
import ViewChargesDocs from "./viewChargesDocComponent";
import "react-datetime/css/react-datetime.css";
import download from 'js-file-download';
import specialchargesMasters from "../masters/specialcharges_masters";
import UpdateTptChargesBtn from "./updateTPTCharges"
import ConfirmBtnComponentBtn from "./confirmBtnComponent";
import UpdateWareHouseChargesBtn from "./updateWareHouseChargescomponent";
import FileDownloadSection from "./showChargeCopies";
var moment = require('moment');
var Constant = require("../constants");
var Constants = Constant.default;
const ConfirmedComponent = (props) => {
    return (
        <div style={{
            backgroundColor: "#DFF2D8", // Light green background
            color: "#2C5F2D",           // Dark green font color
            fontWeight: "bold",
            padding: "5px",             // Optional padding for better spacing
            borderRadius: "3px"         // Optional rounded corners
        }}>
            {props.text}
        </div>
    );
};
// import ViewItems from '../booking/viewitems';
export default class BillingTrips extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            isuploadcsv: 0,
            rowData: [],
            frameworkComponents: {
                UploadPOD: UploadPOD,
                ViewPOD: ViewPOD,
                ViewBatchItems: ViewBatchItems,
                ViewDocumentsList:ViewDocumentsList,
                ViewRemarksAction:ViewRemarksAction,
                ViewDamageItem:ViewDamageItem,
                UpdateTptChargesBtn,UpdateTptChargesBtn,
                ConfirmBtnComponentBtn:ConfirmBtnComponentBtn,
                ConfirmedComponent:ConfirmedComponent,
                UpdateWareHouseChargesBtn: UpdateWareHouseChargesBtn,
                ViewChargesDocs:ViewChargesDocs,
            },
            defaultColDef: {
                // flex: 1,
                minWidth: 100,
                filter: true,
                resizable: true,
                initialWidth: 200,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                sortable: true
            },
            detailCellRendererParams : {},
            childDetailCellRendererParams : {},
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            supportField: '',
            rowSelection: "multiple",
            shipment_slider: 'show-n',
            slidershow: "show-n",
            bulkslidershow: 'show-n',
            rownode: [],
            delivered_date: "",
            liableData: [],
            pendingwithlspData: [],
            showSendBackBtn: 0,
            showSendApproveBtn: 0,
            showSendRejectBtn: 0,
             shipType:{value:"all",label:"All"},
            // liable: "btn-active-y",
            // liable: "btn-default",
            pendingwithlsp: "btn-danger", //btn-default
            pendingwithcust: "btn-default",
            pendingwithfinance: "btn-default",
            invoicewithstatus: "btn-default",
            pendingwithfinapp: "btn-default",
            paywithstat: "btn-default",
            liableshow: "show-m",
            pendinglspshow: "show-n",
            pendingwithcustshow: "show-n",
            submitwithfinshow: "show-n",
            statusinvshow: "show-n",
            pendingwithfinappshow: "show-n",
            invoicewithstatusshow: "show-n",
            paywithstatshow: "show-n",
            actiontype: "pendingwithlsp",
            // liabilityCnt: [],
            pendingwithlspCnt: [],
            pendingwithcustCnt: [],
            counterpendcust: 0,
            counterpendfin: 0,
            counterpendfinance: 0,
            counterpendclose: 0,
            counterpendinv: 0,
            counterpendfinapp: 0,
            pendingwithfinappCnt: [],
            pendingwithfinanceCnt: [],
            // pendingfinappCnt: [],
            paywithstatCnt: [],
            pendingfinappCnt: [],
            pendingwithfinapp: [],
            oRowData: [],
            dieselFreightRate: 0,
            startDate: '', // moment.parseZone().format('YYYY-MM'),
            overly: "show-n",
            loadshow: 'show-n',
            sliderSupportshow: 'show-n',
            supportDocTitle: '',
            supportDoc: '',
            selected_row_nodes: [],
            multiplePodDocs: [],
            multiplePodNames : [],
            multipleFiles : '',
            sliderInvoiceshow:"",
            invRowNodes:[],
            sliderViewRemarks:"",
            invDoc:'',
            invoice_date:"",
            invoice_freight_amount:"",
            invoice_gst_amount:"",
            invoice_no:"",
            defStartDate:"",
            // shipType:{value:"all", label:"All"},
            custsendBackRemarks:"",
            custsliderSendBackRemarks:"",
            custApproveRemarks:"",
            sliderApproveRemarks:"",
            custRejectRemarks:"",
            sliderRejectRemarks:"",
            finApproveRemarks:"",
            transFinApproveRemarks:"",
            sliderFinApproveRemarks:"",
            sliderTransFinApproveRemarks:"",
            custFinRejectRemarks:"",
            sliderFinRejectRemarks:"",
            finalapprovedData:[],
            tab_status : "",
            lspcharges:[],
            from_date:moment.parseZone(new Date()).subtract(7,"days").format("YYYY-MM-DD"),
            to_date:moment.parseZone(new Date()).format("YYYY-MM-DD"),
            gLoad:"show-n",
            remarksRowNode:[],
            damageitemlist:"",
            showUpdateCharges:"",
            consigner: [{ "value": "all", "label": "All" }],            
			flTransporter: "",            
			filterTransporters: [],
            unloading_charges: 0,
            crane_charges: 0,
            odc_charges:0,
            invoiceNo:{ value: "", label: "All" },
            invoiceOptions:[],
            transhipment_charges:0,
            penalty_charges:0,
            showWhUpdateCharges:'',
            wo_remarks:"",
            files: {
                unloading_charges: null,
                crane_charges: null,
                odc_charges: null,
                detention_charges:null,
            },
            isFileRequired: {
                unloading_charges: false,
                crane_charges: false,
                odc_charges: false,
                detention_charges:false,
            },
            selectedChargeType: "", // Keeps track of the selected charge type
            chargeValues: {
                crane_charges: 0,
                odc_charges: 0,
                unloading_charges: 0,
                detention_charges:0,
            },
            detention_charges:0,
            chargesItemsList:"",
            crane_file:[],
            odc_file:[],
            detention_file:[],
            unloading_file:[],
        }
        this.changeInvFileHandler = this.changeInvFileHandler.bind(this);
        this.onClickViewDamageItem = this.onClickViewDamageItem.bind(this);
        this.onClickUpdateChargesBtn = this.onClickUpdateChargesBtn.bind(this)
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickViewChargeItem = this.onClickViewChargeItem.bind(this);
        this.onClickWareHouseChargesUpdateBtn = this.onClickWareHouseChargesUpdateBtn.bind(this);     
    }
    async componentDidMount() {
        try {
            const startDate = moment.parseZone().format('YYYY-MM-DD');    
            // Set the default start date
            this.setState({
                defStartDate: startDate,
                startDate: startDate
            });    
            // Authorization header
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
            };    
            // Fetch plants and filter keys data in parallel
            const [plantsResp, filterKeysResp,invoiceNoResp] = await Promise.all([
                redirectURL.post("/consignments/plants", { dept_code: '' }, { headers }),
                redirectURL.post("/consignments/getConsignmentsNewFilterKeys", {}, { headers }),                
                redirectURL.post("/consignments/getWareHouseInvoices", {}, { headers })
            ]);    
            // Extract data from the responses
            const plants = plantsResp.data;
            const { transportersList: transporters, data } = filterKeysResp.data;    
            // Create filtered options
            const filterCities = [{ label: "All", value: "" }, ...data.cities.map(c => ({ label: c, value: c }))];
            const filterStates = [{ label: "All", value: "" }, ...data.states.map(s => ({ label: s, value: s }))];
            const filterTptCodes = [{ label: "All", value: "" }, ...data.tptCodes.map(t => ({ label: t, value: t }))];
            const filterInvoices = invoiceNoResp.data.map(item =>({"value":item,"label":item})) || []
            // Create unique transporter options
            const tlistarr = transporters.map(t => ({
                value: t.transporter_code,
                label: `${t.transporter_name} - ${t.transporter_code}`
            }));
            const uniqueTransporters = Array.from(new Map(tlistarr.map(t => [t.value, t])).values());
            const filterTransporters = [{ value: "", label: "All" }, ...uniqueTransporters];    
            // Set all the retrieved data in a single setState call
            this.setState({
                plants,
                filterCities,
                filterStates,
                filterTransporters,
                filterTptCodes,
                invoiceOptions:[{ value: "", label: "All" },...filterInvoices] || [{ value: "", label: "All" }]
            });    
            // Fetch liability data with the initialized start date
            this.getLiabilityData(startDate);    
        } catch (error) {
            console.error("Error in componentDidMount:", error);    
            // Set empty values in case of an error
            this.setState({
                filterCities: [],
                filterStates: [],
                filterTransporters: [],
                filterTptCodes: []
            });
        }
    }    
    newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
        // if (filterType == "invoiceNo" && this.state.invoicewithstatus && this.state.invoicewithstatus == "btn-danger" && this.state.invoiceNo && this.state.invoiceNo.value) {
        //     var pendingwithcustomer = (this.state.pendingwithcustCnt || []).filter(item => item.invoice_no && item.invoice_no == this.state.invoiceNo.value) || []
        //     this.setState({
        //         pendingwithcustCnt: pendingwithcustomer,
        //     })
        // }
	}
    getTranstporterCharges(transporter){
        var params = {
            transporter_code:transporter
        }
        redirectURL.post("/masters/getMasterCharges",params,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
            }
        })
        .then((response) => {
            var records = response.data.records;
           
            this.setState({
                lspcharges:records
            })
        })
    }
    getLiabilityData = (startDate) => {
        const { startDate: from_date, endDate: to_date } = this.getMonthDates(startDate);
        // console.log("from_date, to_date",from_date, to_date)
        let params = {
            "from_date": this.state.from_date,
            "to_date": this.state.to_date,
            "consigner_code": [
                {
                    "value": "all",
                    "label": "All"
                }
            ],
            "pod_status": 1,
            "serviceProviders": [],
            "is_filter_call": 0,
            
        }
        this.loadTrips(params);
        // redirectURL.post("/billing/getEpodScreenData", params,{
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': localStorage.getItem("userid")
        //     }
        // }).then(async (res) => {
        //     let respData = res.data.records
        //     //    console.log("resspData",respData)
        //     if(respData.length > 0)
        //     {
        //         var check = 1;
        //         for(var r=0; r<=respData.length; r++)
        //         {
        //             try{
        //                 //  console.log("respData[r].is_submitted_pod ", respData[r].is_submitted_pod)
        //                 if(respData[r].is_submitted_pod == 1)
        //                 {
        //                     check = 1;
        //                 }
        //                 else
        //                 {
        //                     //  console.log("respData[r] ", respData[r])
        //                     check = 0;
        //                     break;
        //                 }
        //             }
        //             catch(e){
        //                 // check = 0;
        //                 break;                            
        //             }
        //         }
                // console.log(check,"check---1")
                // if(check === 0)
                // {
                //     this.setState({
                //         show: true,
                //         loadshow:"show-n",
                //         overly:"show-n",
                //         basicType: "warning",
                //         basicTitle: "Please GO Back EPOD screen and upload all files",
                //         from_date: from_date,
                //         to_date: to_date,
                //     });
                // }
                // else {
        //             await this.setState({
        //                 rowData:[],
        //                 gLoad:"show-m"
        //             })
        //             await setTimeout(() => {
        //                 this.loadTrips(params);
        //            },1000) 
                // }
        //     }
        // })
        // redirectURL.post('/consignments/activecouriers',params).then(res =>{
        //     let responseData  = res.data
        //     console.log("responseData",responseData)
        //     if(!["", " ", undefined].includes(responseData)){
        //         this.setState({
        //             liability_data : responseData,
        //             liabilityCnt : responseData,
        //             rowData : responseData,
        //             loadshow : 'show-n',
        //             overlay : 'show-n'
        //         })
        //     }
        // })
    }
    getMonthDates = (monthYear) => {
        // console.log("monthYear", monthYear)
        const [year, month] = monthYear.split('-');
        const startDate = new Date(year, month - 1, 1).toISOString().slice(0, 10);
        // const now = new Date();
        // const currentYear = now.getFullYear();
        // const currentMonth = now.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
        const endDate = new Date(year, month - 1, daysInMonth).toISOString().slice(0, 10);
        return { startDate, endDate };
    }
    loadTrips = async (params) => {
        await this.setState({
            rowData:[],
            loadshow:"show-m"
        })
        if(localStorage.getItem("supplier_code") !== "" && localStorage.getItem("supplier_code") !== undefined && localStorage.getItem("supplier_code") !== null && localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined && localStorage.getItem("user_type") !== null && localStorage.getItem("supplier_code") !== "undefined") 
        {
            params.user_code = JSON.parse(localStorage.getItem("supplier_code"));
             params.user_type = localStorage.getItem("user_type");
        }
        if(this.state.invoicewithstatus &&  this.state.invoicewithstatus=="btn-danger" && this.state.invoiceNo && this.state.invoiceNo.value){
            params.invoice_no = this.state.invoiceNo.value
        } 
        // console.log("params.user_code",params.user_code)
        // console.log("params.user_type",params.user_type)
        //  console.log("params trips ", params)
        await redirectURL.post("/billing/trips", params,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          }).then((response) => {
            try{
                var records = response.data.records || [];
                // var batches = response.data.batches;
                //  console.log("response_trips", records)
                // Batch Status
                // Step-1:  0-new and invoice status ,  == Pending with transporter == Trip Status = 0
                // Step-2: 1-Sent for Approval customer == Pending with Ware House and Invoice Statuc(TPT) === Batch Status = 0 && == Trip Status = 1
                // Step-3: 2- Approve- Pending for final approval === Batch Status = 1 && == Trip Status = 2
                // Step-4: 3- TPT Approved go to Finanace == Pending With Payment === Batch Status = 2 && == Trip Status = 3
                // Step-5: 4- Fianan Approve == Payment Close === Batch Status = 3 && == Trip Status = 4
                // Step-6: 5- Customer reject == Pending with Transporter === Batch Status = 4 && == Trip Status = 5
                // Step-7: 6- Cusomter Send back === Deviation Workflow === Batch Status = 5 && == Trip Status = 6
                // Step-8: 7-Finance Reject === Pending With Transporter (HOLD)
                // var liabilityCnt = [];
                var pendingwithlspCnt = [];
                var pendingwithcustCnt = [];
                var counterpendcust = [];
                var counterpendinv = [];
                var counterpendfin = [];
                var counterpendfinance = [];
                var counterpendclose = [];
                var pendingwithfinapp = [];
                var pendingwithfinappCnt = [];
                var pendingwithfinanceCnt = [];
                var paywithstatCnt = [];
                var recordsarr = [];
                var allbatches = [];
                var batchitems = [];
                var pendingWithPaymentWareHouse = []
                if (records.length > 0) {
                    records.map((item) => {
                        //  console.log("item ", item)
                        var manualsorders = item.manualorders;
                        var mcharges = 0;
                        // if(manualsorders.length > 0)
                        // {
                        //     manualsorders.map((ite) => {
                        //         if(ite.per_kg_slab_rate !== "" && ite.per_kg_slab_rate !== undefined)
                        //         {
                        //             var mpksrate = ite.per_kg_slab_rate;
                        //         }
                        //         else{
                        //             var mpksrate = 0 ;
                        //         }
                        //         if(ite.allowable_weight !== "" && ite.allowable_weight !== undefined)
                        //         {
                        //             var maw = ite.allowable_weight;
                        //         }
                        //         else{
                        //             var maw = 0 ;
                        //         }
                        //         mcharges = parseFloat(mcharges)+(parseFloat(maw)*parseFloat(mpksrate))
                        //     })
                        // }
                        // console.log("mcharges ", mcharges)
                        // setTimeout(() => {
                            var a = 0;
                            var b = 0;
                            var c = 0;
                            var d = 0;
                            var oCharge = 0;
                            // console.log("item.osp ", item.osp)
                            // if(item.osp !== "" && item.osp !== undefined && item.osp !== null)
                            // {
                            //     a = parseFloat(item.osp);
                            // }
                            // else{
                            //     a=0;
                            //     item.osp = 0;
                            // }
                            // if(item.lu_charges !== "" && item.lu_charges !== undefined && item.lu_charges !== null)
                            // {
                            //     b = parseFloat(item.lu_charges);
                            // }
                            // else{
                            //     b=0;
                            //     item.lu_charges = 0;
                            // }
                            // if(item.special_approval !== "" && item.special_approval !== undefined && item.special_approval !== null)
                            // {
                            //     c = parseFloat(item.special_approval);
                            // }
                            // else{
                            //     c=0;
                            //     item.special_approval = 0;
                            // }
                            // if(item.to_pay_charges !== "" && item.to_pay_charges !== undefined && item.to_pay_charges !== null)
                            // {
                            //     d = parseFloat(item.to_pay_charges);
                            // }
                            // else{
                            //     d=0;
                            //     item.to_pay_charges = 0;
                            // }
                            // if(item.manual_charges !== "" && item.manual_charges !== undefined && item.manual_charges !== null )
                            // {
                            //     oCharge = parseFloat(mcharges);
                            // }
                            // else{
                            //     oCharge = 0;
                            // }
                            // item.manual_charges = mcharges;
                            // // console.log(parseFloat(a)+"+"+parseFloat(b)+"+"+parseFloat(c)+"+"+parseFloat(d)+"+"+parseFloat(oCharge))
                            var other_charges = item.other_charges 
                            item.other_charges =  other_charges; 
                     
                        // if(item.total_freight !== undefined && item.total_freight !== "" && item.total_freight !== null && item.total_freight !== NaN)
                        // {
                        //     var total_freight = item.total_freight;
                        // }
                        // else{
                        //     var total_freight = 0;
                        // }
                        // var odaCharges = 0;
                        // console.log("iteodacharges ", item.odacharges)
                        // if(item.odacharges !== undefined)
                        // {
                        //     if(item.odacharges.length > 0)
                        //     {
                        //         if(item.odacharges[0].plant_code === item.consigner_code)
                        //         {
                        //             odaCharges = item.odacharges[0].oda_charges
                        //         }
                        //     }
                        // }
                        // console.log("item.tat_days",item.tat_days)
                        // item.tat_days = parseInt(transit_days);
                        
                        // item.oda_days = parseInt(oda_days);
                        // item.grace_days = grace_days;
                        // item.holidays = holidays;
                        // item.delay_days = delaydays;
                        //   console.log("total_tat ", total_tat)
                        // item.total_tat = (parseInt(total_tat) !== NaN && parseInt(total_tat) !== undefined)?parseInt(total_tat):0;
                        // item.actual_tat = parseInt(transit_days);
                        // console.log("item.actual_tat", item.actual_tat)
                        // item.ld_charges = ld;
                        // item.oda_charges = odaCharges;
                        if(item.allowable_weight !== "" && item.allowable_weight !== undefined)
                        {
                            var allowable_weight = item.allowable_weight;
                        }
                        else{
                            var allowable_weight = 0;
                        }
                        //  console.log("charged_weight_diff",charged_weight_diff);
                        // if(item.chargable_weight !== "" && item.chargable_weight !== undefined)
                        // {
                        //     var chargable_weight = item.chargable_weight;
                        // }
                        // else{
                        //     var chargable_weight = 0;
                        // }
                        // item.chargable_weight = chargable_weight;
                        // var charged_weight_diff = parseFloat(item.chargable_weight)-parseFloat(allowable_weight);
                        // if(charged_weight_diff > 0)
                        // {
                        //     var charged_weight_diffval = charged_weight_diff;
                        // }
                        // else{
                        //     var charged_weight_diffval = 0;
                        // }
                        // item.charged_weight_diff = charged_weight_diffval;
                        
                        if(item.per_kg_slab_rate !== "" && item.per_kg_slab_rate !== undefined)
                        {
                            var per_kg_slab_rate = item.per_kg_slab_rate;
                        }
                        else{
                            var per_kg_slab_rate = 0;
                        }
                        if(item.allowable_weight !== "" && item.allowable_weight !== undefined && item.allowable_weight !== null)
                        {
                            var aw = item.allowable_weight;
                        }
                        else{
                            var aw = 0;
                        }
                        // var fcot = parseFloat(aw)*parseFloat(per_kg_slab_rate);
                        // var fDiff  =  (charged_weight_diffval*per_kg_slab_rate).toFixed(2);
                        // item.freight_diff =fDiff;
                        // var oda_charges = item.oda_charges || 0
                        // var penalty_charges = item.penalty_charges || 0 
                        // var detention_charges = item.detention_charges || 0
                        // var transhipment_charges = item.transhipment_charges || 0
                        // item.sub_total = (fcot+other_charges+oda_charges+transhipment_charges)-(penalty_charges+detention_charges); 
                        // if(item.gst !== "" && item.gst !== undefined)
                        // {
                        //     var gst_amount = 0
                        //     var gstp = (parseFloat(item.sub_total)*(item.gst/100))
                        //     gst_amount = gstp.toFixed(2);
                        //     item.gst_amount = gst_amount
                        // }
                        // else{
                        //      var gst_amount = 0 
                        //     // console.log("gstp",gstp)
                        //     var gstp = 0;
                        //     item.gst_amount = gst_amount;
                        //     item.gst = gstp;
                            
                        // }
                        // var total_freight = (parseFloat(item.sub_total)+(parseFloat(gst_amount))).toFixed(2);
                        // // console.log("total_freight",total_freight+" === "+item.gc_waybill_no)
                        // item.total_freight = parseFloat(total_freight);
                        // // console.log("item friehgt", item)
                        // var fp = (total_freight-odaCharges).toFixed(2);
                        // item.freight_payable= fp;
                        if(item.ODA_TAT !== "" && item.ODA_TAT !== undefined && item.ODA_TAT !== null && item.ODA_TAT !== NaN)
                        {
                            var ODA_TAT = item.ODA_TAT;
                        }
                        else{
                            var ODA_TAT = 0;
                        }
                        // console.log(parseFloat(oda)+"-"+parseFloat(odaCharges))
                        // var oda_diff = parseFloat(ODA_TAT)-parseFloat(odaCharges);
                        // item.oda_diff = oda_diff;
                        // item.rate_diff = fDiff+oda_diff;
                        
                        // var transit = item.TAT;
                        // if(item.transitdays !== undefined)
                        // {
                        //     if(item.transitdays.length > 0)
                        //     {
                        //         var transitdaysCount = item.transitdays[0].transit_days;
                        //     }
                        //     else{
                        //         var transitdaysCount = 0;
                        //     }
                        // }
                        // else{
                        //     var transitdaysCount = 0;
                        // }
                        // console.log("transit ",item)
                        // var TAT= 0;
                        // var ODA_TAT = 0;
                        // var tat_days= 0;
                         var total_tat=0;
                        // var oda_days = 0;
                        // var grace_days = 0;
                        // var holidays = 0;
                        // var delaydays =  0;
                        //  var ld = 0;
                         var ODA_TAT = 0;
                         var TAT = 0;
                         var grace_count = 0;
                         var holiday_count = 0;
                         var count_sunday = 0;
                        // if(transitdaysCount !== "" && transitdaysCount !== undefined)
                        // {
                        //     // if(transit.length > 0)
                        //     // {
                        //         // var trans = transit.filter((f) => f.consignee_city === item.consignee_city && f.plant_code === item.consigner_code);
                        //         //  console.log("trans ", trans)
                        //         // if(trans.length > 0)
                        //         // {
                        //             if(item.holidays_list !== undefined)
                        //             {
                        //                 if(item.holidays_list.length > 0)
                        //                 {
                        //                     var holidays_list = item.holidays_list.length;
                        //                 }
                        //                 else{
                        //                     var holidays_list = 0;
                        //                 }
                        //             }
                        //             else{
                        //                 var holidays_list = 0;
                        //             }
                        //              // transit_days = parseInt(trans[0].transit_days)
                        //          //    console.log("trans[0].transit_days",trans[0].transit_days)
                        //              // console.log("transit_days",transit_days)
                        //             tat_days = parseInt(transitdaysCount)
                        //             oda_days = parseInt(item.ODA_TAT)
                        //             if(item.grace_count !== undefined && item.grace_count !== "" && item.grace_count !== null && item.grace_count !== NaN)
                        //             {
                        //                 grace_days = item.grace_count
                        //             }
                        //             else{
                        //                 grace_days = 0
                        //             }
                        //             //console.log("grace_days",grace_days)
                        //             if(item.ODA_TAT !== undefined && item.ODA_TAT !== "" && item.ODA_TAT !== null && item.ODA_TAT !== NaN)
                        //             {
                        //                 oda_days = item.ODA_TAT
                        //             }
                        //             else{
                        //                 oda_days = 0
                        //             }
                        //             //console.log("oda_days",oda_days)
                        //             // if(item.TAT !== undefined && item.TAT !== "" && item.TAT !== null && item.TAT !== NaN)
                        //             // {
                        //             //  tat_days = item.TAT
                        //             // }
                        //             // else{
                        //             //  tat_days = 0
                        //             // }
                        //             //console.log("tat_days",tat_days)
                                    
                        //             holidays = holidays_list;
                        //             //  item.tat_days = parseInt(item.TAT);
                        //              item.tat_days = parseInt(tat_days);
                        //              //console.log("item.tat_days",item.tat_days)
                        //              item.oda_days = parseInt(item.ODA_TAT);
                        //              item.grace_days = parseInt(grace_days);
                        //              item.holidays = parseInt(holidays);
                        //               //console.log(parseInt(tat_days)+"+"+parseInt(oda_days)+"+"+parseInt(grace_days)+"+"+parseInt(holidays))
                                // item.grace_count = grace_count;
                                // item.TAT = TAT;
                                // item.ODA_TAT = ODA_TAT;
                                // item.holiday_cout = holiday_count;
                                // item.count_sunday = count_sunday;
                         if(item.ODA_TAT !== "" && item.ODA_TAT !== undefined && item.ODA_TAT !== null && item.ODA_TAT !== NaN)
                        {
                            var ODA_TAT = item.ODA_TAT;
                        }
                        else{
                            var ODA_TAT = 0;
                        }
                        if(item.TAT !== "" && item.TAT !== undefined && item.TAT !== null && item.TAT !== NaN)
                        {
                            var TAT = item.TAT;
                        }
                        else{
                            var TAT = 0;
                        }
                        if(item.grace_count !== "" && item.grace_count !== undefined && item.grace_count !== null && item.grace_count !== NaN)
                        {
                            var grace_count = item.grace_count;
                        }
                        else{
                            var grace_count = 0;
                        }
                        if(item.holiday_count !== "" && item.holiday_count !== undefined && item.holiday_count !== null && item.holiday_count !== NaN)
                        {
                            var holiday_count = item.holiday_count;
                        }
                        else{
                            var holiday_count = 0;
                        }
                        if(item.count_sunday !== "" && item.count_sunday !== undefined && item.count_sunday !== null && item.count_sunday !== NaN)
                        {
                            var count_sunday = item.count_sunday;
                        }
                        else{
                            var count_sunday = 0;
                        }
                       // console.log("total_tat",total_tat)
                       item.total_tat = parseInt(TAT)+parseInt(ODA_TAT)+parseInt(grace_count)+parseInt(holiday_count)+parseInt(count_sunday)
                        //   console.log(parseInt(TAT)+"+"+parseInt(ODA_TAT)+"+"+parseInt(grace_count)+"+"+parseInt(holiday_count))
                        if(item.total_tat !== ""  )
                        {
                            total_tat = item.total_tat
                        }
                        else{
                                total_tat = 0; 
                        }
                        if(item.damage_amount !== null && item.damage_amount !== undefined)
                        {
                            var damage_amount = item.damage_amount;
                        }
                        
                        else{
                            var damage_amount = 0;
                        }
                        item.damage_amount = damage_amount;
                        const {
                            basic_trans_cost = 0,
                            oda_charges = 0,
                            unloading_charges = 0,
                            incentive_charges = 0,
                            crane_charges = 0,
                            odc_charges = 0,
                            detention_charges = 0,
                            transhipment_charges = 0,
                            penalty_charges = 0,
                        } = item;
                        const getParseFloat = (cost) => parseFloat(cost) || 0;
                        const totalFreight =
                            getParseFloat(basic_trans_cost) +
                            getParseFloat(oda_charges) +
                            getParseFloat(unloading_charges) +
                            getParseFloat(incentive_charges) +
                            getParseFloat(crane_charges) +
                            getParseFloat(odc_charges) +
                            getParseFloat(detention_charges) +
                            getParseFloat(other_charges);
                        item.total_freight = parseFloat(totalFreight).toFixed(2) || 0                       
                        const totalReduce =
                            getParseFloat(damage_amount) +
                            getParseFloat(transhipment_charges) +
                            getParseFloat(penalty_charges);
                        item.reduce_amount = parseFloat(totalReduce).toFixed(2) || 0
                        item.total_amount = (parseFloat(totalFreight) - parseFloat(totalReduce)).toFixed(2)
                        // console.log("item.damage_amount",item.damage_amount,item.damage_amount !== null ,item.damage_amount !== undefined)
                        // var total_amount = (parseFloat(item.total_freight)-(parseFloat(damage_amount))).toFixed(2);
                        // console.log("total_amount",total_amount+" === "+item.gc_waybill_no)
                        // item.total_amount = parseFloat(total_amount);
                            // console.log("total_tat",total_tat)
                                
                        //             var dly = parseInt(TAT)-parseInt(total_tat);
                        //             if(parseInt(dly) > 0)
                        //             {
                        //                 delaydays =  parseInt(dly);
                        //             }
                        //             else{
                        //                 delaydays =  0;
                        //             }                                    
                        //             var ad =  delaydays;
                        //             var ab = item.allowable_weight;
                        //             var ac = item.per_kg_slab_rate;
                        //             ld = parseFloat(ad)*(parseFloat(ab)* parseFloat(ac))*0.05;                                
                        //         //}
                        //     // }                           
                        // }
                        // console.log("item.batches ",item)
                        var allbatches = [];
                        if (item.batches !== "" && item.batches !== undefined) {
                            // console.log("item.batches ", item.batches)
                            if (item.batches.length > 0) {
                                item.batches.map((it) => {
                                    it.pod_document = item.pod_document;
                                    it.consignment_code = item.consignment_code;
                                    batchitems.push(it);
                                })
                            }
                        }
                        item.allbatches = allbatches;
                        // if (item.trip_status === 0) {
                        if (!['DELIVERED', "Delivered", "Force Closed", "FORCE CLOSED", "Cancelled",].includes(item.consignment_status) && item.trip_status !== 0) {
                            // console.log('liability')
                            // liabilityCnt.push(item);
                        }
                        // }
                        // console.log(item.trip_status+"======"+item.process_status+"   "+item.gc_waybill_no);
                        if (item.trip_status === 1 && item.process_status === 0) {
                             pendingwithlspCnt.push(item);
                             
                            //  pendingwithcustCnt.push(item);
                        }
                        if (item.process_status === 1 && item.trip_status === 1) {
                             pendingwithcustCnt.push(item);
                        }
                        
                        if(item.trip_status === 2)
                        {
                            pendingwithfinappCnt.push(item);
                        }
                         
                        if(item.trip_status === 3)
                        {
                             pendingwithfinanceCnt.push(item);
                             counterpendfinance.push(item);
                        }
                        if(item.trip_status === 4)
                        {
                            paywithstatCnt.push(item);
                            counterpendclose.push(item);
                        }
                        if(item.trip_status === 5)
                        {
                            pendingwithcustCnt.push(item); 
                        }
                        if(item.trip_status === 6){
                            pendingwithcustCnt.push(item); 
                        }
                        item.freight_rate = this.state.dieselFreightRate;
                        var fr = 0;
                        var tds = 0;
                        // if (this.state.dieselFreightRate !== "" && this.state.dieselFreightRate !== undefined) {
                        //     fr = parseFloat(this.state.dieselFreightRate);
                        //     tds = parseFloat(item.total_travelled_trip_dist_km);
                        //     var totlcost = fr * tds;
                        //     item.total_cost = totlcost;
                        // }
                        // else {
                        //     item.total_cost = 0;
                        // }
                        // console.log("final item ", item)
                        
                        recordsarr.push(item);
                    })
                }
                // console.log("Pending With Payment checking ", batchitems)
                let newbatchitems = [];               
                // Declare an empty object 
                let totuniqueObject = {}; 
                var totobjTitle;
                // Loop for the array elements 
                for (let i in batchitems) { 
                    // Extract the title 
                    totobjTitle = batchitems[i]['gc_waybill_no']; 
                    // Use the title as the index 
                    totuniqueObject[totobjTitle] = batchitems[i]; 
                } 
                // Loop to push unique object into array 
                for (var a in totuniqueObject) { 
                    newbatchitems.push(totuniqueObject[a]); 
                }
                if (newbatchitems.length > 0) {
                    newbatchitems.map((it) => {
                        // if(it.process_status === 0){
                        //       pendingwithlspCnt.push(it);
                        // }
                        // if (it.batch_status === 0  && it.batch_status === 4 && it.batch_status === 5 ) {
                        //     pendingwithlspCnt.push(it);
                        // }
                        if (it.batch_status === 0 && it.trip_status === 1 && it.process_status === 1) {
                            pendingwithcustCnt.push(it);
                            counterpendcust.push(it);
                            counterpendinv.push(it)
                        }
                        if (it.batch_status === 1) {
                            pendingwithfinappCnt.push(it);
                            counterpendfin.push(it)
                            if(localStorage.getItem("user_type") === "CUSTOMER")
                            {
                                counterpendfin.push(it);
                            }
                        }
                        if (it.batch_status === 2) {
                            pendingwithfinanceCnt.push(it);
                            counterpendfinance.push(it);
                            if(localStorage.getItem("user_type") === "CUSTOMER")
                            {
                                counterpendfinance.push(it)
                            }
                        }
                        if (it.batch_status === 3) {
                            paywithstatCnt.push(it);
                             counterpendclose.push(it)
                        }
                        if (it.batch_status === 4 ) {
                            pendingwithcustCnt.push(it);
                            
                        }
                        if(it.batch_status === 5){
                            pendingwithcustCnt.push(it);
                        }
                        // if (it.batch_status === 5) {
                        //     // pendingwithlspCnt.push(it);
                        //     // counterpendinv.push(it);
                        //     // counterpendcust.push(it);
                        // }
                    })
                }
                      
                var pendingwithcustomer = [];
                var submittedtofin = [];
                var paymentclose = [];
                var pendfinapp = [];
                // it is not in use 
                // var batchescustomer = [];
                // if(batches.length > 0){
                //     batches.map((itm) => {
                //         if(itm.batch_status ==  0)
                //         {
                //             batchescustomer.push(itm);
                //         }
                //     })
                // }
                //  console.log(counterpendinv,"counterpendinv")
                //  var batchlists = groupBy(pendingcustomerCnt, rdata => (rdata.batch_no));
                
                //  console.log("counterpendfinance",counterpendfinance)
                let newpayclosebatch = [];               
                // Declare an empty object 
                let totpaybatch = {}; 
                var totobjTitlepay;
                // Loop for the array elements 
                for (let i in counterpendclose) { 
                    // Extract the title 
                    totobjTitlepay = counterpendclose[i]['gc_waybill_no']; 
                    // Use the title as the index 
                    totpaybatch[totobjTitlepay] = counterpendclose[i]; 
                } 
                // Loop to push unique object into array 
                for (var a in totpaybatch) { 
                    newpayclosebatch.push(totpaybatch[a]); 
                }
                let submittedtofinance = [];
                // Declare an empty object 
                let totfinancebatch = {};
                var totfinancepay;
                 for(let i in counterpendfinance) {
                    totfinancepay = counterpendfinance[i]['gc_waybill_no'];
                    totfinancebatch[totfinancepay] = counterpendfinance[i];
                 }
                   for (var a in totfinancebatch) { 
                    submittedtofinance.push(totfinancebatch[a]); 
                }
                let pendingwithcustomertable = [];
                // Declare an empty object 
                let totcustomerbatch = {};
                var totcustomerpay;
                 for(let i in counterpendcust) {
                    totcustomerpay = counterpendcust[i]['gc_waybill_no'];
                    totcustomerbatch[totcustomerpay] = counterpendcust[i];
                }
                   for (var a in totcustomerbatch) { 
                    pendingwithcustomertable.push(totcustomerbatch[a]); 
                }
                var batchlists = groupBy(pendingwithcustomertable, rdata => (rdata.invoice_no));
                var finbatchlists = groupBy(submittedtofinance, rdata => (rdata.invoice_no));
                var paybatchlists = groupBy(newpayclosebatch, rdata => (rdata.invoice_no));
                var finappbatchlists = groupBy(counterpendfin, rdata => (rdata.invoice_no));
                // var finappbatchlists = groupBy(pendingfinappCnt, rdata => (rdata.batch_no));
                try {
                    batchlists.forEach((values, key) => {
                        //  console.log("values ", values)
                        // var totalcost = 0;
                        var rw = {};
                        rw["batch_no"] = key;
                        // if (values.length > 0) {
                        //      var itemlist = [];
                            // values.map((itm) => {
                            //     itm.freight_rate = this.state.dieselFreightRate;
                            //     var fr = 0;
                            //     var tds = 0;
                            //     if (this.state.dieselFreightRate !== "" && this.state.dieselFreightRate !== undefined) {
                            //         fr = parseFloat(this.state.dieselFreightRate);
                            //         tds = parseFloat(itm.total_travelled_trip_dist_km);
                            //         console.log("itm.total_travelled_trip_dist_km ", itm.total_travelled_trip_dist_km)
                            //         var totlcost = parseFloat(fr) * parseFloat(tds);
                            //         itm.total_cost = parseFloat(totlcost);
                            //         totalcost = parseFloat(totalcost) + parseFloat(totlcost);
                            //     }
                            //     else {
                            //         itm.total_cost = 0;
                            //     }
                            //     itemlist.push(itm);
                            // })
                        // }
                            rw["itemlist"] = values;
                            rw["invoice_no"] = values[0].invoice_no;
                            rw["invoice_amount"] = values[0].invoice_amount;
                            rw["invoice_date"] = values[0].invoice_date;
                            rw["invoice_gst_amount"] = values[0].invoice_gst_amount;
                            rw["consignment_code"] = values[0].consignment_code;
                            rw["is_pendingwith_payment"] = values[0].is_pendingwith_payment;
                            rw["batch_no"] = values[0].batch_no;
                            rw["total_freight"] = parseFloat(values[0].invoice_amount  || 0)+ parseFloat(values[0].reduce_amount || 0)
                            rw["reduce_amount"] = parseFloat(values[0].reduce_amount || 0)  || 0
                            //  rw["invoice_document"] = values[0].invoice_document;
                        
                        
                        pendingwithcustomer.push(rw);
                        //    console.log("Rws ", rw)
                    })
                    finbatchlists.forEach((values, key) => {
                        var totalcost = 0;
                        var rw = {};
                        rw["batch_no"] = key;
                        //  if (values.length > 0) {
                        //     var itemlist = [];
                        //     values.map((itm) => {
                        //         itm.freight_rate = this.state.dieselFreightRate;
                        //         var fr = 0;
                        //         var tds = 0;
                        //         if (this.state.dieselFreightRate !== "" && this.state.dieselFreightRate !== undefined) {
                        //             fr = parseFloat(this.state.dieselFreightRate);
                        //             tds = parseFloat(itm.total_travelled_trip_dist_km);
                        //             // console.log("itm.total_travelled_trip_dist_km ", itm.total_travelled_trip_dist_km)
                        //             var totlcost = parseFloat(fr) * parseFloat(tds);
                        //             itm.total_cost = parseFloat(totlcost);
                        //             totalcost = parseFloat(totalcost) + parseFloat(totlcost);
                        //         }
                        //         else {
                        //             itm.total_cost = 0;
                        //         }
                        //         itemlist.push(itm);
                        //     })
                        //  }
                        // rw["total_batch_cost"] = totalcost;
                        rw["itemlist"] = values;
                        rw["invoice_no"] = values[0].invoice_no;
                        rw["invoice_amount"] = values[0].invoice_amount;
                        rw["invoice_date"] = values[0].invoice_date;
                        rw["invoice_gst_amount"] = values[0].invoice_gst_amount;
                        rw["invoice_document"] = values[0].invoice_document;
                        rw["total_freight"] = parseFloat(values[0].invoice_amount || 0) + parseFloat(values[0].reduce_amount || 0)
                        rw["reduce_amount"] = parseFloat(values[0].reduce_amount || 0) || 0
                        submittedtofin.push(rw);
                        //   console.log("Rws ", rw)
                    })
                    paybatchlists.forEach((values, key) => {
                        // var totalcost = 0;
                        var rw = {};
                        rw["batch_no"] = key;
                        // var itemlist = [];
                        // if (values.length > 0) {
                        //     var itemlist = [];
                        //     values.map((itm) => {
                        //         itm.freight_rate = this.state.dieselFreightRate;
                        //         var fr = 0;
                        //         var tds = 0;
                        //         if (this.state.dieselFreightRate !== "" && this.state.dieselFreightRate !== undefined) {
                        //             fr = parseFloat(this.state.dieselFreightRate);
                        //             tds = parseFloat(itm.total_travelled_trip_dist_km);
                        //             // console.log("itm.total_travelled_trip_dist_km ", itm.total_travelled_trip_dist_km)
                        //             var totlcost = parseFloat(fr) * parseFloat(tds);
                        //             itm.total_cost = parseFloat(totlcost);
                        //             totalcost = parseFloat(totalcost) + parseFloat(totlcost);
                        //         }
                        //         else {
                        //             itm.total_cost = 0;
                        //         }
                        //         itemlist.push(itm);
                        //     })
                        // }
                        // rw["total_batch_cost"] = totalcost;
                        rw["itemlist"] = values;
                        rw["invoice_no"] = values[0].invoice_no;
                        rw["invoice_amount"] = values[0].invoice_amount;
                        rw["invoice_date"] = values[0].invoice_date;
                        rw["invoice_gst_amount"] = values[0].invoice_gst_amount;
                        // rw["invoice_document"] = values[0].invoice_document;
                        rw["total_freight"] = parseFloat(values[0].invoice_amount || 0) + parseFloat(values[0].reduce_amount || 0)
                        rw["reduce_amount"] = parseFloat(values[0].reduce_amount || 0) || 0
                        paymentclose.push(rw);
                        // console.log("paymentclose",paymentclose)
                        //  console.log("Rws ", rw)
                    })
                    finappbatchlists.forEach((values, key) => {
                        // console.log("values ", values)
                        // var totalcost = 0;
                        var rw = {};
                        rw["batch_no"] = key;
                        // if (values.length > 0) {
                        //     var itemlist = [];
                        //     values.map((itm) => {
                        //         itm.freight_rate = this.state.dieselFreightRate;
                        //         var fr = 0;
                        //         var tds = 0;
                        //         if (this.state.dieselFreightRate !== "" && this.state.dieselFreightRate !== undefined) {
                        //             fr = parseFloat(this.state.dieselFreightRate);
                        //             tds = parseFloat(itm.total_travelled_trip_dist_km);
                        //             // console.log("itm.total_travelled_trip_dist_km ", itm.total_travelled_trip_dist_km)
                        //             var totlcost = parseFloat(fr) * parseFloat(tds);
                        //             itm.total_cost = parseFloat(totlcost);
                        //             totalcost = parseFloat(totalcost) + parseFloat(totlcost);
                        //         }
                        //         else {
                        //             itm.total_cost = 0;
                        //         }
                        //         itemlist.push(itm);
                        //     })
                        // }
                        // rw["total_batch_cost"] = totalcost;
                        rw["itemlist"] = values;
                        rw["invoice_no"] = values[0].invoice_no;
                        rw["invoice_amount"] = values[0].invoice_amount;
                        rw["invoice_date"] = values[0].invoice_date;
                        rw["invoice_gst_amount"] = values[0].invoice_gst_amount;
                        // rw["invoice_document"] = values[0].invoice_document;
                        pendfinapp.push(rw);
                        // counterpendfin.push(rw);
                        //   console.log("Rws ", rw)
                    })
                } catch (error) {
                }
                if(this.state.invoicewithstatus &&  this.state.invoicewithstatus=="btn-danger" && this.state.invoiceNo && this.state.invoiceNo.value){
                    var pendingwithcustomer = pendingwithcustomer.filter(item => item.invoice_no &&  item.invoice_no== this.state.invoiceNo.value)
                }
                if(localStorage.getItem("user_type") === "CUSTOMER")
                {
                    setTimeout(() => {
                        if(this.state.tab_status == 3 || this.state.tab_status == ""){                            
                            this.setState({
                                rowData: pendingwithcustomer,
                                oRowData: pendingwithcustomer,
                            })
                        }
                        else{
                            this.showTabView(this.state.tab_status)
                        }   
                        this.setState({
                            // liability_data: liabilityCnt,
                            // rowData: pendingwithcustomer,
                            // oRowData: pendingwithcustomer,
                            // liabilityCnt: liabilityCnt,
                            pendingwithlspCnt: pendingwithlspCnt,
                            pendingwithcustCnt: pendingwithcustomer,
                            counterpendfin: pendfinapp.length,
                            finalapprovedData: pendfinapp,
                            pendingwithfinanceCnt:submittedtofin,
                            paywithstatCnt:paymentclose,
                            counterpendfinance: submittedtofin.length,
                            counterpendclose: paymentclose.length,
                            counterpendcust: pendingwithcustomer.length,
                            // counterpendfin: paymentclose.length,
                            // counterpendclose: paymentclose.length,
                            counterpendinv: pendingwithcustomer.length,
                            // counterpendfinance: counterpendfinance.length,
                            // counterpendfinapp: pendingfinappCnt.length,
                            loadshow:"show-n",
                            overly:"show-n",
                            // gLoad:"show-n"
                        })
                    },2000)
                    this.showTabView(3);
                }
                else{
                    setTimeout(()=>{   
                        if(this.state.tab_status == 2 || this.state.tab_status == ""){
                            this.setState({
                                rowData: pendingwithlspCnt,
                                oRowData: pendingwithlspCnt,
                            })
                        }
                        else{
                            this.showTabView(this.state.tab_status)
                        }           
                        this.setState({
                            // liability_data: liabilityCnt,
                            // rowData: pendingwithlspCnt,
                            // oRowData: pendingwithlspCnt,
                            // liabilityCnt: liabilityCnt,
                            pendingwithlspCnt: pendingwithlspCnt,
                            pendingwithcustCnt: pendingwithcustomer,
                            counterpendfin: pendfinapp.length,
                            finalapprovedData: pendfinapp,
                            pendingwithfinanceCnt:submittedtofin,
                            paywithstatCnt:paymentclose,
                            counterpendfinance: submittedtofin.length,
                            counterpendclose: paymentclose.length,
                            counterpendcust: pendingwithcustomer.length,
                            // counterpendfin: paymentclose.length,
                            // counterpendclose: paymentclose.length,
                            counterpendinv: pendingwithcustomer.length,
                            // counterpendfinance: counterpendfinance.length,
                            // counterpendfinapp: pendingfinappCnt.length,
                            loadshow:"show-n",
                            overly:"show-n",
                            // gLoad:"show-n"
                        })
                    },2000)
                }
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                })
            // this.showTabView(2);
            } catch (error) {
                console.log("Error at loadTrips: ",error)
                this.setState({
                    loadshow: "show-n",
                    overly: "show-n",
                })
            }
        })
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    showTabView = (oVal) => {
        const { liability_data } = this.state
        this.setState({
            tab_status:oVal,
            overlay: "show-n",
            
        })
        setTimeout(() => {
            if (oVal === 1) {
                this.setState({
                    liable: "btn-danger",
                    pendingwithlsp: "btn-default",
                    pendingwithcust: "btn-default",
                    invoicewithstatus: "btn-default",
                    pendingwithfinapp: "btn-default",
                    pendingwithfinance: "btn-default",
                    paywithstat: "btn-default",
                    liableshow: "show-m",
                    pendingwithlspshow: "show-n",
                    pendingwithcustshow: "show-n",
                    paywithstatshow: "show-n",
                    pendingwithfinanceshow: "show-n",
                    pendingwithfinappshow: "show-n",
                    invoicewithstatusshow: "show-n",
                    actiontype: "liable",
                    overlay: "show-n",
                    showSendBackBtn: 0,
                    showSendApproveBtn: 0,
                    showSendRejectBtn: 0,
                    rowData: [],
                    oRowData:[],
                    invoiceNo:{ value: "", label: "All" },
                });
            }           
            if (oVal === 2) {
                this.setState({
                    liable: "btn-default",
                    pendingwithlsp: "btn-danger",
                    pendingwithcust: "btn-default",
                    invoicewithstatus: "btn-default",
                    pendingwithfinapp: "btn-default",
                    pendingwithfinance: "btn-default",
                    paywithstat: "btn-default",
                    liableshow: "show-n",
                    pendingwithlspshow: "show-m",
                    pendingwithcustshow: "show-n",
                    paywithstatshow: "show-n",
                    pendingwithfinanceshow: "show-n",
                    pendingwithfinappshow: "show-n",
                    invoicewithstatusshow: "show-n",
                    actiontype: "pendingwithlsp",
                    overlay: "show-n",
                    showSendBackBtn: 0,
                    showSendApproveBtn: 1,
                    showSendRejectBtn: 0,
                    rowData: this.state.pendingwithlspCnt,
                    oRowData:this.state.pendingwithlspCnt,
                    invoiceNo:{ value: "", label: "All" },
                });
            }
            if (oVal === 3) {
                this.setState({
                    liable: "btn-default",
                    pendingwithlsp: "btn-default",
                    pendingwithcust: "btn-danger",
                    invoicewithstatus: "btn-danger",
                    pendingwithfinapp: "btn-default",
                    pendingwithfinance: "btn-default",
                    paywithstat: "btn-default",
                    liableshow: "show-n",
                    pendingwithlspshow: "show-n",
                    pendingwithcustshow: "show-m",
                    paywithstatshow: "show-n",
                    pendingwithfinappshow: "show-n",
                    pendingwithfinanceshow: "show-n",
                    invoicewithstatusshow: "show-m",
                    actiontype: "pendingwithcust",
                    overlay: "show-n",
                    showSendBackBtn: 1,
                    showSendApproveBtn: 1,
                    showSendRejectBtn: 1,
                    rowData: this.state.pendingwithcustCnt,
                    oRowData:this.state.pendingwithcustCnt,
                });
            }
            if (oVal === 4) {
                this.setState({
                    liable: "btn-default",
                    pendingwithlsp: "btn-default",
                    pendingwithcust: "btn-default",
                    invoicewithstatus: "btn-default",
                    pendingwithfinapp: "btn-danger",
                    pendingwithfinance: "btn-default",
                    paywithstat: "btn-default",
                    liableshow: "show-n",
                    pendingwithlspshow: "show-n",
                    pendingwithcustshow: "show-n",
                    paywithstatshow: "show-n",
                    pendingwithfinanceshow: "show-n",
                    pendingwithfinappshow: "show-m",
                    invoicewithstatusshow: "show-n",
                    actiontype: "pendingwithfinapp",
                    overlay: "show-n",
                    showSendBackBtn: 0,
                    showSendApproveBtn: 1,
                    showSendRejectBtn: 0,
                    rowData: this.state.finalapprovedData,
                    oRowData:this.state.finalapprovedData,
                    invoiceNo:{ value: "", label: "All" },
                });
            }
            if (oVal === 5) {
                this.setState({
                    liable: "btn-default",
                    pendingwithlsp: "btn-default",
                    pendingwithcust: "btn-default",
                    invoicewithstatus: "btn-default",
                    pendingwithfinapp: "btn-default",
                    pendingwithfinance: "btn-danger",
                    paywithstat: "btn-default",
                    liableshow: "show-n",
                    pendingwithlspshow: "show-n",
                    pendingwithcustshow: "show-n",
                    paywithstatshow: "show-n",
                    pendingwithfinanceshow: "show-m",
                    pendingwithfinappshow: "show-n",
                    invoicewithstatusshow: "show-n",
                    actiontype: "pendingwithfinance",
                    overlay: "show-n",
                    showSendBackBtn: 0,
                    showSendApproveBtn: 1,
                    showSendRejectBtn: 0,
                    rowData: this.state.pendingwithfinanceCnt,
                    oRowData:this.state.pendingwithfinanceCnt,
                    invoiceNo:{ value: "", label: "All" },
                });
            }
            if (oVal === 6) {
                this.setState({
                    liable: "btn-default",
                    pendingwithlsp: "btn-default",
                    pendingwithcust: "btn-default",
                    invoicewithstatus: "btn-default",
                    pendingwithfinapp: "btn-default",
                    pendingwithfinance: "btn-default",
                    paywithstat: "btn-danger",
                    liableshow: "show-n",
                    pendingwithlspshow: "show-n",
                    pendingwithcustshow: "show-n",
                    paywithstatshow: "show-m",
                    pendingwithfinappshow: "show-n",
                    invoicewithstatusshow: "show-n",
                    actiontype: "paywithstat",
                    pendingwithfinanceshow: "show-n",
                    overlay: "show-n",
                    showSendBackBtn: 0,
                    showSendApproveBtn: 1,
                    showSendRejectBtn: 0,
                    rowData: this.state.paywithstatCnt,
                    oRowData:this.state.paywithstatCnt,
                    invoiceNo:{ value: "", label: "All" },
                });
            }
        }, 500)
    }
    onApproveLSPData = (rownodes, actiontype) => {
        // console.log("paramets", rownodes)
        if (rownodes.length > 0) {
            var params = {
                rownodelist: JSON.stringify(rownodes)
            }
            if (actiontype === "pendingwithlsp") {
                redirectURL.post("/billing/lspaction", params,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                    .then((response) => {
                        if (response.data.status === "success") {
                            this.getMonthDates(this.state.startDate)
                        }
                        else {
                        }
                    })
            }
            if (actiontype === "pendingwithcust") {
                redirectURL.post("/billing/customerapprv", params,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                    .then((response) => {
                        if (response.data.status === "success") {
                            this.getMonthDates(this.state.startDate)
                            let params = {
                                "from_date": this.state.from_date,
                                "to_date": this.state.to_date,
                                "consigner_code": [
                                    {
                                        "value": "all",
                                        "label": "All"
                                    }
                                ],
                                "pod_status": 1,
                                "serviceProviders": [],
                                "is_filter_call": 0
                            }
                            this.loadTrips(params);
                        }
                        else {
                        }
                    })
            }
            if (actiontype === "pendingwithfinance") {
                redirectURL.post("/billing/finapprv", params,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                    .then((response) => {
                        if (response.data.status === "success") {
                            this.getMonthDates(this.state.startDate)
                            let params = {
                                "from_date": this.state.from_date,
                                "to_date": this.state.to_date,
                                "consigner_code": [
                                    {
                                        "value": "all",
                                        "label": "All"
                                    }
                                ],
                                "pod_status": 1,
                                "serviceProviders": [],
                                "is_filter_call": 0
                            }
                            this.loadTrips(params);
                        }
                        else {
                        }
                    })
            }
        }
    }
    onRejectCustomer = (rownodes, actiontype) => {
        //  console.log('rownodes ', rownodes)
        if (rownodes.length > 0) {
            var params = {
                rownodelist: JSON.stringify(rownodes)
            }
            // if (actiontype === "pendingwithcust") {
            //     redirectURL.post("/billing/customeraction", params,{
            //         headers: {
            //             'Content-Type': 'application/json',
            //             'Authorization': localStorage.getItem("userid")
            //           }
            //       })
            //         .then((response) => {
            //             if (response.data.status === "success") {
            //                 this.getMonthDates(this.state.startDate)
            //                 let params = {
            //                     "from_date": this.state.from_date,
            //                     "to_date": this.state.to_date,
            //                     "consigner_code": [
            //                         {
            //                             "value": "all",
            //                             "label": "All"
            //                         }
            //                     ],
            //                     "pod_status": 1,
            //                     "serviceProviders": [],
            //                     "is_filter_call": 0
            //                 }
            //                 this.loadTrips(params);
            //             }
            //             else {
            //             }
            //         })
            // }
            if (actiontype === "pendingwithfinance") {
                redirectURL.post("/billing/finrej", params,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                    .then((response) => {
                        if (response.data.status === "success") {
                            this.getMonthDates(this.state.startDate)
                            let params = {
                                "from_date": this.state.from_date,
                                "to_date": this.state.to_date,
                                "consigner_code": [
                                    {
                                        "value": "all",
                                        "label": "All"
                                    }
                                ],
                                "pod_status": 1,
                                "serviceProviders": [],
                                "is_filter_call": 0
                            }
                            this.loadTrips(params);
                        }
                        else {
                        }
                    })
            }
        }
    }
    uploadDocument = (event) => {
        event.preventDefault();
        const {delivered_date,podDoc,selected_row_nodes,multiplePodDocs,multiplePodNames,rownode,multipleFiles} = this.state
    
        if (rownode.length > 0) { // for single pod upload
            var rowid = this.state.rownode[0]._id;
            const formData = new FormData();
            formData.append("podDoc", podDoc);
            formData.append("delivered_date", delivered_date);
            formData.append("rowid", rowid);
            //   console.log("FormData ", formData);
            redirectURL.post("/billing/savepoddoc", formData)
                .then((resp) => {
                    if (resp.data.status === 'success') {
                        this.setState({
                            selected_row_nodes: [],
                        })
                    }
                     window.location.reload();
                })
        }
        else if (selected_row_nodes.length > 0) {  // for bulk multiple pod uploads
            // console.log('selected_row_nodes', selected_row_nodes)
            // console.log('multiplePodDocs', multiplePodDocs)
            // console.log('multiplePodNames', multiplePodNames)
            let matched = false
            let otpt
            if (multiplePodNames.length >0){
                otpt =  selected_row_nodes.map((e,i) =>{
                    if(e.consignment_code === multiplePodNames[i].gc_waybill_no){
                        matched =true
                    }else{
                        return false
                    }
                })
            }
            // console.log("otpt",otpt)
            if(otpt.includes(false) === true){
                this.setState({
                    show: true,
                    basicTitle: "gc_waybill_no should match.",
                    basicType: "info"
                })
            }else{
                let rowid = this.state.selected_row_nodes.map(e => e._id);
                // console.log("rowid", rowid)
                const formData = new FormData();
                // formData.append("multiplePodDocs", multiplePodDocs);
                formData.append("multiplePodNames", JSON.stringify(multiplePodNames));
                formData.append("rowid", JSON.stringify(rowid));
                for (let i = 0; i < multipleFiles.length; i++) {
                    formData.append('files', multipleFiles[i]);
                  }
                // console.log("FormData ", formData);
                redirectURL.post("/billing/saveMultipledocs", formData)
                    .then((resp) => {
                        if (resp.data.message === 'success') {
                            this.setState({
                                selected_row_nodes: [],
                                multiplePodDocs : [],
                                multiplePodNames : [],
                                show: true,
                                basicTitle: "Success.",
                                basicType: "warning"
                            })
                        }
                        window.location.reload();
                    })
            }
        }
    }
    uploadSpecialDocument = (event) => {
        event.preventDefault();
        var supportField = this.state.supportField;
        var supportDoc = this.state.supportDoc;
        if (this.state.rownode.length > 0) {
            var rowid = this.state.rownode[0]._id;
            const formData = new FormData();
            formData.append("supportDoc", supportDoc);
            formData.append("supportField", supportField);
            formData.append("rowid", rowid);
            //  console.log("FormData ", formData);
            redirectURL.post("/billing/savespecialdoc", formData)
                .then((response) => {
                    // window.location.reload();
                    this.setState({
                        sliderSupportshow:"",
                        overly:"show-n"
                    })
                    document.getElementById("uplodTpt").reset();
                    this.getMonthDates(this.state.defStartDate)
                })
        }
    }
    changeSupportFileHandler = (event) => {
        // console.log("event.target.files[0] ", event)
        if (this.state.supportField === 'osp_doc') {
            this.setState({
                supportDoc: event.target.files[0]
            })
        }
        else if (this.state.supportField === 'lu_charges_doc') {
            this.setState({
                supportDoc: event.target.files[0]
            })
        }
        else if (this.state.supportField === 'special_approval_doc') {
            this.setState({
                supportDoc: event.target.files[0]
            })
        }
        else if (this.state.supportField === 'to_pay_charges_doc') {
            this.setState({
                supportDoc: event.target.files[0]
            })
        }
    }
    setBulkUploadEpod = () => {
        var rownodes = this.gridApi.getSelectedRows();
        if (rownodes.length > 0) {
            this.setState({
                rownode: [],
                selected_row_nodes: rownodes,
                bulkslidershow: "slider-translate-40p", // slide30
                overly: "show-n",
                loadshow: 'show-n'
            })
        } else {
            this.setState({
                show: true,
                basicTitle: "Please Select multiple rows.",
                basicType: "info"
            })
        }
        // console.log("rowNodes", rownodes)
    }
    onClickSendBack= () => {
        var rownodes = this.gridApi.getSelectedRows();
        // var confirmToapprove = 0;
        if (rownodes.length > 0) {
            this.setState({
                custsliderSendBackRemarks:"slider-translate-30p",
                overly:"show-m",
                loadshow: 'show-n',
                invRowNodes: rownodes
            })
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    
    onClickSendBackForm= (event) => {
        event.preventDefault();
        var custsendBackRemarks = this.state.custsendBackRemarks;
        var rownodes = this.gridApi.getSelectedRows();
        var confirmToapprove = 0;
        if (rownodes.length > 0) {
            rownodes.map((item) => {
                if (item.is_approve_status === 2) {
                }
                else {
                    confirmToapprove = 1
                }
            })
            
            //   console.log("sendBack ", rownodes)
            setTimeout(() => {
                var prms = {
                    rownodelist: JSON.stringify(rownodes),
                    remarks:custsendBackRemarks
                }
                //  console.log("prms ", prms)
                redirectURL.post("/billing/sendBackToTransporter",prms,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        var startDate= this.state.defStartDate;
                        this.getLiabilityData(startDate);
                        this.setState({
                            custsliderSendBackRemarks:"",
                            overly:"show-n",
                            custsendBackRemarks:""
                        })
                        let params = {
                            "from_date": this.state.from_date,
                            "to_date": this.state.to_date,
                            "consigner_code": [
                                {
                                    "value": "all",
                                    "label": "All"
                                }
                            ],
                            "pod_status": 1,
                            "serviceProviders": [],
                            "is_filter_call": 0
                        }
                        this.loadTrips(params);
                        window.location.reload();
                        
                        this.showTabView(2);
                         
                    }
                })
            
            }, 500)
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    onClickApprove = () => {
    var rownodes = this.gridApi.getSelectedNodes();
        //   console.log("rownodes ", rownodes)
            if (rownodes.length > 0) {
                this.setState({
                    sliderApproveRemarks:"slider-translate-30p",
                    overly:"show-m",
                    loadshow:"show-n",
                    invRowNodes: rownodes
                })
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Please select at least one item from grid",
                    basicType:"danger"
                });
            }
    }
    onClickFinApproveRemarksForm= (event) => {
        event.preventDefault();
        var finApproveRemarks = this.state.finApproveRemarks;
        // console.log("finApproveRemarks",finApproveRemarks)
        var rownodes = this.gridApi.getSelectedRows();
        // console.log("rownodes",rownodes)
        var confirmToapprove = 0;
        if (rownodes.length > 0) {
            rownodes.map((item) => {
                if (item.is_approve_status === 2) {
                }
                else {
                    confirmToapprove = 1
                }
            })
            
            //  console.log("sendBack ", rownodes)
            setTimeout(() => {
                var prms = {
                    rownodelist: JSON.stringify(rownodes),
                    remarks:finApproveRemarks
                }
                //  console.log("prms ", prms)
                redirectURL.post("/billing/finapprv",prms,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        var startDate= this.state.defStartDate;
                        this.getLiabilityData(startDate);
                        this.setState({
                            sliderFinApproveRemarks:"",
                            overly:"show-n",
                            finApproveRemarks:""
                        })
                        let params = {
                            "from_date": this.state.from_date,
                            "to_date": this.state.to_date,
                            "consigner_code": [
                                {
                                    "value": "all",
                                    "label": "All"
                                }
                            ],
                            "pod_status": 1,
                            "serviceProviders": [],
                            "is_filter_call": 0
                        }
                        this.loadTrips(params);
                        window.location.reload();
                        
                        this.showTabView(2);
                       
                    }
                })
            
            }, 500)
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    onClickViewDamageItem(props) {
        // console.log("props ", props.data)
        var rowId = props.data._id;
        // console.log("rowId",rowId)
        this.setState({
            rowId: rowId,
            rownode: [props.data],
            damageitemlist: "slider-translate-30p",
            overly: "show-m"
        })

    }
    onClickViewChargeItem(props) {
        var rowId = props.data._id;
        const {odc_file=[],detention_file=[],unloading_file=[],crane_file=[]} =  props.data
        this.setState({
            rowId: rowId,
            rownode: [props.data],
            crane_file,
            odc_file,
            detention_file,
            unloading_file,
            chargesItemsList: "slider-translate-30p",
            overly: "show-m"
        })
    }
    onClickUpdateChargesBtn(props){
        var rowId = props.data._id;
        this.setState({
            rowId: rowId,
            selectedRow: props.data,
            chargeValues: {
                unloading_charges: parseFloat(props.data.unloading_charges) || 0,
                crane_charges: parseFloat(props.data.crane_charges) || 0,
                odc_charges: parseFloat(props.data.odc_charges) || 0,
                detention_charges: parseFloat(props.data.detention_charges) || 0,
            },
            unloading_charges: parseFloat(props.data.unloading_charges) || 0,
            crane_charges: parseFloat(props.data.crane_charges) || 0,
            odc_charges: parseFloat(props.data.odc_charges) || 0,
            detention_charges: parseFloat(props.data.detention_charges) || 0,
            showUpdateCharges: "slider-translate-40p",
            overly: "show-m"
        })
    }
    onClickUpdateCharges(event) {
        event.preventDefault();  // Prevent default form submission
        if (this.validateForm()) {
            const { selectedRow, chargeValues, files } = this.state;
            // Safely parse charges as numbers'
            const unloading_charges = chargeValues.unloading_charges
                ? parseFloat(chargeValues.unloading_charges) : 0;
            const crane_charges = chargeValues.crane_charges
                ? parseFloat(chargeValues.crane_charges) : 0;
            const odc_charges = chargeValues.odc_charges
                ? parseFloat(chargeValues.odc_charges) : 0;
            const detention_charges = chargeValues.detention_charges
                ? parseFloat(chargeValues.detention_charges) : 0;
            const selectedUnloadingCharges = parseFloat(selectedRow.unloading_charges || 0);
            const selectedCraneCharges = parseFloat(selectedRow.crane_charges || 0);
            const selectedOdcCharges = parseFloat(selectedRow.odc_charges || 0);
            const selectedDetentionCharges = parseFloat(selectedRow.detention_charges || 0);
            // If any charge value is different, create FormData for update
            if (
                unloading_charges !== selectedUnloadingCharges ||
                crane_charges !== selectedCraneCharges ||
                odc_charges !== selectedOdcCharges ||
                detention_charges !== selectedDetentionCharges
            ) {
                const formData = new FormData();
                // Append basic fields (rowId and charges)
                formData.append("rowId", selectedRow._id);
                // Append charge values if they are different from selected values
                // Check and append Unloading Charges and File
                if ((parseFloat(unloading_charges) !== parseFloat(selectedUnloadingCharges)) && files.unloading_charges) {
                    formData.append("unloading_charges", parseFloat(unloading_charges.toFixed(2)));
                    formData.append("unloading_file", files.unloading_charges);
                }
                // Check and append Crane Charges and File
                if ((parseFloat(crane_charges) !== parseFloat(selectedCraneCharges)) && files.crane_charges) {
                    formData.append("crane_charges", parseFloat(crane_charges.toFixed(2)));
                    formData.append("crane_file", files.crane_charges);
                }
                // Check and append ODC Charges and File
                if ((parseFloat(odc_charges) !== parseFloat(selectedOdcCharges)) && files.odc_charges) {
                    formData.append("odc_charges", parseFloat(odc_charges.toFixed(2)));
                    formData.append("odc_file", files.odc_charges);
                }
                // Check and append Detention Charges and File
                if ((parseFloat(detention_charges) !== parseFloat(selectedDetentionCharges)) && files.detention_charges) {
                    formData.append("detention_charges", parseFloat(detention_charges.toFixed(2)));
                    formData.append("detention_file", files.detention_charges);
                }
                // Send the formData to the backend
                redirectURL.post('/billing/updatecharges', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",  // Ensure multipart/form-data header
                        Authorization: localStorage.getItem("userid"),  // Set authorization header if needed
                    },
                })
                    .then((response) => {
                        const { status, message } = response.data;
                        if (status === "success") {
                            this.setState({
                                show: true,
                                basicTitle: "Updated Charges Successfully",
                                basicType: "success",
                                unloading_charges: 0,
                                crane_charges: 0,
                                odc_charges: 0,
                                detention_charges: 0,
                                files: {
                                    unloading_charges: null,
                                    crane_charges: null,
                                    odc_charges: null,
                                    detention_charges: null,
                                },
                                isFileRequired: {
                                    unloading_charges: false,
                                    crane_charges: false,
                                    odc_charges: false,
                                    detention_charges: false,
                                },
                                selectedChargeType: "", // Keeps track of the selected charge type
                                chargeValues: {
                                    crane_charges: 0,
                                    odc_charges: 0,
                                    other_charges: 0,
                                    detention_charges: 0,
                                },
                                showUpdateCharges: "",
                            });
                            this.getLiabilityData(this.state.defStartDate);
                        } else {
                            this.setState({
                                show: true,
                                basicTitle: message || "No changes to update",
                                basicType: status === "no_changes" ? "info" : "danger",
                            });
                        }
                    })
                    .catch((error) => {
                        console.error("Error updating charges:", error);
                        this.setState({
                            show: true,
                            basicTitle: "An error occurred while updating charges.",
                            basicType: "danger",
                        });
                    });
            } else {
                this.setState({
                    show: true,
                    basicTitle: "Please Update Charges",
                    basicType: "danger",
                });
            }
        } else {
            this.setState({
                show: true,
                basicTitle: "Please upload the required files for changed charges",
                basicType: "danger",
            });
        }
    }    
    onClickWareHouseChargesUpdateBtn= (props)=>{
        // console.log(props.data,"props data")
        var rowId = props.data._id;
        this.setState({
            rowId: rowId,
            selectedRow: props.data,
            transhipment_charges:parseFloat(props.data.transhipment_charges) || 0,
            penalty_charges:parseFloat(props.data.penalty_charges) || 0,
            wo_remarks:props.data.wo_remarks || "",
            showWhUpdateCharges: "slider-translate-30p",
            overly: "show-m"
        })
    }  
    onClickUpdateWhCharges = async (event) => {
        event.preventDefault();
        try {
            const { selectedRow, transhipment_charges, penalty_charges, wo_remarks } = this.state;    
            if (selectedRow.transhipment_charges === transhipment_charges && selectedRow.penalty_charges === penalty_charges) {
                this.setState({
                    show: true,
                    basicTitle: "Please update one of the charges",
                    basicType: "danger",
                    overly: "show-n",
                    loadshow: "show-n",
                });
                return;
            }    
            const reqParams = {
                selectedRow,
                batch_no: selectedRow.batch_no,
                invoice_no: selectedRow.invoice_no,
                wo_update_by: localStorage.getItem("email") || "",
                transhipment_charges: transhipment_charges !== selectedRow.transhipment_charges
                    ? parseFloat(transhipment_charges) || 0
                    : parseFloat(selectedRow.transhipment_charges) || 0,
                penalty_charges: penalty_charges !== selectedRow.penalty_charges
                    ? parseFloat(penalty_charges) || 0
                    : parseFloat(selectedRow.penalty_charges) || 0,
            };    
            if (wo_remarks) {
                reqParams.wo_remarks = wo_remarks;
            }    
            if (!reqParams.batch_no || !reqParams.invoice_no) {
                this.setState({
                    show: true,
                    basicTitle: "Batch No or Invoice Number is missing",
                    basicType: "danger",
                    // overly: "show-n",
                    loadshow: "show-n",
                });
                return;
            }    
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
            });    
            const response = await redirectURL.post("/billing/updateWareHouseCharges", reqParams, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid"),
                },
            });    
            if (response && response.data.status === "success") {
                this.setState({
                    show: true,
                    basicTitle: response.data.message || "Successfully Updated",
                    basicType: "success",
                    overly: "show-n",
                    loadshow: "show-n",
                    transhipment_charges: 0,
                    penalty_charges: 0,                    
                    showWhUpdateCharges:'',
                    wo_remarks: "",
                },async ()=>{
                    const params = {
                        from_date: this.state.from_date,
                        to_date: this.state.to_date,
                        consigner_code: [{ value: "all", label: "All" }],
                        pod_status: 1,
                        serviceProviders: [],
                        is_filter_call: 0,
                    };
                    await this.loadTrips(params);
                }); 
            } else {
                this.setState({
                    show: true,
                    basicTitle: response.data.message || "Batch No or Invoice Number not found",
                    basicType: "danger",
                    // overly: "show-n",
                    loadshow: "show-n",
                });
            }
        } catch (error) {
            console.error("Error during confirmation:", error);
            this.setState({
                show: true,
                basicTitle: "An error occurred. Please try again.",
                basicType: "danger",
                // overly: "show-n",
                loadshow: "show-n",
            });
        }
    };    
    onClickDamageList(event) {
        event.preventDefault();
            // console.log("damagetype",this.state.damagetype)
            // console.log("rownode",this.state.rownode)
            var damage_amount = this.state.damage_amount
        if(this.state.damage_amount === undefined){
               damage_amount = 0
        }
        if (this.state.damagetype != "" && this.state.damagetype != undefined ) {
            var params = {
                damagetype: this.state.damagetype.value,
                damage_amount:damage_amount,
                rowId: this.state.rownode[0]._id,
                rownode: this.state.rownode
            }
            // console.log("params",params)
            redirectURL.post("/billing/submitdamageitems", params,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                  }
              })
                .then((response) => {
                    if (response.data.status == "success") {
                        
                    }
                     window.location.reload();
                    // document.getElementById("damageitremlist").reset();
                })
        }
        else {
            this.setState({
                show: true,
                basicTitle: "Please write comment",
                basicType: "danger"
            })
        }

    }
    onClickCustApproveRemarksForm= (event) => {
        event.preventDefault();
        var custApproveRemarks = this.state.custApproveRemarks;
        // console.log("custApproveRemarks",custApproveRemarks)
        var rownodes = this.gridApi.getSelectedRows();
        // console.log("rownodes",rownodes)
        var confirmToapprove = 0;
        if (rownodes.length > 0) {
            rownodes.map((item) => {
                if (item.is_approve_status === 2) {
                }
                else {
                    confirmToapprove = 1
                }
            })
            
            //  console.log("sendBack ", rownodes)
            setTimeout(() => {
                var prms = {
                    rownodelist: JSON.stringify(rownodes),
                    remarks:custApproveRemarks
                }
                //  console.log("prms ", prms)
                redirectURL.post("/billing/customerapprv",prms,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        var startDate= this.state.defStartDate;
                        this.getLiabilityData(startDate);
                        this.setState({
                            sliderApproveRemarks:"",
                            overly:"show-n",
                            custApproveRemarks:""
                        })
                        let params = {
                            "from_date": this.state.from_date,
                            "to_date": this.state.to_date,
                            "consigner_code": [
                                {
                                    "value": "all",
                                    "label": "All"
                                }
                            ],
                            "pod_status": 1,
                            "serviceProviders": [],
                            "is_filter_call": 0
                        }
                        this.loadTrips(params);
                        window.location.reload();
                        this.showTabView(2);
                         
                    }
                })
            
            }, 500)
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    onClickReject = () => {
        var rownodes = this.gridApi.getSelectedRows();
        if(rownodes.length > 0)
        {
            this.setState({
                sliderRejectRemarks:"slider-translate-30p",
                overly:"show-m",
                loadshow:"show-n"
            })
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    
    onClickCustRejectRemarksForm= (event) => {
        event.preventDefault();
        var custRejectRemarks = this.state.custRejectRemarks;
        var rownodes = this.gridApi.getSelectedRows();
        var confirmToapprove = 0;
        if (rownodes.length > 0) {
            rownodes.map((item) => {
                if (item.is_approve_status === 2) {
                }
                else {
                    confirmToapprove = 1
                }
            })
            
            //  console.log("sendBack ", rownodes)
            setTimeout(() => {
                var prms = {
                    rownodelist: JSON.stringify(rownodes),
                    remarks:custRejectRemarks
                }
                //   console.log("prms ", prms)
                redirectURL.post("/billing/customerreject",prms,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        var startDate= this.state.defStartDate;
                        this.getLiabilityData(startDate);
                        this.setState({
                            sliderRejectRemarks:"",
                            overly:"show-n",
                            custRejectRemarks:""
                        })
                        let params = {
                            "from_date": this.state.from_date,
                            "to_date": this.state.to_date,
                            "consigner_code": [
                                {
                                    "value": "all",
                                    "label": "All"
                                }
                            ],
                            "pod_status": 1,
                            "serviceProviders": [],
                            "is_filter_call": 0
                        }
                        this.loadTrips(params);
                        window.location.reload();
                        this.showTabView(2);
                         
                    }
                })
            
            }, 500)
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
onClickFinApprove = () => {
        var rownodes = this.gridApi.getSelectedRows();
        //  console.log("rownodes",rownodes)
         var confirmToapprove = 0;
        if (rownodes.length > 0) {
            this.setState({
                sliderFinApproveRemarks:"slider-translate-30p",
                overly:"show-m",
                loadshow:"show-n",
                invRowNodes : rownodes
            })
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    
    onClickTransFinApprove = () => {
        var rownodes = this.gridApi.getSelectedRows();
        // console.log("rownodes",rownodes)
        var confirmToapprove = 0;
        if (rownodes.length > 0) {
            this.setState({
                sliderTransFinApproveRemarks:"slider-translate-30p",
                overly:"show-m",
                loadshow:"show-n",
                invRowNodes: rownodes
            })
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    
    onClickTransFinApproveRemarksForm= (event) => {
        event.preventDefault();
        var transFinApproveRemarks = this.state.transFinApproveRemarks;
        var rownodes = this.gridApi.getSelectedRows();
        var confirmToapprove = 0;
        if (rownodes.length > 0) {
            rownodes.map((item) => {
                if (item.is_approve_status === 2) {
                }
                else {
                    confirmToapprove = 1
                }
            })
            // console.log("sendBack ", rownodes)
            setTimeout(() => {
                var prms = {
                    rownodelist: JSON.stringify(rownodes),
                    remarks:transFinApproveRemarks
                }
                //  console.log("prms ", prms)
                redirectURL.post("/billing/transfinapprv",prms,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                .then(async(response) => {
                    if(response.data.status === "success")
                    {
                        var startDate= this.state.defStartDate;
                        await  this.getLiabilityData(startDate);
                        await this.showTabView(4)
                        this.setState({
                            sliderTransFinApproveRemarks:"",
                            overly:"show-n",
                            transFinApproveRemarks:""
                        })
                        let params = {
                            "from_date": this.state.from_date,
                            "to_date": this.state.to_date,
                            "consigner_code": [
                                {
                                    "value": "all",
                                    "label": "All"
                                }
                            ],
                            "pod_status": 1,
                            "serviceProviders": [],
                            "is_filter_call": 0
                        }
                        this.loadTrips(params);
                        window.location.reload();
                        this.showTabView(2);
                    }
                    if(response.data.status === "customerpending")
                    {
                        this.showTabView(2);
                        this.setState({
                            show:true,
                            basicTitle:"Other gc waybill number(s) not yet approved by customer(s) for the invoice no: "+response.data.invoice_no,
                            basicType:'info',
                            sliderTransFinApproveRemarks:"",
                            overly:"show-n",
                            transFinApproveRemarks:""
                        })
                    }
                })
            
            }, 500)
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    onClickFinReject = () => {
        var rownodes = this.gridApi.getSelectedRows();
        if(rownodes.length > 0)
        {
            this.setState({
                sliderFinRejectRemarks:"slider-translate-30p",
                overly:"show-m",
                loadshow: 'show-n'
            })
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    onClickFinRejectRemarksForm= (event) => {
        event.preventDefault();
        var custFinRejectRemarks = this.state.custFinRejectRemarks;
        var rownodes = this.gridApi.getSelectedRows();
        var confirmToapprove = 0;
        if (rownodes.length > 0) {
            rownodes.map((item) => {
                if (item.is_approve_status === 2) {
                }
                else {
                    confirmToapprove = 1
                }
            })
            
            // console.log("sendBack ", rownodes)
            setTimeout(() => {
                var prms = {
                    rownodelist: JSON.stringify(rownodes),
                    remarks:custFinRejectRemarks
                }
                // console.log("prms ", prms)
                redirectURL.post("/billing/finrej",prms,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                      }
                  })
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        var startDate= this.state.defStartDate;
                        this.getLiabilityData(startDate);
                        this.setState({
                            sliderFinRejectRemarks:"",
                            overly:"show-n",
                            custFinRejectRemarks:""
                        })
                        let params = {
                            "from_date": this.state.from_date,
                            "to_date": this.state.to_date,
                            "consigner_code": [
                                {
                                    "value": "all",
                                    "label": "All"
                                }
                            ],
                            "pod_status": 1,
                            "serviceProviders": [],
                            "is_filter_call": 0
                        }
                        this.loadTrips(params);
                        window.location.reload();
                        this.showTabView(2);
                        
                    }
                })
            
            }, 500)
        }
        else{
            this.setState({
                show:true,
                basicTitle:"Please select at least one item from grid",
                basicType:"danger"
            })
        }
    }
    changeDateTimeValue = (event, currentDate, selectedDate) => {
        // console.log("event", event)
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            delivered_date: getHyphenDDMMMYYYYHHMM(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    changeInvDateTimeValue = (event, currentDate, selectedDate) => {
        // console.log("event", event)
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            invoice_date: getHyphenDDMMMYYYYHHMM(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    changeDateTimeGCValue = (event, currentDate, selectedDate) => {
        // console.log("event", event)
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            gc_date: getHyphenDDMMMYYYYHHMM(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    changeDateTimeSCompletion = (event, currentDate, selectedDate) => {
        // console.log("event", event)
        var d = new Date(event._d);
        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        // console.log("getHyphenDDMMMYYYYHHMM(d) ",getHyphenDDMMMYYYYHHMM(d))
        this.setState({
            shipment_completion_date: getHyphenDDMMMYYYYHHMM(d)
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerStartDateTime = (event) => {
        var d = new Date(event._d);
    //  console.log("d.getMonth()",d.getMonth())
        let month = d.getMonth()
        if (month <= 10) {
            month = `0${month + 1}`
        } else {
            month = `${month + 1}`
        }
        let startDate = d.getFullYear() + "-" + month
        //  console.log("startDate", startDate)
        this.setState({
            startDate: startDate,
            // loadshow: 'show-n',
            // overly: 'show-m'
        });
         this.getLiabilityData(startDate)
       
    }
    handlerStartDeliveryDateTime = (event) => {
        const eventDate = moment(event._d);
        const formattedDate = eventDate.format('YYYY-MM-DD');        
        if (!this.state.to_date) {
            this.setState({ from_date: formattedDate });
            return;
        }    
        const to_date = moment(this.state.to_date);
        // Uncomment after approval ,keeeping commented for development purpose
        // const oneMonthBeforeToDate = to_date.subtract(1, 'months');    
        // // Check if from date is at least one month before to date
        // if (eventDate.isBefore(oneMonthBeforeToDate)) {
        //     this.setState({
        //         from_date: "",
        //         show: true,
        //         basicType: "danger",
        //         basicTitle: "Date Range can't be greater than 1 month"
        //     });
        //     return;
        // }    
        // Check if from date is after to date
        if (eventDate.isAfter(to_date)) {
            this.setState({
                from_date: "",
                show: true,
                basicType: "danger",
                basicTitle: "From Date should not be greater than To Date."
            });
            return;
        }    
        this.setState({ from_date: formattedDate });
    }    
    handlerEndDeliveryDateTime = (event) => {
        const eventDate = moment(event._d);
        const formattedDate = eventDate.format('YYYY-MM-DD');        
        if (!this.state.from_date) {
            this.setState({ to_date: formattedDate });
            return;
        }    
        const from_date = moment(this.state.from_date);    
        // Check if to date is after from date
        if (eventDate.isBefore(from_date)) {
            this.setState({
                to_date: "",
                show: true,
                basicType: "danger",
                basicTitle: "To Date should not be earlier than From Date."
            });
            return;
        }    
        // Check if to date is more than one month after from date
        // Uncomment after approval ,keeeping commented for development purpose
        // const oneMonthAfterFromDate = from_date.add(1, 'months');
        // if (eventDate.isAfter(oneMonthAfterFromDate)) {
        //     this.setState({
        //         to_date: "",
        //         show: true,
        //         basicType: "danger",
        //         basicTitle: "Date Range can't be greater than 1 month"
        //     });
        //     return;
        // }    
        this.setState({ to_date: formattedDate });
    }    
    submitShipmentType =(event) => {
        event.preventDefault();
        this.setState({
            overly:"show-m",
            loadshow:"show-m"
        })
        var fromdate = this.state.from_date
		var todate = this.state.to_date
        var shipType = this.state.shipType
        let consigner = this.state.consigner ? this.state.consigner.map(e => e.value) : [];
        let flTransporter = this.state.flTransporter ? this.state.flTransporter.value : '';

        if(shipType !=undefined && shipType !="")
        {
            if(this.state.shipType.value !="all")
            {
                shipType = this.state.shipType.value;
            }
            else
            {
                shipType = {value:"all", label:"All"};
            }
        }
        else
        {
            shipType = {value:"all", label:"All"};
        }
        //   console.log("fromdate", fromdate)
        //    console.log("todate",todate)
		if (fromdate != "" && fromdate != undefined && todate != "" && todate != undefined) {
			this.setState({
				from_date: fromdate,
				to_date: todate,
                // shipType: shipType,
				//loadshow: 'show-m'
			});
			//    console.log("formdate",fromdate)
			    // console.log("todate",todate)
			if (fromdate != '') {
				 var fdt = fromdate.split(" ");
				var sfdate = fromdate.split("-");
				 var fdate = sfdate[0] + "-" + sfdate[1] + "-" + sfdate[2];
                var fdate =moment(fromdate,"YYYY-MM-DD").format("YYYY-MM-DD");
                //   console.log("fdate ", fdate)
			}
			else {
				fdate = ""
			}
			if (todate != '') {
				var tdt = todate.split(" ");
				var stdate = todate.split("-");
				 var tdate = stdate[2]+"-"+stdate[1]+"-"+stdate[0];
				 var tdate = stdate[0] + "-" + stdate[1] + "-" + stdate[2];
                var tdate =moment(todate,"YYYY-MM-DD").format("YYYY-MM-DD");
                //   console.log("tdate", tdate)
			}
			else {
				tdate = ""
			}
            let shipTypeValue = shipType && typeof shipType === "object" && shipType !== null ? shipType.value : shipType;
            // If shipType is "all" or "All", set shipTypeValue to an empty string
            if (["all", "All"].includes(shipTypeValue)) {
                shipTypeValue = "";
            }
            // Define shipType mappings
            const shipTypeMappings = {
                "PTL": ["47", "48"],
                "FTL": ["01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25", "29", "30", "31", "32", "45", "46"],
                "ODC": ["00", "06"],
                "HD": "HD" // Empty array for "HD" as per the original logic
            };
            // Update shipTypeValue based on shipType mappings if it matches a valid type
            if (shipTypeMappings[shipTypeValue]) {
                shipTypeValue = shipTypeMappings[shipTypeValue];
            } else {
                shipTypeValue = shipTypeValue || "";
            }
			var reqparams = {
				 from_date: fdate,
				to_date: tdate,
                shipType: shipTypeValue,
                // overly:"shown-n",
                // loadshow:"show-m"
			}
            if (consigner && consigner.every(e => !["", "all", "undefined"].includes(e.toLowerCase().trim()))) {
                reqparams.consigner = consigner;
            }
            if(flTransporter && !["",' ',"all","All","undefined"].includes(consigner)){
                reqparams.flTransporter = flTransporter
            }
            // console.log("fdate",fdate)
            //   console.log("reqparams ", reqparams)
             if(localStorage.getItem("user_type") === "TRANSPORTER")
             {
                if(localStorage.getItem('supplier_code') !== "" && localStorage.getItem('supplier_code') !== undefined  && localStorage.getItem("supplier_code") !== "undefined")
                {
                    reqparams.transporter_code = JSON.parse(localStorage.getItem('supplier_code'));
                }
             }if(localStorage.getItem("user_type") === "CUSTOMER")
             {
                if(localStorage.getItem('supplier_code') !== "" && localStorage.getItem('supplier_code') !== undefined  && localStorage.getItem("supplier_code") !== "undefined")
                {
                    reqparams.consignee_code = JSON.parse(localStorage.getItem('supplier_code'));
                }
             }
            redirectURL.post("/billing/getEpodScreenData", reqparams,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                }
            }).then(res => {
                let respData = res.data.records || []
                // console.log("res.data.records",res.data.records)
                //    console.log("respData",respData)
                if(respData.length > 0)
                {
                    var check = 1;
                    // console.log("check",check)
                    for(var r=0; r<=respData.length; r++)
                    {
                        try{
                            //  console.log("respData[r].is_submitted_pod ", respData[r].is_submitted_pod)
                            if(respData[r].is_submitted_pod == 1)
                            {
                                check = 1;
                            }
                            else
                            {
                                // console.log("respData[r] ", respData[r])
                                check = 0;
                                break;
                            }
                        }
                        catch(e){
                            // check = 0;
                            break;                            
                        }
                    }
                    // console.log(check,"check---1")
                    if(check === 0)
                    {
                        this.setState({
                            show: true,
                            overly:"show-n",
                            loadshow:"show-n",
                            basicType: "warning",
                            basicTitle: "Uploads for EPOD are pending during this period.",
                            from_date: fromdate,
                            to_date: todate,
                        
                        });
                    
                    } else {
                        
                         this.loadTrips(reqparams);
                         this.showTabView(2); 
                    //         // window.location.reload();
                    }
                        
                }
                //  {
                    // this.setState({
                    //     rowData: respData
                    // })
                    
                // }
                else{
                    let params = {
                         "from_date": moment(fdate,"YYYY-MM-DD ").format("YYYY-MM-DD"),
                         "to_date": moment(tdate,"YYYY-MM-DD ").format("YYYY-MM-DD"),
                        "consigner_code": [
                            {
                                "value": "all",
                                "label": "All"
                            }
                        ],
                        "pod_status": 1,
                        "serviceProviders": [],
                        "is_filter_call": 0
                    }
                    if (consigner && consigner.every(e => !["", "all", "undefined"].includes(e.toLowerCase().trim()))) {
                        params.consigner = consigner;
                    }
                    if(flTransporter && !["",' ',"all","All","undefined"].includes(consigner)){
                        params.flTransporter = flTransporter
                    }
                    params.shipType = shipTypeValue || ""
                    //  console.log("params",params)
                       this.loadTrips(params);
                    // this.setState({
                    //     show: true,
                    //     overly:"show-n",
                    //     loadshow:"show-n"
                    
                    // });
                
                    //  var startDate= this.state.defStartDate;
                    //  this.getLiabilityData(startDate);
                  
                } 
            })
                // var urlpath;
                // if (this.props.match.path == "/trips") {
                //     urlpath = '/consignments/submitdatefilters';
                // }
		}
		// else {
		// 	this.setState({
		// 		show: true,
        //         loadshow:"show-n",
        //         overly:"show-n",
		// 		basicType: "warning",
		// 		basicTitle: "Please GO Back EPOD screen and upload all files",
		// 		// from_date: fromdate,
		// 		// to_date: todate,
        //     //    rowData: respData
		// 	});
		// }
    }
    onClickViewRemarkItems = async (cell) =>{
           
        if (cell.colDef.field == 'remarks') {
            await this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: 'Status',
                                field: "remark_status",
                                width: 150,
                                cellClass:['cellstylegridY'],
                                headerClass:["cellstylegridY"],	
                            },
                            {
                                headerName: 'Description',
                                field: "remarks_desc",
                                width: 200,
                                cellClass:['cellstylegridY'],
                                headerClass:["cellstylegridY"],	
                            },
                        ],
                        defaultColDef: {
                            // flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable: true,
                            initialWidth: 200,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                            sortable: true
                        },
                        context: this,
                        overlayNoRowsTemplate: 'No rows to show'
    
                    },
                    getDetailRowData: async function (param) {
                        var rowData = [];
                        if(param.data.customer_send_back_remarks !== "" && param.data.customer_send_back_remarks !== undefined)
                        {
                            rowData.push({
                                remark_status: "Send Back",
                                remarks_desc:param.data.customer_send_back_remarks
                            });
                        }
                        
                        if(param.data.customer_reject_remarks !== "" && param.data.customer_reject_remarks !== undefined)
                        {
                            rowData.push({
                                remark_status: "Customer Rejected",
                                remarks_desc:param.data.customer_reject_remarks
                            });
                        }
                        if(param.data.customer_approve_remarks !== "" && param.data.customer_approve_remarks !== undefined)
                        {
                            rowData.push({
                                remark_status: "Customer Approved",
                                remarks_desc:param.data.customer_approve_remarks
                            });
                        
                        }
                        
                        if(param.data.customer_finapprove_remarks !== "" && param.data.customer_finapprove_remarks !== undefined)
                        {
                           rowData.push({
                                remark_status: "Finance Approved",
                                remarks_desc:param.data.customer_approve_remarks
                            });
                        }
                        if(param.data.customer_finreject_remarks !== "" && param.data.customer_finreject_remarks !== undefined)
                        {
                           rowData.push({
                                remark_status: "Finance Rejected",
                                remarks_desc:param.data.customer_finreject_remarks
                            });
                        }
                        if(param.data.transporter_finapprove_remarks !== "" && param.data.transporter_finapprove_remarks !== undefined)
                            {
                               rowData.push({
                                    remark_status: "Transporter approved",
                                    remarks_desc:param.data.transporter_finapprove_remarks
                                });
                            }
                        param.successCallback(rowData);
                        
                    },
                    masterDetail: true
                }
            });
            // console.log(propsdata);
            try{
                if (cell.colDef.field == 'remarks') {
                    cell.node.setExpanded(!cell.node.expanded);
                }
                else {
                    cell.node.setExpanded(false);
                }
            }
            catch(e){}
            
        }
        
    }
    onClickViewBatchItems = async (rownode) => {
       
        //  console.log("rownode Here", rownode);
        var childDetailCellRendererParams = this.state.childDetailCellRendererParams;
        const hideLegsFor = localStorage.getItem("user_type") === "TRANSPORTER" || localStorage.getItem("user_type") === "CUSTOMER" || this.state.pendingwithcust !== "btn-danger";
        await this.setState({
            detailRowHeight: 240,
            detailRowAutoHeight: true,
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        {
                            headerName: 'Transporter Updates',
                            headerClass: ["cellstylegridY", "cellTopHeader"],
                            children: [
                                {
                                    headerName: "Invoice No",
                                    field: "invoice_no",
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                    width: 180,
                                },
                                {
                                    headerName: "Invoice Date",
                                    field: "invoice_date",
                                    width: 180,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.invoice_date !== undefined && params.data.invoice_date !== "") {
                                                return getHyphenDDMMMYYYY(params.data.invoice_date);
                                            }
                                            else {
                                                return "";
                                            }
                                        } catch (error) {
                                        }
                                    }
                                },
                                {
                                    headerName: "Total Payable Amount",
                                    field: "invoice_amount",
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                    width: 140
                                },
                                {
                                    headerName: Constants.TRIP_PLANT_CODE,
                                    field: "consigner_code",
                                    width: 120,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                    // checkboxSelection:true
                                },
                                {
                                    headerName: Constants.TRIP_PLANT_NAME,
                                    field: "consigner_name",
                                    width: 100,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                },
                                {
                                    headerName: Constants.TRIP_CONSIGN_NO,
                                    field: "consignment_code",
                                    width: 160,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                },
                                {
                                    headerName: Constants.TRIP_TRANSPORTER_CODE,
                                    field: "transporter_code",
                                    width: 180,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                },
                                {
                                    headerName: Constants.TRIP_TRANSPORTER_NAME,
                                    field: "service_provider",
                                    width: 180,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                },
                                {
                                    headerName: Constants.GC_WAY_BILL_NO,
                                    field: "gc_waybill_no",
                                    width: 120,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    // checkboxSelection: checkSelect
                                },
                                {
                                    headerName: Constants.GC_DATE,
                                    field: "gc_date",
                                    width: 120,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.gc_date != undefined && params.data.gc_date != "") {
                                                return getHyphenDDMMMYYYYHHMM(params.data.gc_date);
                                            }
                                        }
                                        catch (e) { }
                                    }
                                    // checkboxSelection: checkSelect
                                },
                                {
                                    headerName: 'Delivery Date',
                                    field: "delivery_datetime",
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    width: 140,
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.delivery_datetime != undefined && params.data.delivery_datetime != "") {
                                                return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime);
                                            }
                                        }
                                        catch (e) { }
                                    }
                                },
                                {
                                    headerName: "View Pod",
                                    field: "viewpod",
                                    width: 120,
                                    // pinned: "right",
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ['cellstylegridY'],
                                    // cellRenderer:() => `<button class="btn btn-info" style="padding:0px 5px;font-size:12px"> View POD</button>`, 
                                    cellRendererFramework: ViewPODChild

                                },
                                {
                                    headerName: "Allowable Weight(Kgs)",
                                    field: "allowable_weight",
                                    width: 200,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                },
                                {
                                    headerName: "Slab Rate Per Kg (Rs)",
                                    field: "per_kg_slab_rate",
                                    width: 200,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                },
                                {
                                    headerName: 'Freight Cost',
                                    field: "basic_trans_cost",
                                    width: 200,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            const { basic_trans_cost = 0 } = params.data
                                            return basic_trans_cost.toFixed(2)
                                            // if (params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined && params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined) {
                                            //     var a = parseFloat(params.data.allowable_weight) * parseFloat(params.data.per_kg_slab_rate);
                                            //     return a;
                                            // }
                                            // else {
                                            //     return 0;
                                            // }
                                        } catch (error) {

                                        }
                                    }
                                },
                                {
                                    headerName: "Destination Type",
                                    field: "destination_type",
                                    width: 160,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: (params) => {
                                        const { destination_type = "DIRECT",invoice_no } = params.data
                                        if(invoice_no=="Total"){
                                            return ""
                                        }
                                        return destination_type
                                    }
                                },
                                {
                                    headerName: 'Other Charges',
                                    field: "other_charges",
                                    width: 160,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            const { other_charges = 0 } = params.data
                                            return parseFloat(parseFloat(other_charges).toFixed(2))
                                        } catch (error) {

                                        }
                                    }
                                },
                                {
                                    headerName: 'Oda Charges',
                                    field: "oda_charges",
                                    width: 100,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.oda_charges !== "" && params.data.oda_charges !== undefined) {
                                                // return parseFloat(params.data.sub_total).toFixed(2);
                                                return params.data.oda_charges
                                            }
                                            else {
                                                return 0;
                                            }
                                        } catch (error) {

                                        }
                                    }
                                },
                                {
                                    headerName: 'Unloading Charges',
                                    field: "unloading_charges",
                                    width: 100,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.unloading_charges !== "" && params.data.unloading_charges !== undefined) {
                                                // return parseFloat(params.data.sub_total).toFixed(2);
                                                return params.data.unloading_charges
                                            }
                                            else {
                                                return 0;
                                            }
                                        } catch (error) {

                                        }
                                    }
                                },
                                {
                                    headerName: 'Incentive Charges',
                                    field: "incentive_charges",
                                    width: 100,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.incentive_charges !== "" && params.data.incentive_charges !== undefined) {
                                                // return parseFloat(params.data.sub_total).toFixed(2);
                                                return params.data.incentive_charges
                                            }
                                            else {
                                                return 0;
                                            }
                                        } catch (error) {

                                        }
                                    }
                                },
                                {
                                    headerName: 'Crane Charges',
                                    field: "crane_charges",
                                    width: 100,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.crane_charges !== "" && params.data.crane_charges !== undefined) {
                                                // return parseFloat(params.data.sub_total).toFixed(2);
                                                return params.data.crane_charges
                                            }
                                            else {
                                                return 0;
                                            }
                                        } catch (error) {

                                        }
                                    }
                                },
                                {
                                    headerName: 'Odc Charges',
                                    field: "odc_charges",
                                    width: 100,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.odc_charges !== "" && params.data.odc_charges !== undefined) {
                                                // return parseFloat(params.data.sub_total).toFixed(2);
                                                return params.data.odc_charges
                                            }
                                            else {
                                                return 0;
                                            }
                                        } catch (error) {

                                        }
                                    }
                                },
                                {
                                    headerName: 'Detention Charges',
                                    field: "detention_charges",
                                    width: 100,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.detention_charges !== "" && params.data.detention_charges !== undefined) {
                                                // return parseFloat(params.data.sub_total).toFixed(2);
                                                return params.data.detention_charges
                                            }
                                            else {
                                                return 0;
                                            }
                                        } catch (error) {

                                        }
                                    }
                                },
                                // {
                                //     headerName: 'OSP',
                                //     field: "osp",
                                //     width: 100,
                                //     // editable:fEdit,
                                //     cellClass: ['cellstylegridY'],
                                //     headerClass: ["cellstylegridY"],
                                // },
                                // {
                                //     headerName: 'L/U Charges',
                                //     field: "lu_charges",
                                //     width: 100,
                                //     // editable:fEdit,
                                //     cellClass: ['cellstylegridY'],
                                //     headerClass: ["cellstylegridY"],
                                // },
                                // {
                                //     headerName: 'Special Approval',
                                //     field: "special_approval",
                                //     width: 100,
                                //     // editable:fEdit,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],	
                                // },
                                // {
                                //     headerName: 'TO-PAY Charges',
                                //     field: "to_pay_charges",
                                //     width: 100,
                                //     // editable:fEdit,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],	
                                // },

                                // // {
                                // //     headerName: '',
                                // //     field: "other_charges_details",
                                // //     width: 160,
                                // //     cellRenderer: () => `<button class="btn btn-success" style="padding:0px 5px;font-size:12px"> View Charges</button>`,
                                // //     cellClass:['cellstylegridY'],
                                // //     headerClass:["cellstylegridY"],	
                                // // },
                                // {
                                //     headerName: 'Sub Total',
                                //     field: "sub_total",
                                //     width: 100,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],	
                                // },
                                // // {
                                // //     headerName: 'GST %',
                                // //     field: "gst",
                                // //     width: 100,
                                // //     editable:true,
                                // //     cellClass:['cellstylegridY'],
                                // //     headerClass:["cellstylegridY"],	
                                // // },
                                // // {
                                // //     headerName: 'GST Amount',
                                // //     field: "gst_amount",
                                // //     width: 100,
                                // //     editable:true,
                                // //     cellClass:['cellstylegridY'],
                                // //     headerClass:["cellstylegridY"],	
                                // // },
                                // {
                                //     headerName: 'Damage Amount',
                                //     field: "damage_amount",
                                //     width: 100,
                                //     editable: true,
                                //     cellClass: ['cellstylegridY'],
                                //     headerClass: ["cellstylegridY"],
                                // },                               
                                {
                                    headerName: 'Agreed Transit Time (Days)',
                                    field: "TAT",
                                    width: 140,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                },                                
                                {
                                    headerName: 'Planned Transit Time (Days)',
                                    field: "total_tat",
                                    width: 140,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (parmas) {
                                        try {
                                            const {invoice_no} = parmas.data 
                                            if(invoice_no && invoice_no=="Total"){
                                                return ""
                                            }
                                            if (parmas.data.total_tat !== "" && parmas.data.total_tat !== undefined && parmas.data.total_tat !== null) {
                                                // console.log("parmas.data.total_tat",parmas.data.total_tat)

                                                return parmas.data.total_tat

                                            }

                                            else {
                                                return 0;
                                            }
                                        } catch (error) {

                                        }
                                    }
                                },
                                {
                                    headerName: "Actual Transit Time (Days)",
                                    width: 140,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    field: "total_tat",
                                    autoSize: true,
                                    valueGetter:params=>{
                                        try{
                                            let gcDate 
                                            let delDate 
                                            if (params.data.gc_date != undefined && params.data.gc_date != "") {
                                                gcDate = moment.parseZone(new Date(params.data.gc_date)).format("DD-MM-YYYY")
                                            }
                                            if (params.data.delivery_datetime != undefined && params.data.delivery_datetime != "") {
                                                delDate= new Date(params.data.delivery_datetime)
                                            }
                                            var data =  calculateDateDiff(gcDate,delDate)
                                            
                                            return data || ""
                                        }catch(e){
                                            console.log("Error at Actual Transit Time (Days)" ,e)
                                        }
                                    }
                                },
                                // {
                                //     headerName: "Delay in Delivery (Days)",
                                //     field: "",
                                //     width: 130,                
                                //     headerClass: ["cellstylegridY"],
                                //     cellClass: function (params) {
                                //         const { transit_delay} = params.data;                    
                                //         if (transit_delay) { 
                                //             if (transit_delay >= 1) {
                                //                 return 'bgColorDangerLight';  // Apply red color for delay
                                //             }            
                                //             return 'bgColorSuccessMedium';  // Apply green color if on time
                                //         }                    
                                //         return 'bgColorSuccessMedium';  // Return empty if either revised_eta or estimated_datetime is invalid
                                //     },
                                //     valueGetter: function (params) {
                                //         const { transit_delay} = params.data;            
                                //         if (transit_delay) {                                         
                                //             return transit_delay >= 1 ? transit_delay : 0; // Display delay in days if delay >= 1, otherwise return empty
                                //         }
                                //         return 0;  // Return empty if either of the dates are invalid
                                //     }
                                // }, 
                                {
                                    headerName: "Delivery Status",
                                    field: "ontime_delayed_status",
                                    width: 130,
                                    filter: "agSetColumnFilter",
                                    cellRenderer: '',
                                    headerClass: ["cellstylegridY"],
                                    cellClass: function (params) {
                                        var transitDelay = params.data.transit_delay
                                        if (transitDelay) {
                                            // If the difference is greater than or equal to 1, it's delayed
                                            if (transitDelay >= 1) {
                                                return 'bgColorDangerLight'; // Apply red color for delay
                                            } else {
                                                return 'bgColorSuccessMedium'; // Apply success style if on time
                                            }
                                        }
                                        return "bgColorSuccessMedium";  // Return empty if any of the dates are invalid
                                    },
                                    valueGetter: function (params) {
                                        var transitDelay = params.data.transit_delay
                                        var invoice_no = params.data.invoice_no
                                        if(invoice_no=="Total"){
                                            return "";
                                        }
                                        if (transitDelay) {
                                            // Return "Delayed" if the delay is greater than or equal to 1 day, otherwise "On Time"
                                            return transitDelay >= 1 ? "Delayed" : "On Time";
                                        }
                                        return "On Time";  // Return empty if any of the dates are invalid
                                    }
                                },
                                // {
                                //     headerName: 'Delay in Delivery (Days)',
                                //     field: "delay_days",
                                //     width: 100,
                                //     cellClass: ['cellstylegridY'],
                                //     headerClass: ["cellstylegridY"],
                                // },
                                {
                                    headerName: "Delay in Delivery (Days)",
                                    field: "",
                                    width: 130,
                                    headerClass: ["cellstylegridY"],
                                    cellClass: function (params) {
                                        const { transit_delay } = params.data;
                                        if (transit_delay) {
                                            if (transit_delay >= 1) {
                                                return 'bgColorDangerLight';  // Apply red color for delay
                                            }
                                            return 'bgColorSuccessMedium';  // Apply green color if on time
                                        }
                                        return 'bgColorSuccessMedium';  // Return empty if either revised_eta or estimated_datetime is invalid
                                    },
                                    valueGetter: function (params) {
                                        const { transit_delay , invoice_no} = params.data;
                                        if(invoice_no =="Total"){
                                            return ""
                                        }
                                        if (transit_delay) {
                                            return transit_delay >= 1 ? transit_delay : 0; // Display delay in days if delay >= 1, otherwise return empty
                                        }
                                        return 0;  // Return empty if either of the dates are invalid
                                    }
                                },
                                {
                                    headerName: 'Total Freight',
                                    field: "total_freight",
                                    width: 140,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            const {
                                                total_freight = 0
                                            } = params.data;
                                            return total_freight
                                        } catch (error) {
                                            console.error("Error calculating total amount with reduction:", error);
                                            return 0; // Return 0 in case of an error
                                        }
                                    }
                                },
                                {
                                    headerName: 'Damage Amount',
                                    field: "damage_amount",
                                    width: 100,
                                    editable: true,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                },
                                {
                                    headerName: 'Transshipment Charges',
                                    field: "transhipment_charges",
                                    width: 140,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            if (params.data.transhipment_charges !== "" && params.data.transhipment_charges !== undefined) {
                                                // return parseFloat(params.data.sub_total).toFixed(2);
                                                return params.data.transhipment_charges
                                            }
                                            else {
                                                return 0;
                                            }
                                        } catch (error) {

                                        }
                                    }
                                },
                                {
                                    headerName: 'Penalty Charges',
                                    field: "penalty_charges",
                                    width: 100,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            const { penalty_charges = 0 } = params.data;
                                            return penalty_charges.toFixed(2)
                                        } catch (error) {
                                            console.error("Error in valueGetter:", error); // Log the error for debugging purposes
                                            return "0.00"; // Return 0 if an error occurs
                                        }
                                    }


                                },
                                {
                                    headerName: 'Fully Deducted',
                                    field: "reduce_amount",
                                    width: 140,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            const { reduce_amount = 0 } = params.data
                                            return reduce_amount
                                        } catch (error) {
                                            console.error("Error calculating total amount with reduction:", error);  // Log error
                                            return "0.00";  // Return 0 as string in case of an error
                                        }
                                    }
                                },
                                {
                                    headerName: 'Total Amount',
                                    field: "total_amount",
                                    width: 100,
                                    // editable: true,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            const { total_amount = 0 } = params.data
                                            return total_amount || 0
                                        } catch (error) {
                                            console.log("Error at Total Amount", error)
                                            return 0
                                        }
                                    }
                                },
                                {
                                    headerName: 'Transporter Amount',
                                    field: "transporter_amount",
                                    width: 140,
                                    // editable: true,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            const { transporter_amount = 0 } = params.data
                                            return transporter_amount ? parseFloat(transporter_amount.toFixed(2)) || 0 : 0
                                            // if (params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined && params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined) {
                                            //     var a = parseFloat(params.data.allowable_weight) * parseFloat(params.data.per_kg_slab_rate);
                                            //     return a.toFixed(2);
                                            // }
                                            // else {
                                            //     return 0;
                                            // }
                                        } catch (error) {
                                            console.error("Error calculating total amount:", error);
                                            return 0; // Return 0 if an error occurs
                                        }
                                    }
                                },
                                {
                                    headerName: 'Difference Amount',
                                    field: "difference_amount",
                                    width: 140,
                                    // editable: true,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: function (params) {
                                        try {
                                            const { difference_amount = 0 } = params.data
                                            return difference_amount
                                        } catch (error) {
                                            console.error("Error calculating total amount:", error);
                                            return 0; // Return 0 if an error occurs
                                        }
                                    }
                                },
                                // {
                                //     headerName: 'LD',
                                //     field: "ld_charges",
                                //     width: 100,
                                //     cellClass: ['cellstylegridY'],
                                //     headerClass: ["cellstylegridY"],
                                // },
                                // {
                                //     headerName: 'Freight Payable',
                                //     field: "freight_payable",
                                //     width: 100,
                                //     cellClass: ['cellstylegridY'],
                                //     headerClass: ["cellstylegridY"],
                                // },
                                // {
                                //     headerName: 'Chargable Weight',
                                //     field: "chargable_weight",
                                //     width: 200,
                                //     // editable:true,
                                //     cellClass:['green'],
                                //     headerClass:["green"],	
                                // },
                                // {
                                //     headerName: 'Charged Weight Diff',
                                //     field: "charged_weight_diff",
                                //     width: 120,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],	
                                //    /* valueGetter:function(params){
                                //         try {
                                //             if(params.data.chargable_weight !== "" && params.data.chargable_weight !== undefined && params.data.chargable_weight !== null)
                                //             {
                                //                 var p = parseFloat(params.data.chargable_weight);
                                //                 var aw = parseFloat(params.data.allowable_weight);
                                //                 return p-aw;
                                //             }
                                //             else{
                                //                 return 0;
                                //             }
                                //         } catch (error) {

                                //         }
                                //     }*/
                                // },
                                // {
                                //     headerName: 'Freight Diff',
                                //     field: "freight_diff",
                                //     width: 100,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],	
                                //    /* valueGetter:function(params){
                                //         try {
                                //             if(params.data.chargable_weight !== "" && params.data.chargable_weight !== undefined && params.data.chargable_weight !== null)
                                //             {
                                //                 console.log("parseFloat(params.data.per_kg_slab_rate) ", parseFloat(params.data.per_kg_slab_rate))
                                //                 var p = parseFloat(params.data.chargable_weight);
                                //                 var aw = parseFloat(params.data.allowable_weight);
                                //                 return (p-aw)*parseFloat(params.data.per_kg_slab_rate);
                                //             }
                                //             else{
                                //                 return 0;
                                //             }
                                //         } catch (error) {

                                //         }
                                //     }*/
                                // },
                                // {
                                //     headerName: 'ODA Time (Days)',
                                //     field: "ODA_TAT",
                                //     width: 100,
                                //     // editable:true,
                                //     cellClass: ['cellstylegridY'],
                                //     headerClass: ["cellstylegridY"],
                                // },
                                // {
                                //     headerName: 'ODA Diff',
                                //     field: "oda_diff",
                                //     width: 100,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],	
                                // },
                                // {
                                //     headerName: 'Rate Diff',
                                //     field: "rate_diff",
                                //     width: 100,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],	
                                // },
                                // {
                                //     headerName: "",
                                //     field: "_id",
                                //     width: 150,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],
                                //     cellRendererFramework: ViewDamageChild
                                // },
                                {
                                    headerName: "View Damages",
                                    field: "damagetype",
                                    width: 180,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],

                                },
                                {
                                    headerName: 'Transporter Remarks',
                                    field: "transporter_remarks",
                                    width: 200,
                                    editable: true,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                },
                                {
                                    headerName: 'Penalty Remarks',
                                    field: "penalty_remarks",
                                    width: 140,
                                    editable: true,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    valueGetter: (params) => {
                                        let { transit_delay } = params.data
                                        if (transit_delay && transit_delay >= 5) {
                                            return "Delay in Delivery"
                                        }
                                    }
                                },
                                {
                                    headerName: "",
                                    field: "",
                                    width: 180,
                                    cellClass: ['cellstylegridY'],
                                    headerClass: ["cellstylegridY"],
                                    hide: hideLegsFor,
                                    suppressColumnToolPanel: hideLegsFor,
                                    cellRendererFramework: UpdateWareHouseChargesBtn
                                },
                                // {
                                //     headerName: 'Customer Remarks',
                                //     field: "customer_remarks",
                                //     width: 200,
                                //     editable:true,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],
                                // },
                                // {
                                //     headerName: '',
                                //     field: "save_draft",
                                //     width: 160,
                                //     cellRenderer: () => `<button class="btn btn-warning" style="padding:0px 5px;font-size:12px"> Save as Draft</button>`,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],	
                                // },

                                // {
                                //     headerName: 'Remarks',
                                //     field: "remarks",
                                //     width: 100,
                                //     cellClass:['cellstylegridY'],
                                //     headerClass:["cellstylegridY"],
                                //    // cellRenderer: () => `<button class="btn btn-danger" style="padding:0px 5px;font-size:12px">View Remarks</button>`	
                                //    cellRendererFramework:ViewRemarksAction
                                // },
                            ]
                        },
                        {
                            headerName: 'Consignment Details',
                            headerClass: ["cellstylegridGBg", "cellTopHeader"],
                            children: [
                                {
                                    headerName: Constants.TRIP_TRUCK_NO,
                                    field: "truck_no",
                                    width: 120,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                    // checkboxSelection: checkSelect
                                },
                                // {
                                //     headerName: Constants.SHIPMENT_TYPE,
                                //     field: "shipping_type",
                                //     width: 120,
                                //     cellClass:['green'],
                                //     headerClass:["cellstylegridGBg"],
                                //     // checkboxSelection: checkSelect
                                // },
                                // {
                                //     headerName: Constants.TRIP_TRANSPORTER_CODE,
                                //     field: "transporter_code",
                                //     width: 180,
                                //     cellClass:['green'],
                                //     headerClass:["cellstylegridGBg"],
                                // },
                                // {
                                //     headerName: Constants.TRIP_CUSTOMER_CODE,
                                //     field: "consignee_code",
                                //     width: 100,
                                //     cellClass:['green'],
                                //     headerClass:["cellstylegridGBg"],
                                // },
                                {
                                    headerName: Constants.TRIP_CUSTOMER_NAME,
                                    field: "consignee_name",
                                    width: 180,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                },
                                {
                                    headerName: Constants.TRIP_CUSTOMER_CITY,
                                    field: "consignee_city",
                                    width: 160,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                },
                                {
                                    headerName: Constants.TRIP_CUSTOMER_STATE,
                                    field: "consignee_state",
                                    width: 150,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                },
                                {
                                    headerName: Constants.TRIP_ZONE,
                                    field: "consignee_zone",
                                    width: 140,
                                    cellClass: ['green'],
                                    hide: true,
                                    headerClass: ["cellstylegridGBg"],
                                },
                                {
                                    headerName: Constants.TRIP_PIN_CODE,
                                    field: "consignee_pincode",
                                    width: 180,
                                    cellClass: ['green'],
                                    headerClass: ["cellstylegridGBg"],
                                },

                                // {
                                //     headerName: Constants.CREDIT_SALES,
                                //     field: "credit_sales",
                                //     width: 120,
                                //     cellClass:['green'],
                                //     headerClass:["cellstylegridGBg"],
                                //     // checkboxSelection: checkSelect
                                // },
                                // {
                                //     headerName: Constants.TRIP_CUSTOMER_CITY,
                                //     field: "consignee_city",
                                //     width: 160,
                                //     cellClass:['green'],
                                //     headerClass:["cellstylegridGBg"],
                                // },
                                // {
                                //     headerName: Constants.TRIP_CUSTOMER_STATE,
                                //     field: "consignee_state",
                                //     width: 150,
                                //     cellClass:['green'],
                                //     headerClass:["cellstylegridGBg"],
                                // },
                                // {
                                //     headerName: Constants.TRIP_ZONE,
                                //     field: "consignee_zone",
                                //     width: 140,
                                //     cellClass:['green'],
                                //     headerClass:["cellstylegridGBg"],
                                // },
                                // {
                                //     headerName: Constants.TRIP_PIN_CODE,
                                //     field: "consignee_pincode",
                                //     width: 180,
                                //     cellClass:['green'],
                                //     headerClass:["cellstylegridGBg"],
                                // },
                                // {
                                //     headerName: "ODA Time (Days)",
                                //     field: "ODA_TAT",
                                //     width: 180,
                                //     cellClass: ['green'],
                                //     headerClass: ["cellstylegridGBg"],
                                // },

                                // {
                                //     headerName: "Allowable Weight(Kgs)",
                                //     field: "allowable_weight",
                                //     width: 100,
                                //     cellClass: ['green'],
                                //     headerClass: ["cellstylegridGBg"],
                                // },
                                // {
                                //     headerName: "Slab Rate Per Kg (Rs)",
                                //     field: "per_kg_slab_rate",
                                //     width: 100,
                                //     cellClass: ['green'],
                                //     headerClass: ["cellstylegridGBg"],
                                // },
                                // {
                                //     headerName: Constants.TRIP_FREIGHT_COST,
                                //     field: "freight_rate",
                                //     width: 100,
                                //     cellClass: ['green'],
                                //     headerClass: ["cellstylegridGBg"],
                                //     valueGetter: function (params) {
                                //         try {
                                //             var a = parseFloat(params.data.allowable_weight) * parseFloat(params.data.per_kg_slab_rate);
                                //             return a.toFixed(2);
                                //         } catch (error) {

                                //         }
                                //     }
                                // },
                                // {
                                //     headerName: Constants.TRIP_TOTAL_COST,
                                //     field: "total_cost",
                                //     width: 100,
                                //     cellClass:['green'],
                                //     headerClass:["cellstylegridGBg"],
                                // }
                            ]
                        }
                    ],
                    masterDetail: true,
                    detailRowHeight: 240,
                    detailRowAutoHeight: true,
                    // detailCellRendererParams: {
                    //     detailGridOptions: {
                    //         // console.log("level2",params)

                    //         columnDefs: [
                    //             {
                    //                 headerName: 'Status',
                    //                 field: "remark_status",
                    //                 width: 150,
                    //                 cellClass:['cellstylegridY'],
                    //                 headerClass:["cellstylegridY"],	
                    //             },
                    //             {
                    //                 headerName: 'Description',
                    //                 field: "remarks_desc",
                    //                 width: 300,
                    //                 cellClass:['cellstylegridY'],
                    //                 headerClass:["cellstylegridY"],	
                    //             },
                    //         ]

                    //     },

                    //     getDetailRowData: (param) => {
                    //          console.log("He")
                    //         var rowData = [];
                    //         if(param.data.customer_send_back_remarks !== "" && param.data.customer_send_back_remarks !== undefined)
                    //         {
                    //             rowData.push({
                    //                 remark_status: "Send Back",
                    //                 remarks_desc:param.data.customer_send_back_remarks
                    //             });
                    //         }

                    //         if(param.data.customer_reject_remarks !== "" && param.data.customer_reject_remarks !== undefined)
                    //         {
                    //             rowData.push({
                    //                 remark_status: "Customer Rejected",
                    //                 remarks_desc:param.data.customer_reject_remarks
                    //             });
                    //         }
                    //         if(param.data.customer_approve_remarks !== "" && param.data.customer_approve_remarks !== undefined)
                    //         {
                    //             rowData.push({
                    //                 remark_status: "Customer Approved",
                    //                 remarks_desc:param.data.customer_approve_remarks
                    //             });

                    //         }

                    //         if(param.data.customer_finapprove_remarks !== "" && param.data.customer_finapprove_remarks !== undefined)
                    //         {
                    //            rowData.push({
                    //                 remark_status: "Finance Approved",
                    //                 remarks_desc:param.data.customer_approve_remarks
                    //             });
                    //         }
                    //         if(param.data.customer_finreject_remarks !== "" && param.data.customer_finreject_remarks !== undefined)
                    //         {
                    //            rowData.push({
                    //                 remark_status: "Finance Rejected",
                    //                 remarks_desc:param.data.customer_finreject_remarks
                    //             });
                    //         }
                    //         if(param.data.transporter_finapprove_remarks !== "" && param.data.transporter_finapprove_remarks !== undefined)
                    //         {
                    //            rowData.push({
                    //                 remark_status: "Transporter Approved",
                    //                 remarks_desc:param.data.transporter_finapprove_remarks
                    //             });
                    //         }
                    //         param.successCallback(rowData);
                    //     },
                    //  },
                    defaultColDef: {
                        // flex: 1,
                        minWidth: 100,
                        filter: true,
                        resizable: true,
                        initialWidth: 200,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        sortable: true
                    },
                    getRowClass: (params) => {
                        // Check if the row's invoice_no is "Total" and apply the style
                        console.log(params.data ,'params.data ')
                        if (params.data && params.data.invoice_no === "Total") {
                            return {
                                'background-color': '#f2f2f2',  // Light gray background
                                "color": '#000',                  // Black text color
                                'font-weight': 'bold'           // Make text bold
                            };  // Return the styles directly in an object
                        }
                        return ''; // Default case for other rows
                    },
                    getRowStyle: (params) => {
                        if (params.data && params.data.invoice_no === "Total") {
                            return {
                                backgroundColor: '#f2f2f2',  // Light gray background for the total row
                                color: '#000',                // Black text color
                                fontWeight: 'bold'            // Make text bold
                            };
                        }
                        return {};  // Default: No special styling for other rows
                    },                     
                    onGridReady: (params) => {
                        // Check if rowNode and rowNode.data exist
                        const rowData = rownode && rownode.data ? rownode.data.itemlist : [];
                        console.log(rowData, 'rowData')
                        // Assuming the totals object already has a property for each charge type
                        const totals = {
                            invoice_no: "Total",
                            other_charges: 0,
                            crane_charges: 0,
                            basic_trans_cost: 0,
                            oda_charges: 0,
                            unloading_charges: 0,
                            incentive_charges: 0,
                            odc_charges: 0,
                            detention_charges: 0,
                            total_freight: 0,
                            damage_amount: 0,
                            transhipment_charges: 0,
                            penalty_charges: 0,
                            reduce_amount: 0,
                            total_amount: 0,
                            transporter_amount: 0,
                            difference_amount: 0,
                        };

                        // Loop through each row's item and calculate the total for each charge
                        rowData.forEach(function (item) {
                            totals.other_charges += parseFloat(item.other_charges || 0);
                            totals.crane_charges += parseFloat(item.crane_charges || 0);
                            totals.basic_trans_cost += parseFloat(item.basic_trans_cost || 0);
                            totals.oda_charges += parseFloat(item.oda_charges || 0);
                            totals.unloading_charges += parseFloat(item.unloading_charges || 0);
                            totals.incentive_charges += parseFloat(item.incentive_charges || 0);
                            totals.odc_charges += parseFloat(item.odc_charges || 0);
                            totals.detention_charges += parseFloat(item.detention_charges || 0);
                            totals.total_freight += parseFloat(item.total_freight || 0);
                            totals.damage_amount += parseFloat(item.damage_amount || 0);
                            totals.transhipment_charges += parseFloat(item.transhipment_charges || 0);
                            totals.penalty_charges += parseFloat(item.penalty_charges || 0);
                            totals.reduce_amount += parseFloat(item.reduce_amount || 0);
                            totals.total_amount += parseFloat(item.total_amount || 0);
                            totals.transporter_amount += parseFloat(item.transporter_amount || 0);
                            totals.difference_amount += parseFloat(item.difference_amount || 0);
                        });

                        console.log("Calculated Totals:", totals);

                        // Set pinned row
                        if (params.api) {
                            params.api.setPinnedTopRowData([totals]);
                            console.log("Pinned row data set for child grid:", totals);
                        } else {
                            console.warn("Child grid API is not ready.");
                        }
                    },                                        
                },
                getDetailRowData: (param) => {
                    console.log("Data passed to child grid:", param.data);            
                    // Check if param.data and itemlist exist
                    if (param.data && Array.isArray(param.data.itemlist)) {
                        param.successCallback(param.data.itemlist); // Pass data to child grid
                    } else {
                        console.warn("No itemlist found for this row.");
                        param.successCallback([]); // Pass empty array to avoid errors
                    }
                },
                context: this,
                overlayNoRowsTemplate: 'No rows to show',
                onCellClicked: this.onCellClickedFunc,
                onCellDoubleClicked: this.onCellDoubleClickedFunc,
                // The master detail configuration           
            },            
        });
        //  console.log(propsdata);
        try {
            if (rownode.column.colDef.field == 'viewitems') {
                rownode.node.setExpanded(!rownode.node.expanded);
            }
            else {
                rownode.node.setExpanded(false);
            }
        }
        catch (e) { }
    }
    onClickConfirmBtn = async (rownode) => {
        try {
            // console.log(rownode, 'consignment_code');
            const reqParams = {
                batch_no : rownode.data.batch_no,
                invoice_no: rownode.data.invoice_no,
                pendingwith_payment_confirmed_by: localStorage.getItem("email") || "",
            };    
            if (!reqParams.batch_no || !reqParams.invoice_no) {
                this.setState({
                    show: true,
                    basicTitle: " Batch No or Invoice Number is missing",
                    basicType: "danger",
                    overly: "show-n",
                    loadshow: "show-n",
                });
                return;
            }    
            // Show loading
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
            });    
            const response = await redirectURL.post("/billing/submitPendingWithWareHouse", reqParams, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                }
            });    
            const { status, message } = response.data;    
            if (status === "success") {
                this.setState({
                    show: true,
                    basicTitle: message || "Successfully Updated",
                    basicType: "success",
                    overly: "show-n",
                    loadshow: "show-n",
                });    
                // Reload trips with updated params
                const params = {
                    from_date: this.state.from_date,
                    to_date: this.state.to_date,
                    consigner_code: [{ value: "all", label: "All" }],
                    pod_status: 1,
                    serviceProviders: [],
                    is_filter_call: 0,
                };
                await this.loadTrips(params);
            } else {
                // Failure case
                this.setState({
                    show: true,
                    basicTitle: message || "Batch No or Invoice Number not found",
                    basicType: "danger",
                    overly: "show-n",
                    loadshow: "show-n",
                });
            }
        } catch (error) {
            console.error("Error during confirmation:", error);
            // Error state handling
            this.setState({
                show: true,
                basicTitle: "An error occurred. Please try again.",
                basicType: "danger",
                overly: "show-n",
                loadshow: "show-n",
            });
        }
    };
    onSubmitWareHouseCharges = async (rownode) => {
        try {
            // console.log(rownode, 'consignment_code');
            const reqParams = {
                batch_no : rownode.data.batch_no,
                invoice_no: rownode.data.invoice_no,
                pendingwith_payment_confirmed_by: localStorage.getItem("email") || "",
            };    
            if (!reqParams.batch_no || !reqParams.invoice_no) {
                this.setState({
                    show: true,
                    basicTitle: " Batch No or Invoice Number is missing",
                    basicType: "danger",
                    overly: "show-n",
                    loadshow: "show-n",
                });
                return;
            }    
            // Show loading
            this.setState({
                loadshow: "show-m",
                overly: "show-m",
            });    
            const response = await redirectURL.post("/billing/submitPendingWithWareHouse", reqParams, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("userid")
                }
            });    
            const { status, message } = response.data;    
            if (status === "success") {
                this.setState({
                    show: true,
                    basicTitle: message || "Successfully Updated",
                    basicType: "success",
                    overly: "show-n",
                    loadshow: "show-n",
                });    
                // Reload trips with updated params
                const params = {
                    from_date: this.state.from_date,
                    to_date: this.state.to_date,
                    consigner_code: [{ value: "all", label: "All" }],
                    pod_status: 1,
                    serviceProviders: [],
                    is_filter_call: 0,
                };
                await this.loadTrips(params);
            } else {
                // Failure case
                this.setState({
                    show: true,
                    basicTitle: message || "Batch No or Invoice Number not found",
                    basicType: "danger",
                    overly: "show-n",
                    loadshow: "show-n",
                });
            }
        } catch (error) {
            console.error("Error during confirmation:", error);
            // Error state handling
            this.setState({
                show: true,
                basicTitle: "An error occurred. Please try again.",
                basicType: "danger",
                overly: "show-n",
                loadshow: "show-n",
            });
        }
    };       
    onClickViewRemarks = async (rownode) => {
        // console.log("rownode ", rownode.data)
        var rowData = [];
        if(rownode.data.customer_send_back_remarks !== "" && rownode.data.customer_send_back_remarks !== undefined)
        {
            rowData.push({
                remark_status: "Send Back",
                remarks_desc:rownode.data.customer_send_back_remarks
            });
        }
        
        if(rownode.data.customer_reject_remarks !== "" && rownode.data.customer_reject_remarks !== undefined)
        {
            rowData.push({
                remark_status: "Customer Rejected",
                remarks_desc:rownode.data.customer_reject_remarks
            });
        }
        if(rownode.data.customer_approve_remarks !== "" && rownode.data.customer_approve_remarks !== undefined)
        {
            rowData.push({
                remark_status: "Customer Approved",
                remarks_desc:rownode.data.customer_approve_remarks
            });
        
        }
        
        if(rownode.data.customer_finapprove_remarks !== "" && rownode.data.customer_finapprove_remarks !== undefined)
        {
            rowData.push({
                remark_status: "Finance Approved",
                remarks_desc:rownode.data.customer_approve_remarks
            });
        }
        if(rownode.data.customer_finreject_remarks !== "" && rownode.data.customer_finreject_remarks !== undefined)
        {
            rowData.push({
                remark_status: "Finance Rejected",
                remarks_desc:rownode.data.customer_finreject_remarks
            });
        }
        if(rownode.data.transporter_finapprove_remarks !== "" && rownode.data.transporter_finapprove_remarks !== undefined)
        {
            rowData.push({
                remark_status: "Transporter Approved",
                remarks_desc:rownode.data.transporter_finapprove_remarks
            });
        }
        this.setState({
            sliderViewRemarks:"slider-translate-40p",
            overly:"show-m",
            loadshow:"show-n",
            remarksRowNode : rowData
        })
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickUploadPOD = (rownode) => {
        // console.log("rownode",rownode)
        this.setState({
            slidershow: "slider-translate-40p", // slide30
            overly: "show-m",
            rownode: [rownode.data]
        });
    }
    resetUpload = () => {
        $("#invDoc").val("")
        this.setState({
            invoice_no:"",
            invoice_date:"",
            invoice_freight_amount:"",
            invoice_gst_amount:"",
            invoice_document:"",
            overlay:"show-n",
            sliderViewRemarks:""
        })
    }
    hideSlideBlock = () => {
        $("#invDoc").val("")
        this.setState({
            shipment_slider: '',
            slider: "",
            slidershow: "show-n",
            bulkslidershow: "show-n",
            sliderSupportshow: 'show-n',
            custsliderSendBackRemarks:"",
            sliderApproveRemarks:"",
            sliderRejectRemarks:"",
            sliderFinApproveRemarks:"",
            sliderTransFinApproveRemarks:"",
            sliderFinRejectRemarks:"",
            sliderViewRemarks:"",
            sliderInvoiceshow:"",
            damageitemlist:"",
            showUpdateCharges:"",
            // overly: "show-n",
            selected_row_nodes: [],
            loadshow: 'show-n',
            overly: 'show-n',
            custsendBackRemarks:"",
            custsliderSendBackRemarks:"",
            custApproveRemarks:"",
            sliderApproveRemarks:"",
            custRejectRemarks:"",
            invoice_date:"",
            invoice_no:'',
            invoice_gst_amount:'',
            invoice_freight_amount:"",
            invDoc:'',
            invoice_document:"",
            // sliderRejectRemarks:"",
            finApproveRemarks:"",
            transFinApproveRemarks:"",
            sliderFinApproveRemarks:"",
            custFinRejectRemarks:"",
            sliderFinRejectRemarks:"",
            showWhUpdateCharges:"",
            chargesItemsList:"",
        })
       
        document.getElementById("invForm").reset();
        document.getElementById("custAprForm").reset();
        document.getElementById("custRejForm").reset();
        document.getElementById("finAprForm").reset();
        document.getElementById("finAprForm").reset();
    }
    onCloseSliderDiv = () => {
        $("#invDoc").val("")
        this.setState({
            shipment_slider: '',
            slider: "",
            slidershow: "show-n",
            bulkslidershow: "show-n",
            sliderSupportshow: 'show-n',
            custsliderSendBackRemarks:"",
            sliderApproveRemarks:"",
            sliderRejectRemarks:"",
            sliderFinApproveRemarks:"",
            sliderViewRemarks:"",
            sliderTransFinApproveRemarks:"",
            sliderFinRejectRemarks:"",
            sliderInvoiceshow:"",
            overly: "show-n",
            selected_row_nodes: [],
            loadshow: 'show-n',
            // overly: 'show-n',
            multiplePodDocs : [],
            custsendBackRemarks:"",
            custsliderSendBackRemarks:"",
            custApproveRemarks:"",
            sliderApproveRemarks:"",
            custRejectRemarks:"",
            // sliderRejectRemarks:"",
            finApproveRemarks:"",
            invoice_no:"",
            invoice_date:"",
            invoice_freight_amount:"",
            invoice_gst_amount:"",
            invDoc:"",
            invoice_document:"",
            transFinApproveRemarks:"",
            sliderFinApproveRemarks:"",
            custFinRejectRemarks:"",
            sliderFinRejectRemarks:""
        });
    };
    changeFileHandler = (event) => {
        // console.log("event.target.files[0] ", event.target.files[0])
        if(this.state.podDoc !== "" && this.state.podDoc !== undefined){
            this.setState({
                podDoc: event.target.files[0]
            })
        }
        else{
            this.setState({
                podDoc: '',
                file: "",
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }      
    }
    changeConsignerArrayHandler = (consigner) => {
        const { consigner: currentConsigner } = this.state;    
        if (currentConsigner && consigner) {
            if (consigner.length > 6) {
                this.setState({
                    show: "true",
                    basicTitle: "Selection should not be greater than 6",
                    basicType: "danger",
                    errorMessage: '',
                    successMessage: ''
                });
            } else {
                this.setState({
                    consigner,
                    errorMessage: '',
                    successMessage: ''
                });
            }
        } else {
            this.setState({
                consigner: [],
                errorMessage: '',
                successMessage: ''
            });
        }
    };    
    selectConsignerOptionsItems() {
        const { plants = []} = this.state;  
        const items = [
            { value: 'all', label: 'All' },
            ...plants.map(item => ({
                value: item.plant_code,
                label: `${item.plant_code}-${item.plant_name}`
            }))
        ];    
        return items;
    }    
    changeInvFileHandler = (event) => {
        // console.log("event.target.files[0] ", event.target.files[0])
        // if(this.state.invDoc !== "" && this.state.invDoc !== undefined){
            this.setState({
                invDoc: event.target.files[0]
            })
        // }
        // else{
        //     this.setState({
        //         invDoc: '',
        //         file: "",
        //         show: true,
        //         basicType: 'danger',
        //         basicTitle: 'Please upload file having extensions .csv only.',
        //     });
        // } 
       
    }
    changeBulkFileHandler = e => {
        const bulkFiles = Array.prototype.slice.call(e.target.files)
        try{
            var multiplePodNames = bulkFiles.map(e => {
                let [name,date] = e.name.split("_")
                date = date.split(".")[0]
                // console.log("name", name, "\n date",date)
                return {
                    'gc_waybill_no' : name,
                    'delivery_date' : date
                }            
            })
        }
        catch(e){
            this.setState({
                show: true,
                basicTitle: "Please give file name as mentioned.",
                basicType: "warning"
            })
            // console.log("error",e)
            multiplePodNames = []
        }
        // console.log("multiplePodNames",multiplePodNames)
        if (bulkFiles.length > 0) {
            this.setState({
                multiplePodDocs: bulkFiles,
                multipleFiles : e.target.files,
                multiplePodNames
            })
        }
        // console.log("bulkUploadFiles", bulkFiles)
    }
    changeHandler = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        })
    }
    createOtherManualShipment = () => {
        this.setState({
            shipment_slider: "slider-translate-40p", // slide30
            overly: "show-m",
        })
    }
    setCounterData = (counter) => {
        const { oRowData, liabilityCnt } = this.state
        // console.log("oRowData",oRowData)
        if (counter === 'total') {
            // this.setState({
            //     rowData: oRowData
            // })
        }
        // else if (counter === 'liability') {
        //     this.setState({
        //         rowData: liabilityCnt
        //     })
        // }
        else if (counter === 'pendingwithlsp') {
                
        }
        else if (counter === 'invstatus'){
        }
       
        else if (counter === 'pendingwithcustomer') {
        }
        else if (counter === 'submittedtofin') {
        }
        else if (counter === 'pendfinapp'){
            
        }
        else if (counter === 'paymentclose') {
        }
    }
    uploadShipMentData = (e) => {
        e.preventDefault()
        const { consigner_code, truck_no, gc_waybill_no, consignment_code, gc_date, shipment_completion_date,
            shipping_type, transporter_name, transporter_code, consignee_code, consignee_name, consignee_city,
            consignee_state, consignee_region, consignee_pincode, allowable_weight, per_kg_slab_rate, basic_trans_cost } = this.state
        let shipment = {
            consigner_code, truck_no, gc_waybill_no, consignment_code, gc_date, shipment_completion_date,
            shipping_type, transporter_name, transporter_code, consignee_code, consignee_name, consignee_city,
            consignee_state, consignee_region, consignee_pincode, allowable_weight, per_kg_slab_rate, basic_trans_cost
        }
        redirectURL.post("/consignments/createManualShipment", shipment,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          }).then(res => {
            // console.log("res_manual", res)
        })
        // console.log("consignment", shipment)
    }
    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderTranslate: "",
            sliderSupportshow:"",
            sliderInvoiceshow:"",
            sliderApproveRemarks:"",
            sliderRejectRemarks:"",
            sliderFinApproveRemarks:"",
            sliderTransFinApproveRemarks:"",
            sliderFinRejectRemarks:"",
            sliderViewRemarks:"",
            damageitemlist:"",
            showUpdateCharges:"",
            unloading_charges: 0,
            crane_charges: 0,
            odc_charges:0,
            showWhUpdateCharges:"",
            transhipment_charges:0,
            penalty_charges:0,
            showWhUpdateCharges:'',
            wo_remarks:"",
            files: {
                unloading_charges: null,
                crane_charges: null,
                odc_charges: null,
                detention_charges:null,
            },
            isFileRequired: {
                unloading_charges: false,
                crane_charges: false,
                odc_charges: false,
                detention_charges:false,
            },
            selectedChargeType: "", // Keeps track of the selected charge type
            chargeValues: {
                crane_charges: 0,
                odc_charges: 0,
                other_charges: 0,
                detention_charges:0,
            },
            detention_charges:0,
            chargesItemsList:"",
        });
        document.getElementById("invForm").reset();
        document.getElementById("uplodTpt").reset();
        document.getElementById("sendBackForm").reset();
        document.getElementById("custAprForm").reset();
        document.getElementById("custRejForm").reset();
        document.getElementById("finAprForm").reset();
        document.getElementById("finAprForm").reset();
    }
    onClickViewPOD = (e) => {
        // console.log("documnent_view", e)
       
        let params = {
            pod_document : e.data.pod_document,
        }
        // console.log("params",params)
        redirectURL.post("/billing/downloadPodDocument", params,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem("userid")
              }
          }).then(res =>{
            // console.log( res.data.url,' res.data.url')
            // console.log(res.data,"data")
              const downloadUrl = decodeURIComponent(res.data.url);
              const link = document.createElement("a");
              link.href = downloadUrl;
              link.setAttribute("download", e.data.pod_document || ""); // Optional: specify download filename
              link.setAttribute("target", "_blank");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          // //console.log("clicked");
            // console.log("response", res)
            // download(res.data, e.data.pod_document)
            
        }).catch(function(error){
            // console.log(error);
        })
    }
    onCellClickedFunc = async(cell) => {
        
        if(cell.data.transporter_code !== "" && cell.data.transporter_code !== undefined)
        {
            await this.getTranstporterCharges(cell.data.transporter_code);
        }
        // console.log("cell.colDef.field ", cell.data)
        // if(cell.colDef.field == "viewpod")
        // {
        //     let params = {
        //         pod_document : cell.data.pod_document,
        //     }
        //     // console.log("params",params)
        //     redirectURL.post("/billing/downloadPodDocumentUrl", params,{
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': localStorage.getItem("userid")
        //           }
        //       }).then(res =>{
        //         // console.log("response", res)
        //         download(res.data, cell.data.pod_document)
                
        //     }).catch(function(error){
        //         // console.log(error);
        //     })
        // }
        await setTimeout(()=>{
            var ospexists = 0;
            var luexists = 0;
            var scexists = 0;
            var tcexists = 0;
            if(cell.data.transporter_code !== "" && cell.data.transporter_code !== undefined)
            {
                // console.log("this.state.lspcharges ", this.state.lspcharges)
                var specialCharges = this.state.lspcharges;
                if(specialCharges.length > 0)
                {
                    if(specialCharges[0].osp_charge === "Yes")
                    {
                        ospexists = 1;
                    }else{
                        ospexists = 0;
                    }
                    
                    if(specialCharges[0].lu_charge === "Yes")
                    {
                        luexists = 1;
                    }else{
                        luexists = 0;
                    }
                    
                    if(specialCharges[0].special_charge === "Yes")
                    {
                        scexists = 1;
                    }else{
                        scexists = 0;
                    }
                    
                    if(specialCharges[0].topay_charge === "Yes")
                    {
                        tcexists = 1;
                    }else{
                        tcexists = 0;
                    }
                    
                }
                
            }
            else{
                var specialCharges  = [];
            }
            // console.log("cellClicked", cell)
            if (cell.colDef.field === "osp_doc") {
                if(localStorage.getItem("user_type") === "TRANSPORTER")
                {
                    this.setState({
                        supportField: 'osp_doc',
                        supportDocTitle: 'OSP Doc Upload',
                        sliderSupportshow: "slider-translate-40p", // slide30
                        overly: "show-m",
                        rownode: [cell.data]
                    })
        
                }
                else{
                }
                
            } else if (cell.colDef.field === 'lu_charges_doc') {
                    this.setState({
                        supportField: 'lu_charges_doc',
                        supportDocTitle: 'L/U Doc Upload',
                        sliderSupportshow: "slider-translate-40p", // slide30
                        overly: "show-m",
                        rownode: [cell.data]
                    })
            }
            else if (cell.colDef.field === 'special_approval_doc') {
                
                    this.setState({
                        supportField: 'special_approval_doc',
                        supportDocTitle: 'Special Approval Doc Upload',
                        sliderSupportshow: "slider-translate-40p", // slide30
                        overly: "show-m",
                        rownode: [cell.data]
                    })
            }
            else if (cell.colDef.field === 'to_pay_charges_doc') {
                this.setState({
                        supportField: 'to_pay_charges_doc',
                        supportDocTitle: 'To Pay Charges Doc Upload',
                        sliderSupportshow: "slider-translate-40p", // slide30
                        overly: "show-m",
                        rownode: [cell.data]
                    })
            }
            
            else if (cell.colDef.field === 'save_draft') {
                var rownode = [cell.data];
                //  console.log("rownode ", rownode)
                var param ={
                    rownode:rownode
                }
                redirectURL.post("/billing/saveRowDataDraftTpt",param,{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem("userid")
                    }
                })
                .then((response) => {
                })
            }
            // else if (cell.colDef.field === 'other_charges_details') {
            //     var rownode = [cell.data];
            //     this.setState({
            //         detailCellRendererParams: {
            //             detailGridOptions: {
            //                 columnDefs: [
            //                     {
            //                         headerName: 'Charge Type',
            //                         field: "document_name",
            //                         width: 200,
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
            //                     {
            //                         headerName: 'Charges',
            //                         field: "charges",
            //                         width: 200,
            //                         editable:true,
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
        
            //                     {
            //                         headerName: '',
            //                         field: "save_charges",
            //                         width: 260,
            //                         cellRenderer: (prams) => {
            //                             if(prams.data.document_field_name !== "manual_charges")
            //                             {
            //                                 return `<button class="btn btn-danger" style="padding:0px 5px;font-size:12px"> Save Data</button>`;
            //                             }
            //                             else{
            //                                 return "";
            //                             }
            //                         },
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
                                
            //                     {
            //                         headerName: 'UPLOAD DOC',
            //                         field: "view_charge_doc",
            //                         width: 260,
            //                         cellRenderer: (params) => {
            //                             try {
            //                                 if(params.data.document_field_name !== "manual_charges")
            //                                 {
            //                                     return  `<button class="btn btn-success" style="padding:0px 5px;font-size:12px"> Upload Document</button>`;
            //                                 }
            //                                 else{
            //                                     return  "";
            //                                 }
            //                             } catch (error) {
                                            
            //                             }
                                    
            //                         },
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
            //                     {
            //                         headerName: 'View DOC',
            //                         field: "view_uploaded_doc",
            //                         width: 160,
            //                         cellRenderer: (params) => {
            //                             try {
            //                                 if(params.data.document_field_name !== "manual_charges")
            //                                 {
            //                                     if(params.data.view_uploaded_doc !== "" && params.data.view_uploaded_doc !== undefined)
            //                                     {
            //                                         return `<a href="`+params.data.view_uploaded_doc+`" target="_blank" class="btn btn-warning" style="padding:0px 5px;font-size:12px"> View Doc</a>`;
            //                                     }
            //                                     else{
            //                                         return "";
            //                                     }
            //                                 }
            //                                 else{
            //                                     return "";
            //                                 }
            //                             } catch (error) {
                                            
            //                             }
            //                         },
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
            //                     {
            //                         headerName: '',
            //                         field: "viewmanualorders",
            //                         width: 260,
            //                         // hide: (params) => {
            //                         //     try {
            //                         //         console.log("params.data.document_field_name ", params.data.document_field_name)
            //                         //         if(params.data.document_field_name !== "manual_charges")
            //                         //         {
            //                         //             return false;
            //                         //         }
            //                         //         else{
            //                         //             return  false;
            //                         //         }
            //                         //     } catch (error) {
                                            
            //                         //     }
                                    
            //                         // },
            //                         cellRenderer: (params) => {
            //                             try {
            //                                 if(params.data.document_field_name !== "manual_charges")
            //                                 {
            //                                     return "";
            //                                 }
            //                                 else{
            //                                     return  `<button class="btn btn-warning" style="padding:0px 5px;font-size:12px"> View Charges</button>`;
            //                                 }
            //                             } catch (error) {
                                            
            //                             }
                                    
            //                         },
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
            //                 ],
            //                 masterDetail: true,
            //                 detailRowHeight: 240,
            //                 detailRowAutoHeight: true,
            //                 detailCellRendererParams: {
            //                     detailGridOptions: {
            //                         columnDefs: [
            //                             {
            //                                 headerName: 'GC Way Bill No',
            //                                 field: "gc_waybill_no",
            //                                 width: 200,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                             },
            //                             {
            //                                 headerName: 'GC Date',
            //                                 field: "gc_date",
            //                                 width: 200,
            //                                 editable:true,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                                 valueGetter:function(prams){
            //                                     try {
            //                                         if(prams.data.gc_date !== "" && prams.data.gc_date !== undefined)
            //                                         {
            //                                             return getHyphenDDMMMYYYY(prams.data.gc_date);
            //                                         }
            //                                         else{
            //                                             return "";
            //                                         }
            //                                     } catch (error) {
                                                    
            //                                     }
            //                                 }
            //                             },
            //                             {
            //                                 headerName: "Allowable Weight(Kgs)",
            //                                 field: "allowable_weight",
            //                                 width: 200,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                             },
            //                             {
            //                                 headerName: "Slab Rate Per Kg (Rs)",
            //                                 field: "per_kg_slab_rate",
            //                                 width: 200,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                             },
            //                             {
            //                                 headerName: 'Freight Cost',
            //                                 field: "basic_trans_cost",
            //                                 width: 200,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                             },
            //                         ]
                
            //                     },
                            
            //                     getDetailRowData: (param) => {
            //                         // console.log("param11 ", param)
            //                         var rowData = [];
            //                         var orms  = {
            //                             gc_waybill_no:param.data.gc_waybill_no
            //                         }
            //                         redirectURL.post("/billing/gcmanualorders",orms,{
            //                             headers: {
            //                                 'Content-Type': 'application/json',
            //                                 'Authorization': localStorage.getItem("userid")
            //                             }
            //                         })
            //                         .then(async (response) => {
            //                             var records = response.data.records;
            //                             param.successCallback(records);
            //                         })
            //                     },
            //                 },
            //                 defaultColDef: {
            //                     // flex: 1,
            //                     minWidth: 100,
            //                     filter: true,
            //                     resizable: true,
            //                     initialWidth: 200,
            //                     wrapHeaderText: true,
            //                     autoHeaderHeight: true,
            //                     sortable: true
            //                 },
            //                 context: this,
            //                 overlayNoRowsTemplate: 'No rows to show',
            //                 onCellClicked:this.onCellClickedFunc
        
            //             },
            //             getDetailRowData: async function (param) {
            //                 // console.log("param ", param)
            //                 var rowitems = [];
            //                 if(param.data.osp_doc !== "" && param.data.osp_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"OSP",
            //                         document_field_name:"osp_doc",
            //                         charges:(param.data.osp !== "" && param.data.osp !== null && param.data.osp !== undefined)?param.data.osp:0,
            //                         view_charge_doc:param.data.osp_doc,
            //                         view_uploaded_doc:param.data.osp_doc,
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"OSP",
            //                         document_field_name:"osp_doc",
            //                         charges:(param.data.osp !== "" && param.data.osp !== null && param.data.osp !== undefined)?param.data.osp:0,
            //                         view_charge_doc:param.data.osp_doc,
            //                         view_uploaded_doc:"",
            //                     });
            //                 }
            //                 if(param.data.lu_charges_doc !== "" && param.data.lu_charges_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"LU Charges",
            //                         document_field_name:"lu_charges_doc",
            //                         charges:param.data.lu_charges,
            //                         view_charge_doc:param.data.lu_charges_doc,
            //                         view_uploaded_doc:param.data.lu_charges_doc,
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"LU Charges",
            //                         document_field_name:"lu_charges_doc",
            //                         charges:(param.data.lu_charges !== "" && param.data.lu_charges !== null && param.data.lu_charges !== undefined)?param.data.lu_charges:0,
            //                         view_charge_doc:param.data.lu_charges_doc,
            //                         view_uploaded_doc:"",
            //                     });
            //                 }
                            
            //                 if(param.data.to_pay_charges_doc !== "" && param.data.to_pay_charges_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"To Pay Charges",
            //                         document_field_name:"to_pay_charges_doc",
            //                         charges:param.data.to_pay_charges,
            //                         view_charge_doc:param.data.to_pay_charges_doc,
            //                         view_uploaded_doc:param.data.to_pay_charges_doc,
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"To Pay Charges",
            //                         document_field_name:"to_pay_charges_doc",
            //                         charges:(param.data.to_pay_charges !== "" && param.data.to_pay_charges !== null && param.data.to_pay_charges !== undefined)?param.data.to_pay_charges:0,
            //                         view_charge_doc:param.data.to_pay_charges_doc,
            //                         view_uploaded_doc:""
            //                     });
            //                 }
            //                 if(param.data.special_approval_doc !== "" && param.data.special_approval_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"Special Approval",
            //                         document_field_name:"special_approval_doc",
            //                         charges:param.data.special_approval,
            //                         view_charge_doc:param.data.special_approval_doc,
            //                         view_uploaded_doc:param.data.special_approval_doc,
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"Special Approval",
            //                         document_field_name:"special_approval_doc",
            //                         charges:(param.data.special_approval !== "" && param.data.special_approval !== null && param.data.special_approval !== undefined)?param.data.special_approval:0,
            //                         view_charge_doc:param.data.special_approval_doc,
            //                         view_uploaded_doc:""
            //                     });
            //                 }
                            
            //                 if(param.data.special_approval_doc !== "" && param.data.special_approval_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"Special Approval",
            //                         document_field_name:"manual_charges",
            //                         charges:param.data.manual_charges,
            //                         view_charge_doc:"",
            //                         view_uploaded_doc:"",
            //                         gc_waybill_no:param.data.gc_waybill_no
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"Special Approval",
            //                         document_field_name:"manual_charges",
            //                         charges:(param.data.manual_charges !== "" && param.data.manual_charges !== null && param.data.manual_charges !== undefined)?param.data.manual_charges:0,
            //                         view_charge_doc:"",
            //                         view_uploaded_doc:"",
            //                         gc_waybill_no:param.data.gc_waybill_no
            //                     });
            //                 }
            //                 // console.log("rowitems ", rowitems)
            //                 param.successCallback(rowitems);
            //             },
            //             masterDetail: true,
            //         }
            //     });
            //     // console.log(propsdata);
            //     try{
            //         if (cell.colDef.field == 'other_charges_details') {
            //             cell.node.setExpanded(!cell.node.expanded);
            //         }
            //         else {
            //             cell.node.setExpanded(false);
            //         }
            //     }
            //     catch(e){}
            // }
            // else if (cell.colDef.field === 'other_charges_details') {
            //     var rownode = [cell.data];
            //     this.setState({
            //         detailCellRendererParams: {
            //             detailGridOptions: {
            //                 columnDefs: [
            //                     {
            //                         headerName: 'Charge Type',
            //                         field: "document_name",
            //                         width: 200,
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
            //                     {
            //                         headerName: 'Charges',
            //                         field: "charges",
            //                         width: 200,
            //                         editable:true,
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
        
            //                     {
            //                         headerName: '',
            //                         field: "save_charges",
            //                         width: 260,
            //                         cellRenderer: (prams) => {
            //                             if(prams.data.document_field_name !== "manual_charges")
            //                             {
            //                                 return `<button class="btn btn-danger" style="padding:0px 5px;font-size:12px"> Save Data</button>`;
            //                             }
            //                             else{
            //                                 return "";
            //                             }
            //                         },
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
                                
            //                     {
            //                         headerName: 'UPLOAD DOC',
            //                         field: "view_charge_doc",
            //                         width: 260,
            //                         cellRenderer: (params) => {
            //                             try {
            //                                 if(params.data.document_field_name !== "manual_charges")
            //                                 {
            //                                     return  `<button class="btn btn-success" style="padding:0px 5px;font-size:12px"> Upload Document</button>`;
            //                                 }
            //                                 else{
            //                                     return  "";
            //                                 }
            //                             } catch (error) {
                                            
            //                             }
                                    
            //                         },
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
            //                     {
            //                         headerName: 'View DOC',
            //                         field: "view_uploaded_doc",
            //                         width: 160,
            //                         cellRenderer: (params) => {
            //                             try {
            //                                 if(params.data.document_field_name !== "manual_charges")
            //                                 {
            //                                     if(params.data.view_uploaded_doc !== "" && params.data.view_uploaded_doc !== undefined)
            //                                     {
            //                                         return `<a href="`+params.data.view_uploaded_doc+`" target="_blank" class="btn btn-warning" style="padding:0px 5px;font-size:12px"> View Doc</a>`;
            //                                     }
            //                                     else{
            //                                         return "";
            //                                     }
            //                                 }
            //                                 else{
            //                                     return "";
            //                                 }
            //                             } catch (error) {
                                            
            //                             }
            //                         },
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
            //                     {
            //                         headerName: '',
            //                         field: "viewmanualorders",
            //                         width: 260,
            //                         // hide: (params) => {
            //                         //     try {
            //                         //         console.log("params.data.document_field_name ", params.data.document_field_name)
            //                         //         if(params.data.document_field_name !== "manual_charges")
            //                         //         {
            //                         //             return false;
            //                         //         }
            //                         //         else{
            //                         //             return  false;
            //                         //         }
            //                         //     } catch (error) {
                                            
            //                         //     }
                                    
            //                         // },
            //                         cellRenderer: (params) => {
            //                             try {
            //                                 if(params.data.document_field_name !== "manual_charges")
            //                                 {
            //                                     return "";
            //                                 }
            //                                 else{
            //                                     return  `<button class="btn btn-warning" style="padding:0px 5px;font-size:12px"> View Charges</button>`;
            //                                 }
            //                             } catch (error) {
                                            
            //                             }
                                    
            //                         },
            //                         cellClass:['cellstylegridY'],
            //                         headerClass:["cellstylegridY"],	
            //                     },
            //                 ],
            //                 masterDetail: true,
            //                 detailRowHeight: 240,
            //                 detailRowAutoHeight: true,
            //                 detailCellRendererParams: {
            //                     detailGridOptions: {
            //                         columnDefs: [
            //                             {
            //                                 headerName: 'GC Way Bill No',
            //                                 field: "gc_waybill_no",
            //                                 width: 200,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                             },
            //                             {
            //                                 headerName: 'GC Date',
            //                                 field: "gc_date",
            //                                 width: 200,
            //                                 editable:true,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                                 valueGetter:function(prams){
            //                                     try {
            //                                         if(prams.data.gc_date !== "" && prams.data.gc_date !== undefined)
            //                                         {
            //                                             return getHyphenDDMMMYYYY(prams.data.gc_date);
            //                                         }
            //                                         else{
            //                                             return "";
            //                                         }
            //                                     } catch (error) {
                                                    
            //                                     }
            //                                 }
            //                             },
            //                             {
            //                                 headerName: "Allowable Weight(Kgs)",
            //                                 field: "allowable_weight",
            //                                 width: 200,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                             },
            //                             {
            //                                 headerName: "Slab Rate Per Kg (Rs)",
            //                                 field: "per_kg_slab_rate",
            //                                 width: 200,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                             },
            //                             {
            //                                 headerName: 'Freight Cost',
            //                                 field: "basic_trans_cost",
            //                                 width: 200,
            //                                 cellClass:['cellstylegridY'],
            //                                 headerClass:["cellstylegridY"],	
            //                             },
            //                         ]
                
            //                     },
                            
            //                     getDetailRowData: (param) => {
            //                         // console.log("param11 ", param)
            //                         var rowData = [];
            //                         var orms  = {
            //                             gc_waybill_no:param.data.gc_waybill_no
            //                         }
            //                         redirectURL.post("/billing/gcmanualorders",orms,{
            //                             headers: {
            //                                 'Content-Type': 'application/json',
            //                                 'Authorization': localStorage.getItem("userid")
            //                             }
            //                         })
            //                         .then(async (response) => {
            //                             var records = response.data.records;
            //                             param.successCallback(records);
            //                         })
            //                     },
            //                 },
            //                 defaultColDef: {
            //                     // flex: 1,
            //                     minWidth: 100,
            //                     filter: true,
            //                     resizable: true,
            //                     initialWidth: 200,
            //                     wrapHeaderText: true,
            //                     autoHeaderHeight: true,
            //                     sortable: true
            //                 },
            //                 context: this,
            //                 overlayNoRowsTemplate: 'No rows to show',
            //                 onCellClicked:this.onCellClickedFunc
        
            //             },
            //             getDetailRowData: async function (param) {
            //                 // console.log("param ", param)
            //                 var rowitems = [];
            //                 if(param.data.osp_doc !== "" && param.data.osp_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"OSP",
            //                         document_field_name:"osp_doc",
            //                         charges:(param.data.osp !== "" && param.data.osp !== null && param.data.osp !== undefined)?param.data.osp:0,
            //                         view_charge_doc:param.data.osp_doc,
            //                         view_uploaded_doc:param.data.osp_doc,
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"OSP",
            //                         document_field_name:"osp_doc",
            //                         charges:(param.data.osp !== "" && param.data.osp !== null && param.data.osp !== undefined)?param.data.osp:0,
            //                         view_charge_doc:param.data.osp_doc,
            //                         view_uploaded_doc:"",
            //                     });
            //                 }
            //                 if(param.data.lu_charges_doc !== "" && param.data.lu_charges_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"LU Charges",
            //                         document_field_name:"lu_charges_doc",
            //                         charges:param.data.lu_charges,
            //                         view_charge_doc:param.data.lu_charges_doc,
            //                         view_uploaded_doc:param.data.lu_charges_doc,
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"LU Charges",
            //                         document_field_name:"lu_charges_doc",
            //                         charges:(param.data.lu_charges !== "" && param.data.lu_charges !== null && param.data.lu_charges !== undefined)?param.data.lu_charges:0,
            //                         view_charge_doc:param.data.lu_charges_doc,
            //                         view_uploaded_doc:"",
            //                     });
            //                 }
                            
            //                 if(param.data.to_pay_charges_doc !== "" && param.data.to_pay_charges_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"To Pay Charges",
            //                         document_field_name:"to_pay_charges_doc",
            //                         charges:param.data.to_pay_charges,
            //                         view_charge_doc:param.data.to_pay_charges_doc,
            //                         view_uploaded_doc:param.data.to_pay_charges_doc,
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"To Pay Charges",
            //                         document_field_name:"to_pay_charges_doc",
            //                         charges:(param.data.to_pay_charges !== "" && param.data.to_pay_charges !== null && param.data.to_pay_charges !== undefined)?param.data.to_pay_charges:0,
            //                         view_charge_doc:param.data.to_pay_charges_doc,
            //                         view_uploaded_doc:""
            //                     });
            //                 }
            //                 if(param.data.special_approval_doc !== "" && param.data.special_approval_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"Special Approval",
            //                         document_field_name:"special_approval_doc",
            //                         charges:param.data.special_approval,
            //                         view_charge_doc:param.data.special_approval_doc,
            //                         view_uploaded_doc:param.data.special_approval_doc,
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"Special Approval",
            //                         document_field_name:"special_approval_doc",
            //                         charges:(param.data.special_approval !== "" && param.data.special_approval !== null && param.data.special_approval !== undefined)?param.data.special_approval:0,
            //                         view_charge_doc:param.data.special_approval_doc,
            //                         view_uploaded_doc:""
            //                     });
            //                 }
                            
            //                 if(param.data.special_approval_doc !== "" && param.data.special_approval_doc !== undefined)
            //                 {
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"Special Approval",
            //                         document_field_name:"manual_charges",
            //                         charges:param.data.manual_charges,
            //                         view_charge_doc:"",
            //                         view_uploaded_doc:"",
            //                         gc_waybill_no:param.data.gc_waybill_no
            //                     });
            //                 }
            //                 else{
            //                     rowitems.push({
            //                         rownode:param.data,
            //                         document_name:"Special Approval",
            //                         document_field_name:"manual_charges",
            //                         charges:(param.data.manual_charges !== "" && param.data.manual_charges !== null && param.data.manual_charges !== undefined)?param.data.manual_charges:0,
            //                         view_charge_doc:"",
            //                         view_uploaded_doc:"",
            //                         gc_waybill_no:param.data.gc_waybill_no
            //                     });
            //                 }
            //                 // console.log("rowitems ", rowitems)
            //                 param.successCallback(rowitems);
            //             },
            //             masterDetail: true,
            //         }
            //     });
            //     // console.log(propsdata);
            //     try{
            //         if (cell.colDef.field == 'other_charges_details') {
            //             cell.node.setExpanded(!cell.node.expanded);
            //         }
            //         else {
            //             cell.node.setExpanded(false);
            //         }
            //     }
            //     catch(e){}
            // }
           if (cell.colDef.field === 'other_charges_details') {
            // console.log("S2",cell.colDef.field);
                var rownode = [cell.data];
                this.setState({
                    masterDetail: true,
                    detailCellRendererParams: {
                        detailGridOptions: {
                            columnDefs: [
                                {
                                    headerName: 'Charge Type',
                                    field: "document_name",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
                                    headerName: 'Charges',
                                    field: "charges",
                                    width: 200,
                                    editable:true,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
        
                                {
                                    headerName: '',
                                    field: "save_charges",
                                    width: 260,
                                    cellRenderer: (prams) => {
                                        if(prams.data.document_field_name !== "manual_charges")
                                        {
                                            return `<button class="btn btn-danger" style="padding:0px 5px;font-size:12px"> Save Data</button>`;
                                        }
                                        else{
                                            return "";
                                        }
                                    },
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                
                                {
                                    headerName: 'UPLOAD DOC',
                                    field: "view_charge_doc",
                                    width: 260,
                                    cellRenderer: (params) => {
                                        try {
                                            if(params.data.document_field_name !== "manual_charges")
                                            {
                                                return  `<button class="btn btn-success" style="padding:0px 5px;font-size:12px"> Upload Document</button>`;
                                            }
                                            else{
                                                return  "";
                                            }
                                        } catch (error) {
                                            
                                        }
                                    
                                    },
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
                                    headerName: 'View DOC',
                                    field: "view_uploaded_doc",
                                    width: 160,
                                    cellRenderer: (params) => {
                                        try {
                                            if(params.data.document_field_name !== "manual_charges")
                                            {
                                                if(params.data.view_uploaded_doc !== "" && params.data.view_uploaded_doc !== undefined)
                                                {
                                                    return `<a href="`+params.data.view_uploaded_doc+`" target="_blank" class="btn btn-warning" style="padding:0px 5px;font-size:12px"> View Doc</a>`;
                                                }
                                                else{
                                                    return "";
                                                }
                                            }
                                            else{
                                                return "";
                                            }
                                        } catch (error) {
                                            
                                        }
                                    },
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
                                    headerName: '',
                                    field: "viewmanualorders",
                                    width: 260,
                                    // hide: (params) => {
                                    //     try {
                                    //         console.log("params.data.document_field_name ", params.data.document_field_name)
                                    //         if(params.data.document_field_name !== "manual_charges")
                                    //         {
                                    //             return false;
                                    //         }
                                    //         else{
                                    //             return  false;
                                    //         }
                                    //     } catch (error) {
                                            
                                    //     }
                                    
                                    // },
                                    cellRenderer: (params) => {
                                        try {
                                            if(params.data.document_field_name !== "manual_charges")
                                            {
                                                return "";
                                            }
                                            else{
                                                return  `<button class="btn btn-warning" style="padding:0px 5px;font-size:12px"> View Charges</button>`;
                                            }
                                        } catch (error) {
                                            
                                        }
                                    
                                    },
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                            ],
                            masterDetail: true,
                            detailRowHeight: 240,
                            detailRowAutoHeight: true,
                            detailCellRendererParams: {
                                detailGridOptions: {
                                    columnDefs: [
                                        {
                                            headerName: 'GC Way Bill No',
                                            field: "gc_waybill_no",
                                            width: 200,
                                            cellClass:['cellstylegridY'],
                                            headerClass:["cellstylegridY"],	
                                        },
                                        {
                                            headerName: 'GC Date',
                                            field: "gc_date",
                                            width: 200,
                                            editable:true,
                                            cellClass:['cellstylegridY'],
                                            headerClass:["cellstylegridY"],	
                                            valueGetter:function(prams){
                                                try {
                                                    if(prams.data.gc_date !== "" && prams.data.gc_date !== undefined)
                                                    {
                                                        return getHyphenDDMMMYYYY(prams.data.gc_date);
                                                    }
                                                    else{
                                                        return "";
                                                    }
                                                } catch (error) {
                                                    
                                                }
                                            }
                                        },
                                        {
                                            headerName: "Allowable Weight(Kgs)",
                                            field: "allowable_weight",
                                            width: 200,
                                            cellClass:['cellstylegridY'],
                                            headerClass:["cellstylegridY"],	
                                        },
                                        {
                                            headerName: "Slab Rate Per Kg (Rs)",
                                            field: "per_kg_slab_rate",
                                            width: 200,
                                            cellClass:['cellstylegridY'],
                                            headerClass:["cellstylegridY"],	
                                        },
                                        {
                                            headerName: 'Freight Cost',
                                            field: "basic_trans_cost",
                                            width: 200,
                                            cellClass:['cellstylegridY'],
                                            headerClass:["cellstylegridY"],
                                            valueGetter:function(params){
                                                try {
                                                    const { basic_trans_cost = 0 } = params.data
                                                    return basic_trans_cost.toFixed(2)
                                                    // if(params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined && params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined)
                                                    // {
                                                    //     var a = parseFloat(params.data.allowable_weight)*parseFloat(params.data.per_kg_slab_rate);
                                                    //     return a;
                                                    // }
                                                    // else{
                                                    //     return 0;
                                                    // }
                                                } catch (error) {
                                                    
                                                }
                                            }	
                                        },
                                    ]
                
                                },
                            
                                getDetailRowData: (param) => {
                                    // console.log("param11 ", param)
                                    var rowData = [];
                                    var orms  = {
                                        gc_waybill_no:param.data.gc_waybill_no
                                    }
                                    redirectURL.post("/billing/gcmanualorders",orms,{
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': localStorage.getItem("userid")
                                        }
                                    })
                                    .then(async (response) => {
                                        var records = response.data.records;
                                        param.successCallback(records);
                                    })
                                },
                            },
                            defaultColDef: {
                                // flex: 1,
                                minWidth: 100,
                                filter: true,
                                resizable: true,
                                initialWidth: 200,
                                wrapHeaderText: true,
                                autoHeaderHeight: true,
                                sortable: true
                            },
                            context: this,
                            masterDetail: true,
                            overlayNoRowsTemplate: 'No rows to show',
                            onCellClicked:this.onCellClickedFunc,
                            // onClickViewBatchItems:this.onClickViewBatchItems
        
                        },
                        getDetailRowData: async function (param) {
                            // console.log("param ", param)
                            var rowitems = [];
                            if(ospexists === 1)
                            {
                                if(param.data.osp_doc !== "" && param.data.osp_doc !== undefined)
                                {
                                    rowitems.push({
                                        rownode:param.data,
                                        document_name:"OSP",
                                        document_field_name:"osp_doc",
                                        charges:(param.data.osp !== "" && param.data.osp !== null && param.data.osp !== undefined)?param.data.osp:0,
                                        view_charge_doc:param.data.osp_doc,
                                        view_uploaded_doc:param.data.osp_doc,
                                    });
                                }
                                else{
                                    rowitems.push({
                                        rownode:param.data,
                                        document_name:"OSP",
                                        document_field_name:"osp_doc",
                                        charges:(param.data.osp !== "" && param.data.osp !== null && param.data.osp !== undefined)?param.data.osp:0,
                                        view_charge_doc:param.data.osp_doc,
                                        view_uploaded_doc:"",
                                    });
                                }
                            }
                            if(luexists === 1)
                            {
                                if(param.data.lu_charges_doc !== "" && param.data.lu_charges_doc !== undefined)
                                {
                                    rowitems.push({
                                        rownode:param.data,
                                        document_name:"LU Charges",
                                        document_field_name:"lu_charges_doc",
                                        charges:param.data.lu_charges,
                                        view_charge_doc:param.data.lu_charges_doc,
                                        view_uploaded_doc:param.data.lu_charges_doc,
                                    });
                                }
                                else{
                                    rowitems.push({
                                        rownode:param.data,
                                        document_name:"LU Charges",
                                        document_field_name:"lu_charges_doc",
                                        charges:(param.data.lu_charges !== "" && param.data.lu_charges !== null && param.data.lu_charges !== undefined)?param.data.lu_charges:0,
                                        view_charge_doc:param.data.lu_charges_doc,
                                        view_uploaded_doc:"",
                                    });
                                }
                            } 
                            if(tcexists === 1)
                            {
                                if(param.data.to_pay_charges_doc !== "" && param.data.to_pay_charges_doc !== undefined)
                                {
                                    rowitems.push({
                                        rownode:param.data,
                                        document_name:"To Pay Charges",
                                        document_field_name:"to_pay_charges_doc",
                                        charges:param.data.to_pay_charges,
                                        view_charge_doc:param.data.to_pay_charges_doc,
                                        view_uploaded_doc:param.data.to_pay_charges_doc,
                                    });
                                }
                                else{
                                    rowitems.push({
                                        rownode:param.data,
                                        document_name:"To Pay Charges",
                                        document_field_name:"to_pay_charges_doc",
                                        charges:(param.data.to_pay_charges !== "" && param.data.to_pay_charges !== null && param.data.to_pay_charges !== undefined)?param.data.to_pay_charges:0,
                                        view_charge_doc:param.data.to_pay_charges_doc,
                                        view_uploaded_doc:""
                                    });
                                }
                            }
                            if(scexists === 1)
                            {    
                                if(param.data.special_approval_doc !== "" && param.data.special_approval_doc !== undefined)
                                {
                                    rowitems.push({
                                        rownode:param.data,
                                        document_name:"Special Approval",
                                        document_field_name:"special_approval_doc",
                                        charges:param.data.special_approval,
                                        view_charge_doc:param.data.special_approval_doc,
                                        view_uploaded_doc:param.data.special_approval_doc,
                                    });
                                }
                                else{
                                    rowitems.push({
                                        rownode:param.data,
                                        document_name:"Special Approval",
                                        document_field_name:"special_approval_doc",
                                        charges:(param.data.special_approval !== "" && param.data.special_approval !== null && param.data.special_approval !== undefined)?param.data.special_approval:0,
                                        view_charge_doc:param.data.special_approval_doc,
                                        view_uploaded_doc:""
                                    });
                                }
                            }
                            // if(SpecialchargesMasters[0].osp_charge === "Yes")
                            // {   
                                // if(param.data.special_approval_doc !== "" && param.data.special_approval_doc !== undefined)
                                // {
                                //     rowitems.push({
                                //         rownode:param.data,
                                //         document_name:"Special Approval",
                                //         document_field_name:"manual_charges",
                                //         charges:param.data.manual_charges,
                                //         view_charge_doc:"",
                                //         view_uploaded_doc:"",
                                //         gc_waybill_no:param.data.gc_waybill_no
                                //     });
                                // }
                                // else{
                                //     rowitems.push({
                                //         rownode:param.data,
                                //         document_name:"Special Approval",
                                //         document_field_name:"manual_charges",
                                //         charges:(param.data.manual_charges !== "" && param.data.manual_charges !== null && param.data.manual_charges !== undefined)?param.data.manual_charges:0,
                                //         view_charge_doc:"",
                                //         view_uploaded_doc:"",
                                //         gc_waybill_no:param.data.gc_waybill_no
                                //     });
                                // }
                            // }    
                            //  console.log("rowitems ", rowitems)
                            param.successCallback(rowitems);
                        },
                        masterDetail: true,
                    }
                });
                // console.log(propsdata);
                try{
                    if (cell.colDef.field == 'other_charges_details') {
                        cell.node.setExpanded(!cell.node.expanded);
                    }
                    else {
                        cell.node.setExpanded(false);
                    }
                }
                catch(e){}
            }
            if (cell.colDef.field === "view_charge_doc") {
                if(localStorage.getItem("user_type") === "TRANSPORTER")
                {
                    if(cell.data.document_field_name === "osp_doc")
                    {
                        this.setState({
                            supportField: 'osp_doc',
                            supportDocTitle: 'OSP Doc Upload',
                            sliderSupportshow: "slider-translate-40p", // slide30
                            overly: "show-m",
                            rownode: [cell.data.rownode]
                        })
                    }
                    else if (cell.data.document_field_name == "lu_charges_doc") {
                        if(localStorage.getItem("user_type") === "TRANSPORTER")
                        {
                            this.setState({
                                supportField: 'lu_charges_doc',
                                supportDocTitle: 'L/U Doc Upload',
                                sliderSupportshow: "slider-translate-40p", // slide30
                                overly: "show-m",
                                rownode: [cell.data.rownode]
                            })
                        }
            
                    }
                    else if (cell.data.document_field_name == "special_approval_doc") {
                        if(localStorage.getItem("user_type") === "TRANSPORTER")
                        {
                            this.setState({
                                supportField: 'special_approval_doc',
                                supportDocTitle: 'Special Approval Doc Upload',
                                sliderSupportshow: "slider-translate-40p", // slide30
                                overly: "show-m",
                                rownode: [cell.data.rownode]
                            })
                        }
            
                    }
                    else if (cell.data.document_field_name== 'to_pay_charges_doc') {
                        if(localStorage.getItem("user_type") === "TRANSPORTER")
                        {
                            this.setState({
                                supportField: 'to_pay_charges_doc',
                                supportDocTitle: 'To Pay Charges Doc Upload',
                                sliderSupportshow: "slider-translate-40p", // slide30
                                overly: "show-m",
                                rownode: [cell.data.rownode]
                            })
                        }
                    }
                    
                }
                else{
                }
                
            }
            
            if (cell.colDef.field === "save_charges") {
                if(localStorage.getItem("user_type") === "TRANSPORTER")
                {
                    if(cell.data.document_field_name === "osp_doc")
                    {
                        var parameters = {
                            osp : cell.data.charges,
                            rownode:[cell.data.rownode]
                        }
                    }
                    else if (cell.data.document_field_name == "lu_charges_doc") {
                        if(localStorage.getItem("user_type") === "TRANSPORTER")
                        {
                            var parameters = {
                                lu_charges : cell.data.charges,
                                rownode:[cell.data.rownode]
                            }
                        }
            
                    }
                    else if (cell.data.document_field_name == "special_approval_doc") {
                        if(localStorage.getItem("user_type") === "TRANSPORTER")
                        {
                            var parameters = {
                                special_approval : cell.data.charges,
                                rownode:[cell.data.rownode]
                            }
                        }
            
                    }
                    else if (cell.data.document_field_name== 'to_pay_charges_doc') {
                        if(localStorage.getItem("user_type") === "TRANSPORTER")
                        {
                            var parameters = {
                                to_pay_charges : cell.data.charges,
                                rownode:[cell.data.rownode]
                            }
                        }
                    }
                // console.log("parameters ", parameters)
                    redirectURL.post("/billing/updateOtherChargesData",parameters,{
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem("userid")
                        }
                    })
                    .then((response) => {
                        if(response.data.status === "success")
                        {
                            // console.log("this.state.startDate ", this.state.startDate)
                            // this.getLiabilityData(this.state.defStartDate);
                            // this.gridApi.refreshCells();
                            var params = {
                                from_date: this.state.from_date,
                                to_date: this.state.to_date,
                                shipType: this.state.shipType,
                            }
                            this.loadTrips(params);
                        }
                        
                    })
                    
                }
                else{
                }
                
            }
            // if (cell.colDef.field === 'remarks') {
            //     setTimeout(async () => {
            //         // console.log("cell.colDef.field ",cell)
            //         this.setState({
            //             detailCellRendererParams: {
            //                 detailGridOptions: {
            //                     columnDefs: [
            //                         {
            //                             headerName: 'Status',
            //                             field: "remark_status",
            //                             width: 150,
            //                             cellClass:['cellstylegridY'],
            //                             headerClass:["cellstylegridY"],	
            //                         },
            //                         {
            //                             headerName: 'Description',
            //                             field: "remarks_desc",
            //                             width: 200,
            //                             cellClass:['cellstylegridY'],
            //                             headerClass:["cellstylegridY"],	
            //                         },
            //                     ]
            
            //                 },
            //                 getDetailRowData: async function (param) {
            //                     var rowData = [];
            //                     if(param.data.customer_send_back_remarks !== "" && param.data.customer_send_back_remarks !== undefined)
            //                     {
            //                         rowData.push({
            //                             remark_status: "Send Back",
            //                             remarks_desc:param.data.customer_send_back_remarks
            //                         });
            //                     }
                                
            //                     if(param.data.customer_reject_remarks !== "" && param.data.customer_reject_remarks !== undefined)
            //                     {
            //                         rowData.push({
            //                             remark_status: "Customer Rejected",
            //                             remarks_desc:param.data.customer_reject_remarks
            //                         });
            //                     }
            //                     if(param.data.customer_approve_remarks !== "" && param.data.customer_approve_remarks !== undefined)
            //                     {
            //                         rowData.push({
            //                             remark_status: "Customer Approved",
            //                             remarks_desc:param.data.customer_approve_remarks
            //                         });
                                
            //                     }
                                
            //                     if(param.data.customer_finapprove_remarks !== "" && param.data.customer_finapprove_remarks !== undefined)
            //                     {
            //                        rowData.push({
            //                             remark_status: "Finance Approved",
            //                             remarks_desc:param.data.customer_approve_remarks
            //                         });
            //                     }
            //                     if(param.data.customer_finreject_remarks !== "" && param.data.customer_finreject_remarks !== undefined)
            //                     {
            //                        rowData.push({
            //                             remark_status: "Finance Rejected",
            //                             remarks_desc:param.data.customer_finreject_remarks
            //                         });
            //                     }
            //                     if(param.data.transporter_finapprove_remarks !== "" && param.data.transporter_finapprove_remarks !== undefined)
            //                         {
            //                            rowData.push({
            //                                 remark_status: "Transporter approved",
            //                                 remarks_desc:param.data.transporter_finapprove_remarks
            //                             });
            //                         }
            //                     param.successCallback(rowData);
                                
            //                 },
            //                 masterDetail:true,
                        
            //                 // getDetailRowData: (params) => {
            //                 //     // console.log("He")
            //                 //     var rowData = [];
            //                 //     rowData.push({
            //                 //         remark_status: "Send Back",
            //                 //         remarks_desc:"Testing"
            //                 //     });
                                
            //                 //     params.successCallback(rowData);
            //                 // },
                            
            //             }
            //         });
            //         // console.log(propsdata);
            //         try{
            //             if (cell.colDef.field == 'remarks') {
            //                 cell.node.setExpanded(!cell.node.expanded);
            //             }
            //             else {
            //                 cell.node.setExpanded(false);
            //             }
            //         }
            //         catch(e){}
                    
            //     },500)
            // }
            
            if (cell.colDef.field === 'viewmanualorders') {
                
                setTimeout(async () => {
                // console.log("cellcellcell ", cell)
                    this.setState({
                    detailCellRendererParams: {
                        detailGridOptions: {
                            columnDefs: [
                                {
                                    headerName: 'GC Way Bill No',
                                    field: "gc_waybill_no",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
                                    headerName: 'GC Date',
                                    field: "gc_date",
                                    width: 200,
                                    editable:true,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                    valueGetter:function(prams){
                                        try {
                                            if(prams.data.gc_date !== "" && prams.data.gc_date !== undefined)
                                            {
                                                return getHyphenDDMMMYYYY(prams.data.gc_date);
                                            }
                                            else{
                                                return "";
                                            }
                                        } catch (error) {
                                            
                                        }
                                    }
                                },
                                {
                                    headerName: "Allowable Weight(Kgs)",
                                    field: "allowable_weight",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
                                    headerName: "Slab Rate Per Kg (Rs)",
                                    field: "per_kg_slab_rate",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
                                    headerName: 'Freight Cost',
                                    field: "basic_trans_cost",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                    valueGetter:function(params){
                                        try {
                                            const { basic_trans_cost = 0 } = params.data
                                            return basic_trans_cost.toFixed(2)
                                            // if(params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined && params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined)
                                            // {
                                            //     var a = parseFloat(params.data.allowable_weight)*parseFloat(params.data.per_kg_slab_rate);
                                            //     return a;
                                            // }
                                            // else{
                                            //     return 0;
                                            // }
                                        } catch (error) {
                                            
                                        }
                                    }
                                },
                                {
                                    headerName: 'Chargable Weight',
                                    field: "chargable_weight",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                            ]
        
                        },
                    
                        getDetailRowData: (param) => {
                            // console.log("param ", param)
                            var rowData = [];
                            var orms  = {
                                gc_waybill_no:param.data.gc_waybill_no
                            }
                            redirectURL.post("/billing/gcmanualorders",orms,{
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': localStorage.getItem("userid")
                                }
                            })
                            .then(async (response) => {
                                var records = response.data.records;
                                param.successCallback(records);
                            })
                        },
                    },
                });
                    // console.log(propsdata);
                    try{
                        if (cell.colDef.field == 'viewmanualorders') {
                            cell.node.setExpanded(!cell.node.expanded);
                        }
                        else {
                            cell.node.setExpanded(false);
                        }
                    }
                    catch(e){}
                    
                },500)
            }
            if (cell.colDef.field === 'gc_waybill_no') {
                this.setState({
                    detailCellRendererParams: {
                        detailGridOptions: {
                            columnDefs: [
                                {
									headerName: "Truck No",
									field: "truck_no",
                                    width:200,
									cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
								},
                                {
                                    headerName: 'GC Way Bill No',
                                    field: "gc_waybill_no",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
                                    headerName: 'GC Date',
                                    field: "gc_date",
                                    width: 200,
                                    editable:true,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                    valueGetter:function(prams){
                                        try {
                                            if(prams.data.gc_date !== "" && prams.data.gc_date !== undefined)
                                            {
                                                return getHyphenDDMMMYYYY(prams.data.gc_date);
                                            }
                                            else{
                                                return "";
                                            }
                                        } catch (error) {
                                            
                                        }
                                    }
                                },
                                {
									headerName: "Customer name",
									field: "consignee_name",
                                    width:200,
									cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],
								},
								{
									headerName: "Customer Pincode",
									field: "consignee_pincode",
                                    width:200,
									cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],
								},
                                {
                                    headerName: "Allowable Weight(Kgs)",
                                    field: "allowable_weight",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
                                    headerName: "Slab Rate Per Kg (Rs)",
                                    field: "per_kg_slab_rate",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
                                    headerName: 'Freight Cost',
                                    field: "basic_trans_cost",
                                    width: 200,
                                    cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],	
                                },
                                {
									headerName: "Shipping Type",
									field: "shipping_type",
									 width:200,
									cellClass:['cellstylegridY'],
                                    headerClass:["cellstylegridY"],
								}
                            ],
                            defaultColDef: {
                                // flex: 1,
                                minWidth: 100,
                                filter: true,
                                resizable: true,
                                initialWidth: 200,
                                wrapHeaderText: true,
                                autoHeaderHeight: true,
                                sortable: true
                            },
                            context: this,
                            overlayNoRowsTemplate: 'No rows to show'
        
                        },
                        getDetailRowData: async function (param) {
                            var rowData = [];
                            var orms  = {
                                gc_waybill_no:param.data.gc_waybill_no
                            }
                            redirectURL.post("/billing/gcmanualorders",orms,{
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': localStorage.getItem("userid")
                                }
                            })
                            .then(async (response) => {
                                var records = response.data.records;
                                param.successCallback(records);
                            })
                        
                        },
                        masterDetail: true
                    }
                });
                // console.log(propsdata);
                try{
                    if (cell.colDef.field == 'gc_waybill_no') {
                        cell.node.setExpanded(!cell.node.expanded);
                    }
                    else {
                        cell.node.setExpanded(false);
                    }
                }
                catch(e){}
                
            }
            
            
            if (cell.colDef.field === 'total_tat') {
                    // console.log("cell.colDef.field ",cell)
                    this.setState({
                        detailCellRendererParams: {
                            detailGridOptions: {
                                columnDefs: [
                                    {
                                        headerName: 'TAT',
                                        field: "TAT",
                                        width: 150,
                                        cellClass:['cellstylegridY'],
                                        headerClass:["cellstylegridY"],	
                                    },
                                    {
                                        headerName: 'ODA TAT',
                                        field: "ODA_TAT",
                                        width: 150,
                                        cellClass:['cellstylegridY'],
                                        headerClass:["cellstylegridY"],	
                                    },
                                    {
                                        headerName: 'Grace',
                                        field: "grace_count",
                                        width: 150,
                                        cellClass:['cellstylegridY'],
                                        headerClass:["cellstylegridY"],	
                                    },
                                    {
                                        headerName: 'Holidays',
                                        field: "holiday_count",
                                        width: 150,
                                        cellClass:['cellstylegridY'],
                                        headerClass:["cellstylegridY"],
                                        valueGetter:function(params){
                                            try {
                                                var s = (params.data.count_sunday !== "" && params.data.count_sunday !== undefined)?params.data.count_sunday:0;
                                                var h = (params.data.holiday_count !== "" && params.data.holiday_count !== undefined)?params.data.holiday_count:0;
                                                var d = parseInt(s)+parseInt(h);
                                                return d;
                                            } catch (error) {
                                                
                                            }
                                        }	
                                    },
                                ]
            
                            },
                        
                            getDetailRowData: (params) => {
                                var rowData = [];
                                rowData.push({
                                    TAT: params.data.TAT,
                                    ODA_TAT: params.data.ODA_TAT,
                                    grace_count: params.data.grace_count,
                                    holiday_count: params.data.holiday_count,
                                    count_sunday: params.data.count_sunday,
                                    rownode:params.data
                                });
                                 console.log("rowData",rowData)
                                params.successCallback(rowData);
                            },
                            
                        }
                    });
                    // console.log(propsdata);
                    try{
                        if (cell.colDef.field == 'total_tat') {
                            cell.node.setExpanded(!cell.node.expanded);
                        }
                        else {
                            cell.node.setExpanded(false);
                        }
                    }
                    catch(e){}
                    
            }    
            if (cell.colDef.field === 'view_pod') {
                this.onClickViewPOD(cell);
            }        
            if (cell.colDef.field === 'update_charges') {
                this.onClickUpdateChargesBtn(cell);
            }            
            if (cell.colDef.field === 'view_charges') {
                this.onClickViewChargeItem(cell);
            }
            if (cell.colDef.field === 'with_defect') {
                this.onClickViewDamageItem(cell);
            }            
        },500)
    }
    onCellDoubleClickedFunc = async (cell) => {
        // if (cell.colDef.field == 'other_charges') {
        //     await this.setState({
        //         detailCellRendererParams: {
        //             detailGridOptions: {
        //                 columnDefs: [
        //                     {
        //                         headerName: 'GC Way Bill No',
        //                         field: "gc_waybill_no",
        //                         width: 200,
        //                         cellClass:['cellstylegridY'],
        //                         headerClass:["cellstylegridY"],	
        //                     },
        //                     {
        //                         headerName: 'GC Date',
        //                         field: "gc_date",
        //                         width: 200,
        //                         editable:true,
        //                         cellClass:['cellstylegridY'],
        //                         headerClass:["cellstylegridY"],	
        //                         valueGetter:function(prams){
        //                             try {
        //                                 if(prams.data.gc_date !== "" && prams.data.gc_date !== undefined)
        //                                 {
        //                                     return getHyphenDDMMMYYYY(prams.data.gc_date);
        //                                 }
        //                                 else{
        //                                     return "";
        //                                 }
        //                             } catch (error) {
                                        
        //                             }
        //                         }
        //                     },
        //                     {
        //                         headerName: "Allowable Weight(Kgs)",
        //                         field: "allowable_weight",
        //                         width: 200,
        //                         cellClass:['cellstylegridY'],
        //                         headerClass:["cellstylegridY"],	
        //                     },
        //                     {
        //                         headerName: "Slab Rate Per Kg (Rs)",
        //                         field: "per_kg_slab_rate",
        //                         width: 200,
        //                         cellClass:['cellstylegridY'],
        //                         headerClass:["cellstylegridY"],	
        //                     },
        //                     {
        //                         headerName: 'Freight Cost',
        //                         field: "basic_trans_cost",
        //                         width: 200,
        //                         cellClass:['cellstylegridY'],
        //                         headerClass:["cellstylegridY"],	
        //                     },
        //                 ],
        //                 defaultColDef: {
        //                     // flex: 1,
        //                     minWidth: 100,
        //                     filter: true,
        //                     resizable: true,
        //                     initialWidth: 200,
        //                     wrapHeaderText: true,
        //                     autoHeaderHeight: true,
        //                     sortable: true
        //                 },
        //                 context: this,
        //                 overlayNoRowsTemplate: 'No rows to show'
    
        //             },
        //             getDetailRowData: async function (param) {
        //                 var rowData = [];
        //                 var orms  = {
        //                     gc_waybill_no:param.data.gc_waybill_no
        //                 }
        //                 redirectURL.post("/billing/gcmanualorders",orms,{
        //                     headers: {
        //                         'Content-Type': 'application/json',
        //                         'Authorization': localStorage.getItem("userid")
        //                       }
        //                   })
        //                 .then(async (response) => {
        //                     var records = response.data.records;
        //                     param.successCallback(records);
        //                 })
                       
        //             },
        //             masterDetail: true
        //         }
        //     });
        //     // console.log(propsdata);
        //     try{
        //         if (cell.colDef.field == 'other_charges') {
        //             cell.node.setExpanded(!cell.node.expanded);
        //         }
        //         else {
        //             cell.node.setExpanded(false);
        //         }
        //     }
        //     catch(e){}
            
        // }
        
        if (cell.colDef.field == 'remarks') {
            await this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: 'Status',
                                field: "remark_status",
                                width: 150,
                                cellClass:['cellstylegridY'],
                                headerClass:["cellstylegridY"],	
                            },
                            {
                                headerName: 'Description',
                                field: "remarks_desc",
                                width: 200,
                                cellClass:['cellstylegridY'],
                                headerClass:["cellstylegridY"],	
                            },
                        ],
                        defaultColDef: {
                            // flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable: true,
                            initialWidth: 200,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                            sortable: true
                        },
                        context: this,
                        overlayNoRowsTemplate: 'No rows to show'
    
                    },
                    getDetailRowData: async function (param) {
                        var rowData = [];
                        if(param.data.customer_send_back_remarks !== "" && param.data.customer_send_back_remarks !== undefined)
                        {
                            rowData.push({
                                remark_status: "Send Back",
                                remarks_desc:param.data.customer_send_back_remarks
                            });
                        }
                        
                        if(param.data.customer_reject_remarks !== "" && param.data.customer_reject_remarks !== undefined)
                        {
                            rowData.push({
                                remark_status: "Customer Rejected",
                                remarks_desc:param.data.customer_reject_remarks
                            });
                        }
                        if(param.data.customer_approve_remarks !== "" && param.data.customer_approve_remarks !== undefined)
                        {
                            rowData.push({
                                remark_status: "Customer Approved",
                                remarks_desc:param.data.customer_approve_remarks
                            });
                        
                        }
                        
                        if(param.data.customer_finapprove_remarks !== "" && param.data.customer_finapprove_remarks !== undefined)
                        {
                           rowData.push({
                                remark_status: "Finance Approved",
                                remarks_desc:param.data.customer_approve_remarks
                            });
                        }
                        if(param.data.customer_finreject_remarks !== "" && param.data.customer_finreject_remarks !== undefined)
                        {
                           rowData.push({
                                remark_status: "Finance Rejected",
                                remarks_desc:param.data.customer_finreject_remarks
                            });
                        }
                        if(param.data.transporter_finapprove_remarks !== "" && param.data.transporter_finapprove_remarks !== undefined)
                            {
                               rowData.push({
                                    remark_status: "Transporter approved",
                                    remarks_desc:param.data.transporter_finapprove_remarks
                                });
                            }
                        param.successCallback(rowData);
                        
                    },
                    masterDetail: true
                }
            });
            // console.log(propsdata);
            try{
                if (cell.colDef.field == 'remarks') {
                    cell.node.setExpanded(!cell.node.expanded);
                }
                else {
                    cell.node.setExpanded(false);
                }
            }
            catch(e){}
            
        }
    }
    onEditStopUpdateData(rownode){
        // console.log("rownode ", rownode)
        var updateCheck = 0;
        var clickedValue = rownode.value;
        if (clickedValue >= 0 && clickedValue != '' && clickedValue != undefined) {
            updateCheck = 1;
        }
        if (updateCheck == 1) {
            var rowData=[];
            this.gridApi.forEachNode((e) => {
                if(e.data.gc_waybill_no === rownode.data.gc_waybill_no)
                {
                    // console.log("e ",e)
                    if(e.data.allowable_weight !== undefined && e.data.allowable_weight !== "" && e.data.allowable_weight !== null && e.data.allowable_weight !== NaN)
                    {
                        var fbwt = e.data.allowable_weight;
                    }
                    else{
                        var fbwt = 0;
                    }
                    
                    if(e.data.per_kg_slab_rate !== undefined && e.data.per_kg_slab_rate !== "" && e.data.per_kg_slab_rate !== null && e.data.per_kg_slab_rate !== NaN)
                    {
                        var pksrf = e.data.per_kg_slab_rate;
                    }
                    else{
                        var pksrf = 0;
                    }
                    var allowable_weight = fbwt;
                    var per_kg_slab_rate = pksrf;
                    
                    var frieght = parseFloat(fbwt)*parseFloat(pksrf);
                    if(e.data.ODA_TAT !== "" && e.data.ODA_TAT !== undefined && e.data.ODA_TAT !== null && e.data.ODA_TAT !== NaN)
                    {
                        var ODA_TAT = e.data.ODA_TAT; 
                    }
                    else{
                        var ODA_TAT = 0;
                    }
                    
                    if(e.data.osp !== "" && e.data.osp !== undefined && e.data.osp !== null && e.data.osp !== NaN)
                    {
                        var osp = e.data.osp; 
                    }
                    else{
                        var osp = 0;
                    }
                    
                    if(e.data.lu_charges !== "" && e.data.lu_charges !== undefined && e.data.lu_charges !== null && e.data.lu_charges !== NaN)
                    {
                        var lu_charges = e.data.lu_charges; 
                    }
                    else{
                        var lu_charges = 0;
                    }
                    
                    if(e.data.special_approval !== "" && e.data.special_approval !== undefined && e.data.special_approval !== null && e.data.special_approval !== NaN)
                    {
                        var special_approval = e.data.special_approval; 
                    }
                    else{
                        var special_approval = 0;
                    }
                    
                    if(e.data.to_pay_charges !== "" && e.data.to_pay_charges !== undefined && e.data.to_pay_charges !== null && e.data.to_pay_charges !== NaN)
                    {
                        var to_pay_charges = e.data.to_pay_charges; 
                    }
                    else{
                        var to_pay_charges = 0;
                    }
                    
                    if(e.data.chargable_weight !== "" && e.data.chargable_weight !== undefined)
                    {
                        var chargable_weight = e.data.chargable_weight;
                    }
                    else{
                        var chargable_weight = 0;
                    }
                    e.data.chargable_weight = chargable_weight;
                    var charged_weight_diff = parseFloat(e.data.chargable_weight)-parseFloat(allowable_weight);
                    if(charged_weight_diff > 0)
                    {
                        var charged_weight_diffval =charged_weight_diff;
                    }
                    else{
                        var charged_weight_diffval = 0;
                    }
                    e.data.charged_weight_diff = charged_weight_diffval;
                    e.data.freight_diff = parseFloat(charged_weight_diffval)*parseFloat(e.data.per_kg_slab_rate);
                
                    e.data.frieght = frieght;
                    //  console.log(parseFloat(frieght)+"+"+parseFloat(oda)+"+"+parseFloat(osp)+"+"+parseFloat(lu_charges)+"+"+parseFloat(special_approval)+"+"+parseFloat(to_pay_charges))
                    var sub_total = (e.data.sub_total || 0).toFixed(2);
                    // console.log("e.data.gst " , parseFloat(e.data.sub_total)+(parseFloat(e.data.sub_total)*(e.data.gst/100)))
                    if(e.data.gst !== "" && e.data.gst !== undefined)
                    {
                        var gst_amount = 0
                        var gstp = (parseFloat(sub_total)*(e.data.gst/100))
                        gst_amount = gstp.toFixed(2);
                        e.data.gst_amount = gst_amount
                    }
                    else{
                        var gst_amount = 0 
                        // console.log("gstp",gstp)
                        var gstp = 0;
                        e.data.gst_amount = gst_amount;
                    }
                    var total_freight = (parseFloat(sub_total)+(parseFloat(gst_amount))).toFixed(2);
                    // console.log("total_freight",total_freight)
                    e.data.total_freight = total_freight
                }
                rowData.push(e.data)
            })
            // console.log("rowData ", rowData)
            this.setState({
                rowData:rowData
            });
            this.gridApi.refreshCells();
        }
    }
    onClickSendForApprovalToCustomer(){
        var rownodes = this.gridApi.getSelectedRows();
            //  console.log("rownodes ", rownodes)
        if(rownodes.length > 0)
        {
            this.setState({
                sliderInvoiceshow: "slider-translate-30p", // slide30
                overly: "show-m",
                loadshow: "show-n",
                invRowNodes: rownodes
            });
        }
        else{
            this.setState({
                show: true, // slide30
                basicTitle: "Please Select atleast one item from grid",
                basicType: "danger"
            });
          
        }
       
    }
    onSubmitUploadInvoiceAndSendForApproval(event){
        event.preventDefault();
        this.setState({
            loadshow:"show-m"
        });
        var rownodes = this.state.invRowNodes;
        console.log("rownodes", rownodes)
        var famount = 0;
        var gst = 0;
        if(rownodes.length > 0)
        {
            rownodes.map((itm) => {
                // famount = parseFloat(famount)+parseFloat(itm.sub_total);
                console.log("famount loop", famount, itm.total_amount)
                var famunt = parseFloat(famount)+parseFloat(itm.total_amount);
                famount = famunt.toFixed(2);
                gst = parseFloat(gst)+parseFloat(itm.gst_amount);
            })
        }
        if(this.state.invoice_freight_amount !== "" && this.state.invoice_freight_amount !== undefined)
        {
            var invoice_freight_amount = this.state.invoice_freight_amount;
        }
        else{
            var invoice_freight_amount = 0;
        }
        if(this.state.invoice_gst_amount !== "" && this.state.invoice_gst_amount !== undefined)
        {
            var invoice_gst_amount = this.state.invoice_gst_amount;
        }
        else{
            var invoice_gst_amount = 0;
        }
        if(this.state.invoice_date !== "" && this.state.invoice_date !== undefined)
        {
            var invoice_date = this.state.invoice_date;
            var check = 1;
        }
        else{
            var invoice_date = 0;
            var check = 0;
        }
        if (check === 1){
        // console.log("invoice_freight_amount ", invoice_freight_amount)
        // console.log("invoice_gst_amount ", invoice_gst_amount)
        // console.log("famount ", famount)
        console.log("famount ", famount)
        console.log("invoice_freight_amount ", invoice_freight_amount)
        console.log("invoice_gst_amount ", invoice_gst_amount)
        console.log("famount ", famount)
        console.log("gst ", gst)
        // console.log(parseFloat(famount) === parseFloat(invoice_freight_amount) && parseFloat(gst) === parseFloat(invoice_gst_amount));
            // if(parseFloat(famount) === parseFloat(invoice_freight_amount) && parseFloat(gst) === parseFloat(invoice_gst_amount))
            if(parseFloat(famount) === parseFloat(invoice_freight_amount)){
                var invDoc = this.state.invDoc;
                const formData = new FormData();
                formData.append("invoice_no", this.state.invoice_no);
                formData.append("invoice_date", this.state.invoice_date);
                formData.append("invoice_freight_amount", this.state.invoice_freight_amount);
                formData.append("invoice_gst_amount", this.state.invoice_gst_amount);
                formData.append("invoiceDocument", invDoc);
                formData.append("rownodes", JSON.stringify(rownodes));
                formData.append("useremail", localStorage.getItem("email"));
                formData.append("supplier_code", localStorage.getItem("supplier_code") || []);
                redirectURL.post("/billing/sendtptapprovaltocustomer",formData)
                .then((response) => {
                    if(response.data.status === "success")
                    {
                        window.location.reload();
                    }
                    else if(response.data.status === "failure"){
                        this.setState({
                            show:true,
                            basicTitle:response.data.message || "Failed to submit invoice data",
                            basicType:'danger',        
                            loadshow:"show-m"
                        })
                    }
            // })
            // document.getElementById("invForm").reset();
                    else{
                        this.setState({
                            show:true,
                            basicTitle:"Failed to submit invoice data",
                            basicType:'danger',        
                            loadshow:"show-m"
                        })
                    }
                })

                document.getElementById("invForm").reset();
           }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Invoice Freight amount and Total Freight amount not matched",
                    basicType:'danger',
                           
                    loadshow:"show-m"
                });
                document.getElementById("invForm").reset();
            }
        }
        else{
            
            this.setState({
                show:true,
                basicTitle:"please select invoice_date ",
                basicType:'danger',
                      
                loadshow:"show-m"
            });
        }

    }
    onClickViewDocumentsList = async (rownode) =>{
        // console.log("rownode ", rownode)
            await this.setState({
                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "Document Name",
                                field: "document_name",
                                width: 200,
                            },
                            {
                                headerName: "View",
                                field: "view_doc",
                                width: 160,
                                cellRenderer: (params) => `<a href="`+params.data.view_doc+`" target="_blank" class="btn btn-secondary" style="padding:0px 5px;font-size:12px"> View Document</a>`
                            },
    
    
                        ],
                        defaultColDef: {
                            // flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable: true,
                            initialWidth: 200,
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                            sortable: true
                        },
                        context: this,
                        overlayNoRowsTemplate: 'No rows to show',
    
                    },
                    getDetailRowData: async function (param) {
                        // console.log("param ", param)
                        var rowitems = [];
                        if(param.data.osp_doc !== "" && param.data.osp_doc !== undefined)
                        {
                            rowitems.push({
                                document_name:"OSP Document",
                                view_doc:param.data.osp_doc
                            });
                        }
                        if(param.data.lu_charges_doc !== "" && param.data.lu_charges_doc !== undefined)
                        {
                            rowitems.push({
                                document_name:"LU Charges Document",
                                view_doc:param.data.lu_charges_doc
                            });
                        }
                        if(param.data.special_approval_doc !== "" && param.data.special_approval_doc !== undefined)
                        {
                            rowitems.push({
                                document_name:"Special Approval Document",
                                view_doc:param.data.special_approval_doc
                            });
                        }
                        if(param.data.to_pay_charges_doc !== "" && param.data.to_pay_charges_doc !== undefined)
                        {
                            rowitems.push({
                                document_name:"To Pay Charges Document",
                                view_doc:param.data.to_pay_charges_doc
                            });
                        }
                           
                        // console.log("rowitems ", rowitems)
                        param.successCallback(rowitems);
                    },
                    masterDetail: true
                }
            });
            // console.log(propsdata);
            try{
                if (rownode.column.colDef.field == 'view_docs') {
                    rownode.node.setExpanded(!rownode.node.expanded);
                }
                else {
                    rownode.node.setExpanded(false);
                }
            }
            catch(e){}
    }
    
    onChangeShipType(shipType)
    {
        // console.log("shipType",shipType)
        this.setState({shipType},()=>{
            var oRowData= this.state.oRowData;
            //  console.log("oRowData",oRowData)
            if(this.state.shipType.value === "All")
            {
                // console.log("records",records)
                var records = oRowData
                this.setState({
                    rowData:oRowData
                })
            }
            else if(shipType.value === "PTL"){
                var checkData  = oRowData.filter((f)=>["47","48"].includes(f.shipping_type))
                this.setState({
                    rowData:checkData
                })
            }
            else if(shipType.value === "FTL"){
                var checkData  = oRowData.filter((f)=>["01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25","29", "30", "31", "32", "45", "46"].includes(f.shipping_type))
                this.setState({
                    rowData:checkData
                })
            }
            else if(shipType.value === "ODC"){
                var checkData  = oRowData.filter((f)=>["00","06"].includes(f.shipping_type))
                this.setState({
                    rowData:checkData
                })
            }
            else
            {
                // console.log("recrods",recrods)
                var checkData = oRowData.filter((f) => f.shipping_type === this.state.shipType.value)
                this.setState({
                    rowData:checkData
                })
            }
        })
    }
    onChangeDamageType(damagetype) {
        this.setState({damagetype  :damagetype });
    }
    onChangeHandler(field, event) {
        let value = event.target.value;    
        // Remove any non-numeric characters
        value = value.replace(/[^0-9]/g, '');    
        // Update the state with the cleaned value
        this.setState((prevState)=>({ [field]: value,isFileRequired: {
            // ...prevState.isFileRequired,
            [field]: value !== '', // Mark file as required if the value changes
        }}));
    }
    // Handle file uploads
    onFileUpload(field, event) {
        const file = event.target.files[0];
        this.setState((prevState) => ({
            files: {
                // ...prevState.files, // The previous charges also stays the same, It  can enabled if multiple files uploaded at same time 
                [field]: file,
            },
        }));
    }
    // Validate form before submission
    validateForm() {
        const { isFileRequired, files } = this.state;
        for (const field in isFileRequired) {
            if (isFileRequired[field] && !files[field]) {
                return false; // A file is required but not uploaded
            }
        }
        return true;
    }
    // Handle dropdown selection
     onSelectChargeType(event) {
        const selectedChargeType = event.target.value;
        this.setState({ selectedChargeType });
    }
    // Handle charge value change
    onChargeValueChange(field, event) {
        const value = event.target.value;
        console.log(value,"value")
        this.setState((prevState) => ({
            chargeValues: {
                // ...prevState.chargeValues,
                [field]: value,
            },
            isFileRequired: {
                // ...prevState.isFileRequired,
                [field]: value !== "", // Mark file upload as required if the value changes
            },
        }));
    }
    handleFileDownload = (fileName) => {
        const params = { fileName };    
        redirectURL
            .post("/billing/downloadChargesFiles", params, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("userid"),
                },
            })
            .then((res) => {
                try {
                    if (res.status === 200) {
                        const downloadUrl = res.data.url;
                        const link = document.createElement("a");
                        link.href = downloadUrl;
                        link.setAttribute("download", fileName); // Optional: specify download filename
                        link.setAttribute("target", "_blank");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        // console.log("clicked");
                      }
                } catch (e) {
                    console.error("Error handling the download:", e);                    
                }
            })
            .catch((error) => {
                console.error("Error fetching signed URL:", error);
            });
    };
    render() {
        const { selectedChargeType, chargeValues, isFileRequired,crane_file=[],odc_file=[], detention_file=[],unloading_file=[] } = this.state;
        const hideFor = localStorage.getItem("user_type") === "TRANSPORTER" || localStorage.getItem("user_type") === "CUSTOMER"
        
        if(localStorage.getItem("user_type") !== "" && localStorage.getItem("user_type") !== undefined)
        {
            if(localStorage.getItem("user_type") === "TRANSPORTER")
            {
                var fEdit = true;
                var celClass = "yellowlitebg"
            }
            else{
                var fEdit = false;
                var celClass = "";
            }
            
        }
        else{
            var fEdit = false;
            var celClass = "";
        }
        const { multiplePodDocs } = this.state
        var checkSelect = true; // user asked for bulk upload of epods
        // if (this.state.actiontype === 'liable') {
        //     checkSelect = false;
        // }
        // else {
        //     checkSelect = true;
        // }
        // console.log("Constants ", Constants.default)
        var columnDefs = [
            {
                headerName: 'Consignment Details',
                headerClass:["cellstylegridGBg","cellTopHeader"],
                children:[
                    // {
                    //     headerName: Constants.TRIP_PLANT_CODE,
                    //     field: "consigner_code",
                    //     width: 120,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    //     headerCheckboxSelection: true,
                    //     checkboxSelection: checkSelect
                    // },
                    // {
                    //     headerName: Constants.TRIP_PLANT_NAME,
                    //     field: "consigner_name",
                    //     width: 100,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    // },
                    // {
                    //     headerName: Constants.TRIP_CONSIGN_NO,
                    //     field: "consignment_code",
                    //     width: 160,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    // },
                    // {
                    //     headerName: Constants.TRIP_TRANSPORTER_CODE,
                    //     field: "transporter_code",
                    //     width: 180,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    // },
                    // {
                    //     headerName: Constants.TRIP_TRANSPORTER_NAME,
                    //     field: "service_provider",
                    //     width: 180,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    // },                    
                    // {
                    //     headerName: Constants.GC_WAY_BILL_NO,
                    //     field: "gc_waybill_no",
                    //     width: 120,
                    //     cellClass:['yellow'],
                    //     headerClass:["cellstylegridGBg"],
                    //     // checkboxSelection: checkSelect
                    // },
                    // {
                    //     headerName: Constants.GC_DATE,
                    //     field: "gc_date",
                    //     width: 120,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    //     valueGetter: function (params) {
                    //         try {
                    //             if (params.data.gc_date != undefined && params.data.gc_date != "") {
                    //                 return getHyphenDDMMMYYYYHHMM(params.data.gc_date);
                    //             }
                    //         }
                    //         catch (e) { }
                    //     }
                    //     // checkboxSelection: checkSelect
                    // },
                    {
                        headerName: Constants.TRIP_TRUCK_NO,
                        field: "truck_no",
                        width: 120,
                        cellClass:['green'],
                        headerClass:["cellstylegridGBg"],
                        // checkboxSelection: checkSelect
                    },// To be Transferred 
                    {
                        headerName: Constants.SHIPMENT_TYPE,
                        field: "shipping_type",
                        width: 120,
                        cellClass:['green'],
                        headerClass:["cellstylegridGBg"],
                        valueGetter:function(params){
                            try {
                                var sdata = params.data.shipping_type; 
                                var sdataoVal = sdata;
                                if(sdata === "05" || sdata === 5)
                                {
                                    var sdataoVal = "surface";
                                }
                                if(sdata === "07" || sdata === 7)
                                {
                                    var sdataoVal = "air";
                                }
                                if(sdata === "22" || sdata === 22)
                                {
                                    var sdataoVal = "train";
                                }
                                if (sdata === "48" || sdata === 48 || sdata === "47" || sdata === 47) {
                                    var sdataoVal = "Part truck Load";
                                }
                                if (["01", "02", "03", "04", "10", "11", "12", "14", "15", "17", "18", "21", "24", "25", "29", "30", "31", "32", "45", "46"].includes(sdata)) {
                                    var sdataoVal = "Full Truck Load";
                                }
                                if(sdata === "86" || sdata === 86 )
                                {
                                    var sdataoVal = "Adjustment Load";
                                }
                                if(sdata === "00" || sdata === "06")
                                {
                                    var sdataoVal = "Over Dimensional Cargo";
                                }
                                if(sdata === "74" || sdata === 74)
                                {
                                    var sdataoVal = "Multi Point Delivery";
                                }
                                if (sdata === "HD") {
                                    var sdataoVal = "Hand Delivery";
                                }
                                return sdataoVal;
                            } catch (error) {
                                
                            }
                        }
                        // checkboxSelection: checkSelect
                    },                    
                    {
                        headerName: Constants.TRIP_CUSTOMER_CODE,
                        field: "consignee_code",
                        width: 100,
                        cellClass:['green'],
                        headerClass:["cellstylegridGBg"],
                    },
                    {
                        headerName: Constants.TRIP_CUSTOMER_NAME,
                        field: "consignee_name",
                        width: 180,
                        cellClass:['green'],
                        headerClass:["cellstylegridGBg"],
                    },
                    
                    // {
                    //     headerName: Constants.CREDIT_SALES,
                    //     field: "credit_sales",
                    //     width: 120,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    //     // checkboxSelection: checkSelect
                    // },
                    {
                        headerName: Constants.TRIP_CUSTOMER_CITY,
                        field: "consignee_city",
                        width: 160,
                        cellClass:['green'],
                        headerClass:["cellstylegridGBg"],
                    },
                    {
                        headerName: Constants.TRIP_CUSTOMER_STATE,
                        field: "consignee_state",
                        width: 150,
                        cellClass:['green'],
                        headerClass:["cellstylegridGBg"],
                    },
                    {
                        headerName: Constants.TRIP_ZONE,
                        field: "consignee_zone",
                        width: 140,
                        cellClass:['green'],
                        hide:true,
                        headerClass:["cellstylegridGBg"],
                    },
                    {
                        headerName: Constants.TRIP_PIN_CODE,
                        field: "consignee_pincode",
                        width: 180,
                        cellClass:['green'],
                        headerClass:["cellstylegridGBg"],
                    },
                    // {
                    //     headerName: "ODA Time (Days)",
                    //     field: "ODA_TAT",
                    //     width: 180,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    // },
                    
                    // {
                    //     headerName: "Allowable Weight(Kgs)",
                    //     field: "allowable_weight",
                    //     width: 100,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    // },
                    // {
                    //     headerName: "Slab Rate Per Kg (Rs)",
                    //     field: "per_kg_slab_rate",
                    //     width: 150,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    // },
                    // {
                    //     headerName: Constants.TRIP_FREIGHT_COST,
                    //     field: "freight_rate",
                    //     width: 100,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    //     valueGetter:function(params){
                    //         try {
                    //             if(params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined && params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined)
                    //             {
                    //                 var a = parseFloat(params.data.allowable_weight)*parseFloat(params.data.per_kg_slab_rate);
                    //                 return a;
                    //             }
                    //             else{
                    //                 return 0;
                    //             }
                    //         } catch (error) {
                                
                    //         }
                    //     }
                    // },
                    // {
                    //     headerName: Constants.TRIP_TOTAL_COST,
                    //     field: "total_cost",
                    //     width: 100,
                    //     cellClass:['green'],
                    //     headerClass:["cellstylegridGBg"],
                    // }
                ]
            }
        ]
        if (this.state.actiontype === 'liable') {
            columnDefs.push({
                headerName: "Upload POD",
                field: "consignment_code",
                width: 120,
                pinned: "right",
                cellRendererSelector: function (params) {
                    return {
                        component: "UploadPOD"
                    }
                },
            });
        }
        else {
            if (this.state.actiontype === 'paywithstat') {
                columnDefs.push({
                    headerName: "",
                    field: "consignment_code",
                    width: 120,
                    // pinned: "right",
                    cellRendererSelector: function (params) {
                        return {
                            component: "ViewPOD"
                        }
                    },
                }, {
                    headerName: Constants.TRIP_PAYMENT_STATUS,
                    field: "trip_payment_status",
                    pinned: "right",
                    width: 160,
                });
            }
            // else {
            //     columnDefs.push({
            //         headerName: "",
            //         field: "consignment_code",
            //         width: 120,
            //         pinned: "right",
            //         cellRendererSelector: function (params) {
            //             return {
            //                 component: "ViewPOD"
            //             }
            //         },
            //     });
            // }
        }
        var 
        
        columnBatchDefs = [
            // {
            //     headerName: "Batch NO",
            //     field: "batch_no",
            //     width: 200
            // },
            {
                headerName: "Invoice No",
                field: "invoice_no",
                width: 180,
                checkboxSelection: checkSelect
            },
            {
                headerName: "Invoice Date",
                field: "invoice_date",
                width: 180,
                valueGetter:function(params){
                    try {
                        if(params.data.invoice_date !== undefined && params.data.invoice_date !== "")
                        {
                            return getHyphenDDMMMYYYY(params.data.invoice_date);
                        }
                        else{
                            return "";
                        }
                        
                    } catch (error) {
                        
                    }
                }
            },
            {
                headerName: "Total Freight Amount",
                field: "total_freight",
                width: 140
            },
            {
                headerName: 'Fully Deducted Total',
                field: "reduce_amount",
                width: 140,
                valueGetter: function (params) {
                    try {
                        const { reduce_amount = 0 } = params.data
                        return reduce_amount
                    } catch (error) {
                        console.error("Error calculating total amount with reduction:", error);  // Log error
                        return "0.00";  // Return 0 as string in case of an error
                    }
                }
            },
            {
                headerName: "Total Payable Amount",
                // headerName: "Freight Amount",
                field: "invoice_amount",
                width: 180
            },
            // {
            //     headerName: "GST Amount",
            //     field: "invoice_gst_amount",
            //     width: 180
            // },
            // 
            {
                headerName: "",
                field: "viewitems",
                width: 120,
                cellRendererSelector: function (params) {
                    return {
                        component: "ViewBatchItems"
                    };
                },
            }, 
        ]
        if (!hideFor && this.state.pendingwithcust=="btn-danger") {
            columnBatchDefs.push(
                {
                    headerName: "Warehouse Verified",
                    field: "is_pendingwith_payment",
                    width: 140,
                    suppressColumnToolPanel: hideFor,
                    hide: hideFor,
                    cellRendererSelector: function (params) {
                        if (params.data.is_pendingwith_payment === 1) {
                            return {
                                component: "ConfirmedComponent",
                                params: {
                                    style: { color: "#228B22" },
                                    text: "Confirmed"
                                }
                            };
                        } else {
                            return {
                                component: "ConfirmBtnComponentBtn"
                            };
                        }
                    },
                },
            )
        }
        var transporterupdatescols = [
            {
                headerName: 'Delivery Date',
                field: "delivery_datetime",
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                width: 140,
                valueGetter: function (params) {
                    try {
                        if (params.data.delivery_datetime != undefined && params.data.delivery_datetime != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime);
                        }
                    }
                    catch (e) { }
                }
            },
            // {
            //     headerName: "View Pod",
            //     field: "view_pod",
            //     width: 120,
            //     // pinned: "right",
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            //     cellRendererSelector: function (params) {
            //         return {
            //             component: "ViewPOD"
            //         }
            //     },
            // },
            {
                headerName: "View Pod",
                field: "view_pod",
                width: 120,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                cellRenderer: (params) => {
                    // Render the button for "View POD"
                    return (
                        `<button class="btn btn-info" style="padding: 2px 10px; font-size: 13px; margin: 0" title="View POD">View POD</button>`
                    );
                }
            },            
            {
                headerName: "Allowable Weight(Kgs)",
                field: "allowable_weight",
                width: 200,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
            },
            {
                headerName: "Slab Rate Per Kg (Rs)",
                field: "per_kg_slab_rate",
                width: 200,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
            },
            {
                headerName: 'Freight Cost',
                field: "basic_trans_cost",
                width: 200,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        const { basic_trans_cost = 0 } = params.data
                        return basic_trans_cost.toFixed(2)
                        // if(params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined && params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined)
                        // {
                        //     var a = parseFloat(params.data.allowable_weight)*parseFloat(params.data.per_kg_slab_rate);
                        //     return a.toFixed(2);
                        // }
                        // else{
                        //     return 0;
                        // }                
                    } catch (error) {
                        console.error(error);
                        return 0;  // Return 0 in case of any errors
                    }
                }                
            },
            {
                headerName: "Destination Type",
                field: "destination_type",
                width: 160,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter:(params)=>{
                    const {destination_type = "DIRECT"} = params.data 
                    return destination_type
                }
            },  
            {
                headerName: 'Other Charges',
                field: "other_charges",
                width: 160,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        const { other_charges = 0 } = params.data
                        return parseFloat(parseFloat(other_charges).toFixed(2))                      
                    } catch (error) {
                        console.error("Error calculating value:", error);  // Log the error for debugging purposes
                        return "0.00";  // Return zero if an error occurs
                    }
                }                
            },             
            {
                headerName: 'Oda Charges',
                field: "oda_charges",
                width: 100,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        if (params.data.oda_charges !== "" && params.data.oda_charges !== undefined) {
                            // return parseFloat(params.data.sub_total).toFixed(2);
                            return params.data.oda_charges
                        }
                        else {
                            return 0;
                        }
                    } catch (error) {

                    }
                }
            },            
            {
                headerName: 'Unloading Charges',
                field: "unloading_charges",
                width: 100,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        if (params.data.unloading_charges !== "" && params.data.unloading_charges !== undefined) {
                            // return parseFloat(params.data.sub_total).toFixed(2);
                            return params.data.unloading_charges
                        }
                        else {
                            return 0;
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: 'Incentive Charges',
                field: "incentive_charges",
                width: 100,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        if (params.data.incentive_charges !== "" && params.data.incentive_charges !== undefined) {
                            // return parseFloat(params.data.sub_total).toFixed(2);
                            return params.data.incentive_charges
                        }
                        else {
                            return 0;
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: 'Crane Charges',
                field: "crane_charges",
                width: 100,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        if (params.data.crane_charges !== "" && params.data.crane_charges !== undefined) {
                            // return parseFloat(params.data.sub_total).toFixed(2);
                            return params.data.crane_charges
                        }
                        else {
                            return 0;
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: 'Odc Charges',
                field: "odc_charges",
                width: 100,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        if (params.data.odc_charges !== "" && params.data.odc_charges !== undefined) {
                            // return parseFloat(params.data.sub_total).toFixed(2);
                            return params.data.odc_charges
                        }
                        else {
                            return 0;
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: 'Detention Charges',
                field: "detention_charges",
                width: 100,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        if (params.data.detention_charges !== "" && params.data.detention_charges !== undefined) {
                            // return parseFloat(params.data.sub_total).toFixed(2);
                            return params.data.detention_charges
                        }
                        else {
                            return 0;
                        }
                    } catch (error) {

                    }
                }
            },        
            {
                headerName: "Agreed Transit Time (Days)",
                field: "TAT",
                width: 140,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (parmas) {
                    try {
                        if (parmas.data.TAT !== "" && parmas.data.TAT !== undefined && parmas.data.TAT !== null) {
                            // console.log("parmas.data.actual_tat",parmas.data.actual_tat)
                            return parmas.data.TAT
                        }

                        else {
                            return 0;
                        }
                    } catch (error) {

                    }
                }
            },
            // {
            //     headerName: 'ODA Time (Days)',
            //     field: "ODA_TAT",
            //     width: 100,
            //     // editable:fEdit,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            // },
            // {
            //     headerName: 'Holidays (Days)',
            //     field: "holiday_count",
            //     width: 150,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            //     valueGetter: function (params) {
            //         try {
            //             var s = (params.data.count_sunday !== "" && params.data.count_sunday !== undefined) ? params.data.count_sunday : 0;
            //             var h = (params.data.holiday_count !== "" && params.data.holiday_count !== undefined) ? params.data.holiday_count : 0;
            //             var d = parseInt(s) + parseInt(h);
            //             return d;
            //         } catch (error) {

            //         }
            //     }
            // },
            {
                headerName: "Planned Transit Time (Days)",
                field: "total_tat",
                width: 140,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (parmas) {
                    try {
                        if (parmas.data.total_tat !== "" && parmas.data.total_tat !== undefined && parmas.data.total_tat !== null) {
                            // console.log("parmas.data.total_tat",parmas.data.total_tat)
                            return parmas.data.total_tat

                        }

                        else {
                            return 0;
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "Actual Transit Time (Days)",
                 width: 140,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                field: "total_tat",
                autoSize: true,
                valueGetter:params=>{
                    try{
                        let gcDate 
                        let delDate 
                        if (params.data.gc_date != undefined && params.data.gc_date != "") {
                            gcDate = new Date(params.data.gc_date);
                        }
                        if (params.data.delivery_datetime != undefined && params.data.delivery_datetime != "") {
                            delDate= new Date(params.data.delivery_datetime)
                        }
                        var data =  calculateDateDiff(gcDate,delDate)
                        return data
                    }catch(e){
                        console.log("Error at Actual Transit Time (Days)" ,e)
                    }
                }
            },
            {
                headerName: "Delivery Status",
                field: "ontime_delayed_status",
                width: 130,
                filter: "agSetColumnFilter",
                cellRenderer: '',                
                headerClass: ["cellstylegridY"],
                cellClass: function (params) {                    
                    var transitDelay = params.data.transit_delay
                    if (transitDelay) {
                        // If the difference is greater than or equal to 1, it's delayed
                        if (transitDelay >= 1) {
                            return 'bgColorDangerLight'; // Apply red color for delay
                        } else {
                            return 'bgColorSuccessMedium'; // Apply success style if on time
                        }
                    }
                    return "bgColorSuccessMedium";  // Return empty if any of the dates are invalid
                },
                valueGetter: function (params) {
                    var transitDelay = params.data.transit_delay
                    if (transitDelay) {
                        // Return "Delayed" if the delay is greater than or equal to 1 day, otherwise "On Time"
                        return transitDelay >= 1 ? "Delayed" : "On Time";
                    }
                    return "On Time";  // Return empty if any of the dates are invalid
                }
            },
            {
                headerName: "Delay in Delivery (Days)",
                field: "",
                width: 130,                
                headerClass: ["cellstylegridY"],
                cellClass: function (params) {
                    const { transit_delay} = params.data;                    
                    if (transit_delay) { 
                        if (transit_delay >= 1) {
                            return 'bgColorDangerLight';  // Apply red color for delay
                        }            
                        return 'bgColorSuccessMedium';  // Apply green color if on time
                    }                    
                    return 'bgColorSuccessMedium';  // Return empty if either revised_eta or estimated_datetime is invalid
                },
                valueGetter: function (params) {
                    const { transit_delay} = params.data;            
                    if (transit_delay) {                                         
                        return transit_delay >= 1 ? transit_delay : 0; // Display delay in days if delay >= 1, otherwise return empty
                    }
                    return 0;  // Return empty if either of the dates are invalid
                }
            },
            {
                headerName: 'Total Freight',
                field: "total_freight",
                width: 140,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        const {
                            basic_trans_cost = 0,
                            oda_charges = 0,
                            unloading_charges = 0,
                            incentive_charges = 0,
                            crane_charges = 0,
                            odc_charges = 0,
                            detention_charges = 0,
                            other_charges = 0
                        } = params.data;
                        const getParseFloat = (cost) => parseFloat(cost) || 0;                     
                          const totalFreight =
                            getParseFloat(basic_trans_cost) +
                            getParseFloat(oda_charges) +
                            getParseFloat(unloading_charges) +
                            getParseFloat(incentive_charges) +
                            getParseFloat(crane_charges) +
                            getParseFloat(odc_charges) +
                            getParseFloat(detention_charges) +
                            getParseFloat(other_charges);                        
                            return parseFloat(totalFreight.toFixed(2));
                    } catch (error) {
                        console.error("Error calculating total amount with reduction:", error);
                        return 0; // Return 0 in case of an error
                    }
                }
            },
            {
                headerName: 'Damage Amount',
                field: "damage_amount",
                width: 100,
                editable: true,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
            },
            {
                headerName: 'Transshipment Charges',
                field: "transhipment_charges",
                width: 140,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        if (params.data.transhipment_charges !== "" && params.data.transhipment_charges !== undefined) {
                            // return parseFloat(params.data.sub_total).toFixed(2);
                            return params.data.transhipment_charges
                        }
                        else {
                            return 0;
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: 'Penalty Charges',
                field: "penalty_charges",
                width: 100,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        const { penalty_charges= 0 } = params.data;
                        return penalty_charges.toFixed(2)                   
                    } catch (error) {
                        console.error("Error in valueGetter:", error); // Log the error for debugging purposes
                        return "0.00"; // Return 0 if an error occurs
                    }
                }
            },
           
            // ];
            // if(localStorage.getItem("user_type") === "TRANSPORTER")
            // {
            //     transporterupdatescols.push(
            
            
        //  transporterupdatescols.push(

        //  transporterupdatescols.push(
           
            // {
            //     headerName: '',
            //     field: "other_charges_details",
            //     width: 160,
            //     cellRenderer: () => `<button class="btn btn-success" style="padding:0px 5px;font-size:12px"> View Charges</button>`,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            // },

            // {
            //     headerName: 'Penalty Amount For Day',
            //     field: "penalty_amount",
            //     width: 100,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.penalty_amount !== "" && params.data.penalty_amount !== undefined) {
            //                 // return parseFloat(params.data.sub_total).toFixed(2);
            //                 return params.data.penalty_amount
            //             }
            //             else {
            //                 return 0;
            //             }
            //         } catch (error) {

            //         }
            //     }
            // },
            // {
            //     headerName: 'Sub Total',
            //     field: "sub_total",
            //     width: 100,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.sub_total !== "" && params.data.sub_total !== undefined) {
            //                 return parseFloat(params.data.sub_total).toFixed(2);
            //             }
            //             else {
            //                 return 0;
            //             }
            //         } catch (error) {

            //         }
            //     }
            // },
            // {
            //     headerName: 'GST %',
            //     field: "gst",
            //     width: 100,
            //      editable:fEdit,
            //     cellClass:['cellstylegridY'],
            //     headerClass:["cellstylegridY"],	
            // },
            // {
            //     headerName: 'GST Amount',
            //     field: "gst_amount",
            //     width: 100,
            //     // editable:fEdit,
            //     cellClass:['cellstylegridY'],
            //     headerClass:["cellstylegridY"],	
            // },            
            {
                headerName: 'Fully Deducted',
                field: "reduce_amount",
                width: 140,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        const {
                            damage_amount = 0,
                            transhipment_charges = 0,
                            penalty_charges = 0
                        } = params.data;
                        const getParseFloat = (cost) => parseFloat(cost) || 0;
                        const totalReduce =
                            getParseFloat(damage_amount) +
                            getParseFloat(transhipment_charges) +
                            getParseFloat(penalty_charges);
                        return parseFloat(totalReduce.toFixed(2));
                    } catch (error) {
                        console.error("Error calculating total amount with reduction:", error);  // Log error
                        return "0.00";  // Return 0 as string in case of an error
                    }
                }                
            },
            {
                headerName: 'Total Amount',
                field: "total_amount",
                width: 100,
                // editable: true,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        const {
                            basic_trans_cost = 0,
                            oda_charges = 0,
                            unloading_charges = 0,
                            incentive_charges = 0,
                            crane_charges = 0,
                            odc_charges = 0,
                            detention_charges = 0,
                            other_charges = 0,
                            damage_amount = 0,
                            transhipment_charges = 0,
                            penalty_charges = 0,
                        } = params.data;
                        const getParseFloat = (cost) => parseFloat(cost) || 0;
                        const totalFreight =
                            getParseFloat(basic_trans_cost) +
                            getParseFloat(oda_charges) +
                            getParseFloat(unloading_charges) +
                            getParseFloat(incentive_charges) +
                            getParseFloat(crane_charges) +
                            getParseFloat(odc_charges) +
                            getParseFloat(detention_charges) +
                            getParseFloat(other_charges);
                        const totalReduce =
                            getParseFloat(damage_amount) +
                            getParseFloat(transhipment_charges) +
                            getParseFloat(penalty_charges);
                        const totalAmount = (totalFreight - totalReduce).toFixed(2);
                        return parseFloat(totalAmount);
                    } catch (error) {
                        console.error("Error calculating total amount:", error);
                        return 0; // Return 0 if an error occurs
                    }
                }
            },
            {
                headerName: 'Transporter Amount',
                field: "transporter_amount",
                width: 140,
                // editable: true,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        const { transporter_amount = 0 } = params.data
                        return parseFloat(transporter_amount.toFixed(2)) || 0
                        // if (params.data.allowable_weight !== "" && params.data.allowable_weight !== undefined && params.data.per_kg_slab_rate !== "" && params.data.per_kg_slab_rate !== undefined) {
                        //     var a = parseFloat(params.data.allowable_weight) * parseFloat(params.data.per_kg_slab_rate);
                        //     return a.toFixed(2);
                        // }
                        // else {
                        //     return 0;
                        // }
                    } catch (error) {
                        console.error("Error calculating total amount:", error);
                        return 0; // Return 0 if an error occurs
                    }
                }
            },           
            {
                headerName: 'Difference Amount',
                field: "difference_amount",
                width: 140,
                // editable: true,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                valueGetter: function (params) {
                    try {
                        // Transporter Amount - Freight Cost 
                        const { basic_trans_cost = 0 } = params.data
                        return basic_trans_cost - basic_trans_cost
                    } catch (error) {
                        console.error("Error calculating total amount:", error);
                        return 0; // Return 0 if an error occurs
                    }
                }
            },           
            //  transporterupdatescols.push(

            // {
            //     headerName: 'Delay',
            //     field: "delay_days",
            //     width: 100,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            // },
            // {
            //     headerName: 'LD',
            //     field: "ld_charges",
            //     width: 100,
            //     cellClass:['cellstylegridY'],
            //     headerClass:["cellstylegridY"],	
            // },
            // {
            //     headerName: 'Freight Payable',
            //     field: "freight_payable",
            //     width: 100,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            // },
            // {
            //     headerName: 'Chargable Weight',
            //     field: "chargable_weight",
            //     width: 200,
            //     editable: fEdit,
            //     cellClass: ['green'],
            //     headerClass: ["green"],
            // },
            // {
            //     headerName: 'Charged Weight Diff',
            //     field: "charged_weight_diff",
            //     width: 120,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            // },
            // {
            //     headerName: 'Freight Diff',
            //     field: "freight_diff",
            //     width: 100,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            // },
            // {
            //     headerName: 'ODA Diff',
            //     field: "oda_diff",
            //     width: 100,
            //     cellClass:['cellstylegridY'],
            //     headerClass:["cellstylegridY"],	
            // },
            // {
            //     headerName: 'Rate Diff',
            //     field: "rate_diff",
            //     width: 100,
            //     cellClass:['cellstylegridY'],
            //     headerClass:["cellstylegridY"],	
            // },
            // {
            //     headerName: "Update Charges",
            //     field: "update_charges",
            //     width: 180,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            //     cellRendererSelector: function (params) {
            //         let {courier_type =""} = params.data
            //         if(courier_type=="courier"){
            //             return ""
            //         }
            //         return {
            //             component: "UpdateTptChargesBtn"
            //         }
            //     }
            // },
            {
                headerName: "Update Charges",
                field: "update_charges",
                width: 180,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                cellRenderer: (params) => {
                    let {courier_type =""} = params.data
                    if(courier_type=="courier"){
                        return ""
                    }
                    // Return the HTML button with proper class and icon
                    return `
                        <button class="btn btn-warning nopadding font12" title="Update Charges">
                            <i class="icofont icofont-edit f16"></i>&nbsp;Update Charges
                        </button>
                    `;
                }
            },            
            // {
            //     headerName: "",
            //     field: "",
            //     width: 150,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            //     cellRendererSelector: function (params) {
            //         return {
            //             component: "ViewChargesDocs"
            //         }
            //     }
            // },
            {
                headerName: "View Charges",
                field: "view_charges",
                width: 150,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                cellRenderer: (params) => {
                    // Return the button as a string of HTML
                    return `
                        <button class="btn btn-success nopadding font12" title="View Charges">
                            <i class="icofont icofont-eye f16"></i>&nbsp;View Charges
                        </button>
                    `;
                }
            },            
            // {
            //     headerName: "",
            //     field: "_id",
            //     width: 150,
            //     cellClass: ['cellstylegridY'],
            //     headerClass: ["cellstylegridY"],
            //     cellRendererSelector: function (params) {
            //         return {
            //             component: "ViewDamageItem"
            //         }
            //     }
            // },
            {
                headerName: "With Defect",
                field: "with_defect",
                width: 150,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                cellRenderer: (params) => {
                    // Return the HTML for the button
                    return `
                        <button class="btn btn-info nopadding font12" title="With Defect">
                            <i class="icofont icofont-eye f16"></i>&nbsp;With Defect
                        </button>
                    `;
                }
            },            
            {
                headerName: "View Damages ",
                field: "damagetype",
                width: 180,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],

            },
            {
                headerName: 'Transporter Remarks',
                field: "transporter_remarks",
                width: 200,
                editable: fEdit,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
            },
            {
                headerName: 'Customer Remarks',
                field: "customer_remarks",
                width: 200,
                editable: true,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
            },
            {
                headerName: 'Penalty Remarks',
                field: "penalty_remarks",
                width: 140,
                editable:true,
                cellClass:['cellstylegridY'],
                headerClass:["cellstylegridY"],
                valueGetter:(params)=>{
                    let {transit_delay} = params.data
                    if(transit_delay && transit_delay>=5){
                        return "Delay in Delivery"
                    }
                }
            },
            {
                headerName: '',
                field: "save_draft",
                width: 160,
                cellRenderer: () => `<button class="btn btn-warning" style="padding:0px 5px;font-size:12px"> Save as Draft</button>`,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
            },

            {
                headerName: 'Remarks',
                field: "remarks",
                width: 100,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                cellRenderer: () => `<button class="btn btn-info" style="padding:0px 5px;font-size:12px">View Remarks</button>`
            },
            // )
        ];
        transporterupdatescols.unshift({
            headerName: Constants.TRIP_PLANT_CODE,
            field: "consigner_code",
            width: 120,
            cellClass: ['green'],
            headerClass: ["cellstylegridGBg"],
            headerCheckboxSelection: true,
            checkboxSelection: checkSelect
        },
            {
                headerName: Constants.TRIP_PLANT_NAME,
                field: "consigner_name",
                width: 100,
                cellClass: ['green'],
                headerClass: ["cellstylegridGBg"],
            },
            {
                headerName: Constants.TRIP_CONSIGN_NO,
                field: "consignment_code",
                width: 160,
                cellClass: ['green'],
                headerClass: ["cellstylegridGBg"],
            },
            {
                headerName: Constants.TRIP_TRANSPORTER_CODE,
                field: "transporter_code",
                width: 180,
                cellClass: ['green'],
                headerClass: ["cellstylegridGBg"],
            },
            {
                headerName: Constants.TRIP_TRANSPORTER_NAME,
                field: "service_provider",
                width: 180,
                cellClass: ['green'],
                headerClass: ["cellstylegridGBg"],
            },
            {
                headerName: Constants.GC_WAY_BILL_NO,
                field: "gc_waybill_no",
                width: 120,
                cellClass: ['cellstylegridY'],
                headerClass: ["cellstylegridY"],
                // checkboxSelection: checkSelect
            },
            {
                headerName: Constants.GC_DATE,
                field: "gc_date",
                width: 120,
                cellClass: ['green'],
                headerClass: ["cellstylegridGBg"],
                valueGetter: function (params) {
                    try {
                        if (params.data.gc_date != undefined && params.data.gc_date != "") {
                            return getHyphenDDMMMYYYYHHMM(params.data.gc_date);
                        }
                    }
                    catch (e) { }
                }
                // checkboxSelection: checkSelect
            })
    
            
        let pendingWithLsp = [
            {
                headerName: 'Transporter Updates',
                headerClass:["cellstylegridY","cellTopHeader"],	
                children:transporterupdatescols
            }
        ]
        let customer_def = [
            {
                headerName: "Allowable Weight(Kgs)",
                field: "allowable_weight",
                width: 100
            },
            {
                headerName: 'Per Kg Slab Freight',
                field: "per_kg_slab_rate",
                width: 100
            },
            {
                headerName: 'Basic Trans Costs',
                field: "basic_trans_const",
                width: 100
            },
            {
                headerName: 'Other Costs',
                field: "other_const",
                width: 100
            },
            {
                headerName: 'Min Basic Trans Costs',
                field: "min_basic_trans_const",
                width: 100
            },
            {
                headerName: 'ODA Charges',
                field: "min_basic_trans_const",
                width: 100
            },
            {
                headerName: 'Net Value',
                field: "min_basic_trans_const",
                width: 100
            },
            {
                headerName: 'TAT',
                field: "TAT",
                width: 100
            },
            {
                headerName: 'ODA TAT',
                field: "ODA_TAT",
                width: 100
            },
            {
                headerName: 'GRACE',
                field: "grace_count",
                width: 100
            },
            {
                headerName: 'ADD Holiday',
                field: "add_holiday",
                width: 100
            },
            {
                headerName: "Total Transit Time (Days)",
                field: "total_tat",
                width: 100
            },
            {
                headerName: "Agreed Transit Time (Days)",
                field: "TAT",
                width: 100
            },
            {
                headerName: 'Delay',
                field: "delay",
                width: 100
            },
            {
                headerName: 'LD',
                field: "ld",
                width: 100
            },
            {
                headerName: 'Freight Payable',
                field: "freight_payable",
                width: 100
            },
            {
                headerName: 'Charged Weight Diff',
                field: "charged_weight_diff",
                width: 100
            },
            {
                headerName: 'Freight Diff',
                field: "freight_diff",
                width: 100
            },
            {
                headerName: 'ODA Diff',
                field: "oda_diff",
                width: 100
            },
            {
                headerName: 'Rate Diff',
                field: "rate_diff",
                width: 100
            },
            // {
            //     headerName: 'Remarks',
            //     field: "rate_diff",
            //     width: 100
            // },
        ]
        if (this.state.actiontype === 'liable') {
            var columnInitDefs = columnDefs;
        }
        else if (this.state.actiontype === 'pendingwithlsp') {
            columnInitDefs = [ ...pendingWithLsp,...columnDefs];
        }
        else {
            var columnInitDefs = columnBatchDefs;
        }
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row mb-1">
                    <div className='card col-xl-12 col-md-12 beffect'>
                        <div className='card-body'>
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                {(localStorage.getItem("user_type") === "TRANSPORTER")?
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('total')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> Total</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={this.state.pendingwithlspCnt.length
                                            +this.state.counterpendinv+this.state.counterpendfin} /></span></h4>
                                        </div>
                                    </div>
                                :""}
                                {(localStorage.getItem("user_type") === "CUSTOMER")?
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('total')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> Total</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={this.state.counterpendcust+this.state.counterpendfinance+this.state.counterpendclose} /></span></h4>
                                        </div>
                                    </div>
                                :""}
                                 {(localStorage.getItem("user_type") === "TECHUSER")?
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('total')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> Total</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={this.state.pendingwithlspCnt.length+this.state.counterpendcust+
                                            +this.state.counterpendfinance+this.state.counterpendclose} /></span></h4>
                                        </div>
                                    </div>
                                :""}
                                    {/* <div className="col cursorPointer" onClick={() => this.setCounterData('liability')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-cubes f24 greenfont"></i><br /> Liability</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={this.state.liabilityCnt.length} /></span></h4>
                                        </div>
                                    </div> */}
                                    {(localStorage.getItem("user_type") === "TRANSPORTER" || localStorage.getItem("user_type") === "TECHUSER")?
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('pendingwithlsp')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-truck f24 greenfont"></i><br /> Pending With Transporter</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={this.state.pendingwithlspCnt.length} /></span></h4>
                                        </div>
                                    </div>
                                    :""}
                                    {(localStorage.getItem("user_type") === "CUSTOMER" || localStorage.getItem("user_type") === "TECHUSER")?
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('pendingwithcustomer')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-users f24 greenfont"></i><br /> Pending with Warehouse</span>
                                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={this.state.counterpendcust} /></span></h4>
                                        </div>
                                    </div>
                                    :""}
                                    {(localStorage.getItem("user_type") === "TRANSPORTER")?    
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('invstatus')}>  
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-users f24 greenfont"></i><br />Invoice Status</span>
                                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={this.state.counterpendinv} /></span></h4>
                                        </div>     
                                    </div>
                                    :""}   
                                    {/* {(localStorage.getItem("user_type") === "TRANSPORTER")?   
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('pendfinapp')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-users f24 greenfont"></i><br /> Pending For Final Approval</span>
                                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={this.state.counterpendfin} /></span></h4>
                                        </div>
                                    </div>
                                    :""} */}
                                    {/* {(localStorage.getItem("user_type") === "CUSTOMER" || localStorage.getItem("user_type") === "TECHUSER")?     
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('submittedtofin')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-braille f24 greenfont"></i><br /> Pending With Payment</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.counterpendfinance} /></span></h4>
                                        </div>
                                    </div>
                                    :""} */}
                                    {(localStorage.getItem("user_type") === "CUSTOMER" || localStorage.getItem("user_type") === "TECHUSER" || localStorage.getItem("user_type") === "TRANSPORTER")?     
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('submittedtofin')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-solid fa-hourglass-half f24 greenfont"></i><br /> Pending With Payment</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={this.state.counterpendfinance} /></span></h4>
                                        </div>
                                    </div>
                                    :""}
                                    {(localStorage.getItem("user_type") === "CUSTOMER" || localStorage.getItem("user_type") === "TECHUSER" || localStorage.getItem("user_type") === "TRANSPORTER")?
                                    <div className="col cursorDefault" onClick={() => this.setCounterData('paymentclose')}>
                                        <div className=" cirlce-d">
                                            <span className="sptile"><i className="fa fa-check f24 greenfont"></i><br /> Payment Close</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={this.state.counterpendclose} /></span></h4>
                                        </div>
                                    </div>
                                    :""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <div className="row">
                    <div className=" col-xl-12 col-md-12 beffect" style={{ padding: "0" }}>
                        <div className="card">
                            <div className="card-header d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="col-xl-12 h3 mb-0 text-gray-800">Billing Verification
                                    {this.state.isuploadcsv == 1 ?
                                        <a href={"/upload-trips"} className="float-right btn btn-warning">Upload Trips</a>
                                        : ""}
                                </h1>
                            </div>
                            <div className="card-body h-100 py-2">
                                <div className="row mb-30p" >
                                    {/* <div className="col-xl-4 col-lg-4 form-group">
                                        <div className="row">
                                            <label className="col-sm-6">Select Month To Get Monthly Data</label>
                                            <div className="col-sm-5">
                                                <Datetime
                                                    value={this.state.startDate}
                                                    disableCloseOnClickOutside={false}
                                                    closeOnSelect={true}
                                                    inputProps={{ placeholder: 'From', name: 'start_date', autoComplete: 'off', readOnly: true }}
                                                    dateFormat="YYYY-MM"
                                                    name="start_date"
                                                    onChange={this.handlerStartDateTime} />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row" style={{ width: "100%" }}>
                                        <div className="form-group col-xl-3 col-lg-3">
                                            <label>GC Start Date: </label>
                                            <Datetime
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true, value: this.state.from_date }}
                                                dateFormat="YYYY-MM-DD"
                                                name="fromDate"
                                                onChange={this.handlerStartDeliveryDateTime}
                                            />
                                        </div>
                                        <div className="form-group col-xl-3 col-lg-3">
                                            <label>GC End Date: </label>
                                            <Datetime
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                inputProps={{ placeholder: 'To Date', name: 'toDate', autoComplete: 'off', readOnly: true, value: this.state.to_date }}
                                                dateFormat="YYYY-MM-DD"
                                                name="toDate"
                                                onChange={this.handlerEndDeliveryDateTime}
                                            />
                                        </div>
                                        <div className="form-group col-xl-3 col-lg-3">
                                            <label>Select Consigner </label>
                                            <Select
                                                placeholder={"All"}
                                                closeMenuOnSelect={false}
                                                value={this.state.consigner}
                                                isMulti="true" className="border-radius-0"
                                                onChange={this.changeConsignerArrayHandler.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.selectConsignerOptionsItems()}
                                                required
                                            />
                                        </div>
                                        {localStorage.getItem("user_type") != "TRANSPORTER" ?
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Transporter   </label>
                                                <Select
                                                    className="border-radius-0"
                                                    isMulti={false}
                                                    closeMenuOnSelect={true}
                                                    id="selectedTransporter"
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.flTransporter}
                                                    onChange={this.newFilter.bind(this, "flTransporter")}
                                                    options={this.state.filterTransporters}
                                                />
                                            </div>
                                            : ""}
                                        {(this.state.invoicewithstatus && this.state.invoicewithstatus == "btn-danger") ?
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Invoice No </label>
                                                <Select
                                                    className="border-radius-0"
                                                    isMulti={false}
                                                    closeMenuOnSelect={true}
                                                    id="selectedTransporter"
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.invoiceNo}
                                                    onChange={this.newFilter.bind(this, "invoiceNo")}
                                                    options={this.state.invoiceOptions}
                                                />
                                            </div>
                                            : ""}
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label className="col-sm-10">Shipping Type</label>
                                            <div className="col-sm-10">
                                                <Select
                                                    value={this.state.shipType}
                                                    name="shipType"
                                                    options={[
                                                        { value: "All", label: "All" },
                                                        { value: "05", label: "Courier by Surface" },
                                                        { value: "07", label: "Courier by Air" },
                                                        { value: "22", label: "Courier by Train" },
                                                        { value: "PTL", label: "Part truck Load" },
                                                        { value: "FTL", label: "Full truck Load" },
                                                        { value: "86", label: "Adjustment Load" },
                                                        { value: "74", label: "Multi Point Delivery" },
                                                        { value: "ODC", label: "Over Dimensional Cargo" },
                                                        { value: "HD", label: "Hand Delivery" }
                                                    ]}
                                                    onChange={this.onChangeShipType.bind(this)} />
                                            </div>
                                        </div>

                                        <div className="form-group col-xl-3 col-lg-3"   >
                                            <button className="btn btn-success mt-30p" type="submit" onClick={this.submitShipmentType.bind(this)}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                                {/* <button onClick={this.showTabView.bind(this, 1)} type="button" className={"btn " + (this.state.liable)}>Liability</button> */}
                                {(localStorage.getItem("user_type") === "TRANSPORTER") ?

                                    <div className='button-group mb-20p'>
                                        <button onClick={this.showTabView.bind(this, 2)} type="button" className={"btn " + (this.state.pendingwithlsp)}>Pending With Transporter</button>
                                        <button onClick={this.showTabView.bind(this, 3)} type="button" className={"btn " + (this.state.invoicewithstatus)}>Invoice Status</button>
                                        {/* <button onClick={this.showTabView.bind(this, 4)} type="button" className={"btn " + (this.state.pendingwithfinapp)}>Pending For Final Approval</button> */}
                                        <button onClick={this.showTabView.bind(this, 5)} type="button" className={"btn " + (this.state.pendingwithfinance)}>Pending With Payment</button>
                                        <button onClick={this.showTabView.bind(this, 6)} type="button" className={"btn " + (this.state.paywithstat)}>Payment Close</button>
                                    </div>
                                    : ""}
                                {(localStorage.getItem("user_type") === "CUSTOMER") ?

                                    <div className='button-group mb-20p'>
                                        <button onClick={this.showTabView.bind(this, 3)} type="button" className={"btn " + (this.state.pendingwithcust)}>Pending with Warehouse</button>
                                        {/* <button onClick={this.showTabView.bind(this, 5)} type="button" className={"btn " + (this.state.pendingwithfinance)}>Pending With Payment</button> */}
                                        <button onClick={this.showTabView.bind(this, 6)} type="button" className={"btn " + (this.state.paywithstat)}>Payment Close</button>
                                    </div>
                                    : ""}
                                {(localStorage.getItem("user_type") !== "CUSTOMER" && localStorage.getItem("user_type") !== "TRANSPORTER") ?
                                    <div className='button-group mb-20p'>
                                        <button onClick={this.showTabView.bind(this, 2)} type="button" className={"btn " + (this.state.pendingwithlsp)}>Pending With Transporter</button>
                                        <button onClick={this.showTabView.bind(this, 3)} type="button" className={"btn " + (this.state.pendingwithcust)}>Pending with Warehouse</button>
                                        <button onClick={this.showTabView.bind(this, 5)} type="button" className={"btn " + (this.state.pendingwithfinance)}>Pending With Payment</button>
                                        <button onClick={this.showTabView.bind(this, 6)} type="button" className={"btn " + (this.state.paywithstat)}>Payment Close</button>
                                    </div>
                                    : ""}
                                {this.state.liable === "btn-danger" ?
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <button className="float-right btn btn-warning" onClick={this.createOtherManualShipment}>
                                                create a shipment
                                            </button>
                                            <button className="float-right btn btn-info" onClick={this.setBulkUploadEpod}>
                                                bulk upload Pod
                                            </button>
                                        </div>
                                    </div> : ""}
                                <div className={"gLoader " + (this.state.gLoad)}>
                                    <img src={require("../../assets/images/loading.gif")} />
                                </div>

                                <div style={{ width: "100%", height: "70vh" }} className="ag-theme-balham">

                                    <AgGridReact
                                        modules={this.state.modules}
                                        rowData={this.state.rowData}
                                        columnDefs={columnInitDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        enableRangeSelection={true}
                                        enableCharts={true}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        detailRowAutoHeight={true}
                                        // detailGridOptions={this.state.detailGridOptions}
                                        detailRowHeight={400}
                                        masterDetail={true}
                                        onGridReady={this.onGridReady}
                                        sideBar={this.state.sideBar}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        headerHeight={50}
                                        // gridOptions={this.state.gridOptions}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        rowSelection={this.state.rowSelection}
                                        onCellClicked={this.onCellClickedFunc}
                                        onCellDoubleClicked={this.onCellDoubleClickedFunc}
                                        onCellEditingStopped={this.onEditStopUpdateData.bind(this)}
                                        suppressRowClickSelection={true}
                                    />
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-12" style={{ textAlign: "right" }}>
                                        {(localStorage.getItem("user_type") === "TRANSPORTER") ?
                                            (this.state.actiontype === 'pendingwithlsp') ?
                                                <button type="button" className="btn btn-primary btncm" onClick={this.onClickSendForApprovalToCustomer.bind(this)}>Send For Approval</button>
                                                : ""
                                            : ""
                                        }

                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-12" style={{ textAlign: "right" }}>
                                        {(this.state.actiontype === 'pendingwithcust') ?
                                            (localStorage.getItem("user_type") === "CUSTOMER") ?
                                                <button type="button" className="btn btn-primary btncm" onClick={this.onClickSendBack.bind(this)}>Send Back</button>
                                                : "" : ""}
                                        {(this.state.actiontype === 'pendingwithcust') ?
                                            (localStorage.getItem("user_type") === "CUSTOMER") ?
                                                <button type="button" className="btn btn-success btncm" onClick={this.onClickApprove.bind(this)} >Approve</button>
                                                : "" : ""}
                                        {(this.state.actiontype === 'pendingwithcust') ?
                                            (localStorage.getItem("user_type") === "CUSTOMER") ?
                                                <button type="button" className="btn btn-danger btncm" onClick={this.onClickReject.bind(this)} >Reject</button>
                                                : "" : ""}
                                    </div>
                                </div>

                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12" style={{ textAlign: "right" }}>
                                    {(this.state.actiontype === 'pendingwithfinance') ?
                                        (localStorage.getItem("user_type") !== "CUSTOMER" && localStorage.getItem("user_type") !== "TRANSPORTER") ?
                                            <button type="button" className="btn btn-success btncm" onClick={this.onClickFinApprove.bind(this)} >Approve</button>
                                            : "" : ""}
                                    {(this.state.actiontype === 'pendingwithfinance') ?
                                        (localStorage.getItem("user_type") !== "CUSTOMER" && localStorage.getItem("user_type") !== "TRANSPORTER") ?
                                            <button type="button" className="btn btn-danger btncm" onClick={this.onClickFinReject.bind(this)} >Reject</button>
                                            : "" : ""}

                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12" style={{ textAlign: "right" }}>
                                    {(this.state.actiontype === 'pendingwithfinapp') ?
                                        (localStorage.getItem("user_type") === "TRANSPORTER") ?
                                            <button type="button" className="btn btn-success btncm" onClick={this.onClickTransFinApprove.bind(this)} >Approve</button>
                                            : "" : ""}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* supporting doc uploads  */}
                    <div className={"slide-r " + (this.state.sliderSupportshow)}>
                        <div className="slide-r-title">
                            <h4>Upload POD</h4>
                        </div>
                        <div className="col-sm-12">
                            <div className="slide-r-body">
                                <form className="theme-form" method="POST" id="uplodTpt" enctype="multipart/form-data" onSubmit={this.uploadSpecialDocument}>
                                    <div className="row p-20p">
                                        <h4>{this.state.supportDocTitle}</h4>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Upload Document<span className="rclr fbold">*</span></label>
                                            <input type="file" name="podDoc" id="podDoc" className="form-control" onChange={this.changeSupportFileHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success">Uplaod</button>
                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* shipment creation slider */}
                    <div className={"slide-r " + (this.state.shipment_slider)}>
                        <div className="slide-r-title">
                            <h4>Create Shipment</h4>
                        </div>
                        <div className="col-sm-12">
                            <div className="slide-r-body">
                                <form className="theme-form" method="POST" enctype="multipart/form-data" onSubmit={this.uploadShipMentData}>
                                    <div className="row p-20p">
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Consigner Code<span className="rclr fbold">*</span></label>
                                            <input type="text" name="consigner_code" id="consigner_code" className="form-control" onChange={this.changeHandler} autoComplete='Off' required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Truck No<span className="rclr fbold">*</span></label>
                                            <input type="text" name="truck_no" id="truck_no" className="form-control" onChange={this.changeHandler} autoComplete='Off' required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>GC Way Bill No<span className="rclr fbold">*</span></label>
                                            <input type="text" name="gc_waybill_no" id="gc_waybill_no" className="form-control" onChange={this.changeHandler} autoComplete='Off' required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Consignment No<span className="rclr fbold">*</span></label>
                                            <input type="text" name="consignment_code" id="consignment_code" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>GC Date<span className="rclr fbold">*</span></label>
                                            <Datetime
                                                inputProps={{ placeholder: 'GC Date', name: 'gc_date', autoComplete: 'on' }}
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat="HH:mm"
                                                name="gc_date"
                                                onChange={this.changeDateTimeGCValue.bind(this)}
                                            />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Shipment Completion Date<span className="rclr fbold">*</span></label>
                                            <Datetime
                                                inputProps={{ placeholder: 'Shipment Completion Date', name: 'shipment_completion_date', autoComplete: 'off' }}
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat="HH:mm"
                                                name="shipment_completion_date"
                                                onChange={this.changeDateTimeSCompletion.bind(this)}
                                            />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Shipment Type<span className="rclr fbold">*</span></label>
                                            <input type="text" name="shipping_type" id="shipping_type" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Transporter Name<span className="rclr fbold">*</span></label>
                                            <input type="text" name="transporter_name" id="transporter_name" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Transporter Code<span className="rclr fbold">*</span></label>
                                            <input type="text" name="transporter_code" id="transporter_code" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        {/* <div className="form-group col-sm-12 mb-20p">
                                                <label>Transporter Code<span className="rclr fbold">*</span></label>
                                                <input type="text" name="transporter_code" id="transporter_code" className="form-control" onChange={this.changeHandler} required />
                                            </div> */}
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Consignee Code<span className="rclr fbold">*</span></label>
                                            <input type="text" name="consignee_code" id="consignee_code" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Consignee Name<span className="rclr fbold">*</span></label>
                                            <input type="text" name="consignee_name" id="consignee_name" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Consignee City<span className="rclr fbold">*</span></label>
                                            <input type="text" name="consignee_city" id="consignee_city" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Consignee State<span className="rclr fbold">*</span></label>
                                            <input type="text" name="consignee_state" id="consignee_state" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Consignee zone<span className="rclr fbold">*</span></label>
                                            <input type="text" name="consignee_region" id="consignee_region" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Consignee PinCode<span className="rclr fbold">*</span></label>
                                            <input type="text" name="consignee_pincode" id="consignee_pincode" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Allowable Weight<span className="rclr fbold">*</span></label>
                                            <input type="text" name="allowable_weight" id="allowable_weight" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Per Kg Slab Rate<span className="rclr fbold">*</span></label>
                                            <input type="text" name="per_kg_slab_rate" id="per_kg_slab_rate" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label>Basic Trans Cost<span className="rclr fbold">*</span></label>
                                            <input type="text" name="basic_trans_cost" id="basic_trans_cost" className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p text-center">
                                            <button type="submit" className="btn btn-success">Update</button>
                                            <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className={"overlay-block " + (this.state.overlay)} onClick={this.hideSlideBlock.bind(this)}></div> */}
                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                {/*sliderBlock2 ,crd-bg p-10p, slide-body, theme  */}
                <div className={"slide-r " + (this.state.slidershow)}>
                    <div className="slide-r-title">
                        <h4>Upload POD</h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" enctype="multipart/form-data" onSubmit={this.uploadDocument}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Delivered Date<span className="rclr fbold">*</span></label>
                                        {/* <input type="text" name="delivered_date" id="delivered_date" value={this.state.delivered_date} className="form-control" onChange={this.changeHandler} required /> */}
                                        <Datetime
                                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off',readOnly:true }}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat="HH:mm"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="startDate"
                                            onChange={this.changeDateTimeValue.bind(this)}
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Upload Document<span className="rclr fbold">*</span></label>
                                        <input type="file" name="podDoc" id="podDoc" className="form-control" onChange={this.changeFileHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Upload</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* multiple bulk uploads of pods */}
                <div className={"slide-r " + (this.state.bulkslidershow)}>
                    <div className="slide-r-title">
                        <h4>Upload Bulk POD
                            <span
                                className="float-right closebtn"
                                style={{ marginRight: "25px" }}
                                onClick={this.onCloseSliderDiv}
                            >
                                X
                            </span>
                        </h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" enctype="multipart/form-data" onSubmit={this.uploadDocument}>
                                <div className="row p-20p">
                                    {/* <div className="form-group col-sm-12 mb-20p">
                                        <label>Delivered Date<span className="rclr fbold">*</span></label>
                                        <Datetime
                                            inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' }}
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat="HH:mm"
                                            name="startDate"
                                            onChange={this.changeDateTimeValue.bind(this)}
                                        />
                                    </div> */}
                                    <p className='pl-3' style={{ color: 'red' }}> please rename the file with gc_waybill_no deliverydate before uploading e.g. gwayno_28-02-2023</p>
                                    <p className='pl-3' style={{ color: 'red' }}> if gc_waybill_no mentioned in file name matches with row gc_waybill_no, then it is uploaded by mapping the file to that row.</p>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Upload Document<span className="rclr fbold">*</span></label>
                                        <input type="file" multiple name="podDoc" id="podDoc" className="form-control" onChange={this.changeBulkFileHandler} required />
                                    </div>
                                    {multiplePodDocs.length > 0 ?
                                        <>
                                            <ul>
                                                {multiplePodDocs.map((e, i) => (
                                                    <li key={i} className="p2">{i} .{e.name}</li>
                                                ))}
                                            </ul>
                                            <div className="form-group col-sm-12 mb-20p text-center">
                                                <button type="submit" className="btn btn-success">Upload</button>
                                                <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                            </div>
                                        </> : ""
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r  "+(this.state.sliderInvoiceshow)}>
                    <div className="slide-r-title">
                        <h4>Invoice Data
                            <span
                                className="float-right closebtn"
                                style={{ marginRight: "25px" }}
                                onClick={this.onCloseSliderDiv}
                            >
                                X
                            </span>
                        </h4>
                    </div>
                    <div className="col-sm-12">
                    
                        <div className="slide-r-body">
                            <form className="theme" id="invForm" method="POST" enctype="multipart/form-data" onSubmit={this.onSubmitUploadInvoiceAndSendForApproval.bind(this)}>
                                <div className="row p-20p">
                                    
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label> Invoice No<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="invoice_no" id="invoice_no" value={this.state.invoice_no} className="form-control" onChange={this.changeHandler} autoComplete='Off' required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Invoice Date<span className="rclr fbold">*</span></label>                                        
                                        {/* <input type="text" name="delivered_date" id="delivered_date" value={this.state.delivered_date} className="form-control" onChange={this.changeHandler} required /> */}
                                        <Datetime 
                                        inputProps={{ placeholder:'Invoice Date', name: 'invoice_date', id: 'invoice_date', autoComplete:'off' ,readOnly: true,required: true}} 
                                        closeOnSelect={true}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        // timeFormat="HH:mm"  
                                        name="invoice_date"
                                        value={this.state.invoice_date} 
                                        onChange={this.changeInvDateTimeValue.bind(this)}   />
                                       
                                    </div>
                                    
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label> Freight Amount<span className="rclr fbold">*</span></label>                                        
                                        <input type="number" name="invoice_freight_amount" id="invoice_freight_amount" value={this.state.invoice_freight_amount} className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                    </div>
                                    
                                    {/* <div className="form-group col-sm-12 mb-20p">
                                        <label> GST Amount<span className="rclr fbold">*</span></label>                                        
                                        <input type="text" name="invoice_gst_amount" id="invoice_gst_amount" value={this.state.invoice_gst_amount} className="form-control" autoComplete='Off' onChange={this.changeHandler} required />
                                    </div> */}
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Upload Invoice<span className="rclr fbold">*</span></label>
                                        <input type="file" name="invDoc" id="invDoc" className="form-control" onChange={this.changeInvFileHandler} required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Uplaod</button>
                                        <button type="button" className="btn btn-default" onClick={this.resetUpload.bind(this)}>CANCEL</button>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
                {/* Send Back Remarks */}
                <div className={"slide-r " + (this.state.custsliderSendBackRemarks)}>
                    <div className="slide-r-title">
                        <h4>Remarks</h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="sendBackForm" enctype="multipart/form-data" onSubmit={this.onClickSendBackForm.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>
                                        <textarea name="custsendBackRemarks" id="custsendBackRemarks" className="form-control" onChange={this.changeHandler} value={this.state.custsendBackRemarks} required></textarea>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                {/* Customer Approve Remarks */}
                <div className={"slide-r " + (this.state.sliderApproveRemarks)}>
                    <div className="slide-r-title">
                        <h4>Remarks</h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="custAprForm" enctype="multipart/form-data" onSubmit={this.onClickCustApproveRemarksForm.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>
                                        <textarea name="custApproveRemarks" id="custApproveRemarks" className="form-control" onChange={this.changeHandler} value={this.state.custApproveRemarks} required></textarea>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* Customer Reject Remarks */}
                <div className={"slide-r " + (this.state.sliderRejectRemarks)}>
                    <div className="slide-r-title">
                        <h4>Remarks</h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="custRejForm" enctype="multipart/form-data" onSubmit={this.onClickCustRejectRemarksForm.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>
                                        <textarea name="custRejectRemarks" id="custRejectRemarks" className="form-control" onChange={this.changeHandler} value={this.state.custRejectRemarks} required></textarea>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* Finance Approve Remarks */}
                <div className={"slide-r " + (this.state.sliderFinApproveRemarks)}>
                    <div className="slide-r-title">
                        <h4>Remarks</h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="finAprForm" enctype="multipart/form-data" onSubmit={this.onClickFinApproveRemarksForm.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>
                                        <textarea name="finApproveRemarks" id="finApproveRemarks" className="form-control" onChange={this.changeHandler} value={this.state.finApproveRemarks} required></textarea>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* Finance Reject Remarks */}
                <div className={"slide-r " + (this.state.sliderFinRejectRemarks)}>
                    <div className="slide-r-title">
                        <h4>Remarks</h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="finRejForm" enctype="multipart/form-data" onSubmit={this.onClickFinRejectRemarksForm.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>
                                        <textarea name="custFinRejectRemarks" id="custFinRejectRemarks" className="form-control" onChange={this.changeHandler} value={this.state.custFinRejectRemarks} required></textarea>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                 {/* Transporter Approve Remarks */}
                <div className={"slide-r " + (this.state.sliderTransFinApproveRemarks)}>
                    <div className="slide-r-title">
                        <h4>Remarks</h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="finAprForm" enctype="multipart/form-data" onSubmit={this.onClickTransFinApproveRemarksForm.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label>Remarks<span className="rclr fbold">*</span></label>
                                        <textarea name="transFinApproveRemarks" id="transFinApproveRemarks" className="form-control" onChange={this.changeHandler} value={this.state.transFinApproveRemarks} required></textarea>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>  
                
                <div className={"slide-r " + (this.state.sliderViewRemarks)}>
                    <div className="slide-r-title">
                        <h4>View Remarks</h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.remarksRowNode.length > 0?
                                        (this.state.remarksRowNode.map(item=>
                                            <tr>
                                                <td>{item.remark_status}</td>
                                                <td>{item.remarks_desc}</td>
                                            </tr>        
                                    ))
                                    :""
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
                <div className={"slide-r " + (this.state.damageitemlist)}>
                    <div className="slide-r-title">
                        <h4>Damage List
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>
                        </h4>
                       
                    </div>

                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="damageitemlist" enctype="multipart/form-data" onSubmit={this.onClickDamageList.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                            <label className="col-sm-12 mb-20p">Damage list</label>
                                            <div className="col-sm-12">
                                                <Select
                                                value={this.state.damagetype}
                                                name="damagetype"
                                                options={[
                                                    {value:"Damage Item",label:"Damage Item"},
                                                    {value:"Shortage",label:"Shortage"},
                                                    {value:"Damage Lost",label:" Damage Lost"},
                                                ]}
                                                onChange={this.onChangeDamageType.bind(this)}
                                                  placeholder="Select damage type"
                                                />
                                            </div>
                                        </div>
                                    <div className="form-group col-sm-12 mb-20p">
                                        <div className="col-sm-12">
                                            <label>amount<span className="rclr fbold">*</span></label>
                                            <textarea style={{ height: "50px"}} className="form-control" name="damage_amount" id="damage_amount" value={this.state.damage_amount} onChange={this.changeHandler} rows="8"></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                        <button type="button" className="btn btn-default" onClick={this.hideSlideBlock}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.showUpdateCharges)}>
                    <div className="slide-r-title">
                        <h4>Update Charges
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll}>X</span>
                        </h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form
                                className="theme-form"
                                method="POST"
                                id="showUpdateCharges"
                                encType="multipart/form-data"
                                onSubmit={this.onClickUpdateCharges.bind(this)}
                            >
                                <div className="row p-20p">
                                    {/* Dropdown to select charge type */}
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label htmlFor="charge_type" className="form-label">
                                            Select Charge Type
                                        </label>
                                        <select
                                            id="charge_type"
                                            className="form-control"
                                            value={selectedChargeType}
                                            onChange={this.onSelectChargeType.bind(this)}                                            
                                        >
                                            <option value="">-- Select Charge Type --</option>
                                            <option value="crane_charges">Crane Charges</option>
                                            <option value="odc_charges">ODC Charges</option>
                                            <option value="unloading_charges">Unloading Charges</option>
                                            <option value="detention_charges">Detention Charges</option>                                            
                                        </select>
                                    </div>
                                    {/* Render selected charge input dynamically */}
                                    {selectedChargeType && (
                                        <div className="form-group col-sm-12 mb-20p">
                                            <div className="row align-items-center">
                                                {/* Charge Input */}
                                                <div className="col-lg-12 col-12 mb-3">
                                                    <label
                                                        htmlFor={`${selectedChargeType}_input`}
                                                        className="form-label"
                                                    >
                                                        {selectedChargeType
                                                            .replace("_", " ")
                                                            .toUpperCase()}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id={`${selectedChargeType}_input`}
                                                        value={this.state.chargeValues[selectedChargeType] || ""}
                                                        onChange={this.onChargeValueChange.bind(
                                                            this,
                                                            selectedChargeType
                                                        )}
                                                        placeholder={`Enter ${selectedChargeType.replace(
                                                            "_",
                                                            " "
                                                        )}`}
                                                        className="form-control"
                                                    />
                                                </div>
                                                {/* File Upload */}
                                                {/* Comparing for the Entered Values is Different From Actual Value  */}
                                                {(isFileRequired[selectedChargeType] && chargeValues[selectedChargeType]!=this.state[selectedChargeType]) && (
                                                    <div className="col-lg-12 col-12 mb-3">
                                                        <label
                                                            htmlFor={`${selectedChargeType}_file`}
                                                            className="form-label"
                                                        >
                                                            Upload File{" "}
                                                            <span style={{ color: "red" }}>
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id={`${selectedChargeType}_file`}
                                                            accept="image/*,application/pdf"
                                                            onChange={this.onFileUpload.bind(
                                                                this,
                                                                selectedChargeType
                                                            )}
                                                            className="form-control"
                                                            required
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {/* Submit Button */}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.showWhUpdateCharges)}>
                    <div className="slide-r-title">
                        <h4>Update Charges
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll}>X</span>
                        </h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <form className="theme-form" method="POST" id="showWhUpdateCharges" enctype="multipart/form-data" onSubmit={this.onClickUpdateWhCharges.bind(this)}>
                                <div className="row p-20p">
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label className="col-sm-12 mb-20p">Transshipment Charges</label>
                                        <div className="col-sm-12">
                                            <input
                                                type="text"
                                                value={this.state.transhipment_charges}
                                                name="transhipment_charges"
                                                onChange={this.onChangeHandler.bind(this, 'transhipment_charges')}
                                                placeholder="Enter Transshipment charges"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    {/* Crane Charges */}
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label className="col-sm-12 mb-20p">Penalty Charges</label>
                                        <div className="col-sm-12">
                                            <input
                                                type="text"
                                                value={this.state.penalty_charges}
                                                name="penalty_charges"
                                                onChange={this.onChangeHandler.bind(this, 'penalty_charges')}
                                                placeholder="Enter Penalty charges"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    {/* Ware House  Remarks */}
                                    <div className="form-group col-sm-12 mb-20p">
                                        <label className="col-sm-12 mb-20p">Remarks</label>
                                        <div className="col-sm-12">
                                            <textarea
                                                value={this.state.wo_remarks}
                                                name="wo_remarks"
                                                onChange={(event)=>{this.setState({wo_remarks:event.target.value})}}
                                                placeholder="Remarks"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    {/* Submit Button */}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>   
                <div className={"slide-r " + (this.state.chargesItemsList)}>
                    <div className="slide-r-title">
                        <h4>View Charges
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>
                        </h4>
                    </div>
                    <div className="col-sm-12">
                        <div className="slide-r-body">
                            <FileDownloadSection
                                crane_file={this.state.crane_file} // Replace with your actual state or props
                                odc_file={this.state.odc_file} // Replace with your actual state or props
                                detention_file={this.state.detention_file} // Replace with your actual state or props
                                unloading_file={this.state.unloading_file} // Replace with your actual state or props
                                handleFileDownload={this.handleFileDownload} // Replace with your actual method
                            />
                        </div>
                    </div>
                </div>           
            </div>
        );
    }
}
function redirectToConfigurations() {
    window.location.href = "/configurations"
}
function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map11.get(key);
        if (!collection) {
            map11.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map11;
}
function differenceInDays(startDate, endDate) {
	// console.log("startDate, endDate", startDate,endDate);
	startDate = moment.parseZone(startDate);
	endDate = moment.parseZone(endDate);
	// var sDate = startDate.setHours(0, 0, 0, 0);
	// var eDate = endDate.setHours(0, 0, 0, 0);
	// console.log("start",sDate);
	// console.log("end",eDate);
	var res = Math.abs((startDate - endDate) / 1000);
	return Math.floor((res) / 86400);
}

function calculateDateDiff(startDateStr, endDateStr) {
    try {
      // Convert date strings to moment objects
      const startDate = moment(startDateStr, 'DD-MM-YYYY').startOf('day');
      const endDate = moment(endDateStr, 'DD-MM-YYYY');
  
      // Calculate difference in days
      const diffInDays = endDate.diff(startDate, 'days');
  
      return diffInDays;
    } catch (error) {
      console.error("Error calculating date difference:", error);
      return null; 
    }
  }